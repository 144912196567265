import React, { useEffect, useState } from 'react'
import { AlertColor, Box, IconButton, Stack, Tooltip } from '@mui/material'
import ChallengeService from '../../../../../services/challenge.service'
import { ChallengeLevels } from '../../../../../store/Challenge/types'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'

import * as XLSX from 'xlsx'
import { ChallengeLevelImport } from '../../../../../store/Challenge/types'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import { omit } from 'lodash'
import { ImportParameterColumn } from '../../../../../store/Import/types'
import ImportService from '../../../../../services/import.service'
import ImportPrepare from '../../../Import/partials/ImportPrepare'
import ImportAccept from '../../../Import/partials/ImportAccept'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'

const StyledLabel = styled('label')(({ theme }) => ({
  color: theme.colorsPalette.gray.gray6,
  fontWeight: 'bold',
  fontSize: '.75rem',
  display: 'block',
  marginBottom: '0.5em',
}))

type ChallengeImportFormProps = {
  challengeId: number | null
  challengeLevels: ChallengeLevels
  isImportPossible: boolean
}

const ChallengeImportForm: React.FunctionComponent<
  ChallengeImportFormProps
> = ({ challengeId, challengeLevels, isImportPossible }) => {
  const name = 'user_challenge_plans'
  const { t } = useTranslation()
  const [loadingImport, setLoadingImport] = useState<boolean>(false)
  const [helperImportData, setHelperImportData] = useState<
    Partial<ChallengeLevelImport>[]
  >([])

  const [loading, setLoading] = useState<boolean>(true)
  const [importParameters, setImportParameters] = useState<
    ImportParameterColumn[]
  >([])
  const [acceptResponse, setAcceptResponse] = useState<{
    message: {
      type: AlertColor
      message: string
      hasSecondStep: boolean
    }
    additionalInfo?: React.ReactNode
  } | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const importParametersResponse =
          await ImportService.getImportParameters(name)
        if (importParametersResponse.data) {
          setImportParameters(importParametersResponse.data.columns)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, name])

  const downloadXLSX = () => {
    const fileName = `${t('common.form')}.xlsx`

    const headers = [importParameters.map((param) => param.columnName[0])]

    //Had to create a new workbook and then add the header
    const ws: XLSX.WorkSheet = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(ws, headers)

    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, fileName)

    XLSX.writeFile(wb, fileName)
  }

  const exportToExcel = () => {
    const fileName = `${t('pages.challenge.import.exampleFilename')}.xlsx`

    const translatedHeaders = {
      userId: t('excel.challengeImport.userId'),
      userCentralId: t('excel.challengeImport.userCentralId'),
      userInternalId: t('excel.challengeImport.userInternalId'),
      email: t('excel.challengeImport.email'),
      username: t('excel.challengeImport.username'),
      firstname: t('excel.challengeImport.firstname'),
      lastname: t('excel.challengeImport.lastname'),
      companyName: t('excel.challengeImport.companyName'),
      companyShortName: t('excel.challengeImport.companyShortName'),
      regionName: t('excel.challengeImport.regionName'),
      regionShortName: t('excel.challengeImport.regionShortName'),
      amateur: t('excel.challengeImport.amateur'),
      expert: t('excel.challengeImport.expert'),
      master: t('excel.challengeImport.master'),
    }

    const headers = [
      Object.keys(helperImportData[0]).map(
        (key) => (translatedHeaders as any)[key],
      ),
    ]

    //Had to create a new workbook and then add the header
    const ws: XLSX.WorkSheet = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(ws, headers)

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, helperImportData, {
      origin: 'A2',
      skipHeader: true,
    })

    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      t('pages.challenge.import.exampleFilename'),
    )

    XLSX.writeFile(wb, fileName)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (challengeId) {
          setLoadingImport(true)
          const importTemplateResponse =
            await ChallengeService.getChallengeImportTemplate(challengeId)

          if (importTemplateResponse.data.users) {
            const formattedData = importTemplateResponse.data.users.map(
              (user) => {
                return {
                  userId: user.userId,
                  userCentralId: user.userCentralId,
                  userInternalId: user.userInternalId,
                  username: user.username,
                  firstname: user.firstname,
                  lastname: user.lastname,
                  companyName: user.companyName,
                  regionName: user.regionName,
                  amateur: null,
                  expert: null,
                  master: null,
                }
              },
            )
            const levelsToRemove: string[] = []
            if (!challengeLevels.amateur) {
              levelsToRemove.push('amateur')
            }
            if (!challengeLevels.expert) {
              levelsToRemove.push('expert')
            }
            if (!challengeLevels.master) {
              levelsToRemove.push('master')
            }

            setHelperImportData(
              formattedData.map((d) => omit(d, levelsToRemove)),
            )
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoadingImport(false)
      }
    }
    if (challengeId) {
      fetchData()
    }
  }, [t, challengeId, challengeLevels])

  return (
    <>
      {loadingImport && <LoadingSpinner />}
      {!loadingImport && (
        <>
          <Box
            my={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <StyledLabel>{t('pages.challenge.import.title')}</StyledLabel>
            <PrimaryButton
              onClick={exportToExcel}
              size="small"
              disabled={helperImportData.length === 0}
            >
              {t('pages.challenge.import.downloadExampleXLSX')}
            </PrimaryButton>
            <Box sx={{ display: 'flex', color: '#9E9E9E', marginTop: 4 }}>
              <InfoOutlinedIcon sx={{ marginRight: 1 }} />
              <Box sx={{ marginRight: 1 }}>Kolumny:</Box>
              <Box
                id="columnFields"
                sx={{ marginRight: 1, display: 'flex', fontWeight: 'bold' }}
              >
                {importParameters.map((parameter, i) => (
                  <span key={i}>
                    {parameter.columnName[0]}
                    {i < importParameters.length - 1 && <>, </>}{' '}
                  </span>
                ))}
              </Box>
              <Tooltip title={`${t('common.downloadXlsxForm')}`}>
                <IconButton
                  aria-label="copy"
                  size="small"
                  style={{ padding: 0, marginRight: '5px' }}
                  onClick={downloadXLSX}
                >
                  <DownloadIcon sx={{ width: '20px' }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {loading && <LoadingSpinner />}
          {!loading && (
            <>
              <Box>
                <ImportPrepare
                  importParameters={importParameters}
                  importName={name}
                  handleSetAcceptResponse={setAcceptResponse}
                  additionalParams={{
                    challengeId,
                  }}
                />
              </Box>

              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                width="100%"
                className="buttons-container"
              >
                <Stack spacing={2} direction="row">
                  <ImportAccept
                    importName={name}
                    acceptResponse={acceptResponse}
                    handleSetAcceptResponse={setAcceptResponse}
                  />
                </Stack>
              </Stack>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ChallengeImportForm
