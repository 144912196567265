import React, { FunctionComponent, useEffect, useState } from 'react'
import { Box, Grid, List, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ChallengeService from '../../../../services/challenge.service'
import CompanyService from '../../../../services/company.service'
import ImageService from '../../../../services/image.service'
import ManufacturerService from '../../../../services/manufacturer.service'
import PeriodService from '../../../../services/period.service'
import {
  ChallengeDetails,
  ChallengeUnit,
} from '../../../../store/Challenge/types'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { isSuperAdmin, isCentralAdmin } from '../../../../helpers/checkRole'
import { useSelector, RootStateOrAny } from 'react-redux'
import { ImageContent } from '../../../../store/Image/types'
import { Company } from '../../../../store/Company/types'
import { Manufacturer } from '../../../../store/Manufacturer/types'
import { StepContainer } from './styles'
import BottomBar from '../partials/BottomBar'
import AmateurImg from '../../../../assets/images/other/amateur.png'
import ExpertImg from '../../../../assets/images/other/expert.png'
import MasterImg from '../../../../assets/images/other/master.png'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import { PeriodDetails } from '../../../../store/Period/types'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type ChallengeShowProps = {}
type ChallengeParams = {
  id: string
}

const ChallengeShow: FunctionComponent<ChallengeShowProps> = () => {
  let { id } = useParams<ChallengeParams>()
  const { t } = useTranslation()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const [challengeDetails, setChallengeDetails] =
    useState<ChallengeDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [image, setImage] = useState<ImageContent | null>(null)
  const [unit, setUnit] = useState<ChallengeUnit | null>(null)
  const [companies, setCompanies] = useState<Company[]>([])
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([])
  const [periodDetails, setPeriodDetails] = useState<PeriodDetails | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const challengeDetailsResponse =
          await ChallengeService.getChallengeDetails(parseInt(id))
        if (challengeDetailsResponse.data) {
          setChallengeDetails(challengeDetailsResponse.data)

          if (isSuperAdmin(currentUser) || isCentralAdmin(currentUser)) {
            const companyListResponse = await CompanyService.getCompanyList()

            if (
              companyListResponse.data.companies &&
              challengeDetailsResponse.data.companies
            ) {
              const selectedCompanies: Company[] = []
              challengeDetailsResponse.data.companies.forEach((company) => {
                const foundCompany = companyListResponse.data.companies.find(
                  (c) => c.companyId === company.id,
                )
                if (foundCompany) {
                  selectedCompanies.push(foundCompany)
                }
              })
              setCompanies(selectedCompanies)
            }
          }
          const manufacturerListResponse =
            await ManufacturerService.getManufacturerList()

          if (manufacturerListResponse.data.manufacturers) {
            setManufacturers(manufacturerListResponse.data.manufacturers)
          }

          if (challengeDetailsResponse.data.periodId) {
            const periodResponse = await PeriodService.getPeriodDetails(
              challengeDetailsResponse.data.periodId,
            )
            setPeriodDetails(periodResponse.data)
          }
          if (challengeDetailsResponse.data.imageId) {
            const imageResponse = await ImageService.getImageContent(
              challengeDetailsResponse.data.imageId,
            )

            if (imageResponse) {
              setImage(imageResponse.data)
            }
          }
          if (challengeDetailsResponse.data.unitId) {
            const unitListResponse =
              await ChallengeService.getChallengeUnitList()

            if (unitListResponse.data.challengeUnits) {
              setUnit(
                unitListResponse.data.challengeUnits.find(
                  (unit) => unit.id === challengeDetailsResponse.data.unitId,
                ) || null,
              )
            }
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, currentUser, t])

  if (!challengeDetails) return null

  return (
    <Box>
      <Box sx={{ maxWidth: 800, margin: '0 auto 100px auto' }}>
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            {/* Step 1 */}
            <StepContainer>
              <Stack direction={'row'}>
                <div className="step-line first">
                  <div className="step-circle">1</div>
                  <div className="step-arrow first"></div>
                </div>
                <Box className="show-container">
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12}>
                      <div className="step-title">
                        {t('pages.challenge.step1Show.title')}
                      </div>
                      <div className="step-subtitle">
                        {t('pages.challenge.step1Show.subtitle')}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={4}>
                      <div className="detail_label">
                        {t('pages.challenge.step1Show.id')}:
                      </div>
                      <div className="detail_txt">
                        {challengeDetails.challengeId}
                      </div>
                    </Grid>

                    <Grid item xs={8}>
                      <div className="detail_label">
                        {t('pages.challenge.step1Show.name')}:
                      </div>
                      <div className="detail_txt">{challengeDetails.name}</div>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginBottom: 4 }}>
                    {periodDetails !== null && (
                      <Grid item xs={4}>
                        <div className="detail_label">
                          {t('pages.challenge.step1Show.period')}:
                        </div>
                        <div className="detail_txt">{periodDetails.name}</div>
                      </Grid>
                    )}

                    <Grid item xs={8}>
                      {(isSuperAdmin(currentUser) ||
                        isCentralAdmin(currentUser)) &&
                        companies && (
                          <>
                            <strong>
                              {t('pages.challenge.step1Show.companies')}:
                            </strong>
                            <List
                              sx={{
                                width: '100%',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 200,
                                padding: 0,
                                '& ul': { padding: 0 },
                              }}
                            >
                              <ul>
                                {companies.map((company) => (
                                  <li>{company.name}</li>
                                ))}
                              </ul>
                            </List>
                          </>
                        )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                    <Grid item md={3}>
                      <div className="detail_label">
                        {t('pages.challenge.step1Show.availableFrom')}:
                      </div>
                      <div className="detail_txt">
                        {challengeDetails.availableFrom}
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div className="detail_label">
                        {t('pages.challenge.step1Show.availableTo')}:
                      </div>
                      <div className="detail_txt">
                        {challengeDetails.availableTo}
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div className="detail_label">
                        {t('pages.challenge.step1Show.validFrom')}:
                      </div>
                      <div className="detail_txt">
                        {challengeDetails.validFrom}
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div className="detail_label">
                        {t('pages.challenge.step1Show.validTo')}:
                      </div>
                      <div className="detail_txt">
                        {challengeDetails.validTo}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </StepContainer>

            {/* Step 2 */}
            <StepContainer>
              <Stack direction={'row'}>
                <div className="step-line first">
                  <div className="step-circle">2</div>
                  <div className="step-arrow first"></div>
                </div>
                <Box className="show-container">
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12}>
                      <div className="step-title">
                        {t('pages.challenge.step2Show.title')}
                      </div>
                      <div className="step-subtitle">
                        {t('pages.challenge.step2Show.subtitle')}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item md={12}>
                      {challengeDetails.description && (
                        <>
                          <div className="detail_label">
                            {t('pages.challenge.step2Show.description')}:
                          </div>
                          <div
                            className="detail_txt"
                            dangerouslySetInnerHTML={{
                              __html: challengeDetails.description,
                            }}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item md={12}>
                      <div className="detail_label">
                        {t('pages.challenge.step2Show.image')}:
                      </div>
                      <div className="detail_img">
                        {image && (
                          <img
                            src={`data:${image.imageMimeType};base64,${image.imageContent}`}
                            alt={image.name}
                            className="detail_img--image"
                          />
                        )}
                      </div>
                    </Grid>
                    <br />
                  </Grid>
                </Box>
              </Stack>
            </StepContainer>

            {/* Step 3 */}
            <StepContainer>
              <Stack direction={'row'}>
                <div className="step-line first">
                  <div className="step-circle">3</div>
                  <div className="step-arrow first"></div>
                </div>
                <Box className="show-container">
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12}>
                      <div className="step-title">
                        {t('pages.challenge.step3Show.title')}
                      </div>
                      <div className="step-subtitle">
                        {t('pages.challenge.step3Show.subtitle')}
                      </div>
                    </Grid>
                  </Grid>
                  {unit && (
                    <>
                      <Grid container sx={{ marginBottom: 4 }}>
                        <Grid item sm={12}>
                          <div className="detail_label">
                            {t('pages.challenge.step3Show.unit')}:
                          </div>
                          <div className="detail_txt">{unit.name}</div>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginBottom: 4 }}>
                        <Grid item xs={12}>
                          <div className="detail_label">
                            {t('pages.challenge.step3Show.description')}:
                          </div>
                          <div className="detail_txt">{unit.description}</div>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginBottom: 4 }}>
                        {['SM', 'AV'].includes(unit.code) && (
                          <>
                            <Grid item xs={12}>
                              <ul>
                                {challengeDetails.manufacturers.map(
                                  (manufacturer) => (
                                    <li>
                                      {manufacturers.map((m) => (
                                        <span key={m.manufacturerId}>
                                          {m.manufacturerId ===
                                            manufacturer.manufacturerId && (
                                            <span>
                                              <strong>
                                                {t(
                                                  'pages.challenge.step3Show.manufacturers.label',
                                                )}
                                                :{' '}
                                              </strong>
                                              {m.name},{' '}
                                            </span>
                                          )}
                                        </span>
                                      ))}
                                      <strong>
                                        {t(
                                          'pages.challenge.step3Show.manufacturers.requiredValue',
                                        )}
                                        :
                                      </strong>{' '}
                                      {manufacturer.requiredAmount}.
                                    </li>
                                  ),
                                )}
                              </ul>
                            </Grid>
                          </>
                        )}
                        {['SP', 'QV'].includes(unit.code) && (
                          <>
                            <Grid item xs={12}>
                              <ul>
                                {challengeDetails.products.map((product) => (
                                  <li>
                                    <strong>
                                      {t(
                                        'pages.challenge.step3Show.products.ean',
                                      )}
                                      :
                                    </strong>{' '}
                                    {product.productCode},{' '}
                                    <strong>
                                      {t(
                                        'pages.challenge.step3Show.products.productName',
                                      )}
                                      :
                                    </strong>{' '}
                                    {product.productName},{' '}
                                    <strong>
                                      {t(
                                        'pages.challenge.step3Show.products.requiredValue',
                                      )}
                                      :
                                    </strong>{' '}
                                    {product.requiredAmount}.
                                  </li>
                                ))}
                              </ul>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid container sx={{ marginBottom: 4 }}>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                          <div
                            className="detail_label"
                            style={{ marginRight: 4 }}
                          >
                            {t('pages.challenge.step3Show.minimalTotalAmount')}:
                          </div>
                          <div className="detail_txt">
                            {challengeDetails.minimalTotalAmount}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              </Stack>
            </StepContainer>

            {/* Step 4 */}
            <StepContainer>
              <Stack direction={'row'}>
                <div className="step-line first">
                  <div className="step-circle">4</div>
                  <div className="step-arrow first"></div>
                </div>
                <Box className="show-container">
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12}>
                      <div className="step-title">
                        {t('pages.challenge.step4Show.title')}
                      </div>
                      <div className="step-subtitle">
                        {t('pages.challenge.step4Show.subtitle')}
                      </div>
                    </Grid>
                  </Grid>

                  {challengeDetails.amateur && (
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 3,
                      }}
                    >
                      <Grid item xs={4}>
                        <img style={{ width: 130 }} src={AmateurImg} alt="" />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid container marginBottom={2}>
                          <Grid container>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <ThumbUpOffAltIcon />
                            </Grid>
                            <Grid item xs={11}>
                              <Grid container spacing={1}>
                                {isXpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.xp`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.amateurXpPlus}
                                    </span>
                                  </Grid>
                                )}
                                {isVpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.vp`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.amateurVpPlus}
                                    </span>
                                  </Grid>
                                )}
                                {isEpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.ep`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.amateurEpPlus}
                                    </span>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {challengeDetails.countPenalty && (
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ThumbDownOffAltIcon color="error" />
                              </Grid>
                              <Grid item xs={11}>
                                <Grid container spacing={1}>
                                  {isXpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.xp`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {' '}
                                        {challengeDetails.amateurXpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                  {isVpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.vp`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {' '}
                                        {challengeDetails.amateurVpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                  {isEpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.ep`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {' '}
                                        {challengeDetails.amateurEpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {challengeDetails.expert && (
                    <Grid
                      container
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 3,
                      }}
                    >
                      <Grid item xs={4}>
                        <img style={{ width: 130 }} src={ExpertImg} alt="" />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid container marginBottom={2}>
                          <Grid container>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <ThumbUpOffAltIcon />
                            </Grid>
                            <Grid item xs={11}>
                              <Grid container spacing={1}>
                                {isXpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.xp`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.expertXpPlus}
                                    </span>
                                  </Grid>
                                )}
                                {isVpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.vp`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.expertVpPlus}
                                    </span>
                                  </Grid>
                                )}
                                {isEpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.ep`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.expertEpPlus}
                                    </span>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {challengeDetails.countPenalty && (
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ThumbDownOffAltIcon color="error" />
                              </Grid>
                              <Grid item xs={11}>
                                <Grid container spacing={1}>
                                  {isXpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.xp`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {' '}
                                        {challengeDetails.expertXpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                  {isVpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.vp`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {' '}
                                        {challengeDetails.expertVpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                  {isEpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.ep`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {' '}
                                        {challengeDetails.expertEpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {challengeDetails.master && (
                    <Grid
                      container
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid item xs={4}>
                        <img style={{ width: 130 }} src={MasterImg} alt="" />
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'column',
                        }}
                      >
                        <Grid container marginBottom={2}>
                          <Grid container>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <ThumbUpOffAltIcon />
                            </Grid>
                            <Grid item xs={11}>
                              <Grid container spacing={1}>
                                {isXpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.xp`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.masterXpPlus}
                                    </span>
                                  </Grid>
                                )}
                                {isVpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.vp`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.masterVpPlus}
                                    </span>
                                  </Grid>
                                )}
                                {isEpPointsVisible() && (
                                  <Grid item xs={4}>
                                    <span className="detail_label">
                                      {t(`pages.challenge.step4Show.ep`)}:
                                    </span>
                                    <span className="detail_txt">
                                      {' '}
                                      {challengeDetails.masterEpPlus}
                                    </span>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {challengeDetails.countPenalty && (
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <ThumbDownOffAltIcon color="error" />
                              </Grid>
                              <Grid item xs={11}>
                                <Grid container spacing={1}>
                                  {isXpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.xp`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {challengeDetails.masterXpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                  {isVpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.vp`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {challengeDetails.masterVpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                  {isEpPointsVisible() && (
                                    <Grid item xs={4}>
                                      <span className="detail_label">
                                        {t(`pages.challenge.step4Show.ep`)}:
                                      </span>
                                      <span className="detail_txt">
                                        {challengeDetails.masterEpMinus}
                                      </span>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Stack>
            </StepContainer>
          </>
        )}
      </Box>
      <BottomBar
        challengeId={null}
        challengeDetails={null}
        setIsSaveDisabled={() => {}}
        refreshTimer={false}
        setRefreshTimer={() => {}}
      />
    </Box>
  )
}

export default ChallengeShow
