import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import './App.css'
import './locales/i18n'

import Login from './components/Pages/Login/Login'

import { clearMessage } from './actions/message'
import { history } from './helpers/history'
import Header from './components/Pages/shared/Header'
import Navigation from './components/Pages/shared/Navigation'
import { checkAuth } from './helpers/checkAuth'
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/system'
import Challenges from './components/Pages/Challenges/Challenges'
import Home from './components/Pages/Home/Home'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Stores from './components/Pages/Stores/Stores'
import PasswordReset from './components/Pages/PasswordReset/PasswordReset'
import Users from './components/Pages/Users/Users'
import PeriodPlans from './components/Pages/PeriodPlans/PeriodPlans'
import Periods from './components/Pages/Periods/Periods'
import Edubox from './components/Pages/Edubox/Edubox'
import Import from './components/Pages/Import/Import'
import Game from './components/Pages/Game/Game'
import TradeActions from './components/Pages/TradeActions/TradeActions'
import Images from './components/Pages/Images/Images'
import Plans from './components/Pages/Plans/Plans'
import Companies from './components/Pages/Companies/Companies'
import { ApplicationModule } from './store/Config/types'
import ChallengesUsers from './components/Pages/ChallengesUsers/ChallengesUsers'
import Configuration from './components/Pages/Configuration/Configuration'
import UsersPlans from './components/Pages/UsersPlans/UsersPlans'
import UsersPlansUpdate from './components/Pages/UsersPlansUpdate/UsersPlansUpdate'
import ConfigService from './services/config.service'
import { errorHandler } from './helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import StoresPlans from './components/Pages/StoresPlans/StoresPlans'
import Orders from './components/Pages/Orders/Orders'
import Products from './components/Pages/Products/Products'
import Manufacturers from './components/Pages/Manufacturers/Manufacturers'
import ChallengesStores from './components/Pages/ChallengesStores/ChallengesStores'

const RootDiv = styled('div')({
  display: 'flex',
})

const MainContent = styled('main')({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
})

const App = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [applicationModules, setApplicationModules] = React.useState<
    ApplicationModule[]
  >([])
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const [loading, setLoading] = React.useState<boolean>(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    history.listen(() => {
      dispatch(clearMessage())
    })
  }, [dispatch])

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const applicationModulesResponse =
          await ConfigService.getApplicationModules()
        if (applicationModulesResponse.data) {
          setApplicationModules(applicationModulesResponse.data.modules)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (isLoggedIn) {
      fetchData()
    }
  }, [t, isLoggedIn])

  return (
    <RootDiv>
      <CssBaseline />
      {currentUser && isLoggedIn && !loading && (
        <>
          <Header
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            currentUser={currentUser}
            applicationModules={applicationModules}
          />
          <Navigation
            open={open}
            handleDrawerClose={handleDrawerClose}
            currentUser={currentUser}
            applicationModules={applicationModules}
          />
        </>
      )}
      <MainContent>
        <Router history={history}>
          <div style={{ height: '100px' }} />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/password/reset/:token"
              component={PasswordReset}
            />
            <Route exact path={['/', '/home']} component={Home} />
            <Route
              exact
              path={[
                '/challenges-users/:challengeId/period/:periodId/status/:statusCode',
                '/challenges-users',
              ]}
              component={ChallengesUsers}
            />
            <Route path={['/challenges-stores']} component={ChallengesStores} />
            <Route path={['/challenges']} component={Challenges} />
            <Route path={['/period-plans']} component={PeriodPlans} />
            <Route path={['/stores']} component={Stores} />
            <Route path={['/game']} component={Game} />
            <Route path={['/users']} component={Users} />
            <Route path={['/periods']} component={Periods} />
            <Route path={['/edubox']} component={Edubox} />
            <Route path={['/import/:name/:param1?']} component={Import} />
            <Route path={['/actions']} component={TradeActions} />
            <Route path={['/images']} component={Images} />
            <Route exact path={['/plans/users']} component={UsersPlans} />
            <Route exact path={['/plans/stores']} component={StoresPlans} />
            <Route path={['/user-plans']} component={UsersPlansUpdate} />
            <Route path={['/plans-manage']} component={Plans} />
            <Route path={['/configuration']} component={Configuration} />
            <Route path={['/companies']} component={Companies} />
            <Route path={['/orders']} component={Orders} />
            <Route path={['/products']} component={Products} />
            <Route path={['/manufacturers']} component={Manufacturers} />
            <Redirect to="/login" />
          </Switch>
        </Router>
        {currentUser && (
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{ whiteSpace: 'pre-line' }}
          />
        )}
      </MainContent>
    </RootDiv>
  )
}

export default App
