import React, { useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'

type ImageResizerProps = {
  imageToResize: File
  onImageResized: (
    value: React.SetStateAction<
      string | File | Blob | ProgressEvent<FileReader>
    >,
  ) => void
  maxWidth: number
  maxHeight: number
  compressFormat?: string
  resizeQuality?: number
}

const ImageResizer: React.FC<ImageResizerProps> = ({
  imageToResize,
  onImageResized,
  maxWidth,
  maxHeight,
  compressFormat = 'JPEG',
  resizeQuality = 100,
}) => {
  const [imageToResizeUri, setImageToResizeUri] = useState<
    string | ArrayBuffer | null
  >()
  const [imageToResizeWidth, setImageToResizeWidth] = useState<number>()
  const [imageToResizeHeight, setImageToResizeHeight] = useState<number>()

  useEffect(() => {
    if (imageToResize) {
      const reader = new FileReader()

      reader.addEventListener('load', () => setImageToResizeUri(reader.result))

      reader.readAsDataURL(imageToResize)
    }
  }, [imageToResize])

  useEffect(() => {
    if (imageToResize && imageToResizeWidth && imageToResizeHeight) {
      Resizer.imageFileResizer(
        imageToResize,
        maxWidth,
        maxHeight,
        compressFormat,
        resizeQuality,
        0,
        (uri) => {
          onImageResized(uri)
        },
        'base64',
      )
    }
  }, [
    imageToResize,
    imageToResizeWidth,
    imageToResizeHeight,
    onImageResized,
    resizeQuality,
    compressFormat,
    maxWidth,
    maxHeight,
  ])

  return imageToResizeUri ? (
    <img
      src={imageToResizeUri.toString()}
      alt=""
      onLoad={(e) => {
        const img = e.target as HTMLImageElement
        setImageToResizeWidth(img.width)
        setImageToResizeHeight(img.height)
      }}
      crossOrigin="anonymous" // to avoid CORS-related problems
      style={{
        display: 'none',
      }}
    />
  ) : (
    <></>
  )
}

export default ImageResizer
