import axios from 'axios'
import { CompanyListReponse, CompanyListResponse } from '../store/Company/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getCompanyList = (): Promise<CompanyListReponse> => {
  return axios.get(API_URL + '/api/admin/company', {
    headers: authHeader(),
  })
}

const getPaginatedCompanyList = async (
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  regions: { id: number }[],
): Promise<CompanyListResponse> => {
  return axios.post(
    API_URL + '/api/admin/company/list',
    {
      search,
      sortBy,
      sortOrder,
      per,
      page,
      regions,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getCompanyList,
  getPaginatedCompanyList,
}

export default exportedObject
