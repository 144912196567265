import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  Grid,
  IconButton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import ClearIcon from '@mui/icons-material/Clear'
import PlanService from '../../../../services/plan.service'
import { Plan } from '../../../../store/Plan/types'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type PlanDetailsDialogProps = {
  planId: number
  open: boolean
  handleClose: (refresh: boolean) => void
}

const PlanDetailsDialog: React.FunctionComponent<PlanDetailsDialogProps> = ({
  planId,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [planDetails, setPlanDetails] = useState<Plan | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const planDetailsResponse = await PlanService.getPlanDetails(planId)
        setPlanDetails(planDetailsResponse.data)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, planId])

  const renderUserDetailsRow = (
    label: string,
    value: JSX.Element | JSX.Element[] | string,
  ) => {
    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={4} textAlign="right">
          <Typography
            variant="body2"
            gutterBottom
            fontWeight="bold"
            style={{ wordBreak: 'break-all' }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" gutterBottom>
            {value}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleClose(false)}
    >
      {loading && <LoadingSpinner />}
      {!loading && planDetails && (
        <>
          <DialogTitle style={{ height: '90px' }}>
            <Stack flexDirection="row">
              <Stack justifyContent="center" style={{ marginLeft: '15px' }}>
                <Typography variant="caption">
                  {t('pages.plans.planDetailsDialog.planId')}: {planId}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  component="div"
                >
                  {planDetails.name}
                </Typography>
              </Stack>
              <IconButton
                style={{ marginLeft: 'auto', height: '50px', width: '50px' }}
                onClick={() => handleClose(false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {renderUserDetailsRow(
                t('pages.plans.planDetailsDialog.periodName'),
                planDetails.periodName,
              )}
              {renderUserDetailsRow(
                t('pages.plans.planDetailsDialog.validFrom'),
                planDetails.validFrom,
              )}
              {renderUserDetailsRow(
                t('pages.plans.planDetailsDialog.validTo'),
                planDetails.validTo,
              )}
              {isXpPointsVisible() &&
                renderUserDetailsRow(
                  t('pages.plans.planDetailsDialog.xpStrategyDescription'),
                  planDetails.xpStrategyDescription
                    ? planDetails.xpStrategyDescription
                    : '',
                )}
              {isVpPointsVisible() &&
                renderUserDetailsRow(
                  t('pages.plans.planDetailsDialog.xpStrategyDescription'),
                  planDetails.vpStrategyDescription
                    ? planDetails.vpStrategyDescription
                    : '',
                )}
              {isEpPointsVisible() &&
                renderUserDetailsRow(
                  t('pages.plans.planDetailsDialog.epStrategyDescription'),
                  planDetails.epStrategyDescription
                    ? planDetails.epStrategyDescription
                    : '',
                )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack flexDirection="row" justifyContent="flex-end" width="100%">
              <SecondaryButton onClick={() => handleClose(false)}>
                {t('common.close')}
              </SecondaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default PlanDetailsDialog
