import { styled } from '@mui/material/styles'

export const StepContainer = styled('div')(({ theme }) => ({
  '.step-line': {
    borderLeft: '4px solid #373739',
    position: 'relative',
    width: 40,
    paddingTop: 40,
    '&.first': {
      marginTop: 40,
      paddingTop: 0,
    },
  },
  '.step-circle': {
    borderRadius: '50%',
    width: 40,
    height: 40,
    backgroundColor: '#373739',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    position: 'absolute',
    left: -22,
    color: '#fff',
    '&.inactive': {
      backgroundColor: '#9E9E9E',
    },
  },
  '.step-arrow': {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 0 15px 20px',
    borderColor: 'transparent transparent transparent #373739',
    position: 'absolute',
    left: 5,
    top: 45,
    '&.inactive': {
      borderColor: 'transparent transparent transparent #9E9E9E',
    },
    '&.first': {
      top: 5,
    },
  },
  '.show-container': {
    width: '100%',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
    backgroundColor: theme.colorsPalette.gray.gray1,
    '.step-title': {
      fontSize: 20,
      fontWeight: 'bolder',
    },
    '.step-subtitle': {
      fontSize: 12,
      fontWeight: 'bolder',
    },
    '.detail': {
      '&_label': {
        fontWeight: 'bold',
      },
      '&_img': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colorsPalette.gray.gray3,
        borderRadius: 5,
        '&--logo': {
          width: '100%',
          maxWidth: '250px',
        },
        '&--image': {
          width: '100%',
          maxWidth: '400px',
        },
      },
    },
  },
}))
