import React, { FunctionComponent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import BasicInformation from '../partials/Steps/BasicInformation'
import Quiz from '../partials/Steps/Quiz'
import Questions from '../partials/Steps/Questions'
import BottomBar from '../partials/BottomBar'
import { AssetDetails } from '../../../../store/Edubox/types'
import EduboxService from '../../../../services/edubox.service'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useTranslation } from 'react-i18next'

type QuizCreateProps = {}

export type Block = {
  id: number | null
  name: string
  visibility: boolean
}

const QuizCreate: FunctionComponent<QuizCreateProps> = () => {
  const { t } = useTranslation()
  const [assetId, setAssetId] = useState<number | null>(null)
  const [quizId, setQuizId] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [assetDetails, setAssetDetails] = useState<AssetDetails | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const tradeActionDetailsResponse = await EduboxService.getAssetDetails(
          assetId!,
        )
        if (tradeActionDetailsResponse.data) {
          setAssetDetails(tradeActionDetailsResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (assetId) {
      fetchData()
    }
  }, [assetId, t])

  return (
    <Box>
      <Box sx={{ maxWidth: 800, margin: '0 auto 100px auto' }}>
        <BasicInformation setAssetId={setAssetId} assetId={assetId} />
        <Quiz assetId={assetId} assetDetails={assetDetails} isLoading={loading} setQuizId={setQuizId} />
        <Questions assetId={assetId} assetDetails={assetDetails} quizId={quizId} />
      </Box>
      <BottomBar />
    </Box>
  )
}

export default QuizCreate
