import React from 'react'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import {
  ChallengeDetails,
  ChallengeLevels,
} from '../../../../../store/Challenge/types'
import { useTranslation } from 'react-i18next'
import ChallengeImportForm from './ChallengeImportForm'
import { StepContainer } from './styles'
import { grey } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'

type ChallengeStep5FormProps = {
  challengeId: number | null
  challengeDetails?: ChallengeDetails
  isStep4Saved: boolean
  challengeLevels: ChallengeLevels | null
  isSaveDisabled: boolean
}

const ChallengeStep5Form: React.FunctionComponent<ChallengeStep5FormProps> = ({
  challengeId,
  challengeDetails,
  isStep4Saved,
  challengeLevels,
  isSaveDisabled,
}) => {
  const { t } = useTranslation()
  const isImportPossible = (): boolean => {
    if (challengeDetails) {
      return (
        challengeDetails.isEditable &&
        (challengeDetails.amateur ||
          challengeDetails.expert ||
          challengeDetails.master) &&
        challengeDetails.unitId !== null
      )
    }
    return false
  }

  if (!challengeId || !isStep4Saved) {
    return (
      <StepContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">5</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography variant="subtitle2" fontWeight={'bold'} component="div">
              {t('pages.challenge.step5.title')}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              dangerouslySetInnerHTML={{
                __html: t('pages.challenge.step5.subtitle'),
              }}
            ></Typography>
            <IconButton
              aria-label="delete"
              disabled
              size="large"
              className="add-button"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      </StepContainer>
    )
  }

  return (
    <StepContainer>
      <Stack direction={'row'}>
        <div className="step-line first">
          <div className="step-circle">5</div>
          <div className="step-arrow first"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <div className="step-title">
                {t('pages.challenge.step5.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.challenge.step5.subtitle')}
              </div>
            </Grid>
          </Grid>
          {!isSaveDisabled && (
            <>
              <ChallengeImportForm
                challengeId={challengeId}
                challengeLevels={{
                  amateur: challengeLevels?.amateur || false,
                  expert: challengeLevels?.expert || false,
                  master: challengeLevels?.master || false,
                }}
                isImportPossible={isImportPossible()}
              />
            </>
          )}
          {isSaveDisabled && <>{t('pages.challenge.step5.stepDisabled')}</>}
        </Box>
      </Stack>
    </StepContainer>
  )
}

export default ChallengeStep5Form
