import { useEffect, useState } from 'react'
import { PhoneAppBarDetails, PhoneCard, PhoneContent } from '../styles'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import GameService from '../../../services/game.service'
import TradeActionService from '../../../services/tradeAction.service'
import { TradeAction } from '../../../store/Game/types'
import { Box, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material'
import LoadingSpinner from '../../shared/LoadingSpinner'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import BlockContent from '../partials/BlockContent'
import Image from '../../shared/Image'
import { ReactComponent as RulesIcon } from '../../../assets/images/phone/rules_icon.svg'
import { RulesFile } from '../../../store/TradeAction/types'
import DownloadIcon from '@mui/icons-material/Download'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

type ActionDetailsScreenProps = {
  actionId: number | null
  userId: number
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>
}

const ActionDetailsScreen: React.FunctionComponent<
  ActionDetailsScreenProps
> = ({ actionId, userId, setCurrentScreen }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tradeActionDetails, setTradeActionDetails] =
    useState<TradeAction | null>(null)
  const [tabValue, setTabValue] = useState<number>(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const tabStyle = {
    color: '#FBEBA7',
    textTransform: 'none',
    '&.Mui-selected': {
      color: '#FFD748',
    },
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const tradeActionsListResponse =
          await GameService.getTradeActionDetails(actionId!)
        setTradeActionDetails(tradeActionsListResponse.data)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (actionId) {
      fetchData()
      setTabValue(0)
    }
  }, [t, actionId])

  const getFile = async (
    file: Pick<
      RulesFile,
      'fileId' | 'fileName' | 'mimeType' | 'size' | 'description'
    >,
  ) => {
    try {
      if (file.fileId) {
        const fileResponse = await TradeActionService.getFileContent(
          file.fileId,
        )
        const linkSource = `data:${file.mimeType};base64,${fileResponse.data.fileContent}`
        const downloadLink = document.createElement('a')
        const fileName = file.fileName

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
    } catch (error) {
      errorHandler(error, t)
    }
  }

  const renderRulesCard = (action: TradeAction) => {
    return (
      <PhoneCard style={{ marginTop: '16px' }}>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="20px"
          alignItems="center"
          fontWeight="700"
          marginBottom="20px"
        >
          <RulesIcon style={{ marginRight: 5, fill: '#2D2A2B' }} />
          <div>{t('pages.game.phone.screens.actionsScreen.rules')}</div>
        </Stack>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Image
            imageId={action.rulesImageId}
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
        <Stack fontSize="13px" textAlign="left">
          <div
            dangerouslySetInnerHTML={{
              __html: action.rules,
            }}
          ></div>
        </Stack>
      </PhoneCard>
    )
  }

  const renderFilesCard = (
    files: Pick<
      RulesFile,
      'fileId' | 'fileName' | 'mimeType' | 'size' | 'description'
    >[],
  ) => {
    return (
      <PhoneCard>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="20px"
          alignItems="center"
          fontWeight="700"
          marginBottom="20px"
        >
          <DownloadIcon style={{ marginRight: 5, fill: '#2D2A2B' }} />
          <div>
            {t('pages.game.phone.screens.actionsScreen.filesToDownload')}
          </div>
        </Stack>
        {files.length === 0 && (
          <Stack component="div" fontSize="14px">
            {t('pages.game.phone.screens.actionsScreen.noFiles')}
          </Stack>
        )}
        {files.length > 0 && (
          <div>
            {files.map((file, i) => (
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="4px 8px"
                justifyContent="space-between"
                marginBottom={files.length - 1 === i ? '0' : '16px'}
                style={{ backgroundColor: '#F7F7F7', borderRadius: '4px' }}
              >
                <Stack
                  component="div"
                  fontSize="14px"
                  fontWeight="bold"
                  textAlign="left"
                >
                  {file.fileName}
                </Stack>
                <IconButton size="small" onClick={() => getFile(file)}>
                  <DownloadIcon style={{ width: '18px', height: '18px' }} />
                </IconButton>
              </Stack>
            ))}
          </div>
        )}
      </PhoneCard>
    )
  }

  return (
    <>
      <PhoneAppBarDetails>
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          px="8px"
        >
          <IconButton onClick={() => setCurrentScreen('actions')}>
            <ArrowBackIcon htmlColor="#FFD748" />
          </IconButton>
          {tradeActionDetails ? tradeActionDetails.name : ' '}
          <Stack width={'40px'}></Stack>
        </Stack>
      </PhoneAppBarDetails>
      {loading && (
        <PhoneContent>
          <LoadingSpinner />
        </PhoneContent>
      )}
      {!loading && tradeActionDetails && (
        <>
          <Box sx={{ bgcolor: '#2D2A2B' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                justifyContent: 'space-around',
                '& .MuiTabs-indicator': {
                  backgroundColor: '#FFD748',
                },
              }}
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label={'Zasady'} sx={tabStyle} />
              {tradeActionDetails.actionBlocks.map((block) => (
                <Tab
                  key={`tab-${block.blockId}`}
                  label={block.blockName}
                  sx={tabStyle}
                />
              ))}
            </Tabs>
          </Box>
          <PhoneContent
            style={{
              height: 'calc(590px - 48px)',
            }}
          >
            <TabPanel value={tabValue} index={0}>
              {renderRulesCard(tradeActionDetails)}
              {renderFilesCard(tradeActionDetails.files)}
            </TabPanel>
            {tradeActionDetails.actionBlocks.map((block, index) => (
              <TabPanel
                value={tabValue}
                index={index + 1}
                key={`tabpanel-${block.blockId}`}
              >
                <BlockContent blockId={block.blockId} userId={userId} />
              </TabPanel>
            ))}
          </PhoneContent>
        </>
      )}
    </>
  )
}

export default ActionDetailsScreen
