import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { ImportTradeActionResponse } from '../../../../../store/Import/types'

export const getTradeActionsResponse = (
  importResponse: ImportTradeActionResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (
    (importResponse.data.addedResults ||
      importResponse.data.addedResults === 0) &&
    (importResponse.data.skippedResults ||
      importResponse.data.skippedResults === 0)
  ) {
    const addedResults = importResponse.data.addedResults
    const skippedResults = importResponse.data.skippedResults

    return {
      message: {
        type: alertType,
        message: t(`import.messages.tradeActions.${messageType}Message`, {
          addedResults: addedResults,
          skippedResults: skippedResults,
        }),
        hasSecondStep: false,
      },
    }
  }
  return null
}
