import React, { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logout, refreshToken } from '../../../actions/auth'
import { useTranslation } from 'react-i18next'
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { styled } from '@mui/material/styles'
import Logo from '../../../../src/assets/images/logos/logo.png'
import { User } from '../../../store/Auth/types'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PrimaryButton from '../../../styles/Buttons/PrimaryButton'
import Messenger from '../../Messenger/Messenger'
import { ApplicationModule } from '../../../store/Config/types'
import { isModuleVisible } from '../../../helpers/utils'

const drawerWidth = 240
let logoutInterval: NodeJS.Timeout
let idleTimer: NodeJS.Timeout
let isUserInactive: boolean = false

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background:
    'linear-gradient(90deg, rgba(30,28,29,1) 0%, rgba(104,103,104,1) 50%, rgba(30,28,29,1) 100%)',
  boxShadow: 'none',
  '.open-hide-icon svg': {
    fill: theme.colorsPalette.yellow.main,
  },
  '.logo-container': {
    position: 'absolute',
    width: '150px',
    height: '70px',
    top: '25px',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '40px',
    padding: '10px 20px',
    backgroundColor: '#FFF',
    transition: '0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
}))

type HeaderProps = {
  open: boolean
  handleDrawerOpen: () => void
  currentUser: User
  applicationModules: ApplicationModule[]
}

const Header: FunctionComponent<HeaderProps> = ({
  open,
  handleDrawerOpen,
  currentUser,
  applicationModules,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const defaultTitle = process.env.REACT_APP_WEBSITE_NAME || ''

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [messengerOpen, setMessengerOpen] = useState<boolean>(false)
  const [timerDisplay, setTimerDisplay] = useState<string | null>(null)
  const [title, setTitle] = useState<string>(defaultTitle)
  const [backdropOpen, setBackdropOpen] = useState<boolean>(false)

  useEffect(() => {
    document.title = title
  }, [title])

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleLogout = () => {
    setTitle(defaultTitle)
    dispatch(logout())
  }

  const inactivityTime = () => {
    window.onload = resetTimer
    // DOM Events
    document.onmousemove = resetTimer
    document.onkeydown = resetTimer

    function logout() {
      isUserInactive = true
    }

    function resetTimer() {
      clearTimeout(idleTimer)
      isUserInactive = false
      idleTimer = setTimeout(logout, 300000) // 5mins
    }
  }

  useEffect(() => {
    let tokenRefreshed = false
    inactivityTime()

    if (logoutInterval) {
      clearInterval(logoutInterval)
    }

    logoutInterval = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime()
      const countDownDate = currentUser.exp * 1000

      // Find the distance between now and the count down date
      const distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      const minutesWithLeadingZero = minutes < 10 ? `0${minutes}` : minutes
      const secondsWithLeadingZero = seconds < 10 ? `0${seconds}` : seconds

      setTimerDisplay(
        `${
          hours > 0 ? `${hours}h ` : ''
        }${minutesWithLeadingZero}min ${secondsWithLeadingZero}s`,
      )

      if (
        days === 0 &&
        hours === 0 &&
        minutes === 0 &&
        seconds < 59 &&
        !isUserInactive
      ) {
        if (!tokenRefreshed) {
          dispatch(refreshToken(currentUser.token))
          tokenRefreshed = true
        }
      }

      // If the count down is finished, write some text
      if (distance < 0) {
        setTimerDisplay(`${hours > 0 ? `0h ` : ''}00min 00s`)
        setTitle(defaultTitle)
        setBackdropOpen(true)
        clearInterval(logoutInterval)
      }
    }, 1000)

    return () => {
      if (logoutInterval) {
        clearInterval(logoutInterval)
      }
    }
  }, [currentUser, dispatch, defaultTitle])

  const renderTimer = (text: string) => (
    <Stack flexDirection="row" alignItems="center">
      <AccessTimeOutlinedIcon style={{ width: '17px', height: '17px' }} />
      <Typography variant="body2" display="block" ml={1}>
        {text}
      </Typography>
    </Stack>
  )

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          className="open-hide-icon"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        {timerDisplay && renderTimer(timerDisplay)}
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          <div
            className="logo-container"
            style={{ opacity: messengerOpen ? 0 : 1 }}
          >
            <img src={Logo} alt="" />
          </div>
        </Typography>
        <Messenger
          documentTitle={defaultTitle}
          setOpen={setMessengerOpen}
          isMessengerActive={isModuleVisible(
            'CHAT_BOX',
            applicationModules,
            currentUser,
          )}
        />

        <IconButton color="inherit" onClick={handleClickOpenDialog}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogTitle>{t('common.areYouSureToLogout')}</DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseDialog}
            color="primary"
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleLogout}
            color="primary"
            autoFocus
          >
            {t('common.yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <Card sx={{ minWidth: 275 }} elevation={0}>
          <CardContent style={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: 14, marginBottom: 2 }} gutterBottom>
              {t('common.sessionExpired')}
            </Typography>
            <PrimaryButton onClick={handleLogout}>
              {t('common.loginAgain')}
            </PrimaryButton>
          </CardContent>
        </Card>
      </Backdrop>
    </AppBar>
  )
}

export default Header
