import React, { FunctionComponent, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import BasicInformation from '../partials/Steps/BasicInformation'
import Rules from '../partials/Steps/Rules'
import CustomBlock from '../partials/Steps/CustomBlock'
import { ActionContainer } from '../partials/Steps/styles'
import { IconButton, Stack, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { grey } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import BottomBar from '../partials/BottomBar'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import TradeActionService from '../../../../services/tradeAction.service'
import { CreateOrUpdateBlockParams, ParameterType } from '../../../../store/TradeAction/types'

type TradeActionCreateProps = {}

const TradeActionCreate: FunctionComponent<TradeActionCreateProps> = () => {
  const NUM_OF_BLOCKS = 5
  const [actionId, setActionId] = useState<number | null>(null)
  const [blocks, setBlocks] = useState<CreateOrUpdateBlockParams[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [parameterTypes, setParameterTypes] = useState<ParameterType[]>([])
  const { t } = useTranslation()

  const addNewBlock = () => {
    if (blocks.length < NUM_OF_BLOCKS && actionId) {
      const emptyBlock: CreateOrUpdateBlockParams = {
        blockId: null,
        actionId,
        blockName: '',
        isVisible: true,
        userFilter: false,
        parameters: []
      }

      setBlocks((prevBlocks) => [...prevBlocks, emptyBlock])
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const parameterTypesResponse = await TradeActionService.getParameterTypes()

        if (parameterTypesResponse.data.success) {
          setParameterTypes(parameterTypesResponse.data.parameterTypes)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Box>
          <Box sx={{ maxWidth: 800, margin: '0 auto 100px auto' }}>
            <BasicInformation setActionId={setActionId} actionId={actionId} />
            <Rules actionId={actionId} />
            {actionId && blocks.map((block, i) => (
              <CustomBlock
                key={`block-${i}-${block.blockId}`}
                actionId={actionId}
                block={block}
                order={i + 1}
                handleBlockRemove={() => {
                  blocks.splice(i, 1)
                  setBlocks((prevBlocks) => [...blocks])
                }}
                handleBlockSave={(block) => {
                  const updatedBlocks = [...blocks]
                  updatedBlocks[i].blockId = block.blockId
                  updatedBlocks[i].blockName = block.blockName
                  updatedBlocks[i].isVisible = block.isVisible
                  updatedBlocks[i].userFilter = block.userFilter
                  setBlocks(updatedBlocks)
                }}
                parameterTypes={parameterTypes}
              />
            ))}
            {actionId && blocks.length < NUM_OF_BLOCKS && (
              <ActionContainer>
                <Stack direction={'row'}>
                  <div className="step-line inactive">
                    <div className="step-circle inactive">
                      {blocks.length + 3}
                    </div>
                    <div className="step-arrow inactive"></div>
                  </div>
                  <Box
                    className="form-container"
                    textAlign={'center'}
                    color={grey[500]}
                    position={'relative'}
                    mb={2}
                    paddingBottom={'24px !important'}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight={'bold'}
                      component="div"
                    >
                      {t('pages.tradeActions.addNewBlock')}
                    </Typography>
                    <Typography
                      variant="caption"
                      gutterBottom
                      component="div"
                      dangerouslySetInnerHTML={{
                        __html: t('pages.tradeActions.addNewBlockHint2', {
                          numOfBlocks: NUM_OF_BLOCKS,
                          numOfBlocksLeft: NUM_OF_BLOCKS - blocks.length,
                        }),
                      }}
                    ></Typography>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      className="add-button"
                      onClick={addNewBlock}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Stack>
              </ActionContainer>
            )}
          </Box>
          <BottomBar />
        </Box>
      )}
    </>
  )
}

export default TradeActionCreate
