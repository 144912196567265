import axios from 'axios'
import authHeader from './authHeader'
import {
  ApiVersionResponse,
  AppliactionModulesResponse,
  AppliactionReleasesResponse,
  ConfigurationParameterName,
  ConfigurationParameterResponse,
  SetUpdateDateResponse,
  UpdateDateResponse,
} from '../store/Config/types'

const API_URL = process.env.REACT_APP_API_URL

const getApplicationModules = async (): Promise<AppliactionModulesResponse> => {
  return await axios.get(API_URL + `/api/admin/config/module`, {
    headers: authHeader(),
  })
}

const getConfigurationParameter = async (
  parameterName: ConfigurationParameterName,
): Promise<ConfigurationParameterResponse> => {
  return await axios.get(
    API_URL + `/api/admin/config/parameter/${parameterName}`,
    {
      headers: authHeader(),
    },
  )
}

const getUpdateDate = async (): Promise<UpdateDateResponse> => {
  return await axios.get(
    API_URL + `/api/admin/config/parameter/LAST_UPDATE_DATE`,
    {
      headers: authHeader(),
    },
  )
}

const setUpdateDate = async (
  lastUpdateDate: string,
): Promise<SetUpdateDateResponse> => {
  return await axios.post(
    API_URL + `/api/admin/config/parameter/update/last-update-date`,
    { lastUpdateDate },
    {
      headers: authHeader(),
    },
  )
}

const getApplicationReleases = async (
  applicationCode: string,
): Promise<AppliactionReleasesResponse> => {
  return await axios.post(
    API_URL + `/api/admin/config/application/release`,
    { applicationCode },
    {
      headers: authHeader(),
    },
  )
}

const getApiVersion = async (): Promise<ApiVersionResponse> => {
  return await axios.get(API_URL + `/api/version`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getApplicationModules,
  getConfigurationParameter,
  getUpdateDate,
  setUpdateDate,
  getApplicationReleases,
  getApiVersion,
}

export default exportedObject
