import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { User } from '../../../../store/Auth/types'

interface ManufacturersToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  submitSearch: (value: string) => void
  value: string
  filterActivity: (event: SelectChangeEvent) => void
  activityValue: string
  filterVisibility: (event: SelectChangeEvent) => void
  visibilityValue: string
  user: User
}

export default function ManufacturersToolbar(props: ManufacturersToolbarProps) {
  const { t } = useTranslation()

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" sx={{ marginRight: 1, display: 'none' }}>
          <label>{t('pages.manufacturers.toolbar.filters.isActive')}</label>
          <Select
            id="visibility-select"
            onChange={props.filterActivity}
            value={props.activityValue}
            defaultValue={props.activityValue}
          >
            <MenuItem value="all">
              {t('pages.manufacturers.toolbar.filters.all')}
            </MenuItem>
            <MenuItem value="1">{t('common.yes')}</MenuItem>
            <MenuItem value="0">{t('common.no')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ marginRight: 1, display: 'none' }}>
          <label>{t('pages.manufacturers.toolbar.filters.isVisible')}</label>
          <Select
            id="visibility-select"
            onChange={props.filterVisibility}
            value={props.visibilityValue}
            defaultValue={props.visibilityValue}
          >
            <MenuItem value="all">
              {t('pages.manufacturers.toolbar.filters.all')}
            </MenuItem>
            <MenuItem value="1">{t('common.yes')}</MenuItem>
            <MenuItem value="0">{t('common.no')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          size="small"
          value={props.value}
          onChange={props.onChange}
          placeholder={t('pages.products.table.search')}
          onKeyDown={onSearchKeyDown}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 100 }}
        />
        <PrimaryButton
          onClick={() => props.submitSearch(props.value)}
          style={{
            padding: '7px 5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          }}
        >
          {t('common.search')}
        </PrimaryButton>
      </div>
    </div>
  )
}
