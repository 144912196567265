import axios from 'axios'
import {
  AccountOperationListResponse,
} from '../store/AccountOperation/types'
import { UserChangePointsParams, UserChangePointsResponse } from '../store/User/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getAccountOperationList = (): Promise<AccountOperationListResponse> => {
  return axios.get(API_URL + '/api/admin/game/operation-type', {
    headers: authHeader(),
  })
}

const userChangePoints = async (
  params: UserChangePointsParams,
): Promise<UserChangePointsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/game/operation',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const getGroupAccountOperationList = (): Promise<AccountOperationListResponse> => {
  return axios.get(API_URL + '/api/admin/game/group-operation-type', {
    headers: authHeader(),
  })
}

const userChangeGroupPoints = async (
  params: UserChangePointsParams,
): Promise<UserChangePointsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/game/group-operation',
    {
      ...params,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getAccountOperationList,
  userChangePoints,
  getGroupAccountOperationList,
  userChangeGroupPoints,
}

export default exportedObject
