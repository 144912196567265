import React, { FunctionComponent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import BottomBar from '../partials/BottomBar'
import { useParams } from 'react-router-dom'
import { AssetDetails, AssetTypeCode } from '../../../../store/Edubox/types'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import EduboxService from '../../../../services/edubox.service'
import { QuizContainer } from '../partials/Steps/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import Image from '../../../shared/Image'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import htmlToDraft from 'html-to-draftjs'
import { zeroPad } from '../../../../helpers/utils'
import { isEpPointsVisible, isVpPointsVisible, isXpPointsVisible } from '../../../../helpers/environment'

type QuizShowProps = {}

type QuizParams = {
  id: string
}

export type Block = {
  id: number | null
  name: string
  visibility: boolean
}

const QuizShow: FunctionComponent<QuizShowProps> = () => {
  const { t } = useTranslation()
  let { id } = useParams<QuizParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [assetDetails, setAssetDetails] = useState<AssetDetails | null>(null)

  const [expandedQuestion, setExpandedQuestion] = useState<string | false>(
    false,
  )

  const handleExpandedQuestionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedQuestion(isExpanded ? panel : false)
    }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const tradeActionDetailsResponse = await EduboxService.getAssetDetails(
          parseInt(id),
        )
        if (tradeActionDetailsResponse.data) {
          setAssetDetails(tradeActionDetailsResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, t])

  if (!assetDetails) return null

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Box>
          <Box sx={{ maxWidth: 800, margin: '0 auto 100px auto' }}>
            <QuizContainer>
              <Stack direction={'row'}>
                <div className="step-line first">
                  <div className="step-circle">1</div>
                  <div className="step-arrow first"></div>
                </div>
                <Box className="form-container">
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={7}>
                      <div className="step-title">
                        {t('pages.edubox.basicInformation.title')}
                      </div>
                    </Grid>
                    <Grid item xs={5} className="switch-button">
                      {assetDetails.isVisible
                        ? t('pages.edubox.switchActive')
                        : t('pages.edubox.switchNotActive')}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ maxWidth: 520, marginBottom: 4 }}>
                    <Grid item xs={12} sx={{ marginBottom: 4 }}>
                      <strong>
                        {t('pages.edubox.basicInformation.form.name')}
                      </strong>
                      <div>{assetDetails.name}</div>
                    </Grid>
                    <Grid item xs={6}>
                      <strong>
                        {t('pages.edubox.basicInformation.form.validFrom')}
                      </strong>
                      <div>{assetDetails.assetAvailableFrom}</div>
                    </Grid>
                    <Grid item xs={6}>
                      <strong>
                        {t('pages.edubox.basicInformation.form.validTo')}
                      </strong>
                      <div>{assetDetails.assetAvailableTo}</div>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginBottom: 2 }}>
                    <Grid item xs={12}>
                      <strong>
                        {t('pages.edubox.basicInformation.form.description')}
                      </strong>
                      {assetDetails.description ? (
                        <div
                          style={{
                            background: '#fff',
                            border: '1px solid #ccc',
                            padding: '0 10px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: assetDetails.description,
                          }}
                        />
                      ) : (
                        <div>-</div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12}>
                      <strong>
                        {t('pages.edubox.basicInformation.form.image')}
                      </strong>
                      <div
                        style={{
                          maxHeight: '200px',
                          maxWidth: '100%',
                          textAlign: 'left',
                          marginBottom: '5px',
                        }}
                      >
                        <Image
                          imageId={assetDetails.imageId}
                          style={{
                            maxHeight: '200px',
                            maxWidth: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginBottom: 2 }}>
                    <Grid item xs={12}>
                      <strong>
                        {t(
                          `pages.edubox.basicInformation.form.${assetDetails.assetTypeCode}`,
                        )}
                      </strong>
                    </Grid>
                  </Grid>
                  {assetDetails.assetTypeCode === AssetTypeCode.PDF && (
                    <Grid container sx={{ marginBottom: 4 }}>
                      <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        {assetDetails.contentName}
                      </Grid>
                      <Grid item xs={3}>
                        <strong>
                          {t('pages.edubox.basicInformation.form.slidesNumber')}
                        </strong>
                        <div>{assetDetails.length}</div>
                      </Grid>
                      <Grid item ml={1}>
                        <strong>
                          {t('pages.edubox.basicInformation.form.orientation')}
                        </strong>
                        <div>
                          {t(
                            `pages.edubox.basicInformation.form.${assetDetails.orientation.toLocaleLowerCase()}`,
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  {assetDetails.assetTypeCode === AssetTypeCode.VIDEO && (
                    <Grid container sx={{ marginBottom: 4 }}>
                      <Grid item xs={12} sx={{ marginBottom: 4 }}>
                        {assetDetails.content && (
                          <a href={assetDetails.content} target="_bank">
                            {t('pages.edubox.basicInformation.form.videoUrl')}
                          </a>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <strong>
                          {t('pages.edubox.basicInformation.form.minutes')}
                        </strong>
                        <div>{Math.floor(assetDetails.length / 60)}</div>
                      </Grid>
                      <Grid item xs={1}>
                        <strong>
                          {t('pages.edubox.basicInformation.form.seconds')}
                        </strong>
                        <div>{zeroPad(assetDetails.length % 60, 2)}</div>
                      </Grid>
                      <Grid item ml={1}>
                        <strong>
                          {t('pages.edubox.basicInformation.form.orientation')}
                        </strong>
                        <div>
                          {t(
                            `pages.edubox.basicInformation.form.${assetDetails.orientation.toLocaleLowerCase()}`,
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Stack>
            </QuizContainer>
            {assetDetails.quiz && (
              <QuizContainer>
                <Stack direction={'row'}>
                  <div className="step-line">
                    <div className="step-circle">{2}</div>
                    <div className="step-arrow"></div>
                  </div>
                  <Box className="form-container">
                    <Grid container sx={{ marginBottom: 4 }}>
                      <Grid item xs={7}>
                        <div className="step-title">
                          {t('pages.edubox.scoring.title')}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginBottom: 2 }}>
                      <Grid item xs={8}>
                        <strong>
                          {t('pages.edubox.scoring.form.quizName')}
                        </strong>
                        <div>{assetDetails.quiz?.quizName}</div>
                      </Grid>
                      <Grid item xs={4} textAlign="right">
                        <Grid item xs={12}>
                          <strong>
                            {t('pages.edubox.scoring.form.duration')}
                          </strong>
                          <div>
                            {Math.floor(assetDetails.quiz?.durationTime / 60)}:
                            {zeroPad(assetDetails.quiz?.durationTime % 60, 2)}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ maxWidth: 520, marginBottom: 4 }}>
                      <Grid item xs={6}>
                        <strong>
                          {t('pages.edubox.scoring.form.validFrom')}
                        </strong>
                        <div>{assetDetails.quiz?.quizAvailableFrom}</div>
                      </Grid>
                      <Grid item xs={6}>
                        <strong>
                          {t('pages.edubox.scoring.form.validTo')}
                        </strong>
                        <div>{assetDetails.quiz?.quizAvailableTo}</div>
                      </Grid>
                    </Grid>
                    <Grid container mb={1}>
                      {assetDetails.quiz?.points.map((score, i) => (
                        <Grid container key={`score-${i}`} mb={2}>
                          <Grid item xs={2}>
                            <strong>
                              {t('pages.edubox.scoring.form.placeFrom')}
                            </strong>
                            <div>{score.startPlace}</div>
                          </Grid>
                          <Grid item xs={2}>
                            <strong>
                              {t('pages.edubox.scoring.form.placeTo')}
                            </strong>
                            <div>{score.endPlace}</div>
                          </Grid>

                          {isXpPointsVisible() && (
                            <Grid item xs={2} sx={{ marginLeft: 3 }}>
                              <strong>
                                {t('pages.edubox.scoring.form.awardXp')}
                              </strong>
                              <div>{score.xpReward}</div>
                            </Grid>
                          )}
                          {isVpPointsVisible() && (
                            <Grid item xs={2}>
                              <strong>
                                {t('pages.edubox.scoring.form.awardVp')}
                              </strong>
                              <div>{score.vpReward}</div>
                            </Grid>
                          )}
                          {isEpPointsVisible() && (
                            <Grid item xs={2}>
                              <strong>
                                {t('pages.edubox.scoring.form.awardEp')}
                              </strong>
                              <div>{score.epReward}</div>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Stack>
              </QuizContainer>
            )}
            {assetDetails.quiz && assetDetails.quiz.questions.length > 0 && (
              <QuizContainer>
                <Stack direction={'row'}>
                  <div className="step-line">
                    <div className="step-circle">{3}</div>
                    <div className="step-arrow"></div>
                  </div>
                  <Box className="form-container">
                    <Grid container sx={{ marginBottom: 4 }}>
                      <Grid item xs={7}>
                        <div className="step-title">
                          {t('pages.edubox.questions.title')}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container mb={1}>
                      {assetDetails.quiz.questions.map(
                        (question, questionIndex) => (
                          <Grid
                            container
                            key={`question-${questionIndex}`}
                            mb={2}
                          >
                            <Grid item xs={12}>
                              <Accordion
                                expanded={
                                  expandedQuestion ===
                                  `question-${questionIndex}`
                                }
                                onChange={handleExpandedQuestionChange(
                                  `question-${questionIndex}`,
                                )}
                                sx={{
                                  boxShadow: 'none',
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                >
                                  <Typography>
                                    {t('pages.edubox.questions.form.question')}{' '}
                                    {questionIndex + 1}/
                                    {assetDetails.quiz &&
                                      assetDetails.quiz.questions.length}
                                  </Typography>
                                  {expandedQuestion !==
                                    `question-${questionIndex}` && (
                                    <Typography
                                      variant="body2"
                                      style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '250px',
                                        lineHeight: '24px',
                                        marginLeft: 10,
                                        color: '#666',
                                      }}
                                    >
                                      {htmlToDraft(question.content)
                                        .contentBlocks[0].getText()
                                        .substring(0, 50)}
                                    </Typography>
                                  )}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container sx={{ marginBottom: 2 }}>
                                    <div
                                      style={{
                                        background: '#fff',
                                        border: '1px solid #ccc',
                                        padding: '0 10px',
                                        width: '100%',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: question.content,
                                      }}
                                    />
                                  </Grid>
                                  {question.imageId && (
                                    <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                      <div
                                        style={{
                                          maxHeight: '200px',
                                          maxWidth: '100%',
                                          textAlign: 'left',
                                          marginBottom: '5px',
                                        }}
                                      >
                                        <Image
                                          imageId={question.imageId}
                                          style={{
                                            maxHeight: '200px',
                                            maxWidth: '100%',
                                            objectFit: 'contain',
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                  )}
                                  {question.answers.map(
                                    (answer, answerIndex) => (
                                      <Grid
                                        container
                                        key={`answer-${questionIndex}-${answerIndex}`}
                                        borderBottom="1px solid #ccc"
                                      >
                                        <Grid item xs={9}>
                                          <label>
                                            <Typography
                                              variant="caption"
                                              display="block"
                                            >
                                              {t(
                                                'pages.edubox.questions.form.answer',
                                              )}{' '}
                                              {answerIndex + 1}/
                                              {question.answers.length}
                                            </Typography>
                                          </label>
                                          <div>{answer.content}</div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={answer.isCorrect}
                                              />
                                            }
                                            label={t(
                                              'pages.edubox.questions.form.correctAnswer',
                                            )}
                                          />
                                        </Grid>
                                      </Grid>
                                    ),
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        ),
                      )}
                    </Grid>
                  </Box>
                </Stack>
              </QuizContainer>
            )}
          </Box>
          <BottomBar />
        </Box>
      )}
    </>
  )
}

export default QuizShow
