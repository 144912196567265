import { Alert, AlertColor, Typography } from '@mui/material'
import React from 'react'
import { TFunction } from 'react-i18next'
import { downloadXLSX } from '../../../../../helpers/utils'
import { ImportStoreResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getStoresResponse = (
  importResponse: ImportStoreResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (messageType === 'prepare') {
    if (
      (importResponse.data.addedResults ||
        importResponse.data.addedResults === 0) &&
      (importResponse.data.updatedResults ||
        importResponse.data.updatedResults === 0) &&
      (importResponse.data.skippedResults ||
        importResponse.data.skippedResults === 0)
    ) {
      const addedResults = importResponse.data.addedResults
      const updatedResults = importResponse.data.updatedResults
      const skippedResults = importResponse.data.skippedResults
      const skippedCompanies = importResponse.data.skippedCompanies
      const skippedUsers = importResponse.data.skippedUsers

      const renderAdditionalInfo = () => {
        return (
          <>
            {skippedCompanies && skippedCompanies.length > 0 && (
              <Alert severity="error" style={{ marginBottom: '10px' }}>
                <Typography variant="body2">
                  {t('import.messages.stores.wrongCompanies')}:{' '}
                  {skippedCompanies.map(
                    (company, i) =>
                      company + (i + 1 < skippedCompanies.length ? ', ' : ''),
                  )}
                </Typography>
              </Alert>
            )}
            {skippedUsers && skippedUsers.length > 0 && (
              <Alert severity="error" style={{ marginBottom: '10px' }}>
                <Typography variant="body2">
                  {t('import.messages.stores.wrongUsers')}:{' '}
                  {skippedUsers.map(
                    (user, i) =>
                      user + (i + 1 < skippedUsers.length ? ', ' : ''),
                  )}
                </Typography>
              </Alert>
            )}
            {skippedResults > 0 && (
              <div>
                <SecondaryButton
                  onClick={() =>
                    downloadXLSX(
                      importResponse.data.skippedStores,
                      t('import.messages.stores.skippedStoresFilename'),
                    )
                  }
                  size="small"
                  style={{ marginBottom: '10px' }}
                >
                  {t('import.messages.stores.downloadSkippedStores')}
                </SecondaryButton>
              </div>
            )}
          </>
        )
      }

      return {
        message: {
          type: alertType,
          message: t(`import.messages.stores.${messageType}Message`, {
            addedResults: addedResults.toLocaleString(),
            updatedResults: updatedResults.toLocaleString(),
            skippedResults: skippedResults.toLocaleString(),
          }),
          hasSecondStep: true,
        },
        additionalInfo: renderAdditionalInfo(),
      }
    }
  }
  if (messageType === 'accept') {
    if (
      (importResponse.data.addedResults ||
        importResponse.data.addedResults === 0) &&
      (importResponse.data.updatedResults ||
        importResponse.data.updatedResults === 0)
    ) {
      const addedResults = importResponse.data.addedResults
      const updatedResults = importResponse.data.updatedResults

      return {
        message: {
          type: alertType,
          message: t(`import.messages.stores.${messageType}Message`, {
            addedResults: addedResults.toLocaleString(),
            updatedResults: updatedResults.toLocaleString(),
          }),
          hasSecondStep: true,
        },
      }
    }
  }
  return null
}
