import React, { useEffect, useState } from 'react'
import {
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  LinearProgress,
  linearProgressClasses,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import GameService from '../../../../services/game.service'
import { styled } from '@mui/material/styles'
import { ReactComponent as TopBarIcons } from '../../../../assets/images/phone/top_bar_icons.svg'
import { ReactComponent as BackArrow } from '../../../../assets/images/phone/back_arrow.svg'
import PhoneFrame from '../../../../assets/images/phone/phone_frame.svg'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import theme from '../../../../theme'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import { UserQuiz } from '../../../../store/Game/types'
import Image from '../../../shared/Image'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'

const Phone = styled('div')({
  backgroundImage: `url(${PhoneFrame})`,
  backgroundSize: 'cover',
  padding: '12px 17px',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 400,
  backgroundColor: 'transparent',
  height: '776px',
  position: 'relative',
})

const PhoneContainer = styled('div')({
  backgroundColor: '#F1F1F1',
  borderRadius: 26,
  height: '752px',
  paddingBottom: 56,
})

const PhoneContent = styled('div')({
  paddingTop: '16px',
  backgroundColor: '#F1F1F1',
  height: '610px',
  overflowY: 'scroll',
  /* total width */
  '::-webkit-scrollbar': {
    backgroundColor: '#F1F1F1',
    width: '16px',
  },
  /* background of the scrollbar except button or resizer */
  '::-webkit-scrollbar-track': {
    backgroundColor: '#F1F1F1',
  },
  /* scrollbar itself */
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0',
    borderRadius: '16px',
    border: '4px solid #F1F1F1',
  },
  /* set button(top and bottom of the scrollbar) */
  '::-webkit-scrollbar-button': {
    display: 'none',
  },
})

const PhoneTopBar = styled('div')({
  backgroundColor: '#1F1D1E',
  height: 26,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: 24,
  borderTopLeftRadius: 26,
  borderTopRightRadius: 26,
})

const PhoneAppBarDetails = styled('div')({
  height: 56,
  backgroundColor: '#2D2A2B',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  color: '#FFD748',
  position: 'relative',
})

const PhoneCard = styled('div')({
  background: '#FFFFFF',
  borderRadius: '4px',
  padding: '16px',
  marginLeft: '16px',
  marginBottom: '16px',
  p: {
    margin: 0,
  },
})

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F1F1F1',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: '#00813D',
  },
}))

const LinearProgressInfo = styled('div')({
  fontSize: '12px',
  lineHeight: '16px',
  marginTop: '4px',
})

type QuizPreviewOnPhoneDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  quizId: number
  userId: number
}

const QuizPreviewOnPhoneDialog: React.FunctionComponent<
  QuizPreviewOnPhoneDialogProps
> = ({ open, handleClose, quizId, userId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [userQuiz, setUserQuiz] = useState<UserQuiz | null>(null)
  const [currentQuestion, setCurrentQuestion] = useState<number>(1)
  const [currentPercentage, setCurrentPercentage] = useState<number>(0)

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1)
    if (userQuiz) {
      setCurrentPercentage(
        Math.round(((currentQuestion + 1) * 100) / userQuiz.allQuestionsAmount),
      )
    }
  }

  const prevQuestion = () => {
    setCurrentQuestion(currentQuestion - 1)
    if (userQuiz) {
      setCurrentPercentage(
        Math.round(((currentQuestion - 1) * 100) / userQuiz.allQuestionsAmount),
      )
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        setCurrentQuestion(1)
        const quizResultResponse = await GameService.getUserQuiz(userId, quizId)
        if (quizResultResponse.data) {
          setUserQuiz(quizResultResponse.data)

          setCurrentPercentage(
            Math.round((1 * 100) / quizResultResponse.data.allQuestionsAmount),
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, quizId, userId])

  const renderAnswerIcon = (correct: boolean, selected: boolean) => {
    if (correct && selected) {
      return (
        <ThumbUpOutlinedIcon
          sx={{
            marginRight: '10px',
          }}
        />
      )
    }
    if (!correct && selected) {
      return (
        <ThumbDownOutlinedIcon
          htmlColor="#EE3042"
          sx={{
            marginRight: '10px',
          }}
        />
      )
    }
    return (
      <ThumbDownOutlinedIcon
        htmlColor="#fff"
        sx={{
          marginRight: '10px',
        }}
      />
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(false)}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '394px',
            height: '776px',
            background: 'transparent',
            boxShadow: 'none',
          },
        },
      }}
    >
      <Phone>
        {loading && (
          <PhoneContainer>
            <PhoneTopBar>
              <TopBarIcons />
            </PhoneTopBar>
            <PhoneAppBarDetails>
              <BackArrow style={{ position: 'absolute', top: 16, left: 16 }} />
            </PhoneAppBarDetails>
            <LoadingSpinner />
          </PhoneContainer>
        )}
        {!loading && userQuiz && (
          <PhoneContainer>
            <PhoneTopBar>
              <TopBarIcons />
            </PhoneTopBar>
            <PhoneAppBarDetails>
              <BackArrow
                style={{
                  position: 'absolute',
                  top: 16,
                  left: 16,
                  fill: '#FFD748',
                }}
              />
              <div style={{ textAlign: 'center', padding: '0 46px' }}>
                {userQuiz.quizName}
              </div>
            </PhoneAppBarDetails>
            <PhoneContent>
              <PhoneCard>
                <Stack
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                  marginBottom={'3px'}
                  fontWeight={'bold'}
                  fontSize={'20px'}
                >
                  <Stack
                    display={'flex'}
                    alignItems={'center'}
                    direction={'row'}
                  >
                    <TimerOutlinedIcon
                      sx={{
                        marginRight: '5px',
                      }}
                    />
                    {Math.floor(userQuiz.userQuizTimeLengthInSeconds / 60)}:
                    {userQuiz.userQuizTimeLengthInSeconds % 60}
                  </Stack>
                  <Stack
                    display={'flex'}
                    alignItems={'center'}
                    direction={'row'}
                  >
                    <ThumbUpOutlinedIcon
                      sx={{
                        marginRight: '5px',
                      }}
                    />
                    {userQuiz.correctlyAnsweredQuestions}
                    <ThumbDownOutlinedIcon
                      htmlColor="#EE3042"
                      sx={{
                        marginLeft: '15px',
                        marginRight: '5px',
                      }}
                    />
                    <span
                      style={{
                        color: '#EE3042',
                      }}
                    >
                      {userQuiz.wrongAnsweredQuestions}
                    </span>
                  </Stack>
                </Stack>
                <BorderLinearProgress
                  variant="determinate"
                  value={currentPercentage}
                />
                <Stack
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <LinearProgressInfo>
                    {t('pages.edubox.phoneDialog.question')} {currentQuestion}{' '}
                    {t('pages.edubox.phoneDialog.of')}{' '}
                    {userQuiz.allQuestionsAmount}
                  </LinearProgressInfo>
                  <LinearProgressInfo>{currentPercentage}%</LinearProgressInfo>
                </Stack>
              </PhoneCard>
              <PhoneCard>
                <div
                  style={{ fontSize: '16px', lineHeight: '24px' }}
                  dangerouslySetInnerHTML={{
                    __html: userQuiz.quizQuestion[currentQuestion - 1].content,
                  }}
                />
                <Divider
                  light
                  sx={{ marginTop: '16px', marginBottom: '16px' }}
                />
                {userQuiz.quizQuestion[currentQuestion - 1].imageId && (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    <Image
                      imageId={
                        userQuiz.quizQuestion[currentQuestion - 1].imageId
                      }
                      style={{
                        width: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </div>
                )}
                {userQuiz.quizQuestion[currentQuestion - 1].questionAnswers
                  .length === 0 && (
                  <Stack
                    sx={{
                      width: '100%',
                      backgroundColor: '#fdeded',
                      fontSize: '0.875rem',
                      borderRadius: '4px',
                      marginBottom: '10px',
                      padding: '10px',
                      lineHeight: '1.2'
                    }}
                    color="error"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <ErrorOutlinedIcon color="error" sx={{ marginRight: '5px' }} />
                    {t('pages.edubox.phoneDialog.noAnswer')}
                  </Stack>
                )}
                {userQuiz.quizQuestion[
                  currentQuestion - 1
                ].allAvailableAnswers.map((answer) => (
                  <Stack
                    key={answer.answerId}
                    display={'flex'}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    {renderAnswerIcon(
                      answer.isCorrect,
                      userQuiz.quizQuestion[
                        currentQuestion - 1
                      ].questionAnswers.find(
                        (questionAnswer) =>
                          questionAnswer.answerId === answer.answerId,
                      )
                        ? true
                        : false,
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            userQuiz.quizQuestion[
                              currentQuestion - 1
                            ].questionAnswers.find(
                              (questionAnswer) =>
                                questionAnswer.answerId === answer.answerId,
                            )
                              ? true
                              : false
                          }
                          name={`answer-${answer.answerId}`}
                          style={{ color: theme.colorsPalette.yellow.main }}
                        />
                      }
                      label={answer.content}
                      componentsProps={{
                        typography: {
                          fontSize: '14px',
                          fontWeight: answer.isCorrect ? 'bold' : 'normal',
                        },
                      }}
                    />
                  </Stack>
                ))}
              </PhoneCard>
            </PhoneContent>
            <Stack
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
              padding={'0 16px 0 16px'}
            >
              {currentQuestion !== 1 && (
                <PrimaryButton onClick={prevQuestion}>
                  {t('pages.edubox.phoneDialog.previous')}
                </PrimaryButton>
              )}
              {currentQuestion !== userQuiz.allQuestionsAmount && (
                <PrimaryButton
                  style={{ marginLeft: 'auto' }}
                  onClick={nextQuestion}
                >
                  {t('pages.edubox.phoneDialog.next')}
                </PrimaryButton>
              )}
            </Stack>
          </PhoneContainer>
        )}
      </Phone>
    </Dialog>
  )
}

export default QuizPreviewOnPhoneDialog
