import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const StyledInput = styled('input')({
  background: '#F1F1F1',
  border: '1px solid #DCDCDC',
  borderRadius: '5px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  lineHeight: '17px',
  padding: '9px 30px 9px 9px',
  marginTop: '10px',
  ':hover': {
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
})

const IconContainer = styled('div')({
  position: 'absolute',
  pointerEvents: 'none',
  top: '19px',
  right: '10px',
  svg: {
    width: '16px',
    height: '16px',
  },
})

type InlineEditProps = {
  value: string
  setValue: (name: string) => void
  maxLength: number
  minLength: number
}

const InlineEdit: React.FC<InlineEditProps> = ({
  value,
  setValue,
  maxLength,
  minLength,
}) => {
  const { t } = useTranslation()
  const [editingValue, setEditingValue] = useState(value)

  useEffect(() => {
    setEditingValue(value)
  }, [value])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditingValue(event.target.value)
  }

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement },
  ) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur()
    }
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value.trim().length < minLength) {
      toast.error(
        t('messages.error.tooShortName', {
          minLength,
        }),
      )
    } else if (event.target.value.trim() === '') {
      setEditingValue(value)
    } else {
      setValue(event.target.value)
    }
  }

  return (
    <Stack position="relative">
      <StyledInput
        type="text"
        aria-label="image-name"
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        maxLength={maxLength}
        minLength={minLength}
      />
      <IconContainer>
        <BorderColorIcon htmlColor={grey[600]} />
      </IconContainer>
    </Stack>
  )
}

export default InlineEdit
