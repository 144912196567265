import {
  Stack,
  FormControl,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material'
import { ReactComponent as CalendarIcon } from '../../../assets/images/game/icons/calendar_icon.svg'
import { ReactComponent as PpIcon } from '../../../assets/images/game/icons/pp_icon.svg'
import { ReactComponent as VpIcon } from '../../../assets/images/game/icons/vp_icon.svg'
// import { ReactComponent as FilterIcon } from '../../../assets/images/game/icons/filter_icon.svg'
import {
  BorderLinearProgress,
  // FilterButton,
  PhoneAppBarDetails,
  PhoneCard,
  PhoneContent,
} from '../styles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PlansIcon } from '../../../assets/images/game/icons/plans_icon.svg'
import { useEffect, useState } from 'react'
import PlanDetailsDialog from '../partials/PlanDetailsDialog'
import { Period } from '../../../store/Period/types'
import { UserPlansPreview } from '../../../store/Game/types'
import GameService from '../../../services/game.service'
import PeriodService from '../../../services/period.service'
import { errorHandler } from '../../../helpers/errorHandler'
import Image from '../../shared/Image'
import { thousandsSeparator } from '../../../helpers/utils'
type PlansScreenProps = {
  handleSetPlans: React.Dispatch<React.SetStateAction<UserPlansPreview[]>>
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>
  setPlanId: React.Dispatch<React.SetStateAction<number | null>>
  userId: number
}

const PlansScreen: React.FunctionComponent<PlansScreenProps> = ({
  handleSetPlans,
  setCurrentScreen,
  setPlanId,
  userId,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [planDetailsDialogOpen, setPlanDetailsDialogOpen] = useState(false)
  const [periods, setPeriods] = useState<Period[]>([])
  const [periodValue, setPeriodValue] = useState<string>('')
  const [detailsPlanId, setDetailsPlanId] = useState<number | null>(null)
  const [plans, setPlans] = useState<UserPlansPreview[]>([])

  const handlePlanDetailsDialogOpen = () => {
    setPlanDetailsDialogOpen(true)
  }

  const handlePlanDetailsDialogClose = () => {
    setPlanDetailsDialogOpen(false)
  }

  const handlePeriodChange = async (event: SelectChangeEvent) => {
    setPeriodValue(event.target.value as string)
    const userPlansPreviewResponse = await GameService.getUserPlansPreview(
      parseInt(event.target.value),
      userId,
    )
    if (userPlansPreviewResponse.data.plans) {
      setPlans(userPlansPreviewResponse.data.plans)
      handleSetPlans(userPlansPreviewResponse.data.plans)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        let activePeriod = null
        const periodListResponse = await PeriodService.getPeriodList()
        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          activePeriod = periodListResponse.data.periods.find(
            (period) => period.isActive,
          )
          setPeriodValue(activePeriod ? activePeriod.id.toString() : '')
        }
        if (activePeriod !== null && activePeriod !== undefined) {
          setPeriodValue(activePeriod.id.toString())
        }
        if (activePeriod) {
          const userPlansPreviewResponse =
            await GameService.getUserPlansPreview(activePeriod.id, userId)
          if (userPlansPreviewResponse.data.plans) {
            setPlans(userPlansPreviewResponse.data.plans)
            handleSetPlans(userPlansPreviewResponse.data.plans)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, userId, handleSetPlans])

  const renderPlanCard = (plan: UserPlansPreview) => {
    return (
      <>
        {loading && (
          <>
            <CircularProgress />
          </>
        )}
        {!loading && (
          <PhoneCard style={{ marginTop: '36px', paddingBottom: '4px' }}>
            <Image
              imageId={plan.imageId}
              style={{
                width: '96px',
                height: '40px',
                objectFit: 'contain',
                position: 'absolute',
                left: 'calc(50% - 48px)',
                top: '-20px',
              }}
            />
            <Stack
              display="flex"
              flexDirection="row"
              fontSize="12px"
              justifyContent="space-between"
              alignItems="center"
              fontWeight="600"
              marginTop="10px"
              marginBottom="4px"
            >
              <Stack display="flex" flexDirection="row" alignItems="center">
                <CalendarIcon />
                {plan.timeScope}
              </Stack>
              <div>{plan.resultPercentValue}%</div>
            </Stack>
            <BorderLinearProgress
              variant="determinate"
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor:
                    plan.resultPercentValue >= 100 ? 'green' : '#EE3042',
                },
              }}
              value={
                plan.resultPercentValue <= 100 ? plan.resultPercentValue : 100
              }
            />
            <Stack
              display="flex"
              flexDirection="row"
              fontSize="12px"
              justifyContent="space-between"
              alignItems="center"
              fontWeight="400"
              marginTop="4px"
              marginBottom="16px"
            >
              <div>
                {t('pages.game.phone.screens.plansScreen.resultValue')}{' '}
                {thousandsSeparator(plan.resultValue)}
              </div>
              <div>
                {t('pages.game.phone.screens.plansScreen.planValue')}{' '}
                {thousandsSeparator(plan.planValue)}
              </div>
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              fontSize="12px"
              justifyContent="flex-end"
              alignItems="center"
              fontWeight="600"
              paddingBottom="15px"
              borderBottom="1px solid #F5F2E3"
            >
              {t('pages.game.phone.screens.plansScreen.granted')}
              <div style={{ fontSize: '16px', margin: '0 5px 0 7px' }}>
                {plan.xpValue}
              </div>
              <PpIcon />
              <div style={{ fontSize: '16px', margin: '0 5px 0 7px' }}>
                {plan.vpValue}
              </div>
              <VpIcon />
            </Stack>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              marginTop="4px"
            >
              <Button
                variant="text"
                style={{
                  textTransform: 'capitalize',
                  marginRight: '5px',
                }}
                onClick={() => {
                  handlePlanDetailsDialogOpen()
                  setDetailsPlanId(plan.planId)
                }}
              >
                {t('pages.game.phone.screens.plansScreen.details')}
              </Button>
              <Button
                variant="text"
                style={{
                  textTransform: 'capitalize',
                  marginLeft: '5px',
                }}
                onClick={() => {
                  setCurrentScreen('stores')
                  setPlanId(plan.planId)
                }}
              >
                {t('pages.game.phone.screens.plansScreen.storeList')}
              </Button>
            </Stack>
          </PhoneCard>
        )}
      </>
    )
  }

  return (
    <>
      <PhoneAppBarDetails>
        <PlansIcon fill="#FFD748" />
        <div style={{ textAlign: 'center', paddingLeft: '10px' }}>
          {t('pages.game.phone.screens.plans')}
        </div>
      </PhoneAppBarDetails>
      <PhoneContent className="plans-phone-content">
        <Stack
          display="flex"
          flexDirection="row"
          padding="16px 16px 0"
          alignItems="flex-end"
        >
          {/* <FilterButton>
            <FilterIcon />
          </FilterButton> */}
          <FormControl
            style={
              {
                /*marginLeft: '16px'*/
              }
            }
            fullWidth
          >
            <div
              style={{
                color: '#808080',
                fontSize: '12px',
                marginBottom: '3px',
              }}
            >
              {t('pages.game.phone.screens.plansScreen.selectPeriod')}
            </div>
            <Select
              id="period-select"
              value={periodValue}
              onChange={handlePeriodChange}
              style={{ background: '#fff', height: 48 }}
              defaultValue={periodValue}
            >
              {periods.map((period) => (
                <MenuItem value={period.id} key={period.id}>
                  {period.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        {plans.map((p) => renderPlanCard(p))}
      </PhoneContent>
      <PlanDetailsDialog
        planId={detailsPlanId}
        open={planDetailsDialogOpen}
        handleClose={handlePlanDetailsDialogClose}
      />
    </>
  )
}

export default PlansScreen
