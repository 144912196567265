import { HTMLAttributes } from 'react'
import { LinearProgress, linearProgressClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import PhoneFrame from '../../assets/images/phone/phone_frame.svg'

export type NavigationItemProps = HTMLAttributes<HTMLDivElement> & {
  active?: boolean
}

export type PhoneStoreCardProps = HTMLAttributes<HTMLDivElement> & {
  even?: boolean
}

const navigationItemColor = '#FBEBA7'
const navigationItemActiveColor = '#FFC315'

export const Phone = styled('div')({
  backgroundImage: `url(${PhoneFrame})`,
  backgroundSize: 'cover',
  padding: '12px 17px',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 400,
  backgroundColor: 'transparent',
  height: '776px',
  position: 'relative',
})

export const PhoneContainer = styled('div')({
  backgroundColor: '#F1F1F1',
  borderRadius: 26,
  height: '752px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

export const PhoneContent = styled('div')({
  backgroundColor: '#F1F1F1',
  height: '590px',
  overflowY: 'auto',
  /* total width */
  '::-webkit-scrollbar': {
    backgroundColor: '#F1F1F1',
    width: '16px',
  },
  /* background of the scrollbar except button or resizer */
  '::-webkit-scrollbar-track': {
    backgroundColor: '#F1F1F1',
  },
  /* scrollbar itself */
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0',
    borderRadius: '16px',
    border: '4px solid #F1F1F1',
  },
  /* set button(top and bottom of the scrollbar) */
  '::-webkit-scrollbar-button': {
    display: 'none',
  },
})

export const PhoneTopBar = styled('div')({
  backgroundColor: '#1F1D1E',
  height: 26,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: 24,
  borderTopLeftRadius: 26,
  borderTopRightRadius: 26,
})

export const PhoneBottomBar = styled('div')({
  backgroundColor: '#1F1D1E',
  height: '80px',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  borderBottomLeftRadius: 26,
  borderBottomRightRadius: 26,
})

export const NavigationItem = styled('div')<NavigationItemProps>(
  ({ active = false }) => ({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    color: active ? navigationItemActiveColor : navigationItemColor,
    width: '25%',
    '& svg': {
      fill: active ? navigationItemActiveColor : navigationItemColor,
    },
  }),
)

export const PhoneAppBarDetails = styled('div')({
  height: 56,
  backgroundColor: '#2D2A2B',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  color: '#FFD748',
  position: 'relative',
})

export const PhoneCard = styled('div')({
  background: '#FFFFFF',
  borderRadius: '4px',
  padding: '16px',
  marginRight: '16px',
  marginLeft: '16px',
  marginBottom: '16px',
  position: 'relative',
})

export const PhoneStoreCard = styled('div')<PhoneStoreCardProps>(
  ({ even = false }) => ({
    background: even ? '#FFFFFF' : '#F7F7F7',
    padding: '16px',
    position: 'relative',
  }),
)

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F5F2E3',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
  },
}))

export const ChartContainer = styled('div')({
  width: '118px',
  height: '118px',
  background: '#fff',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '-29px',
})

export const RealizationBlock = styled('div')({
  margin: '20px 16px 0',
  background: '#fff',
  borderRadius: '4px',
  height: '60px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'relative',
})

export const RealizationBlockInfo = styled('div')({
  width: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

export const RealizationSmallTxt = styled('div')({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  color: '#808080',
})

export const RealizationBigTxt = styled('div')({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
})

export const RealizationDate = styled('div')({
  position: 'absolute',
  left: '4px',
  bottom: '-20px',
  fontSize: '10px',
  color: '#808080',
})

export const PositionBadge = styled('div')({
  position: 'absolute',
  width: '60px',
  height: '50px',
  bottom: 0,
  right: 0,
  background: '#2D2A2B',
  borderRadius: '4px 0px 0px 4px',
  display: 'flex',
  flexDirection: 'column',
  color: '#fff',
  justifyContent: 'center',
  alignItems: 'center',
  '& strong': {
    fontSize: '22px',
    lineHeight: '21px',
  },
  '& span': {
    fontSize: '10px',
    lineHeight: '12px',
  },
})

export const FilterButton = styled('div')({
  width: '48px',
  height: '48px',
  background: '#FFFFFF',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  flexBasis: '60px',
})
