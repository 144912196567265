import axios from 'axios'
import {
  PeriodPlanResponse,
  PeriodPlanDetailsResponse,
} from '../store/PeriodPlan/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPeriodPlanList = async (
  period: { id: number }[],
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<PeriodPlanResponse> => {
  return await axios.post(
    API_URL + '/api/admin/period-plan',
    {
      period,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getPeriodPlanDetails = async (planId: number): Promise<PeriodPlanDetailsResponse> => {
  return await axios.get(API_URL + `/api/admin/period-plan/${planId}`, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getPeriodPlanList,
  getPeriodPlanDetails,
}

export default exportedObject
