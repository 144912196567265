import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import { AlertColor, Grid, Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import ImportService from '../../../services/import.service'
import { ImportParameterColumn } from '../../../store/Import/types'
import LoadingSpinner from '../../shared/LoadingSpinner'
import ImportPrepare from './partials/ImportPrepare'
import ImportAccept from './partials/ImportAccept'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { red } from '@mui/material/colors'

interface ParamTypes {
  name: string
  param1?: string
}

const Import = () => {
  const { t } = useTranslation()
  let { name, param1 } = useParams<ParamTypes>()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [importParameters, setImportParameters] = useState<
    ImportParameterColumn[]
  >([])
  const [additionalParams, setAdditionalParams] = useState({})
  const [acceptResponse, setAcceptResponse] = useState<{
    message: {
      type: AlertColor
      message: string
      hasSecondStep: boolean
    }
    additionalInfo?: React.ReactNode
  } | null>(null)

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  useEffect(() => {
    if (param1) {
      switch (name) {
        case 'store_plans':
        case 'store_plan_results':
        case 'user_plans':
          setAdditionalParams({
            planId: param1,
          })
          break

        default:
          break
      }
    }
  }, [param1, name])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const importParametersResponse =
          await ImportService.getImportParameters(name)
        if (importParametersResponse.data) {
          setImportParameters(importParametersResponse.data.columns)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, name])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading && <LoadingSpinner />}
          {!loading && (
            <Stack padding={5}>
              <TableContainer
                component={Paper}
                style={{ marginBottom: '20px' }}
              >
                <Table size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>kolumna w excelu</TableCell>
                      <TableCell>kolumna w api</TableCell>
                      <TableCell>typ danych</TableCell>
                      <TableCell>max. długość</TableCell>
                      <TableCell>puste wartości?</TableCell>
                      <TableCell>duplikaty?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {importParameters.map((importParameter) => (
                      <TableRow
                        key={importParameter.columnName[0]}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>
                          {importParameter.columnName
                            .toString()
                            .split(',')
                            .join(', ')}
                        </TableCell>
                        <TableCell>{importParameter.fieldName}</TableCell>
                        <TableCell>{importParameter.fieldType}</TableCell>
                        <TableCell>{importParameter.length}</TableCell>
                        <TableCell>
                          {importParameter.nullable ? (
                            'tak'
                          ) : (
                            <strong style={{ color: red[500] }}>nie</strong>
                          )}
                        </TableCell>
                        <TableCell>
                          {importParameter.unique ? (
                            <strong style={{ color: red[500] }}>nie</strong>
                          ) : (
                            'tak'
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <ImportPrepare
                importParameters={importParameters}
                importName={name}
                handleSetAcceptResponse={setAcceptResponse}
                additionalParams={additionalParams}
              />
              <ImportAccept
                importName={name}
                acceptResponse={acceptResponse}
                handleSetAcceptResponse={setAcceptResponse}
              />
            </Stack>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Import
