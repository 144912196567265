import React, { useEffect, useState } from 'react'
import { Box, Dialog, Grid, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  ChallengeDetails,
  ChallengeUnit,
} from '../../../../store/Challenge/types'
import ChallengeService from '../../../../services/challenge.service'
import { ImageContent } from '../../../../store/Image/types'
import ImageService from '../../../../services/image.service'
import { styled } from '@mui/material/styles'
import { ReactComponent as TopBarIcons } from '../../../../assets/images/phone/top_bar_icons.svg'
import { ReactComponent as ChallengesIcon } from '../../../../assets/images/phone/challenges_icon.svg'
import { ReactComponent as Search } from '../../../../assets/images/phone/search.svg'
import { ReactComponent as ArrowRight } from '../../../../assets/images/phone/arrow_right.svg'
import { ReactComponent as ArrowUp } from '../../../../assets/images/phone/arrow_up.svg'
import { ReactComponent as BottomNav } from '../../../../assets/images/phone/bottom_nav.svg'
import { ReactComponent as BackArrow } from '../../../../assets/images/phone/back_arrow.svg'
import { ReactComponent as RulesIcon } from '../../../../assets/images/phone/rules_icon.svg'
import { ReactComponent as LevelsIcon } from '../../../../assets/images/phone/levels_icon.svg'
import { ReactComponent as TargetIcon } from '../../../../assets/images/phone/target_icon.svg'
import { ReactComponent as AwardIcon } from '../../../../assets/images/phone/award_icon.svg'
import { ReactComponent as PenaltyIcon } from '../../../../assets/images/phone/penalty_icon.svg'
import PhoneFrame from '../../../../assets/images/phone/phone_frame.svg'
import AmateurImg from '../../../../assets/images/phone/amateur.png'
import ExpertImg from '../../../../assets/images/phone/expert.png'
import MasterImg from '../../../../assets/images/phone/master.png'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

const Phone = styled('div')({
  backgroundImage: `url(${PhoneFrame})`,
  backgroundSize: 'cover',
  padding: '12px 17px',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 400,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  height: '776px',
  position: 'relative',
})

const PhoneContainer = styled('div')({
  backgroundColor: '#F5F2E3',
  borderRadius: 26,
  height: '752px',
  paddingBottom: 56,
})

const PhoneContent = styled('div')({
  paddingTop: '16px',
  backgroundColor: '#F5F2E3',
  height: '614px',
  overflowY: 'scroll',
  /* total width */
  '::-webkit-scrollbar': {
    backgroundColor: '#F5F2E3',
    width: '16px',
  },
  /* background of the scrollbar except button or resizer */
  '::-webkit-scrollbar-track': {
    backgroundColor: '#F5F2E3',
  },
  /* scrollbar itself */
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0',
    borderRadius: '16px',
    border: '4px solid #F5F2E3',
  },
  /* set button(top and bottom of the scrollbar) */
  '::-webkit-scrollbar-button': {
    display: 'none',
  },
})

const PhoneTopBar = styled('div')({
  backgroundColor: '#00632C',
  height: 26,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: 24,
  borderTopLeftRadius: 26,
  borderTopRightRadius: 26,
})

const PhoneAppBar = styled('div')({
  height: 56,
  backgroundColor: '#00813D',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#FFFFFF',
})

const PhoneAppBarDetails = styled('div')({
  height: 56,
  backgroundColor: '#00813D',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
  color: '#FFFFFF',
  position: 'relative',
})

const ChallengeCard = styled('div')({
  background: '#FFFFFF',
  borderRadius: 4,
  padding: 16,
  marginLeft: 16,
  marginRight: 16,
  marginBottom: 16,
  gap: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const ChallengeCardHeader = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ChallengeName = styled('div')({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  display: 'flex',
  alignItems: 'center',
})

const ChallengeInfo = styled('div')({
  width: '100%',
  height: '25px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  fontSize: '12px',
  borderBottom: '1px solid #F5F2E3',
})

const ChallengeWarning = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: 'center',
  color: '#EE3042',
  svg: {
    marginBottom: 5,
  },
})

const ChallengeBottom = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 8,
  span: {
    fontSize: '16px',
    fontWeight: 700,
    marginRight: 'auto',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 31px',
    gap: '10px',
    width: '191px',
    height: '40px',
    background: '#00813D',
    borderRadius: 4,
    border: 0,
    fontWeight: 700,
    fontSize: '12px',
    color: '#fff',
    cursor: 'pointer',
  },
})

const BottomNavContainer = styled('div')({
  position: 'absolute',
  bottom: 6,
  svg: {
    borderBottomLeftRadius: 26,
    borderBottomRightRadius: 26,
  },
})

const LevelColumn = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  span: {
    fontSize: '11px',
    color: '#808080',
    lineHeight: '18px',
  },
  div: {
    fontWeight: 700,
    color: '#0E3519',
    lineHeight: '17px',
    fontSize: '13px',
    textAlign: 'center',
  },
})

interface StyledTabsProps {
  children?: React.ReactNode
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  height: '40px',
  minHeight: '40px',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
})

interface StyledTabProps {
  label: string
  value: string
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 700,
  fontSize: '13px',
  color: '#B8B8B8',
  minHeight: '40px',
  height: '40px',
  '&.Mui-selected': {
    color: '#FDFDFD',
    background: '#139248',
    borderRadius: '4px',
  },
}))

type ChallengePreviewOnPhoneDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  challengeId: number
}

const ChallengePreviewOnPhoneDialog: React.FunctionComponent<
  ChallengePreviewOnPhoneDialogProps
> = ({ open, handleClose, challengeId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [challengeDetails, setChallengeDetails] =
    useState<ChallengeDetails | null>(null)
  // const [logo, setLogo] = useState<Image | null>(null)
  const [image, setImage] = useState<ImageContent | null>(null)
  const [currentScreen, setCurrentScreen] = useState<'main' | 'details'>('main')
  const [tabValue, setTabValue] = useState('')
  const [unit, setUnit] = useState<ChallengeUnit | null>(null)

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setImage(null)
        setCurrentScreen('main')
        setLoading(true)
        const challengeDetailsResponse =
          await ChallengeService.getChallengeDetails(challengeId)
        if (challengeDetailsResponse.data) {
          setChallengeDetails(challengeDetailsResponse.data)

          if (challengeDetailsResponse.data.expert) {
            setTabValue('expert')
          } else if (challengeDetailsResponse.data.master) {
            setTabValue('master')
          } else if (challengeDetailsResponse.data.amateur) {
            setTabValue('amateur')
          } else {
            setTabValue('')
          }

          // if (challengeDetailsResponse.data.logoId) {
          //   const logoResponse = await ImageService.getImageContent(
          //     challengeDetailsResponse.data.logoId,
          //   )

          //   if (logoResponse) {
          //     setLogo(logoResponse.data)
          //   }
          // }
          if (challengeDetailsResponse.data.imageId) {
            const imageResponse = await ImageService.getImageContent(
              challengeDetailsResponse.data.imageId,
            )

            if (imageResponse) {
              setImage(imageResponse.data)
            }
          }
          if (challengeDetailsResponse.data.unitId) {
            const unitListResponse =
              await ChallengeService.getChallengeUnitList()

            if (unitListResponse.data.challengeUnits) {
              setUnit(
                unitListResponse.data.challengeUnits.find(
                  (unit) => unit.id === challengeDetailsResponse.data.unitId,
                ) || null,
              )
            }
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, challengeId])

  const renederLevelImage = (level: string) => {
    const imgStyle = {
      width: '296px',
      height: '110px',
      objectFit: 'contain',
    } as React.CSSProperties

    switch (level) {
      case 'amateur':
        return <img src={AmateurImg} alt="" style={imgStyle} />
      case 'expert':
        return <img src={ExpertImg} alt="" style={imgStyle} />
      case 'master':
        return <img src={MasterImg} alt="" style={imgStyle} />

      default:
        return <img src={ExpertImg} alt="" />
    }
  }

  const renderLevelBlock = (level: string) => {
    const xpPlus = parseInt((challengeDetails as any)[`${level}XpPlus`])
    const vpPlus = parseInt((challengeDetails as any)[`${level}VpPlus`])
    const epPlus = parseInt((challengeDetails as any)[`${level}EpPlus`])

    const xpMinus = parseInt((challengeDetails as any)[`${level}XpMinus`])
    const vpMinus = parseInt((challengeDetails as any)[`${level}VpMinus`])
    const epMinus = parseInt((challengeDetails as any)[`${level}EpMinus`])

    if (!challengeDetails) {
      return null
    }
    return (
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <LevelColumn>
              <TargetIcon />
              <span>{t('pages.challenge.previewOnPhoneDialog.target')}</span>
              <div>{unit?.name}</div>
            </LevelColumn>
          </Grid>
          <Grid item xs={4}>
            <LevelColumn>
              <AwardIcon />
              <span>{t('pages.challenge.previewOnPhoneDialog.award')}</span>
              {isXpPointsVisible() && (
                <div>
                  {xpPlus} {t('pages.challenge.previewOnPhoneDialog.xp')}
                </div>
              )}
              {isVpPointsVisible() && (
                <div>
                  {vpPlus} {t('pages.challenge.previewOnPhoneDialog.vp')}
                </div>
              )}
              {isEpPointsVisible() && (
                <div>
                  {epPlus} {t('pages.challenge.previewOnPhoneDialog.ep')}
                </div>
              )}
            </LevelColumn>
          </Grid>
          <Grid item xs={4}>
            <LevelColumn>
              <PenaltyIcon />
              <span>{t('pages.challenge.previewOnPhoneDialog.penalty')}</span>
              {isXpPointsVisible() && (
                <div>
                  {xpMinus > 0 ? '-' : ''}
                  {xpMinus} {t('pages.challenge.previewOnPhoneDialog.xp')}
                </div>
              )}
              {isVpPointsVisible() && (
                <div>
                  {vpMinus > 0 ? '-' : ''}
                  {vpMinus} {t('pages.challenge.previewOnPhoneDialog.vp')}
                </div>
              )}
              {isEpPointsVisible() && (
                <div>
                  {epMinus > 0 ? '-' : ''}
                  {epMinus} {t('pages.challenge.previewOnPhoneDialog.ep')}
                </div>
              )}
            </LevelColumn>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(false)}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '394px',
            height: '776px',
            background: 'transparent',
            boxShadow: 'none',
          },
        },
      }}
    >
      <Phone>
        {loading && (
          <PhoneContainer>
            <PhoneTopBar>
              <TopBarIcons />
            </PhoneTopBar>
            <PhoneAppBar>
              <ChallengesIcon style={{ marginRight: 10 }} />
              {t('pages.challenge.previewOnPhoneDialog.challenges')}
            </PhoneAppBar>
            <LoadingSpinner />
          </PhoneContainer>
        )}
        {!loading && challengeDetails && currentScreen === 'main' && (
          <PhoneContainer onClick={() => setCurrentScreen('details')}>
            <PhoneTopBar>
              <TopBarIcons />
            </PhoneTopBar>
            <PhoneAppBar>
              <ChallengesIcon style={{ marginRight: 10 }} />
              {t('pages.challenge.previewOnPhoneDialog.challenges')}
            </PhoneAppBar>
            <Search style={{ margin: 16 }} />
            <ChallengeCard>
              <ChallengeCardHeader>
                <ChallengeName>{challengeDetails.name}</ChallengeName>
                <ArrowRight />
              </ChallengeCardHeader>
              {image && (
                <img
                  src={`data:${image.imageMimeType};base64,${image.imageContent}`}
                  alt={image.name}
                  style={{
                    width: '296px',
                    height: '165px',
                    objectFit: 'contain',
                  }}
                />
              )}
              {/* {logo && (
              <img
                src={`data:${logo.imageMimeType};base64,${logo.imageContent}`}
                alt={logo.name}
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'contain',
                }}
              />
            )} */}
              <ChallengeInfo>
                <div>
                  {t('pages.challenge.previewOnPhoneDialog.left')}:{' '}
                  <strong>
                    ... {t('pages.challenge.previewOnPhoneDialog.days')}
                  </strong>
                </div>
                <div>
                  {t('pages.challenge.previewOnPhoneDialog.applications')}:{' '}
                  <strong>
                    {challengeDetails.availableFrom.substring(8, 10)}.
                    {challengeDetails.availableFrom.substring(5, 7)} -{' '}
                    {challengeDetails.availableTo.substring(8, 10)}.
                    {challengeDetails.availableTo.substring(5, 7)}
                  </strong>
                </div>
              </ChallengeInfo>
              <ChallengeBottom>
                <span>
                  {t(
                    'pages.challenge.previewOnPhoneDialog.registrationForChallenge',
                  )}
                </span>
                <button>
                  {t('pages.challenge.previewOnPhoneDialog.joinChallenge')}
                </button>
              </ChallengeBottom>
            </ChallengeCard>
            <BottomNavContainer>
              <BottomNav />
            </BottomNavContainer>
          </PhoneContainer>
        )}
        {!loading && challengeDetails && currentScreen === 'details' && (
          <PhoneContainer>
            <PhoneTopBar>
              <TopBarIcons />
            </PhoneTopBar>
            <PhoneAppBarDetails>
              <BackArrow
                onClick={() => setCurrentScreen('main')}
                style={{ position: 'absolute', top: 16, left: 16 }}
              />
              {challengeDetails.name}
            </PhoneAppBarDetails>
            <PhoneContent>
              <ChallengeCard style={{ marginRight: 0 }}>
                <ChallengeInfo>
                  <div>
                    {t('pages.challenge.previewOnPhoneDialog.startsIn')}:{' '}
                    <strong>
                      ... {t('pages.challenge.previewOnPhoneDialog.days')}
                    </strong>
                  </div>
                  <div>
                    {t('pages.challenge.previewOnPhoneDialog.pending')}:{' '}
                    <strong>
                      {challengeDetails.validFrom.substring(8, 10)}.
                      {challengeDetails.validFrom.substring(5, 7)} -{' '}
                      {challengeDetails.validTo.substring(8, 10)}.
                      {challengeDetails.validTo.substring(5, 7)}
                    </strong>
                  </div>
                </ChallengeInfo>
                <ChallengeBottom style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '20px', fontWeight: 700 }}>
                    {t(
                      'pages.challenge.previewOnPhoneDialog.registrationForChallenge',
                    )}
                  </div>
                  <div style={{ fontSize: '13px' }}>
                    {t('pages.challenge.previewOnPhoneDialog.registrationInfo')}
                  </div>
                </ChallengeBottom>
              </ChallengeCard>
              <ChallengeCard style={{ marginRight: 0 }}>
                <ChallengeCardHeader>
                  <ChallengeName>
                    <RulesIcon style={{ marginRight: 5, fill: '#00632C' }} />
                    {t('pages.challenge.previewOnPhoneDialog.challengeRules')}
                  </ChallengeName>
                  <ArrowUp />
                </ChallengeCardHeader>
                {challengeDetails.description ? (
                  <div
                    style={{ fontSize: '13px', width: '100%' }}
                    dangerouslySetInnerHTML={{
                      __html: challengeDetails.description,
                    }}
                  />
                ) : (
                  <ChallengeWarning>
                    <WarningAmberIcon />
                    {t('pages.challenge.previewOnPhoneDialog.emptyStep2')}
                  </ChallengeWarning>
                )}
                {image && (
                  <img
                    src={`data:${image.imageMimeType};base64,${image.imageContent}`}
                    alt={image.name}
                    style={{
                      width: '150px',
                      height: '150px',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </ChallengeCard>
              <ChallengeCard style={{ marginRight: 0 }}>
                <ChallengeCardHeader>
                  <ChallengeName>
                    <LevelsIcon style={{ marginRight: 5 }} />
                    {t(
                      'pages.challenge.previewOnPhoneDialog.chooseChallengeLevel',
                    )}
                  </ChallengeName>
                  <ArrowUp />
                </ChallengeCardHeader>
                {tabValue !== '' && renederLevelImage(tabValue)}
                {tabValue !== '' && renderLevelBlock(tabValue)}
                {tabValue !== '' && (
                  <Box
                    sx={{
                      width: '100%',
                      background: '#FDFDFD',
                      border: '1px solid #F5F2E3',
                      borderRadius: '4px',
                      padding: '8px',
                    }}
                  >
                    <StyledTabs value={tabValue} onChange={handleTabChange}>
                      {challengeDetails.amateur && (
                        <StyledTab
                          label={t(
                            'pages.challenge.previewOnPhoneDialog.amateur',
                          )}
                          value="amateur"
                        />
                      )}
                      {challengeDetails.expert && (
                        <StyledTab
                          label={t(
                            'pages.challenge.previewOnPhoneDialog.expert',
                          )}
                          value="expert"
                        />
                      )}
                      {challengeDetails.master && (
                        <StyledTab
                          label={t(
                            'pages.challenge.previewOnPhoneDialog.master',
                          )}
                          value="master"
                        />
                      )}
                    </StyledTabs>
                  </Box>
                )}
                {tabValue === '' && (
                  <ChallengeWarning>
                    <WarningAmberIcon />
                    {t('pages.challenge.previewOnPhoneDialog.emptyStep4')}
                  </ChallengeWarning>
                )}
              </ChallengeCard>
            </PhoneContent>
            <BottomNavContainer>
              <BottomNav />
            </BottomNavContainer>
          </PhoneContainer>
        )}
      </Phone>
    </Dialog>
  )
}

export default ChallengePreviewOnPhoneDialog
