import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, SelectChangeEvent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UsersPlansToolbar from '../partials/UsersPlansToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import PeriodService from '../../../../services/period.service'
import UserPlanService from '../../../../services/userPlan.service'
import PlanService from '../../../../services/plan.service'
import CompanyService from '../../../../services/company.service'
import {
  getHiddenColumns,
  getTableState,
  setTableState,
  thousandsSeparator,
} from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { UserPlan } from '../../../../store/UserPlan/types'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type UsersPlansListProps = {
  path: string
  user: User
}

const UsersPlansList: React.FC<UsersPlansListProps> = ({ path, user }) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'userPlans'
  const periodState = getTableState(tableName, 'period')

  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>('')
  const [periodValue, setPeriodValue] = useState<string>(
    periodState ? periodState : 'all',
  )

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filteredUsersPlansList, setFilteredUsersPlansList] = useState<
    UserPlan[]
  >([])
  const [periods, setPeriods] = useState<Period[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [plansFilter, setPlansFilter] = useState<Option[]>([])
  const [selectedPlansFilter, setSelectedPlansFilter] = useState<Option[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const generateTableColumns = useCallback(
    (userPlans: UserPlan[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.userPlans.table.planId').toString(),
          accessor: 'planId',
          width: 80,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.userPlans.table.periodName').toString(),
          accessor: 'periodName',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.userPlans.table.planName').toString(),
          accessor: 'planName',
          width: 220,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'userCentralId',
          Header: t('pages.userPlans.table.userId').toString(),
          width: 90,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'email',
          Header: t('pages.userPlans.table.email').toString(),
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'firstname',
          Header: t('pages.userPlans.table.firstname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'lastname',
          Header: t('pages.userPlans.table.lastname').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'companyName',
          Header: t('pages.userPlans.table.companyName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'regionName',
          Header: t('pages.userPlans.table.regionName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'plan',
          Header: t('pages.userPlans.table.plan').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlans.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'realization',
          Header: t('pages.userPlans.table.realization').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlans.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'percentRealization',
          Header: t('pages.userPlans.table.percentRealization').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : t('pages.userPlans.table.null')}
            </Stack>
          ),
        },
        {
          accessor: 'forecast',
          Header: t('pages.userPlans.table.forecast').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlans.table.none')}
            </Stack>
          ),
        },
        {
          accessor: 'percentForecast',
          Header: t('pages.userPlans.table.percentForecast').toString(),
          width: 100,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value, true) + '%'
                : t('pages.userPlans.table.null')}
            </Stack>
          ),
        },
      )
      isXpPointsVisible() &&
        columns.push({
          accessor: 'xpAmount',
          Header: t('pages.userPlans.table.xpAmount').toString(),
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlans.table.null')}
            </Stack>
          ),
        })
      isVpPointsVisible() &&
        columns.push({
          accessor: 'vpAmount',
          Header: t('pages.userPlans.table.vpAmount').toString(),
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlans.table.null')}
            </Stack>
          ),
        })
      isEpPointsVisible() &&
        columns.push({
          accessor: 'epAmount',
          Header: t('pages.userPlans.table.epAmount').toString(),
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value)
                : t('pages.userPlans.table.null')}
            </Stack>
          ),
        })

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const response = await UserPlanService.getUserPlans(
            selectedCompanies.map((company) => ({ id: company.value })),
            periodValue === 'all'
              ? periods.map((period) => ({ id: period.id }))
              : [{ id: parseInt(periodValue) }],
            selectedPlansFilter.map((plan) => ({
              id: plan.value,
            })),
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (response.data.userPlans) {
            setTableColumns(generateTableColumns(response.data.userPlans))

            setFilteredUsersPlansList(response.data.userPlans)

            setTotalCount(response.data.totalCount)
            setPageCount(Math.ceil(response.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      searchValue,
      generateTableColumns,
      selectedPlansFilter,
      selectedCompanies,
      periods,
    ],
  )

  useEffect(() => {
    const fetchPeriodPlans = async () => {
      try {
        const plansFilterResponse = await PlanService.getPlanFilter(
          parseInt(periodValue),
        )

        if (plansFilterResponse.data.plans) {
          const multiSelectOptions: Option[] = []
          plansFilterResponse.data.plans.forEach((plan) =>
            multiSelectOptions.push({
              value: plan.planId,
              label: plan.name,
            }),
          )
          setPlansFilter(multiSelectOptions)
          setSelectedPlansFilter(multiSelectOptions)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    if (periodValue) {
      fetchPeriodPlans()
    }
  }, [periodValue, t])

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)

        const periodListResponse = await PeriodService.getPeriodList()
        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
        }

        const companyListResponse = await CompanyService.getCompanyList()

        if (companyListResponse.data.companies) {
          const multiSelectOptions: Option[] = []
          companyListResponse.data.companies.forEach((company) =>
            multiSelectOptions.push({
              value: company.companyId,
              label: company.name,
            }),
          )
          setCompanies(multiSelectOptions)
          setSelectedCompanies(multiSelectOptions)
        }

        const plansFilterResponse = await PlanService.getPlanFilter(
          parseInt(periodValue),
        )

        if (plansFilterResponse.data.plans) {
          const multiSelectOptions: Option[] = []
          plansFilterResponse.data.plans.forEach((plan) =>
            multiSelectOptions.push({
              value: plan.planId,
              label: plan.name,
            }),
          )
          setPlansFilter(multiSelectOptions)
          setSelectedPlansFilter(multiSelectOptions)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t, periodValue])

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const response = await UserPlanService.getUserPlans(
        selectedCompanies.map((company) => ({ id: company.value })),
        periodValue === 'all'
          ? periods.map((period) => ({ id: period.id }))
          : [{ id: parseInt(periodValue) }],
        selectedPlansFilter.map((plan) => ({
          id: plan.value,
        })),
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataUserPlans = response.data.userPlans
      if (dataUserPlans) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'planId',
            'periodName',
            'planName',
            'userCentralId',
            'email',
            'firstname',
            'lastname',
            'companyName',
            'regionName',
            'plan',
            'realization',
            'percentRealization',
            'forecast',
            'percentForecast',
            ...(isXpPointsVisible() ? ['xpAmount'] : []),
            ...(isVpPointsVisible() ? ['vpAmount'] : []),
            ...(isEpPointsVisible() ? ['epAmount'] : []),
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredUsersPlansList = dataUserPlans.map((plan) =>
          pick(plan, visibleColumns),
        )

        const translatedHeaders = {
          planId: t('excel.userPlans.planId'),
          periodName: t('excel.userPlans.periodName'),
          planName: t('excel.userPlans.planName'),
          userCentralId: t('excel.userPlans.userId'),
          email: t('excel.userPlans.email'),
          firstname: t('excel.userPlans.firstname'),
          lastname: t('excel.userPlans.lastname'),
          companyName: t('excel.userPlans.companyName'),
          regionName: t('excel.userPlans.regionName'),
          plan: t('excel.userPlans.plan'),
          realization: t('excel.userPlans.realization'),
          percentRealization: t('excel.userPlans.percentRealization'),
          forecast: t('excel.userPlans.forecast'),
          percentForecast: t('excel.userPlans.percentForecast'),
          xpAmount: t('excel.userPlans.xpAmount'),
          vpAmount: t('excel.userPlans.vpAmount'),
          epAmount: t('excel.userPlans.epAmount'),
        }

        const headers = [
          Object.keys(filteredUsersPlansList[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredUsersPlansList, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('userPlans')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>

          <UsersPlansToolbar
            periods={periods}
            periodValue={periodValue}
            value={searchText}
            companies={companies}
            selectedCompanies={selectedCompanies}
            plansFilter={plansFilter}
            selectedPlansFilter={selectedPlansFilter}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
            }}
            filterPeriod={(event: SelectChangeEvent) => {
              setPeriodValue(event.target.value)
              setSkipPageReset(false)
              setTableState(tableName, 'period', event.target.value)
            }}
            setSelectedCompanies={(companies: Option[]) => {
              setSelectedCompanies(companies)
              setSkipPageReset(false)
            }}
            setSelectedPlansFilter={(plans: Option[]) => {
              setSelectedPlansFilter(plans)
              setSkipPageReset(false)
            }}
            user={user}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredUsersPlansList}
            height="calc(100vh - 330px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={0}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[
              'periodName',
              'planName',
              'email',
              'firstname',
              'lastname',
              'companyName',
              'regionName',
              'forecast',
              'percentForecast',
              ...(isEpPointsVisible() ? ['epAmount'] : []),
            ]}
            toggleVisibility={setColumnsVisibility}
            saveTableState={false}
          />
        </>
      )}
    </>
  )
}

export default UsersPlansList
