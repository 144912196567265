import React, { FunctionComponent } from 'react'
import plLocale from 'date-fns/locale/pl'
import { FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import { FormError } from '../../store/types'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateValidationError } from '@mui/x-date-pickers/internals'

type DateTimePickerProps = {
  label: string
  date: Date | null
  time: Date | null
  onDateChange: (date: Date | null, keyboardInputValue?: string) => void
  onTimeChange: (date: Date | null, keyboardInputValue?: string) => void
  maxDate?: Date
  minDate?: Date
  error?: FormError
  style?: React.CSSProperties
  onError?: (reason: DateValidationError, value: Date | null) => void
}

const DateTimePicker: FunctionComponent<DateTimePickerProps> = ({
  label,
  date,
  time,
  onDateChange,
  onTimeChange,
  maxDate,
  minDate,
  error,
  style,
  onError,
}) => {
  return (
    <FormControl error={error && error.error} style={style}>
      <label>{label}</label>
      <div style={{ display: 'flex' }}>
        <Grid container>
          <Grid item xs={7}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={plLocale}
            >
              <DatePicker
                value={date}
                onChange={onDateChange}
                renderInput={(params) => <TextField {...params} size="small" />}
                maxDate={maxDate}
                minDate={minDate}
                mask="__.__.____"
                inputFormat="dd.MM.yyyy"
                onError={onError}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={plLocale}
            >
              <TimePicker
                value={time}
                onChange={onTimeChange}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
      {error && error.error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}

export default DateTimePicker
