import React from 'react'
import { Box, Tooltip, IconButton } from '@mui/material'
import { ImportParameterColumn } from '../../../../store/Import/types'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import { useTranslation } from 'react-i18next'
import * as XLSX from 'xlsx'

type ImportColumnsProps = {
  importParameters: ImportParameterColumn[]
}

const ImportColumns: React.FC<ImportColumnsProps> = ({ importParameters }) => {
  const { t } = useTranslation()

  const downloadXLSX = () => {
    const fileName = `${t('common.form')}.xlsx`

    const headers = [importParameters.map((param) => param.columnName[0])]

    //Had to create a new workbook and then add the header
    const ws: XLSX.WorkSheet = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(ws, headers)

    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, fileName)

    XLSX.writeFile(wb, fileName)
  }

  return (
    <Box sx={{ display: 'flex', color: '#9E9E9E', fontSize: '14px' }} mb={1}>
      <InfoOutlinedIcon sx={{ marginRight: 1 }} fontSize="small" />
      <Box sx={{ marginRight: 1 }}>{t('import.generic.columns')}:</Box>
      <Box id="columnFields" sx={{ marginRight: 1, fontWeight: 'bold' }}>
        {importParameters.map((parameter, i) => (
          <span key={i}>
            {parameter.columnName[0]}
            {i < importParameters.length - 1 && <>, </>}{' '}
          </span>
        ))}
      </Box>
      <Tooltip title={`${t('common.downloadXlsxForm')}`}>
        <IconButton
          aria-label="copy"
          size="small"
          style={{ padding: 0, marginRight: '5px' }}
          onClick={downloadXLSX}
        >
          <DownloadIcon sx={{ width: '20px' }} fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default ImportColumns
