import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { ActionContainer } from './styles'
import { grey } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'
import FileUploader from '../FileUploader'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ImageLibrary from '../../../../ImageLibrary/ImageLibrary'
import { ImageTypeCode } from '../../../../../store/Image/types'
import draftToHtml from 'draftjs-to-html'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import TradeActionService from '../../../../../services/tradeAction.service'
import {
  Action,
  CreateOrUpdateRulesParams,
  RulesErrors,
  RulesFile,
} from '../../../../../store/TradeAction/types'
import { FormError } from '../../../../../store/types'
import { getBase64 } from '../../../../../helpers/utils'
import FileList from '../FileList'
import htmlToDraft from 'html-to-draftjs'

const EDITOR_MAX_LENGTH = 10000
type RulesProps = {
  actionId: number | null
  tradeActionDetails?: Action
}

const Rules: FunctionComponent<RulesProps> = ({
  actionId,
  tradeActionDetails,
}) => {
  const { t } = useTranslation()
  const [savingStep, setSavingStep] = useState(false)
  const [imageId, setImageId] = useState<number | null>(null)
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )
  const [description, setDescription] = useState<string | null>(null)
  const [descriptionLength, setDescriptionLength] = useState<number>(0)
  const [files, setFiles] = useState<RulesFile[]>([])

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultRulesFormErrors: RulesErrors = {
    description: defaultError,
  }
  const [rulesFormErrors, setRulesFormErrors] = useState<RulesErrors>(
    defaultRulesFormErrors,
  )

  useEffect(() => {
    setDescriptionLength(
      editorState.getCurrentContent().getPlainText('').length,
    )
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  const onEditorStateChange = (currentEditorState: EditorState) => {
    const contentState = currentEditorState.getCurrentContent()
    const oldContent = editorState.getCurrentContent()
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= EDITOR_MAX_LENGTH
    ) {
      setEditorState(currentEditorState)
    } else {
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      )
      setEditorState(newEditorState)
    }
  }

  useEffect(() => {
    // load data if update
    if (tradeActionDetails) {
      if (tradeActionDetails.rules) {
        const blocksFromHTML = htmlToDraft(tradeActionDetails.rules)
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )
        setEditorState(EditorState.createWithContent(content))
        setDescription(tradeActionDetails.rules)
      }
      setFiles(
        tradeActionDetails.files.map((file) => ({
          fileId: file.fileId,
          fileName: file.fileName,
          fileTypeCode: 'RUF',
          fileContent: null,
        })),
      )
    }
  }, [tradeActionDetails])

  if (!actionId) {
    return (
      <ActionContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">2</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography variant="subtitle1" fontWeight={'bold'} component="div">
              Dodaj Zasady
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              dangerouslySetInnerHTML={{
                __html: t('pages.tradeActions.rules.addBlockCondition'),
              }}
            ></Typography>
            <IconButton
              aria-label="delete"
              disabled
              size="large"
              className="add-button"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      </ActionContainer>
    )
  }

  const handleAcceptedFiles = async (
    acceptedFiles: File[] | Blob[] | MediaSource[],
  ) => {
    const filesToAdd: RulesFile[] = []
    for (const acceptedFile of acceptedFiles) {
      const fileContent = await getBase64(acceptedFile as Blob)
      filesToAdd.push({
        fileId: null,
        fileName: (acceptedFile as File).name,
        fileTypeCode: 'RUF',
        fileContent: fileContent?.toString().split('base64,')[1] || '',
      })
    }
    setFiles((prevState) => [...prevState, ...filesToAdd])
  }

  const clearForm = () => {
    setRulesFormErrors(defaultRulesFormErrors)
    setEditorState(EditorState.createEmpty())
    setDescription(null)
    setFiles([])
    setImageId(null)
  }

  const saveForm = async () => {
    setRulesFormErrors(defaultRulesFormErrors)
    const rulesFormErrors = defaultRulesFormErrors

    if (descriptionLength === 0 || !description) {
      setRulesFormErrors({
        ...rulesFormErrors,
        description: {
          error: true,
          message: t('pages.tradeActions.rules.form.descriptionRequired'),
        },
      })
      return
    }

    const formParams: CreateOrUpdateRulesParams = {
      actionId,
      rules: description,
      imageId,
      files: files.length > 0 ? files : null,
    }

    try {
      setSavingStep(true)
      const createInformationsResponse =
        await TradeActionService.createOrUpdateRules(formParams)

      if (createInformationsResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        setFiles(
          createInformationsResponse.data.files.map((responseFile) => ({
            ...responseFile,
            fileTypeCode: 'RUF',
            fileContent: null,
          })),
        )
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSavingStep(false)
    }
  }

  return (
    <ActionContainer>
      <Stack direction={'row'}>
        <div className="step-line">
          <div className="step-circle">2</div>
          <div className="step-arrow"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <div className="step-title">
                {t('pages.tradeActions.rules.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.tradeActions.rules.subtitle')}
              </div>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <label>{t('pages.tradeActions.rules.form.description')}</label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: ['inline', 'list', 'colorPicker', 'emoji'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                  },
                }}
              />
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <small style={{ color: grey[500] }}>
                  {descriptionLength} / {EDITOR_MAX_LENGTH}
                </small>
              </Stack>
              {rulesFormErrors.description.error && descriptionLength === 0 && (
                <FormHelperText error>
                  {rulesFormErrors.description.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <ImageLibrary
                imageType={ImageTypeCode.TMARI}
                selectedImageId={tradeActionDetails?.rulesImageId || null}
                setSelectedImageId={setImageId}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <label>{t('pages.tradeActions.rules.form.files')}</label>
              <FileUploader
                label={t('pages.tradeActions.fileUploader.dragAndDropFiles')}
                buttonLabel={t('pages.tradeActions.fileUploader.addFilesLabel')}
                hintLabel={t('pages.tradeActions.rules.form.filesHint')}
                accept={{
                  'image/png': [],
                  'image/jpeg': [],
                  'application/pdf': [],
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    [],
                  'application/vnd.ms-excel': [],
                }}
                handleAcceptedFiles={handleAcceptedFiles}
                maxSize={25000000} // 25MB
              />
              <FileList files={files} setFiles={setFiles} />
            </Grid>
          </Grid>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
            className="buttons-container"
          >
            <Stack spacing={2} direction="row">
              <SecondaryButton onClick={clearForm} disabled={savingStep}>
                {t('common.clear')}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  saveForm()
                }}
                disabled={savingStep}
              >
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </ActionContainer>
  )
}

export default Rules
