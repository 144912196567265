import { AlertColor, Stack } from '@mui/material'
import React from 'react'
import { TFunction } from 'react-i18next'
import { downloadXLSX } from '../../../../../helpers/utils'
import { ImportOrdersResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getOrdersResponse = (
  importResponse: ImportOrdersResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (messageType === 'prepare') {
    if (
      (importResponse.data.addedResultsSummary ||
        importResponse.data.addedResultsSummary === 0) &&
      (importResponse.data.skippedResultsSummary ||
        importResponse.data.skippedResultsSummary === 0) &&
      (importResponse.data.updatedResultsSummary ||
        importResponse.data.updatedResultsSummary === 0)
    ) {
      const addedResultsSummary = importResponse.data.addedResultsSummary
      const skippedResultsSummary = importResponse.data.skippedResultsSummary
      const updatedResultsSummary = importResponse.data.updatedResultsSummary
      const skippedStores = importResponse.data.skippedStores
      const skippedProducts = importResponse.data.skippedProducts
      const skippedOrders = importResponse.data.skippedOrders

      const renderAdditionalInfo = () => {
        return (
          <Stack display="flex" flexDirection="row">
            {skippedStores && skippedStores.length > 0 && (
              <div>
                <SecondaryButton
                  onClick={() =>
                    downloadXLSX(
                      skippedStores.map((result) => ({
                        storeCentralId: result,
                      })),
                      t('import.messages.orders.skippedStoresFilename'),
                    )
                  }
                  size="small"
                  style={{ marginBottom: '10px', marginRight: '5px' }}
                >
                  {t('import.messages.orders.downloadSkippedStores')}
                </SecondaryButton>
              </div>
            )}
            {skippedProducts && skippedProducts.length > 0 && (
              <div>
                <SecondaryButton
                  onClick={() =>
                    downloadXLSX(
                      skippedProducts.map((result) => ({
                        productCentralId: result,
                      })),
                      t('import.messages.orders.skippedProductsFilename'),
                    )
                  }
                  size="small"
                  style={{ marginBottom: '10px', marginRight: '5px' }}
                >
                  {t('import.messages.orders.downloadSkippedProducts')}
                </SecondaryButton>
              </div>
            )}
            {skippedOrders && skippedOrders.length > 0 && (
              <div>
                <SecondaryButton
                  onClick={() =>
                    downloadXLSX(
                      skippedOrders,
                      t('import.messages.orders.skippedOrdersFilename'),
                    )
                  }
                  size="small"
                  style={{ marginBottom: '10px' }}
                >
                  {t('import.messages.orders.downloadSkippedOrders')}
                </SecondaryButton>
              </div>
            )}
          </Stack>
        )
      }

      return {
        message: {
          type: alertType,
          message: t(`import.messages.orders.${messageType}Message`, {
            addedResults: addedResultsSummary.toLocaleString(),
            skippedResults: skippedResultsSummary.toLocaleString(),
            updatedResults: updatedResultsSummary.toLocaleString(),
          }),
          hasSecondStep: true,
        },
        additionalInfo: renderAdditionalInfo(),
      }
    }
  }
  if (messageType === 'accept') {
    if (
      (importResponse.data.addedResults ||
        importResponse.data.addedResults === 0) &&
      (importResponse.data.updatedResults ||
        importResponse.data.updatedResults === 0)
    ) {
      const addedResults = importResponse.data.addedResults
      const updatedResults = importResponse.data.updatedResults

      return {
        message: {
          type: alertType,
          message: t(`import.messages.orders.${messageType}Message`, {
            addedResults: addedResults.toLocaleString(),
            updatedResults: updatedResults.toLocaleString(),
          }),
          hasSecondStep: true,
        },
      }
    }
  }
  return null
}
