import { FunctionComponent, useCallback, useRef, useState } from 'react'
import {
  CircularProgress,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  getHiddenColumns,
  getTableState,
  handleNavigationClick,
  setTableState,
  thousandsSeparator,
  zeroPad,
} from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import RankingToolbar from '../partials/RankingToolbar'
import TableControlled from '../../../Table/TableControlled'
import { Column, ColumnInstance } from 'react-table'
import { errorHandler } from '../../../../helpers/errorHandler'
import EduboxService from '../../../../services/edubox.service'
import { QuizUser } from '../../../../store/Edubox/types'
import { useParams } from 'react-router-dom'
import { ReactComponent as PreviewPhoneIcon } from '../../../../assets/images/icons/preview_phone.svg'
import QuizPreviewOnPhoneDialog from '../partials/QuizPreviewOnPhoneDialog'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { pick } from 'lodash'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type QuizRankingListProps = {
  path: string
}

type QuizRankingListParams = {
  quizId: string
}

const QuizRankingList: FunctionComponent<QuizRankingListProps> = ({ path }) => {
  const { t } = useTranslation()
  let { quizId } = useParams<QuizRankingListParams>()
  const fetchIdRef = useRef(0)
  const tableName = 'quiz-ranking-list'

  const statusState = getTableState(tableName, 'status')
  const [searchText, setSearchText] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [filteredUsersList, setFilteredUsersList] = useState<QuizUser[]>([])
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false)
  const [userId, setUserId] = useState<number | null>(null)
  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [statusValue, setStatusValue] = useState<string>(
    statusState ? statusState : 'all',
  )

  const handlePhoneDialogClickOpen = (userId: number) => {
    setUserId(userId)
    setPhoneDialogOpen(true)
  }

  const handlePhoneDialogClose = () => {
    setPhoneDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (users: QuizUser[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.edubox.table.userId').toString(),
          accessor: 'userId',
          width: 70,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.edubox.table.userFirstname').toString(),
          accessor: 'userFirstname',
          width: 120,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.edubox.table.userLastname').toString(),
          accessor: 'userLastname',
          width: 160,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.edubox.table.companyName').toString(),
          accessor: 'companyName',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.edubox.table.questions').toString(),
          accessor: 'questions',
          width: 100,
          disableSortBy: true,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.row.original.incorrectQuestionAnswers !== null
                ? thousandsSeparator(
                    params.row.original.incorrectQuestionAnswers +
                      params.row.original.correctQuestionAnswers,
                  )
                : ''}
            </Stack>
          ),
        },
        {
          Header: t('pages.edubox.table.incorrectQuestionAnswers').toString(),
          accessor: 'incorrectQuestionAnswers',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        },
        {
          Header: t('pages.edubox.table.incorrectAnswersPercent').toString(),
          accessor: 'incorrectAnswersPercent',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value) + '%'
                : ''}
            </Stack>
          ),
        },
        {
          Header: t('pages.edubox.table.correctQuestionAnswers').toString(),
          accessor: 'correctQuestionAnswers',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        },
        {
          Header: t('pages.edubox.table.correctAnswersPercent').toString(),
          accessor: 'correctAnswersPercent',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? thousandsSeparator(params.value) + '%'
                : ''}
            </Stack>
          ),
        },
        {
          Header: t('pages.edubox.table.quizTime').toString(),
          accessor: 'quizTime',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null
                ? `${Math.floor(params.value / 60)}:${zeroPad(
                    params.value % 60,
                    2,
                  )}`
                : ''}
            </Stack>
          ),
        },
        {
          Header: t('pages.edubox.table.rankPlace').toString(),
          accessor: 'rankPlace',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        },
      )
      isXpPointsVisible() &&
        columns.push({
          Header: t('pages.edubox.table.xpAmount').toString(),
          accessor: 'xpAmount',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        })
      isVpPointsVisible() &&
        columns.push({
          Header: t('pages.edubox.table.vpAmount').toString(),
          accessor: 'vpAmount',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        })
      isEpPointsVisible() &&
        columns.push({
          Header: t('pages.edubox.table.epAmount').toString(),
          accessor: 'epAmount',
          width: 80,
          Cell: (params: any) => (
            <Stack textAlign="right">
              {params.value !== null ? thousandsSeparator(params.value) : ''}
            </Stack>
          ),
        })
      columns.push({
        accessor: 'actions',
        Header: t('pages.edubox.table.actions').toString(),
        width: 55,
        disableSortBy: true,
        sticky: 'right',
        Cell: (params: any) => (
          <Grid container>
            <Grid
              item
              sx={{
                width: '35px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tooltip title={`${t('pages.edubox.table.previewOnPhone')}`}>
                <IconButton
                  onClick={() =>
                    handlePhoneDialogClickOpen(params.row.original.userId)
                  }
                  size="small"
                  style={{
                    padding: 0,
                    opacity: params.row.original.quizTime === null ? '.3' : '1',
                  }}
                  disabled={params.row.original.quizTime === null}
                >
                  <PreviewPhoneIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ),
      })

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)

          const page = ++pageIndex
          const eduboxResponse = await EduboxService.getQuizPreview(
            parseInt(quizId),
            statusValue === 'all' ? null : statusValue === '1' ? true : false,
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (eduboxResponse.data.users) {
            setTableColumns(generateTableColumns(eduboxResponse.data.users))

            setFilteredUsersList(eduboxResponse.data.users)

            setTotalCount(eduboxResponse.data.totalCount)
            setPageCount(Math.ceil(eduboxResponse.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [t, generateTableColumns, searchValue, quizId, statusValue],
  )

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const response = await EduboxService.getQuizPreview(
        parseInt(quizId),
        statusValue === 'all' ? null : statusValue === '1' ? true : false,
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const quizPreviewResponse = response.data.users
      if (quizPreviewResponse) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'userId',
            'userFirstname',
            'userLastname',
            'companyName',
            'questions',
            'incorrectQuestionAnswers',
            'incorrectAnswersPercent',
            'correctQuestionAnswers',
            'correctAnswersPercent',
            'quizTime',
            'rankPlace',
            ...(isXpPointsVisible() ? ['xpAmount'] : []),
            ...(isVpPointsVisible() ? ['vpAmount'] : []),
            ...(isEpPointsVisible() ? ['epAmount'] : []),
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredChallengeResultList = quizPreviewResponse.map((user) => {
          user.questions =
            (user.correctQuestionAnswers || 0) +
            (user.incorrectQuestionAnswers || 0)
          user.quizTime =
            user.quizTime !== null
              ? `${Math.floor(
                  parseInt(user.quizTime.toString()) / 60,
                )}:${zeroPad(parseInt(user.quizTime.toString()) % 60, 2)}`
              : ''
          return pick(user, visibleColumns)
        })

        const translatedHeaders = {
          userId: t('excel.quizRanking.userId'),
          userFirstname: t('excel.quizRanking.userFirstname'),
          userLastname: t('excel.quizRanking.userLastname'),
          companyName: t('excel.quizRanking.companyName'),
          questions: t('excel.quizRanking.questions'),
          incorrectQuestionAnswers: t(
            'excel.quizRanking.incorrectQuestionAnswers',
          ),
          incorrectAnswersPercent: t(
            'excel.quizRanking.incorrectAnswersPercent',
          ),
          correctQuestionAnswers: t('excel.quizRanking.correctQuestionAnswers'),
          correctAnswersPercent: t('excel.quizRanking.correctAnswersPercent'),
          quizTime: t('excel.quizRanking.quizTime'),
          rankPlace: t('excel.quizRanking.rankPlace'),
          xpAmount: t('excel.quizRanking.xpAmount'),
          vpAmount: t('excel.quizRanking.vpAmount'),
          // epAmount: t('excel.quizRanking.epAmount'),
        }

        const headers = [
          Object.keys(filteredChallengeResultList[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredChallengeResultList, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      <Stack
        display="flex"
        alignContent="space-between"
        flexDirection="row"
        marginBottom={1}
      >
        <PrimaryButton
          variant="contained"
          onClick={() => handleNavigationClick(`${path}`)}
        >
          {t('common.back')}
        </PrimaryButton>
        <SecondaryButton
          variant="contained"
          onClick={() => downloadXLSX('ranking')}
          sx={{ marginLeft: 'auto' }}
          disabled={isDownloading}
        >
          {isDownloading && (
            <CircularProgress
              style={{ height: 12, width: 12, marginRight: 10 }}
            />
          )}
          {isDownloading
            ? t('common.generatingFile')
            : t('common.downloadTableAsXLSX')}
        </SecondaryButton>
      </Stack>
      <RankingToolbar
        value={searchText}
        onChange={(event: { target: { value: string } }) => {
          setSearchText(event.target.value)
        }}
        submitSearch={(searchValue) => {
          setSkipPageReset(false)
          setSearchValue(searchValue)
        }}
        clearSearch={() => {
          setSkipPageReset(false)
          setSearchText('')
          setSearchValue('')
        }}
        statusValue={statusValue}
        filterStatus={(event: SelectChangeEvent) => {
          setStatusValue(event.target.value)
          setTableState(tableName, 'status', event.target.value)
          setSkipPageReset(false)
        }}
      />
      <TableControlled
        name={tableName}
        columns={tableColumns}
        data={filteredUsersList}
        height="calc(100vh - 270px)"
        fetchData={fetchData}
        loading={tableLoading}
        pageIndex={0}
        pageCount={pageCount}
        totalCount={totalCount}
        skipPageReset={skipPageReset}
        columnsVisibility={[]}
        toggleVisibility={setColumnsVisibility}
      />
      {userId && (
        <QuizPreviewOnPhoneDialog
          open={phoneDialogOpen}
          handleClose={handlePhoneDialogClose}
          quizId={parseInt(quizId)}
          userId={userId}
        />
      )}
    </>
  )
}

export default QuizRankingList
