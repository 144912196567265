import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Grid, IconButton, Tooltip } from '@mui/material'
import PeriodService from '../../../../services/period.service'
import { useTranslation } from 'react-i18next'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Period } from '../../../../store/Period/types'
import Table from '../../../Table/Table'
import { Column } from 'react-table'

import PeriodActivateDialog from '../partials/PeriodActivateDialog'
import PeriodEditDialog from '../partials/PeriodEditDialog'
import { errorHandler } from '../../../../helpers/errorHandler'

type PeriodListProps = {
  path: string
}

const PeriodList: FunctionComponent<PeriodListProps> = ({ path }) => {
  const tableName = 'periods'
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [periodList, setPeriodList] = useState<Period[]>([])
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [openActivatePeriodDialog, setActivatePeriodDialogOpen] =
    useState(false)
  const [openPeriodEditDialog, setPeriodEditDialogOpen] = useState(false)

  const [id, setId] = useState<number | null>(null)
  const [refresh, setRefresh] = useState(false)
  const [name, setName] = useState<string>('')

  const handleActivatePeriodDialogClickOpen = () => {
    setActivatePeriodDialogOpen(true)
  }

  const handlePeriodEditDialogClickOpen = (id: number) => {
    setId(id)
    setPeriodEditDialogOpen(true)
  }

  const handleActivatePeriodDialogClose = (refreshTable: boolean = false) => {
    setActivatePeriodDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }
  const handleEditPeriodDialogClose = (refreshTable: boolean = false) => {
    setPeriodEditDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const generateTableColumns = useCallback(
    (period: Period[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'id',
          Header: t('pages.periods.table.id').toString(),
          width: 60,
        },
        {
          accessor: 'name',
          Header: t('pages.periods.table.name').toString(),
          width: 140,
        },
        {
          accessor: 'validFrom',
          Header: t('pages.periods.table.validFrom').toString(),
          width: 160,
        },
        {
          accessor: 'validTo',
          Header: t('pages.periods.table.validTo').toString(),
          width: 160,
        },
        {
          accessor: 'isActive',
          Header: t('pages.periods.table.default').toString(),

          Cell: (params: any) => (
            <>
              {params.row.original.isActive ? t('common.yes') : t('common.no')}
            </>
          ),
        },
        {
          accessor: 'isVisible',
          Header: t('pages.periods.table.isVisible').toString(),
          Cell: (params: any) => (
            <>
              {params.row.original.isVisible ? t('common.yes') : t('common.no')}
            </>
          ),
        },
        {
          accessor: 'isActivated',
          Header: t('pages.periods.table.isActivated').toString(),
          Cell: (params: any) => (
            <>
              {params.row.original.isActivated
                ? t('common.yes')
                : t('common.no')}
            </>
          ),
        },
        {
          accessor: 'actions',
          Header: t('pages.periods.table.actions').toString(),
          disableSortBy: true,
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.periods.table.edit')}`}>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() =>
                      handlePeriodEditDialogClickOpen(params.row.original.id)
                    }
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.periods.table.activate')}`}>
                  <IconButton
                    aria-label="activate"
                    size="small"
                    sx={{
                      opacity:
                        params.row.original.isActive ||
                        params.row.original.isActivated ||
                        !params.row.original.isAllowedToActivate
                          ? '.3'
                          : '1',
                    }}
                    onClick={() => {
                      setName(params.row.original.name)
                      handleActivatePeriodDialogClickOpen()
                    }}
                    disabled={
                      params.row.original.isActive ||
                      params.row.original.isActivated ||
                      !params.row.original.isAllowedToActivate
                    }
                  >
                    <RocketLaunchIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )
      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriodList(periodListResponse.data.periods)
          setTableColumns(generateTableColumns(periodListResponse.data.periods))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, generateTableColumns, refresh])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Table
            name={tableName}
            columns={tableColumns}
            data={periodList}
            height="calc(100vh - 165px)"
          />
          <PeriodActivateDialog
            open={openActivatePeriodDialog}
            handleClose={handleActivatePeriodDialogClose}
            name={name}
          />
          {id && (
            <PeriodEditDialog
              open={openPeriodEditDialog}
              handleClose={handleEditPeriodDialogClose}
              periodId={id}
            />
          )}
        </>
      )}
    </>
  )
}

export default PeriodList
