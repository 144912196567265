import React, { FunctionComponent, useState } from 'react'
import Box from '@mui/material/Box'
import BottomBar from '../partials/BottomBar'
import PlanDetails from '../partials/steps/PlanDetails'
import PlanStrategies from '../partials/steps/PlanStrategies'

type PlanCreateProps = {}

const PlanCreate: FunctionComponent<PlanCreateProps> = () => {
  const [planId, setPlanId] = useState<number | null>(null)

  return (
    <Box>
      <Box sx={{ maxWidth: 800, margin: '0 auto 100px auto' }}>
        <PlanDetails setPlanId={setPlanId} planId={planId} />
        <PlanStrategies planId={planId} />
      </Box>
      <BottomBar />
    </Box>
  )
}

export default PlanCreate
