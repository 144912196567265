import axios from 'axios'
import {
  CreateOrUpdatePlanDetailsParams,
  CreateOrUpdatePlanDetailsResponse,
  CreateOrUpdatePlanStrategiesParams,
  CreateOrUpdatePlanStrategiesResponse,
  PlanDetailsReponse,
  PlanFilerReponse,
  PlanListReponse,
  PlansForecastReponse,
  PlanStrategiesReponse,
  PlanTypesResponse,
} from '../store/Plan/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPlanList = async (
  periodId: number | null,
  planTypeCode: string | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<PlanListReponse> => {
  return await axios.post(
    API_URL + '/api/admin/plan/list',
    {
      periodId,
      planTypeCode,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getPlanTypeList = (): Promise<PlanTypesResponse> => {
  return axios.get(API_URL + '/api/admin/plan/type', {
    headers: authHeader(),
  })
}

const getPlanDetails = (planId: number): Promise<PlanDetailsReponse> => {
  return axios.get(API_URL + `/api/admin/plan/preview/${planId}`, {
    headers: authHeader(),
  })
}

const createOrUpdatePlanDetails = async (
  params: CreateOrUpdatePlanDetailsParams,
): Promise<CreateOrUpdatePlanDetailsResponse> => {
  return await axios.post(API_URL + '/api/admin/plan', params, {
    headers: authHeader(),
  })
}

const createOrUpdatePlanStrategies = async (
  params: CreateOrUpdatePlanStrategiesParams,
): Promise<CreateOrUpdatePlanStrategiesResponse> => {
  return await axios.post(API_URL + '/api/admin/plan/strategy', params, {
    headers: authHeader(),
  })
}

const getPlanStrategies = (
  strategy: string,
): Promise<PlanStrategiesReponse> => {
  return axios.get(API_URL + `/api/admin/plan/strategy/${strategy}`, {
    headers: authHeader(),
  })
}

const getPlanFilter = (periodId?: number): Promise<PlanFilerReponse> => {
  let fetchUrl = '/api/admin/plan/list/simple'
  if (periodId) {
    fetchUrl = `${fetchUrl}/${periodId}`
  }
  return axios.get(API_URL + fetchUrl, {
    headers: authHeader(),
  })
}

const setPlansForecast = async (
  plans: { id: number }[],
  timeExpiration: number,
): Promise<PlansForecastReponse> => {
  return await axios.post(
    API_URL + '/api/admin/plan/forecast',
    {
      plans,
      timeExpiration,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getPlanList,
  getPlanDetails,
  getPlanTypeList,
  createOrUpdatePlanDetails,
  createOrUpdatePlanStrategies,
  getPlanStrategies,
  getPlanFilter,
  setPlansForecast,
}

export default exportedObject
