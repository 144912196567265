import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TextField,
} from '@mui/material'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DateTimePicker from '../../../../shared/DateTimePicker'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../../store/types'
import {
  Action,
  BasicInformationErrors,
  CreateOrUpdateInformationsParams,
} from '../../../../../store/TradeAction/types'
import { ActionContainer } from './styles'
import ImageLibrary from '../../../../ImageLibrary/ImageLibrary'
import { ImageTypeCode } from '../../../../../store/Image/types'
import { errorHandler } from '../../../../../helpers/errorHandler'
import moment from 'moment'
import TradeActionService from '../../../../../services/tradeAction.service'
import { toast } from 'react-toastify'
import { GreenSwitch } from '../../../../shared/GreenSwitch'

type BasicInformationProps = {
  actionId: number | null
  setActionId: React.Dispatch<React.SetStateAction<number | null>>
  tradeActionDetails?: Action
}

const BasicInformation: FunctionComponent<BasicInformationProps> = ({
  actionId,
  setActionId,
  tradeActionDetails,
}) => {
  const { t } = useTranslation()
  const [name, setName] = useState(tradeActionDetails?.name || '')
  const [savingStep, setSavingStep] = useState(false)
  const [visibilitySwitch, setVisibilitySwitch] = useState(
    tradeActionDetails ? (tradeActionDetails.isVisible ? true : false) : true,
  )
  const [mainImageId, setMainImageId] = useState<number | null>(null)
  const [nameLength, setNameLength] = useState(
    tradeActionDetails?.name.length || 0,
  )
  const [validFromDate, setValidFromDate] = useState<Date | null>(null)
  const [validFromTime, setValidFromTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [validToDate, setValidToDate] = useState<Date | null>(null)
  const [validToTime, setValidToTime] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultBasicInformationFormErrors: BasicInformationErrors = {
    name: defaultError,
    validFromDate: defaultError,
    validFromTime: defaultError,
    validToDate: defaultError,
    validToTime: defaultError,
    mainImage: defaultError,
  }
  const [basicInformationFormErrors, setBasicInformationFormErrors] =
    useState<BasicInformationErrors>(defaultBasicInformationFormErrors)

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setNameLength(event.target.value.length)
  }

  const handleVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVisibilitySwitch(event.target.checked)
  }

  useEffect(() => {
    if (tradeActionDetails) {
      setValidFromDate(new Date(tradeActionDetails.validFrom.slice(0, 10)))
      setValidFromTime(
        new Date(
          new Date().setHours(
            parseInt(tradeActionDetails.validFrom.slice(11, 13)),
            parseInt(tradeActionDetails.validFrom.slice(14, 16)),
          ),
        ),
      )
      setValidToDate(new Date(tradeActionDetails.validTo.slice(0, 10)))
      setValidToTime(
        new Date(
          new Date().setHours(
            parseInt(tradeActionDetails.validTo.slice(11, 13)),
            parseInt(tradeActionDetails.validTo.slice(14, 16)),
          ),
        ),
      )
    }
  }, [tradeActionDetails])

  const saveForm = async () => {
    setBasicInformationFormErrors(defaultBasicInformationFormErrors)
    const basicInformationFormErrors = defaultBasicInformationFormErrors

    if (name === '') {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        name: {
          error: true,
          message: t('pages.tradeActions.basicInformation.form.nameRequired'),
        },
      })
      return
    } else if (
      !validFromDate ||
      !validFromTime ||
      validFromDate.toString() === 'Invalid Date' ||
      validFromTime.toString() === 'Invalid Date'
    ) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        validFromDate: {
          error: true,
          message: t('pages.tradeActions.basicInformation.form.dateRequired'),
        },
      })
      return
    } else if (
      !validToDate ||
      !validToTime ||
      validToDate.toString() === 'Invalid Date' ||
      validToTime.toString() === 'Invalid Date'
    ) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        validToDate: {
          error: true,
          message: t('pages.tradeActions.basicInformation.form.dateRequired'),
        },
      })
      return
    } else if (!mainImageId) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        mainImage: {
          error: true,
          message: t('pages.tradeActions.basicInformation.form.imageRequired'),
        },
      })
      return
    }

    const validFrom =
      moment(validFromDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validFromTime).format('HH:mm:ss')

    const validTo =
      moment(validToDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validToTime).format('HH:mm:ss')

    const formParams: CreateOrUpdateInformationsParams = {
      actionId,
      name,
      validFrom,
      validTo,
      isVisible: visibilitySwitch,
      imageId: mainImageId,
    }

    try {
      setSavingStep(true)
      const createInformationsResponse =
        await TradeActionService.createOrUpdateInformations(formParams)

      if (createInformationsResponse.data.success) {
        setActionId(createInformationsResponse.data.actionId)
        toast.success(t('messages.success.savedSuccessfully'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSavingStep(false)
    }
  }

  const clearForm = async () => {
    setBasicInformationFormErrors(defaultBasicInformationFormErrors)
    setName('')
    setMainImageId(null)
    setValidFromDate(null)
    setValidFromTime(new Date(new Date().setHours(0, 0, 0, 0)))
    setValidToDate(null)
    setValidToTime(new Date(new Date().setHours(23, 59, 59, 0)))
  }

  return (
    <ActionContainer>
      <Stack direction={'row'}>
        <div className="step-line first">
          <div className="step-circle">1</div>
          <div className="step-arrow first"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={7}>
              <div className="step-title">
                {t('pages.tradeActions.basicInformation.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.tradeActions.basicInformation.subtitle')}
              </div>
            </Grid>
            <Grid item xs={5} className="switch-button">
              <FormControlLabel
                value="start"
                control={
                  <GreenSwitch
                    value={visibilitySwitch}
                    onChange={handleVisibilityChange}
                    checked={visibilitySwitch}
                  />
                }
                label={
                  <div className="visibility-label-container">
                    <div className="visibility">
                      {visibilitySwitch
                        ? t('pages.tradeActions.switchVisible')
                        : t('pages.tradeActions.switchNotVisible')}
                    </div>
                    <div className="visibility-label">
                      {t('pages.tradeActions.switchLabel')}
                    </div>
                  </div>
                }
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ maxWidth: 520, marginBottom: 4 }}>
            <Grid item xs={12} sx={{ marginBottom: 4 }}>
              <FormControl fullWidth>
                <label>
                  {t('pages.tradeActions.basicInformation.form.name')}
                </label>
                <TextField
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  inputProps={{ maxLength: 60 }}
                  error={basicInformationFormErrors.name.error}
                  helperText={
                    basicInformationFormErrors.name.error &&
                    basicInformationFormErrors.name.message
                  }
                />
                <div className="character-amount">{nameLength}/60</div>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label={t('pages.tradeActions.basicInformation.form.validFrom')}
                date={validFromDate}
                time={validFromTime}
                onDateChange={setValidFromDate}
                onTimeChange={setValidFromTime}
                maxDate={validToDate || undefined}
                error={basicInformationFormErrors.validFromDate}
                style={{
                  width: 260,
                  marginRight: 8,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label={t('pages.tradeActions.basicInformation.form.validTo')}
                date={validToDate}
                time={validToTime}
                onDateChange={setValidToDate}
                onTimeChange={setValidToTime}
                minDate={validFromDate || undefined}
                error={basicInformationFormErrors.validToDate}
                style={{
                  width: 260,
                  marginRight: 8,
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <ImageLibrary
                imageType={ImageTypeCode.TMAL}
                selectedImageId={tradeActionDetails?.actionImageId || null}
                setSelectedImageId={setMainImageId}
              />
              {basicInformationFormErrors.mainImage.error && !mainImageId && (
                <FormHelperText error>
                  {basicInformationFormErrors.mainImage.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ marginBottom: 1 }} />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
            className="buttons-container"
          >
            <Stack spacing={2} direction="row">
              <SecondaryButton disabled={savingStep} onClick={clearForm}>
                {t('common.clear')}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  saveForm()
                }}
                disabled={savingStep}
              >
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </ActionContainer>
  )
}

export default BasicInformation
