import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import { Grid } from '@mui/material'
import PeriodPlansList from './list/PeriodPlansList'
import PeriodPlanImportResult from './importResult/PeriodPlanImportResult'
import PeriodPlanImportPlan from './importPlan/PeriodPlanImportPlan'
import PeriodPlanImportStorePlan from './importStorePlan/PeriodPlanImportStorePlan'
const PeriodPlans = () => {
  let { path } = useRouteMatch()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: '0 20px 0 20px',
            }}
          >
            <Switch>
              <Route exact path={path}>
                <PeriodPlansList path={path} />
              </Route>
              <Route exact path={`${path}/import-result/:id`}>
                <PeriodPlanImportResult />
              </Route>
              <Route exact path={`${path}/import-plan/:id`}>
                <PeriodPlanImportPlan />
              </Route>
              <Route exact path={`${path}/import-store-plan/:id`}>
                <PeriodPlanImportStorePlan />
              </Route>
            </Switch>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default PeriodPlans
