import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import ConfigService from '../../../../services/config.service'
import { Stack } from '@mui/system'
import { Card, Typography } from '@mui/material'
import DateTimePicker from '../../../shared/DateTimePicker'
import { FormError } from '../../../../store/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import moment from 'moment'
import { toast } from 'react-toastify'

const UpdateDate: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [updateDateTime, setUpdateDateTime] = useState<string>('')
  const [updateDate, setUpdateDate] = useState<Date | null>(null)
  const [updateTime, setUpdateTime] = useState<Date | null>(null)

  const defaultError: FormError = {
    error: false,
    message: '',
  }

  const [updateDateError, setUpdateDateError] =
    useState<FormError>(defaultError)

  const fetchData = useCallback(async () => {
    try {
      const updateDateResponse = await ConfigService.getUpdateDate()

      if (updateDateResponse.data) {
        setUpdateDateTime(updateDateResponse.data.lastUpdateDate)
        setUpdateDate(
          new Date(updateDateResponse.data.lastUpdateDate.slice(0, 10)),
        )
        setUpdateTime(
          new Date(
            new Date().setHours(
              parseInt(updateDateResponse.data.lastUpdateDate.slice(11, 13)),
              parseInt(updateDateResponse.data.lastUpdateDate.slice(14, 16)),
            ),
          ),
        )
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setLoading(false)
    }
  }, [t])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const saveUpdateDate = async () => {
    setUpdateDateError(defaultError)

    if (
      !updateDate ||
      !updateTime ||
      updateDate.toString() === 'Invalid Date' ||
      updateTime.toString() === 'Invalid Date'
    ) {
      setUpdateDateError({
        error: true,
        message: t('pages.edubox.basicInformation.form.dateRequired'),
      })
      return
    }

    const lastUpdateDate =
      moment(updateDate).format('YYYY-MM-DD') +
      ' ' +
      moment(updateTime).format('HH:mm') +
      ':00'

    try {
      setIsSaving(true)
      const updateDateResponse = await ConfigService.setUpdateDate(
        lastUpdateDate,
      )

      if (updateDateResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        fetchData()
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Stack p={3}>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Card style={{ padding: '20px' }}>
          <Typography variant="subtitle2" gutterBottom>
            {t('pages.configuration.updateDate.dataPerDay')}:
          </Typography>
          {moment(updateDateTime).format('DD.MM.YYYY HH:mm')}
          <Typography variant="subtitle2" gutterBottom mt={4}>
            {t('pages.configuration.updateDate.setAndUpdate')}:
          </Typography>
          <Stack display="flex" flexDirection="row" width={'360px'}>
            <DateTimePicker
              label={''}
              date={updateDate}
              time={updateTime}
              onDateChange={setUpdateDate}
              onTimeChange={setUpdateTime}
              error={updateDateError}
              onError={() => {
                setUpdateDateError({
                  error: true,
                  message: t('pages.configuration.updateDate.dateRequired'),
                })
              }}
            />
            <PrimaryButton
              style={{ marginLeft: '10px', height: '40px' }}
              onClick={saveUpdateDate}
              disabled={isSaving}
            >
              {t('pages.configuration.updateDate.update')}
            </PrimaryButton>
          </Stack>
        </Card>
      )}
    </Stack>
  )
}

export default UpdateDate
