import { Stack } from '@mui/material'
import MapInfo from '../../../assets/images/game/map_info.png'
import RealizationChart from '../partials/RealizationChart'
import { ReactComponent as MenuIcon } from '../../../assets/images/game/icons/menu_icon.svg'
import { ReactComponent as MessengerIcon } from '../../../assets/images/game/icons/messenger_icon.svg'
import { ReactComponent as NotificationsIcon } from '../../../assets/images/game/icons/notifications_icon.svg'
import { ReactComponent as BadgeIcon } from '../../../assets/images/game/icons/badge.svg'
import {
  PositionBadge,
  RealizationBlock,
  RealizationDate,
  RealizationBlockInfo,
  RealizationSmallTxt,
  RealizationBigTxt,
  ChartContainer,
  PhoneAppBarDetails,
  PhoneContent,
} from '../styles'

import { UserLevelPreview } from '../../../store/Game/types'
import GameService from '../../../services/game.service'
import { useEffect, useState } from 'react'
import { errorHandler } from '../../../helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import LoadingSpinner from '../../shared/LoadingSpinner'
import { thousandsSeparator } from '../../../helpers/utils'
import { backgroundsArray } from '../PhoneConfig'

type DashboardScreenProps = {
  userId: number
}

const DashboardScreen: React.FunctionComponent<DashboardScreenProps> = ({
  userId,
}) => {
  const { t } = useTranslation()
  const [userLevelPreview, setUserLevelPreview] =
    useState<UserLevelPreview | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [backgrounds, setBackgrounds] = useState<string[]>([])

  const generateBackgrounds = (numOfLevels: number) => {
    const bgs: string[] = []
    for (let index = 0; index < numOfLevels; index++) {
      bgs.push(
        require(`../../../assets/images/game/map/phone_preview/${
          index + 1
        }.png`),
      )
    }
    return bgs
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const userLevelPreviewResponse = await GameService.getUserLevelPreview(
          userId,
        )
        setUserLevelPreview(userLevelPreviewResponse.data)
        if (backgroundsArray.length === 0) {
          setBackgrounds(generateBackgrounds(6))
        } else {
          setBackgrounds(backgroundsArray)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, userId])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && userLevelPreview !== null && (
        <>
          <PhoneAppBarDetails>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
              px={2}
            >
              <MenuIcon />
              <Stack position="relative">
                <BadgeIcon
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    left: '10px',
                  }}
                />
                <MessengerIcon />
              </Stack>
              <Stack position="relative">
                <BadgeIcon
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    left: '10px',
                  }}
                />
                <NotificationsIcon />
              </Stack>
            </Stack>
          </PhoneAppBarDetails>
          <PhoneContent>
            <Stack position="relative">
              <img
                src={MapInfo}
                alt=""
                style={{
                  position: 'absolute',
                  top: '20px',
                  left: '10px',
                  cursor: 'pointer',
                }}
              />
              {backgrounds[userLevelPreview.currentLevel] ? (
                <img
                  src={backgrounds[userLevelPreview.currentLevel]}
                  alt=""
                  width="100%"
                />
              ) : (
                <img
                  src={backgrounds[backgrounds.length - 1]}
                  alt=""
                  width="100%"
                />
              )}
              <PositionBadge>
                <strong>{userLevelPreview.rankPlace}</strong>
                <span>
                  {t('pages.game.phone.screens.dashboardScreen.rankPlace')}
                </span>
              </PositionBadge>
            </Stack>
            <RealizationBlock>
              <RealizationDate>
                {t('pages.game.phone.screens.dashboardScreen.lastUpdateDate')}{' '}
                {moment(userLevelPreview.lastUpdateDate).format('DD.MM.YYYY')}
              </RealizationDate>
              <RealizationBlockInfo>
                <RealizationSmallTxt>
                  {t('pages.game.phone.screens.dashboardScreen.xpValue')}
                </RealizationSmallTxt>
                <RealizationBigTxt>
                  {thousandsSeparator(userLevelPreview.xpValue)}
                </RealizationBigTxt>
              </RealizationBlockInfo>
              <ChartContainer>
                <RealizationChart
                  currentLevel={userLevelPreview.currentLevel}
                  currentLevelPercentValue={
                    userLevelPreview.currentLevelPercentValue
                  }
                />
              </ChartContainer>
              <RealizationBlockInfo>
                <RealizationSmallTxt>
                  {t(
                    'pages.game.phone.screens.dashboardScreen.xpNextLevelThreshold',
                  )}
                </RealizationSmallTxt>
                <RealizationBigTxt>
                  {thousandsSeparator(userLevelPreview.xpNextLevelThreshold)}
                </RealizationBigTxt>
              </RealizationBlockInfo>
            </RealizationBlock>
          </PhoneContent>
        </>
      )}
    </>
  )
}

export default DashboardScreen
