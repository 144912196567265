import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import ImageLibrary from '../../ImageLibrary/ImageLibrary'
import { ImageTypeCode } from '../../../store/Image/types'

const Images = () => {
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div style={{ padding: '50px' }}>
      <div style={{ width: '700px', margin: '0 auto' }}>
        <ImageLibrary
          imageType={ImageTypeCode.ML}
          selectedImageId={null}
          setSelectedImageId={(id) => console.log('ML', id)}
        />
        <br />
        <br />
        <ImageLibrary
          imageType={ImageTypeCode.CD}
          selectedImageId={null}
          setSelectedImageId={(id) => console.log('CD', id)}
        />
        {/* <br />
        <br />
        <ImageLibrary
          imageType={ImageTypeCode.TMAL}
          selectedImageId={null}
          setSelectedImageId={(id) => console.log('TMAL', id)}
        />
        <br />
        <br />
        <ImageLibrary
          imageType={ImageTypeCode.TMARI}
          selectedImageId={null}
          setSelectedImageId={(id) => console.log('TMARI', id)}
        /> */}
      </div>
    </div>
  )
}

export default Images
