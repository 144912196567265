import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { downloadXLSX, thousandsSeparator } from '../../../../../helpers/utils'
import { ImportStorePlanResponse } from '../../../../../store/Import/types'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'

export const getStorePlansResponse = (
  importResponse: ImportStorePlanResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (
    importResponse.data.addedResults &&
    importResponse.data.updatedResults &&
    importResponse.data.skippedResults
  ) {
    const addedResults = importResponse.data.addedResults
    const updatedResults = importResponse.data.updatedResults
    const skippedResults = importResponse.data.skippedResults
    const addedResultsSummary = importResponse.data.addedResultsSummary
    const updatedResultsSummary = importResponse.data.updatedResultsSummary
    const skippedResultsSummary = importResponse.data.skippedResultsSummary

    const renderAdditionalInfo = () => {
      return (
        <>
          {skippedResults.length > 0 && (
            <div>
              <SecondaryButton
                onClick={() =>
                  downloadXLSX(
                    skippedResults.map((result) => ({
                      storeCentralId: result,
                    })),
                    t('import.messages.storePlans.skippedResultsFilename'),
                  )
                }
                size="small"
                style={{ marginBottom: '10px' }}
              >
                {t('import.messages.storePlans.downloadSkippedResults')}
              </SecondaryButton>
            </div>
          )}
        </>
      )
    }

    return {
      message: {
        type: alertType,
        message: t(`import.messages.storePlans.${messageType}Message`, {
          addedResultsSummary: thousandsSeparator(addedResultsSummary || 0),
          updatedResultsSummary: thousandsSeparator(updatedResultsSummary || 0),
          skippedResultsSummary: thousandsSeparator(skippedResultsSummary || 0),
          addedResults: addedResults.length,
          updatedResults: updatedResults.length,
          skippedResults: skippedResults.length,
        }),
        hasSecondStep: false,
      },
      additionalInfo: renderAdditionalInfo(),
    }
  }
  return null
}
