import { Button } from '@mui/material'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import { HTMLAttributes } from 'react'

export const FileUploaderContainer = styled('div')(({ theme }) => ({
  '.file-uploader': {
    transition: 'background-color 0.5s ease',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    fontSize: 14,
    '&.error': {
      backgroundColor: '#CF0000',
      color: '#fff',
      textAlign: 'center',
      '.drag-drop-hint': {
        color: '#fff',
      },
    },
    '.drop-btn': {
      backgroundColor: theme.colorsPalette.orange.medium,
      padding: '5px 10px 5px 10px',
      borderRadius: 5,
      fontWeight: 'bolder',
      cursor: 'pointer',
    },
    '.drag-drop-hint': {
      fontSize: 11,
      marginTop: 10,
      color: theme.colorsPalette.gray.gray4,
      textAlign: 'center',
    },
  },
}))

export const SuggestedImagesTitle = styled('div')({
  fontSize: '12px',
  lineHeight: '14px',
  color: '#373739',
  marginBottom: '5px',
  textAlign: 'left',
})

export const SuggestedImagesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  marginBottom: '7px',
})

export const StyledButton = styled(Button)({
  background: '#FFC20F',
  borderRadius: '3px',
  padding: '4px 22px',
  fontSize: '14px',
  lineHeight: '17px',
  color: '#000',
  textTransform: 'capitalize',
})

export const ImageLibraryTitle = styled('div')({
  fontSize: '12px',
  lineHeight: '14px',
  color: grey[800],
  marginBottom: '5px',
})

export const ImageLibraryContainer = styled('div')({
  background: '#FFFFFF',
  border: '1px solid #DCDCDC',
  borderRadius: '5px',
})

export const ImageLibraryContent = styled('div')({
  textAlign: 'center',
  borderBottom: '1px solid #DCDCDC',
  padding: '20px',
})

export const AllImagesTitle = styled('div')({
  fontSize: '20px',
  lineHeight: '24px',
  color: '#000',
  fontWeight: 800,
  marginBottom: '5px',
  textAlign: 'left',
})

export const AllImagesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginBottom: '20px',
})

export const ImageThumbnailContainer = styled('div')({
  display: 'block',
  width: '90px',
  margin: '0 14px 14px 0',
  cursor: 'pointer',
})

export const ImageThumbnailName = styled('div')({
  fontSize: '11px',
  lineHeight: '13px',
  color: '#9E9E9E',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

type ImageThumbnailContentProps = HTMLAttributes<HTMLDivElement> & {
  selected?: boolean
}

export const ImageThumbnailContent = styled('div')<ImageThumbnailContentProps>(
  ({ selected = false }) => ({
    width: '90px',
    height: '90px',
    background: '#FFFFFF',
    border: selected ? '2px solid #FFC20F' : '1px solid #DCDCDC',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
)

type MainImageDivProps = HTMLAttributes<HTMLDivElement> & {
  imagewidth?: string
}

export const MainImageContainer = styled('div')<MainImageDivProps>(
  ({ imagewidth }) => ({
    display: 'block',
    width: imagewidth,
    margin: '0 auto 20px',
  }),
)

export const MainImageContent = styled('div')<MainImageDivProps>(
  ({ imagewidth }) => ({
    width: imagewidth,
    height: '180px',
    background: '#FFFFFF',
    border: '1px solid #DCDCDC',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  }),
)
