import { FunctionComponent, useCallback, useRef, useState } from 'react'
import {
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { handleNavigationClick } from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import ActionsToolbar from '../partials/ActionsToolbar'
import TableControlled from '../../../Table/TableControlled'
import { Column } from 'react-table'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import { errorHandler } from '../../../../helpers/errorHandler'
import TradeActionService from '../../../../services/tradeAction.service'
import { Action } from '../../../../store/TradeAction/types'
import StatusSwitcher from '../partials/StatusSwitcher'
import { ReactComponent as UserImportPlan } from '../../../../assets/images/icons/user_import_plan.svg'
import { ReactComponent as UserImportResult } from '../../../../assets/images/icons/user_import_result.svg'
import ActionUsersDialog from '../partials/ActionUsersDialog'
import './TradeActionList.scss'
import BlockCell from '../partials/BlockCell'
import ActionImportDialog from '../partials/ActionImportDialog'

type TradeActionListProps = {
  path: string
}

const TradeActionList: FunctionComponent<TradeActionListProps> = ({ path }) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'actions'

  const [searchText, setSearchText] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [statusValue, setStatusValue] = useState<string>('all')
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [filteredActionsList, setFilteredActionsList] = useState<Action[]>([])

  const [action, setAction] = useState<Action | null>(null)
  const [openActionUsersDialog, setActionUsersDialogOpen] = useState(false)
  const [openActionImportDialog, setActionImportDialogOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const handleActionUsersDialogClickOpen = (action: Action) => {
    setAction(action)
    setActionUsersDialogOpen(true)
  }

  const handleActionUsersDialogClose = (refreshTable: boolean = false) => {
    setActionUsersDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const handleActionImportDialogClickOpen = (action: Action) => {
    setAction(action)
    setActionImportDialogOpen(true)
  }

  const handleActionImportDialogClose = (refreshTable: boolean = false) => {
    setActionImportDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const generateTableColumns = useCallback(
    (actions: Action[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.tradeActions.table.actionId').toString(),
          accessor: 'actionId',
          width: 70,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.tradeActions.table.name').toString(),
          accessor: 'name',
          width: 250,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.tradeActions.table.validFrom').toString(),
          accessor: 'validFrom',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'validTo',
          Header: t('pages.tradeActions.table.validTo').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'isVisible',
          Header: t('pages.tradeActions.table.isVisible').toString(),
          width: 140,
          Cell: (params: any) => (
            <StatusSwitcher
              actionId={params.row.original.actionId}
              isActive={params.value}
            />
          ),
        },
        {
          accessor: 'users',
          Header: t('pages.tradeActions.table.users').toString(),
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'block0',
          Header: t('pages.tradeActions.table.block1').toString(),
          width: 160,
          disableSortBy: true,
          Cell: (params: any) => (
            <BlockCell
              actionId={params.row.original.actionId}
              actionBlock={params.row.original.actionBlocks[0]}
            />
          ),
        },
        {
          accessor: 'block1',
          Header: t('pages.tradeActions.table.block2').toString(),
          width: 160,
          disableSortBy: true,
          Cell: (params: any) => (
            <BlockCell
              actionId={params.row.original.actionId}
              actionBlock={params.row.original.actionBlocks[1]}
            />
          ),
        },
        {
          accessor: 'block2',
          Header: t('pages.tradeActions.table.block3').toString(),
          width: 160,
          disableSortBy: true,
          Cell: (params: any) => (
            <BlockCell
              actionId={params.row.original.actionId}
              actionBlock={params.row.original.actionBlocks[2]}
            />
          ),
        },
        {
          accessor: 'block3',
          Header: t('pages.tradeActions.table.block4').toString(),
          width: 160,
          disableSortBy: true,
          Cell: (params: any) => (
            <BlockCell
              actionId={params.row.original.actionId}
              actionBlock={params.row.original.actionBlocks[3]}
            />
          ),
        },
        {
          accessor: 'block4',
          Header: t('pages.tradeActions.table.block5').toString(),
          width: 160,
          disableSortBy: true,
          Cell: (params: any) => (
            <BlockCell
              actionId={params.row.original.actionId}
              actionBlock={params.row.original.actionBlocks[4]}
            />
          ),
        },
        {
          accessor: 'actions',
          Header: t('pages.tradeActions.table.actions').toString(),
          width: 140,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.tradeActions.table.edit')}`}>
                  <IconButton
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/update/${params.row.original.actionId}`,
                      )
                    }
                    size="small"
                    style={{ padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.tradeActions.table.addUsers')}`}>
                  <IconButton
                    onClick={() =>
                      handleActionUsersDialogClickOpen(params.row.original)
                    }
                    size="small"
                    style={{
                      padding: 0,
                      opacity: params.row.original.users === 'ALL' ? '.3' : '1',
                    }}
                    disabled={params.row.original.users === 'ALL'}
                  >
                    <UserImportPlan />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.tradeActions.table.importData')}`}>
                  <IconButton
                    onClick={() =>
                      handleActionImportDialogClickOpen(params.row.original)
                    }
                    size="small"
                    style={{ padding: 0 }}
                  >
                    <UserImportResult />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )

      return columns
    },
    [t, path],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          // setDownloadSortBy(sortColumn)
          // setDownloadSortOrder(sortDirection)

          const page = ++pageIndex
          const actionsResponse = await TradeActionService.getActions(
            statusValue === 'all' ? null : statusValue === '1' ? true : false,
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (actionsResponse.data.tradeActions) {
            setTableColumns(
              generateTableColumns(actionsResponse.data.tradeActions),
            )

            setFilteredActionsList(actionsResponse.data.tradeActions)

            setTotalCount(actionsResponse.data.totalCount)
            setPageCount(Math.ceil(actionsResponse.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [t, statusValue, searchValue, generateTableColumns, refresh],
  )

  return (
    <>
      <Stack
        display="flex"
        alignContent="space-between"
        flexDirection="row"
        marginBottom={1}
      >
        <PrimaryButton
          variant="contained"
          onClick={() => handleNavigationClick(`${path}/create`)}
        >
          {t('pages.tradeActions.addAction')}
        </PrimaryButton>
      </Stack>
      <ActionsToolbar
        value={searchText}
        onChange={(event: { target: { value: string } }) => {
          setSearchText(event.target.value)
          setControlledPageIndex(0)
        }}
        submitSearch={(searchValue) => {
          setSkipPageReset(false)
          setSearchValue(searchValue)
        }}
        clearSearch={() => {
          setSkipPageReset(false)
          setSearchText('')
          setSearchValue('')
        }}
        statusValue={statusValue}
        filterStatus={(event: SelectChangeEvent) => {
          setStatusValue(event.target.value)
        }}
      />
      <TableControlled
        name={tableName}
        columns={tableColumns}
        data={filteredActionsList}
        height="calc(100vh - 300px)"
        fetchData={fetchData}
        loading={tableLoading}
        pageIndex={controlledPageIndex}
        pageCount={pageCount}
        totalCount={totalCount}
        skipPageReset={skipPageReset}
        columnsVisibility={[]}
        toggleVisibility={() => {}}
      />
      {action && (
        <>
          <ActionUsersDialog
            open={openActionUsersDialog}
            handleClose={handleActionUsersDialogClose}
            action={action}
          />
          <ActionImportDialog
            open={openActionImportDialog}
            handleClose={handleActionImportDialogClose}
            action={action}
          />
        </>
      )}
    </>
  )
}

export default TradeActionList
