import axios from 'axios'
import {
  AddUserGamePointsResponse,
  LevelPlayersResponse,
  PlayersResponse,
  TradeActionBlockResponse,
  TradeActionDetailsResponse,
  TradeActionsListResponse,
  UserChallengesPreviewResponse,
  UserLevelPreviewResponse,
  UserPlanDetailsResponse,
  UserPlansPreviewResponse,
  UserPlanStoresDetailsResponse,
  UserQuizResponse,
} from '../store/Game/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getPlayers = (
  levelId: number | null,
  companies: { id: number }[],
  users: number[],
): Promise<PlayersResponse> => {
  return axios.post(
    API_URL + '/api/admin/game/user',
    {
      levelId,
      companies,
      users,
    },
    {
      headers: authHeader(),
    },
  )
}

const getLevelPlayers = (
  companies: { id: number }[],
  search: string,
): Promise<LevelPlayersResponse> => {
  return axios.post(
    API_URL + '/api/admin/game/level',
    {
      companies,
      search,
    },
    {
      headers: authHeader(),
    },
  )
}

const getUserLevelPreview = async (
  userId: number,
): Promise<UserLevelPreviewResponse> => {
  return await axios.get(
    API_URL + `/api/admin/game/preview/main/level/${userId}`,
    {
      headers: authHeader(),
    },
  )
}

const getUserPlansPreview = async (
  periodId: number,
  userId: number,
): Promise<UserPlansPreviewResponse> => {
  return await axios.get(
    API_URL + `/api/admin/game/preview/plan/list/${periodId}/${userId}`,
    {
      headers: authHeader(),
    },
  )
}

const getPlanDetails = async (
  planId: number,
): Promise<UserPlanDetailsResponse> => {
  return await axios.get(API_URL + `/api/plan/description/${planId}`, {
    headers: authHeader(),
  })
}

const getUserPlanStoresDetails = async (
  planId: number,
  userId: number,
): Promise<UserPlanStoresDetailsResponse> => {
  return await axios.get(
    API_URL + `/api/admin/game/preview/plan/details/${planId}/${userId}`,
    {
      headers: authHeader(),
    },
  )
}

const getChallengesList = async (
  periodId: number,
  userId: number,
): Promise<UserChallengesPreviewResponse> => {
  return await axios.get(
    API_URL + `/api/admin/game/preview/challenge/list/${periodId}/${userId}`,
    { headers: authHeader() },
  )
}

const addUserGamePoints = (
  userId: number,
  xpAmount: number | null,
  vpAmount: number | null,
  epAmount: number | null,
): Promise<AddUserGamePointsResponse> => {
  return axios.post(
    API_URL + '/api/admin/game/user/operation',
    {
      userId,
      xpAmount,
      vpAmount,
      epAmount,
    },
    {
      headers: authHeader(),
    },
  )
}

const getTradeActionsList = async (
  userId: number,
): Promise<TradeActionsListResponse> => {
  return await axios.get(
    API_URL + `/api/admin/game/preview/trade-actions/list/${userId}`,
    {
      headers: authHeader(),
    },
  )
}

const getTradeActionDetails = async (
  actionId: number,
): Promise<TradeActionDetailsResponse> => {
  return await axios.get(API_URL + `/api/trade-actions/${actionId}`, {
    headers: authHeader(),
  })
}

const getTradeActionBlock = async (
  blockId: number,
  userId: number,
): Promise<TradeActionBlockResponse> => {
  return await axios.get(
    API_URL +
      `/api/admin/game/preview/trade-actions/block/${blockId}/${userId}`,
    {
      headers: authHeader(),
    },
  )
}

const getUserQuiz = async (
  userId: number,
  quizId: number,
): Promise<UserQuizResponse> => {
  return await axios.get(
    API_URL + `/api/admin/game/preview/quiz/${userId}/${quizId}`,
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getPlayers,
  getLevelPlayers,
  getUserLevelPreview,
  getUserPlansPreview,
  getPlanDetails,
  getUserPlanStoresDetails,
  getChallengesList,
  addUserGamePoints,
  getTradeActionsList,
  getTradeActionDetails,
  getTradeActionBlock,
  getUserQuiz,
}

export default exportedObject
