import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Box,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { Action } from '../../../../store/TradeAction/types'
import { ReactComponent as NoActionUsersIcon } from '../../../../assets/images/icons/no_action_users.svg'
import { errorHandler } from '../../../../helpers/errorHandler'
import TradeActionService from '../../../../services/tradeAction.service'
import CompanyService from '../../../../services/company.service'
import { toast } from 'react-toastify'
import { Company } from '../../../../store/Company/types'
import GenericImport from '../../Import/partials/GenericImport'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

type ActionUsersDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  action: Action
}

interface CompanyCheckbox extends Company {
  checked: boolean
}

const ActionUsersDialog: React.FunctionComponent<ActionUsersDialogProps> = ({
  open,
  handleClose,
  action,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [tabValue, setTabValue] = useState<number>(0)
  const [companies, setCompanies] = useState<CompanyCheckbox[]>([])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedCompanies = companies.map((company) => {
      if (company.name === event.target.name) {
        return {
          ...company,
          checked: event.target.checked,
        }
      }
      return company
    })
    setCompanies(updatedCompanies)
  }

  useEffect(() => {
    if (open) {
      if (action.users.includes('REGION')) {
        setTabValue(2)
      } else if (action.users.includes('PH')) {
        setTabValue(3)
      } else {
        setTabValue(0)
      }
    }
  }, [open, action.users])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // get companies
        const companiesResponse = await CompanyService.getCompanyList()

        if (companiesResponse.data.companies) {
          // get selected companies if they were already checked
          if (action.users.includes('REGION')) {
            const actionCompaniesResponse =
              await TradeActionService.getActionCompanies(action.actionId)
            setCompanies(
              companiesResponse.data.companies.map((company) => ({
                ...company,
                checked: actionCompaniesResponse.data.companies.includes(
                  company.companyId,
                ),
              })),
            )
          } else {
            setCompanies(
              companiesResponse.data.companies.map((company) => ({
                ...company,
                checked: false,
              })),
            )
          }
        }
      } catch (error) {
        errorHandler(error, t)
      }
      setLoading(false)
    }
    if (open && tabValue === 2) {
      fetchData()
    }
  }, [open, t, action, tabValue])

  const saveParticipants = async (tabId: number) => {
    try {
      setIsSaving(true)
      switch (tabId) {
        case 1:
          const setActionGlobalParticipantsResponse =
            await TradeActionService.setActionGlobalParticipants(
              action.actionId,
            )

          if (setActionGlobalParticipantsResponse.data.success) {
            toast.success(t('messages.success.savedSuccessfully'))
          }
          break
        case 2:
          const setActionCompanyParticipantsResponse =
            await TradeActionService.setActionCompanyParticipants(
              action.actionId,
              companies
                .filter((company) => company.checked)
                .map((r) => ({ id: r.companyId })),
            )

          if (setActionCompanyParticipantsResponse.data.success) {
            toast.success(t('messages.success.savedSuccessfully'))
          }
          break
        case 3:
          break

        default:
          break
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
      handleClose(true)
    }
  }

  const displayTab = (tabId: number) => {
    if (action.users === 'NONE') {
      return true
    }
    switch (tabId) {
      case 1:
        if (action.users.includes('REGION')) {
          return false
        } else if (action.users.includes('PH')) {
          return false
        }
        return false
      case 2:
        if (action.users.includes('REGION')) {
          return true
        } else if (action.users.includes('PH')) {
          return false
        }
        return false
      case 3:
        if (action.users.includes('REGION')) {
          return false
        } else if (action.users.includes('PH')) {
          return true
        }
        return false

      default:
        return true
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.tradeActions.actionUsersDialog.title')}: {action.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ justifyContent: 'space-around' }}
            >
              <Tab sx={{ display: 'none' }} />
              <Tab
                label={t('pages.tradeActions.actionUsersDialog.tabs.addAll')}
                sx={{
                  textTransform: 'none',
                  display: displayTab(1) ? 'inherit' : 'none',
                }}
              />
              <Tab
                label={t(
                  'pages.tradeActions.actionUsersDialog.tabs.addFromCompany',
                )}
                sx={{
                  textTransform: 'none',
                  display: displayTab(2) ? 'inherit' : 'none',
                }}
              />
              <Tab
                label={t(
                  'pages.tradeActions.actionUsersDialog.tabs.addSelected',
                )}
                sx={{
                  textTransform: 'none',
                  display: displayTab(3) ? 'inherit' : 'none',
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <NoActionUsersIcon />
            <br />
            <strong>
              {t('pages.tradeActions.actionUsersDialog.noUsers')}
              <br />
              {t('pages.tradeActions.actionUsersDialog.selectUsersAddMethod')}
            </strong>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <strong>{t('pages.tradeActions.actionUsersDialog.addAll')}</strong>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {loading && <LoadingSpinner />}
            {!loading && (
              <FormGroup>
                {companies.map((company, i) => (
                  <FormControlLabel
                    key={`company-${i}`}
                    control={
                      <Checkbox
                        checked={company.checked}
                        onChange={handleCompanyChange}
                        name={company.name}
                      />
                    }
                    label={company.name}
                  />
                ))}
              </FormGroup>
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Stack textAlign="left">
              <GenericImport
                name="trade_action_users"
                param1={action.actionId}
              />
            </Stack>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          {[1, 2].includes(tabValue) && (
            <>
              <SecondaryButton
                onClick={() => handleClose(tabValue === 3 ? true : false)}
                disabled={isSaving}
              >
                {t('common.cancel')}
              </SecondaryButton>
              <PrimaryButton
                onClick={() => saveParticipants(tabValue)}
                disabled={isSaving}
              >
                {t('pages.tradeActions.actionUsersDialog.addAndClose')}
              </PrimaryButton>
            </>
          )}
          {[0, 3].includes(tabValue) && (
            <>
              <div></div>
              <SecondaryButton
                onClick={() => handleClose(tabValue === 3 ? true : false)}
                disabled={isSaving}
              >
                {t('common.close')}
              </SecondaryButton>
            </>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default ActionUsersDialog
