import { FunctionComponent, useCallback, useRef, useState } from 'react'
import { Grid, IconButton, SelectChangeEvent, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  getTableState,
  handleNavigationClick,
  setTableState,
} from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import PeriodService from '../../../../services/period.service'
import ChallengeService from '../../../../services/challenge.service'
import TableControlled from '../../../Table/TableControlled'
import { Column, ColumnInstance } from 'react-table'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import { errorHandler } from '../../../../helpers/errorHandler'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import { ReactComponent as PreviewPhoneIcon } from '../../../../assets/images/icons/preview_phone.svg'
import { ReactComponent as UserImportResult } from '../../../../assets/images/icons/user_import_result.svg'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import ChallengePreviewOnPhoneDialog from '../partials/ChallengePreviewOnPhoneDialog'
import { Challenge, ChallengeState } from '../../../../store/Challenge/types'
import { Period } from '../../../../store/Period/types'
import ChallengesToolbar from '../partials/ChallengesToolbar'
import ChallengeCompleteDialog from '../partials/ChallengeCompleteDialog'
import ImportDialog from '../../../shared/ImportDialog'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { isCompanyAdmin } from '../../../../helpers/checkRole'
import { User } from '../../../../store/Auth/types'
import { ReactComponent as StoreImportPlan } from '../../../../assets/images/icons/store_import_plan.svg'

type ChallengeListProps = {
  path: string
  user: User
}

const ChallengeList: FunctionComponent<ChallengeListProps> = ({
  path,
  user,
}) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'challenge'
  const searchState = getTableState(tableName, 'search')
  const periodState = getTableState(tableName, 'period')
  const statusState = getTableState(tableName, 'status')

  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [searchValue, setSearchValue] = useState<string>('')
  const [statusValue, setStatusValue] = useState<string>(
    statusState ? statusState : 'all',
  )
  const [periodValue, setPeriodValue] = useState<string>(
    periodState ? periodState : 'all',
  )
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [filteredChallengeList, setFilteredChallengeList] = useState<
    Challenge[]
  >([])
  const [periods, setPeriods] = useState<Period[]>([])
  const [statuses, setStatuses] = useState<ChallengeState[]>([])
  const [challengeId, setChallengeId] = useState<number | null>(null)
  const [importName, setImportName] = useState<string | null>(null)
  const [
    challengeResultsImportDialogOpen,
    setChallengeResultsImportDialogOpen,
  ] = useState<boolean>(false)

  const [challengeCompleteDialogOpen, setChallangeCompleteDialogOpen] =
    useState<boolean>(false)
  const [challengeName, setChallengeName] = useState<string>('')
  const [refresh, setRefresh] = useState(false)
  const [openPreviewOnPhoneDialog, setPreviewOnPhoneDialogOpen] =
    useState(false)
  // eslint-disable-next-line
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])

  const handlePreviewOnPhoneDialogClickOpen = (challengeId: number) => {
    setChallengeId(challengeId)
    setPreviewOnPhoneDialogOpen(true)
  }

  const handlePreviewOnPhoneDialogClose = () => {
    setPreviewOnPhoneDialogOpen(false)
  }

  const handleChallengeCompleteDialogClose = (
    refreshTable: boolean = false,
  ) => {
    setChallangeCompleteDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }
  const handleChallengeCompleteDialogClickOpen = (challengeId: number) => {
    setChallengeId(challengeId)
    setChallangeCompleteDialogOpen(true)
  }
  const handleChallengeResultsImportDialogClickOpen = (challengeId: number) => {
    setChallengeId(challengeId)
    setChallengeResultsImportDialogOpen(true)
  }

  const handleChallengeResultsImportDialogClose = () => {
    setChallengeResultsImportDialogOpen(false)
  }

  const generateTableColumns = useCallback(
    (challange: Challenge[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'challengeId',
          Header: t('pages.challenge.table.id').toString(),
          width: 60,
          Cell: (params: any) => (
            <div style={{ width: '100%' }}>
              <Link
                to={`/challenges-users/${
                  params.row.values.challengeId
                }/period/${periodValue}/status/${statusValue.toLowerCase()}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            </div>
          ),
        },
        {
          accessor: 'periodName',
          Header: t('pages.challenge.table.period').toString(),
          width: 140,
          Cell: (params: any) => (
            <div style={{ width: '100%' }}>
              <Link
                to={`/challenges-users/${
                  params.row.values.challengeId
                }/period/${periodValue}/status/${statusValue.toLowerCase()}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            </div>
          ),
        },
        {
          accessor: 'name',
          Header: t('pages.challenge.table.name').toString(),
          width: 200,
          Cell: (params: any) => (
            <div style={{ width: '100%' }}>
              <Link
                to={`/challenges-users/${
                  params.row.values.challengeId
                }/period/${periodValue}/status/${statusValue.toLowerCase()}`}
                style={{ color: 'rgba(0,0,0,.87)' }}
              >
                {params.value}
              </Link>
            </div>
          ),
        },
        {
          accessor: 'availableFrom',
          Header: t('pages.challenge.table.availableFrom').toString(),
          width: 130,
          Cell: (params: any) =>
            moment(params.value).format('YYYY-MM-DD HH:mm'),
        },
        {
          accessor: 'availableTo',
          Header: t('pages.challenge.table.availableTo').toString(),
          width: 130,
          Cell: (params: any) =>
            moment(params.value).format('YYYY-MM-DD HH:mm'),
        },
        {
          accessor: 'validFrom',
          Header: t('pages.challenge.table.validFrom').toString(),
          width: 130,
          Cell: (params: any) =>
            moment(params.value).format('YYYY-MM-DD HH:mm'),
        },
        {
          accessor: 'validTo',
          Header: t('pages.challenge.table.validTo').toString(),
          width: 130,
          Cell: (params: any) =>
            moment(params.value).format('YYYY-MM-DD HH:mm'),
        },
        {
          accessor: 'amateur',
          Header: t('pages.challenge.table.amateur').toString(),
          width: 100,
        },
        {
          accessor: 'expert',
          Header: t('pages.challenge.table.expert').toString(),
          width: 100,
        },
        {
          accessor: 'master',
          Header: t('pages.challenge.table.master').toString(),
          width: 100,
        },
        {
          accessor: 'total',
          Header: t('pages.challenge.table.overall').toString(),
          width: 160,
        },
        {
          accessor: 'statusName',
          Header: t('pages.challenge.table.status').toString(),
          Cell: (params: any) => (
            <>
              {t(
                `pages.challenge.table.statuses.${params.row.original.challengeStateCode}`,
              )}
            </>
          ),
        },
        {
          accessor: 'actions',
          Header: t('pages.challenge.table.actions').toString(),
          disableSortBy: true,
          sticky: 'right',
          width: 230,
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.challenge.table.edit')}`}>
                  <IconButton
                    aria-label="edit"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: '5px',
                      opacity:
                        !params.row.original.isEditable || isCompanyAdmin(user)
                          ? '.3'
                          : '1',
                    }}
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/update/${params.row.values.challengeId}`,
                      )
                    }
                    disabled={
                      !params.row.original.isEditable || isCompanyAdmin(user)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.challenge.table.show')}`}>
                  <IconButton
                    aria-label="show"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: '5px',
                      opacity: params.row.original.isEditable ? '.3' : '1',
                    }}
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/show/${params.row.values.challengeId}`,
                      )
                    }
                    disabled={params.row.original.isEditable}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.challenge.table.importResults')}`}>
                  <IconButton
                    aria-label="show"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: '5px',
                      opacity:
                        params.row.original.challengeStateCode ===
                          'COMPLETED' ||
                        !params.row.original.isImportAllowed ||
                        isCompanyAdmin(user)
                          ? '.3'
                          : '1',
                    }}
                    onClick={() => {
                      setImportName('user_challenge_results')
                      handleChallengeResultsImportDialogClickOpen(
                        params.row.values.challengeId,
                      )
                    }}
                    disabled={
                      params.row.original.challengeStateCode === 'COMPLETED' ||
                      !params.row.original.isImportAllowed ||
                      isCompanyAdmin(user)
                    }
                  >
                    <UserImportResult />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip
                  title={`${t('pages.challenge.table.importSpResults')}`}
                >
                  <IconButton
                    aria-label="show"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: '5px',
                      opacity:
                        params.row.original.challengeStateCode ===
                          'COMPLETED' ||
                        params.row.original.unitCode !== 'SP' ||
                        !params.row.original.isImportAllowed ||
                        isCompanyAdmin(user)
                          ? '.3'
                          : '1',
                    }}
                    onClick={() => {
                      setImportName('challenge_sp_results')
                      handleChallengeResultsImportDialogClickOpen(
                        params.row.values.challengeId,
                      )
                    }}
                    disabled={
                      params.row.original.challengeStateCode === 'COMPLETED' ||
                      params.row.original.unitCode !== 'SP' ||
                      !params.row.original.isImportAllowed ||
                      isCompanyAdmin(user)
                    }
                  >
                    <StoreImportPlan />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.challenge.table.showOnPhone')}`}>
                  <IconButton
                    aria-label="show"
                    size="small"
                    style={{
                      padding: 0,
                      marginRight: '5px',
                    }}
                    onClick={() =>
                      handlePreviewOnPhoneDialogClickOpen(
                        params.row.values.challengeId,
                      )
                    }
                  >
                    <PreviewPhoneIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              {!isCompanyAdmin(user) && (
                <Grid
                  item
                  sx={{
                    width: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={`${t('pages.challenge.table.settle')}`}>
                    <IconButton
                      aria-label="show"
                      size="small"
                      style={{
                        padding: 0,
                        marginRight: '5px',
                        opacity:
                          params.row.original.challengeStateCode ===
                            'COMPLETED' ||
                          params.row.original.isEditable ||
                          isCompanyAdmin(user)
                            ? '.3'
                            : '1',
                      }}
                      onClick={() => {
                        setChallengeName(params.row.original.name)
                        handleChallengeCompleteDialogClickOpen(
                          params.row.values.challengeId,
                        )
                      }}
                      disabled={
                        params.row.original.challengeStateCode ===
                          'COMPLETED' ||
                        params.row.original.isEditable ||
                        isCompanyAdmin(user)
                      }
                    >
                      <SportsScoreIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          ),
        },
      )
      return columns
    },
    [t, path, periodValue, statusValue, user],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          // setDownloadSortBy(sortColumn)
          // setDownloadSortOrder(sortDirection)
          const challengeStateListResponse =
            await ChallengeService.getChallengeStateList()
          if (challengeStateListResponse.data.challengeStates) {
            setStatuses(challengeStateListResponse.data.challengeStates)
            const periodListResponse = await PeriodService.getPeriodList()
            if (periodListResponse.data.periods) {
              setPeriods(periodListResponse.data.periods)
              const page = ++pageIndex
              const response = await ChallengeService.getChallengeList(
                periodValue === 'all' ? null : parseInt(periodValue),
                statusValue === 'all' ? null : statusValue,
                searchValue,
                sortColumn,
                sortDirection,
                pageSize,
                page,
              )

              if (response.data.challenges) {
                setTableColumns(generateTableColumns(response.data.challenges))

                setFilteredChallengeList(response.data.challenges)

                setTotalCount(response.data.totalCount)
                setPageCount(Math.ceil(response.data.totalCount / pageSize))
              }
            }
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [t, statusValue, periodValue, searchValue, generateTableColumns, refresh],
  )

  return (
    <>
      <PrimaryButton
        variant="contained"
        onClick={() => handleNavigationClick(`${path}/create`)}
        sx={{ marginRight: 1 }}
      >
        {t('common.create')}
      </PrimaryButton>
      <ChallengesToolbar
        periods={periods}
        statuses={statuses}
        periodValue={periodValue}
        value={searchText}
        onChange={(event: { target: { value: string } }) => {
          setSearchText(event.target.value)
          setTableState(tableName, 'search', event.target.value)
          setControlledPageIndex(0)
        }}
        submitSearch={(searchValue) => {
          setSkipPageReset(false)
          setSearchValue(searchValue)
        }}
        clearSearch={() => {
          setSkipPageReset(false)
          setSearchText('')
          setSearchValue('')
        }}
        statusValue={statusValue}
        filterStatus={(event: SelectChangeEvent) => {
          setStatusValue(event.target.value)
          setTableState(tableName, 'status', event.target.value)
          setControlledPageIndex(0)
          setSkipPageReset(false)
        }}
        filterPeriod={(event: SelectChangeEvent) => {
          setPeriodValue(event.target.value)
          setTableState(tableName, 'period', event.target.value)
          setControlledPageIndex(0)
          setSkipPageReset(false)
        }}
      />
      <TableControlled
        name={tableName}
        columns={tableColumns}
        data={filteredChallengeList}
        height="calc(100vh - 300px)"
        fetchData={fetchData}
        loading={tableLoading}
        pageIndex={controlledPageIndex}
        pageCount={pageCount}
        totalCount={totalCount}
        skipPageReset={skipPageReset}
        columnsVisibility={[
          'periodName',
          'availableFrom',
          'availableTo',
          'statusName',
        ]}
        toggleVisibility={setColumnsVisibility}
      />
      {challengeId && importName && (
        <ImportDialog
          open={challengeResultsImportDialogOpen}
          handleClose={handleChallengeResultsImportDialogClose}
          name={importName}
          param1={challengeId}
          title={t('pages.challenge.dialog.importTitle', {
            challengeId,
          })}
        />
      )}
      {challengeId && (
        <ChallengePreviewOnPhoneDialog
          open={openPreviewOnPhoneDialog}
          handleClose={handlePreviewOnPhoneDialogClose}
          challengeId={challengeId}
        />
      )}
      {challengeId && (
        <ChallengeCompleteDialog
          open={challengeCompleteDialogOpen}
          handleClose={handleChallengeCompleteDialogClose}
          name={challengeName}
          challengeId={challengeId}
        />
      )}
    </>
  )
}

export default ChallengeList
