import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import CompanyService from '../../../../services/company.service'
import RegionService from '../../../../services/region.service'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { Column, ColumnInstance } from 'react-table'
import {
  getTableState,
  setTableState,
  getHiddenColumns,
} from '../../../../helpers/utils'
import { errorHandler } from '../../../../helpers/errorHandler'
import { CompanyList, Option } from '../../../../store/Company/types'
import TableControlled from '../../../Table/TableControlled'
import CompaniesToolbar from '../partials/CompaniesToolbar'
import { Stack } from '@mui/system'
import { pick } from 'lodash'
import * as XLSX from 'xlsx'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { CircularProgress } from '@mui/material'

type CompanyListProps = {
  path: string
}

const CompaniesList: FunctionComponent<CompanyListProps> = ({ path }) => {
  const tableName = 'company'
  const searchState = getTableState(tableName, 'search')

  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [filteredCompanyList, setFilteredCompanyList] = useState<CompanyList[]>(
    [],
  )
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [regions, setRegions] = useState<Option[]>([])
  const [selectedRegions, setSelectedRegions] = useState<Option[]>([])
  const fetchIdRef = useRef(0)
  const [searchText, setSearchText] = useState<string>(
    searchState ? searchState : '',
  )
  const [searchValue, setSearchValue] = useState<string>(
    searchState ? searchState : '',
  )

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')

  const generateTableColumns = useCallback(
    (company: CompanyList[]) => {
      const columns = []
      columns.push(
        {
          accessor: 'companyId',
          Header: t('pages.company.table.companyId').toString(),
          width: 60,
        },
        {
          accessor: 'companyCentralId',
          Header: t('pages.company.table.companyCentralId').toString(),
          width: 140,
        },
        {
          accessor: 'companyName',
          Header: t('pages.company.table.companyName').toString(),
          width: 300,
        },
        {
          accessor: 'regionName',
          Header: t('pages.company.table.regionName').toString(),
          width: 160,
        },
      )
      return columns
    },
    [t],
  )

  useEffect(() => {
    const fetchRegionsData = async () => {
      try {
        const regionListResponse = await RegionService.getRegionList()

        if (regionListResponse.data.regions) {
          const multiSelectOptions: Option[] = []
          regionListResponse.data.regions.forEach((region) =>
            multiSelectOptions.push({
              value: region.regionId,
              label: region.name,
            }),
          )
          setRegions(multiSelectOptions)
          const regionsState = getTableState(tableName, 'regions')
          if (regionsState) {
            setSelectedRegions(JSON.parse(regionsState))
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchRegionsData()
  }, [t])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)

          const page = ++pageIndex
          const companyListResponse =
            await CompanyService.getPaginatedCompanyList(
              searchValue,
              sortColumn,
              sortDirection,
              pageSize,
              page,
              selectedRegions.map((region) => {
                return {
                  id: region.value,
                }
              }),
            )

          if (companyListResponse.data.companies) {
            setFilteredCompanyList(companyListResponse.data.companies)
            setTableColumns(
              generateTableColumns(companyListResponse.data.companies),
            )
            setTotalCount(companyListResponse.data.totalCount)
            setPageCount(
              Math.ceil(companyListResponse.data.totalCount / pageSize),
            )
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setTableLoading(false)
        }
      }
    },
    [t, generateTableColumns, selectedRegions, searchValue],
  )
  const handleSelectedRegionsChange = (regions: Option[]) => {
    setSkipPageReset(false)
    setControlledPageIndex(0)
    setTableState(tableName, 'regions', JSON.stringify(regions))
    setSelectedRegions(regions)
  }

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const companyListResponse = await CompanyService.getPaginatedCompanyList(
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
        selectedRegions.map((region) => {
          return {
            id: region.value,
          }
        }),
      )

      const dataCompany = companyListResponse.data.companies
      if (dataCompany) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'companyId',
            'companyCentralId',
            'companyName',
            'regionName',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredCompanyData = dataCompany.map((store) => {
          return pick(store, visibleColumns)
        })

        const translatedHeaders = {
          companyId: t('excel.company.companyId'),
          companyCentralId: t('excel.company.companyCentralId'),
          companyName: t('excel.company.companyName'),
          regionName: t('excel.company.regionName'),
        }

        const headers = [
          Object.keys(filteredCompanyData[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredCompanyData, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredCompanyData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('company')}
              sx={{ marginLeft: 'auto', marginTop: '8px', marginBottom: '7px' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>
          <CompaniesToolbar
            regions={regions}
            selectedRegions={selectedRegions}
            value={searchText}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
              setTableState(tableName, 'search', event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            setSelectedRegions={(regions) =>
              handleSelectedRegionsChange(regions)
            }
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
            }}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredCompanyList}
            height="calc(100vh - 310px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={controlledPageIndex}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[]}
            toggleVisibility={setColumnsVisibility}
          />
        </>
      )}
    </>
  )
}

export default CompaniesList
