import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { Stack } from '@mui/system'
import { Grid } from '@mui/material'

const EDITOR_MAX_LENGTH = 1000

type QuestionWYSIWYGProps = {
  questionContent: string
  setQuestionContent: (content: string) => void
}

const QuestionWYSIWYG: React.FC<QuestionWYSIWYGProps> = ({
  questionContent,
  setQuestionContent,
}) => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )
  const [questionContentLength, setQuestionContentLength] = useState<number>(0)

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(questionContent)
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )
    setEditorState(EditorState.createWithContent(content))
    setQuestionContent(questionContent)
  }, [questionContent, setQuestionContent])

  useEffect(() => {
    setQuestionContentLength(
      editorState.getCurrentContent().getPlainText('').length,
    )
    setQuestionContent(
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    )
  }, [editorState, setQuestionContent])

  const onEditorStateChange = (currentEditorState: EditorState) => {
    const contentState = currentEditorState.getCurrentContent()
    const oldContent = editorState.getCurrentContent()
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= EDITOR_MAX_LENGTH
    ) {
      setEditorState(currentEditorState)
    } else {
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      )
      setEditorState(newEditorState)
    }
  }

  return (
    <Grid item xs={12}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="questionClassName"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'list', 'colorPicker', 'emoji'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <div className="character-amount">
          {questionContentLength} / {EDITOR_MAX_LENGTH}
        </div>
      </Stack>
    </Grid>
  )
}

export default QuestionWYSIWYG
