import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { styled } from '@mui/material/styles'
import { handleNavigationClick } from '../../../../helpers/utils'
import { FunctionComponent } from 'react'
import { ChallengeDetails } from '../../../../store/Challenge/types'
import ChallengeCountdownTimer from './ChallengeCountdownTimer'

type ChallengeCountdownTimerProps = {
  challengeId: number | null
  challengeDetails: ChallengeDetails | null
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>
  refreshTimer: boolean
  setRefreshTimer: React.Dispatch<React.SetStateAction<boolean>>
}

export const BottomBarContainer = styled('div')(() => ({
  '.bottomBar-buttons': {
    textDecoration: 'none !important',
  },
}))

const BottomBar: FunctionComponent<ChallengeCountdownTimerProps> = ({
  challengeId,
  challengeDetails,
  setIsSaveDisabled,
  refreshTimer,
  setRefreshTimer,
}) => {
  const { t } = useTranslation()

  return (
    <BottomBarContainer
      style={{
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 65,
        maxWidth: 'calc(100% - 71px)',
        marginLeft: '-20px',
        backgroundColor: '#000',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <SecondaryButton onClick={() => handleNavigationClick('challenges')}>
        {t('common.back')}
      </SecondaryButton>
      {challengeDetails !== null && challengeId && (
        <>
          <ChallengeCountdownTimer
            challengeId={challengeId}
            expiryTimestamp={new Date(challengeDetails.availableFrom)}
            setIsSaveDisabled={setIsSaveDisabled}
            refreshTimer={refreshTimer}
            setRefreshTimer={setRefreshTimer}
          />
        </>
      )}
    </BottomBarContainer>
  )
}

export default BottomBar
