import { FormError } from '../types'

export const enum QuizStateCode {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  COMPLETED = 'COMPLETED',
}

export const enum AssetTypeCode {
  VIDEO = 'VIDEO',
  PDF = 'PDF',
}

export type AssetFile = {
  contentId: number | null
  contentName: string
  content: string | null
}

export type Asset = {
  assetId: number
  name: string
  description: string | null
  assetAvailableFrom: string
  assetAvailableTo: string
  isVisible: boolean
  isEditable: boolean
  users: number
  isQuiz: boolean
  quizStateCode: QuizStateCode
  quizAvailableFrom: string | null
  quizAvailableTo: string | null
  quizId: number | null
  isQuizCompleted: boolean
  isQuizReadyToComplete: boolean
}

export type EduboxListResponse = {
  data: {
    assets: Asset[]
    totalCount: number
  }
}

export type QuizState = {
  name: string
  code: string
}

export type QuizStateListResponse = {
  data: {
    quizStates: QuizState[]
  }
}

export type AssetType = {
  id: number
  name: string
  code: string
}

export type AssetTypeListResponse = {
  data: {
    assetTypes: AssetType[]
  }
}

export type CreateOrUpdateAssetParams = {
  assetId: number | null
  assetTypeId: number
  assetAvailableFrom: string
  assetAvailableTo: string
  contentName: string
  content: string | null
  length: number
  orientation: string
  name: string
  description: string | null
  isVisible: boolean
  imageId: number | null
}

export type BasicInformationErrors = {
  name: FormError
  validFromDate: FormError
  validFromTime: FormError
  validToDate: FormError
  validToTime: FormError
  mainImage: FormError
  materialType: FormError
  videoUrl: FormError
  videoLength: FormError
  assetFile: FormError
  slidesLength: FormError
}

export type QuizErrors = {
  name: FormError
  validFromDate: FormError
  validFromTime: FormError
  validToDate: FormError
  validToTime: FormError
  length: FormError
}

export type CreateOrUpdateAssetResponse = {
  data: {
    assetId: number
    success: boolean
  }
}

export type QuizPoints = {
  startPlace: number
  endPlace: number
  xpReward: number
  vpReward: number
  epReward: number
}

export type QuizAnswer = {
  answerId: number | null
  content: string
  isCorrect: boolean
}

export type QuizQuestion = {
  questionId: number | null
  imageId: number | null
  content: string
  answers: QuizAnswer[]
}

export type Quiz = {
  quizId: number
  quizName: string
  quizDescription: string | null
  quizAvailableFrom: string
  quizAvailableTo: string
  durationTime: number
  points: QuizPoints[]
  questions: QuizQuestion[]
}

export type AssetDetails = {
  assetId: number
  assetTypeId: number
  assetTypeCode: string
  name: string
  description: string | null
  imageId: number | null
  isVisible: boolean
  assetAvailableFrom: string
  assetAvailableTo: string
  contentName: string
  content: string | null
  length: number
  orientation: string
  quiz: Quiz | null
}

export type AssetDetailsResponse = {
  data: AssetDetails
}

export type CreateOrUpdateQuizParams = {
  assetId: number
  quizName: string
  quizDescription: string | null
  quizAvailableFrom: string
  quizAvailableTo: string
  durationTime: number
  points: QuizPoints[]
}

export type CreateOrUpdateQuizResponse = {
  data: {
    assetId: number
    quizId: number
    success: boolean
  }
}

export type CreateOrUpdateQuestionsParams = {
  assetId: number
  questions: QuizQuestion[]
}

export type CreateOrUpdateQuestionsResponse = {
  data: {
    success: boolean
    assetId: number
    questions: QuizQuestion[]
  }
}

export type QuizUser = {
  quizId: number
  userId: number
  userCentralId: string
  userInternalId: string | null
  userFirstname: string
  userLastname: string
  userFullName: string
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number | null
  regionName: string | null
  regionShortName: string | null
  correctQuestionAnswers: number | null
  correctAnswersPercent: number | null
  incorrectQuestionAnswers: number | null
  incorrectAnswersPercent: number | null
  quizTime: number | string | null
  rankPlace: number | null
  xpAmount: number | null
  vpAmount: number | null
  epAmount: number | null
  questions: number | null // for xlsx export
}

export type QuizPreviewResponse = {
  data: {
    users: QuizUser[]
    totalCount: number
  }
}

export type CompleteQuizResponse = {
  data: {
    success: boolean
  }
}
