import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SearchIcon from '@mui/icons-material/Search'
import PlaceIcon from '@mui/icons-material/Place'
import {
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ReactComponent as CalendarIcon } from '../../../assets/images/game/icons/calendar_icon.svg'
// import { ReactComponent as FilterAltIcon } from '../../../assets/images/game/icons/filter_alt_icon.svg'
import { ReactComponent as PpIcon } from '../../../assets/images/game/icons/pp_icon.svg'
import { ReactComponent as VpIcon } from '../../../assets/images/game/icons/vp_icon.svg'
import {
  BorderLinearProgress,
  // FilterButton,
  PhoneAppBarDetails,
  PhoneCard,
  PhoneContent,
  PhoneStoreCard,
} from '../styles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import GameService from '../../../services/game.service'
import LoadingSpinner from '../../shared/LoadingSpinner'
import {
  PlanStores,
  UserPlansPreview,
  UserPlanStoresDetails,
} from '../../../store/Game/types'
import Image from '../../shared/Image'
import {
  thousandsSeparator,
  arraySearch,
  sortFunction,
} from '../../../helpers/utils'
import PlanSwitcher from '../partials/PlanSwitcher'
import {
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../helpers/environment'

type StoresScreenProps = {
  plans: UserPlansPreview[]
  setCurrentScreen: React.Dispatch<React.SetStateAction<string>>
  planId: number
  userId: number
}

const StoresScreen: React.FunctionComponent<StoresScreenProps> = ({
  plans,
  setCurrentScreen,
  planId,
  userId,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [planStoresDetails, setPlanStoresDetails] =
    useState<UserPlanStoresDetails | null>(null)

  const [storesList, setStoresList] = useState<PlanStores[]>([])
  const [currentPlanId, setCurrentPlanId] = useState<number>(planId)

  const getUserPlanStoresDetailsService = (planId: number, userId: number) => {
    return GameService.getUserPlanStoresDetails(planId, userId)
  }

  const handleOnSearchChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let value = e.target.value
    const usersPlanStoresDetailsForSearch =
      await getUserPlanStoresDetailsService(planId, userId)
    let search = await arraySearch(
      usersPlanStoresDetailsForSearch.data.stores,
      value,
      'name',
    )
    setStoresList(sortFunction(search, 'name'))
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const usersPlanStoresDetails = await getUserPlanStoresDetailsService(
          currentPlanId,
          userId,
        )

        if (usersPlanStoresDetails.data) {
          setPlanStoresDetails(usersPlanStoresDetails.data)
          setStoresList(
            sortFunction(usersPlanStoresDetails.data.stores, 'name'),
          )
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, currentPlanId, userId])

  const renderStore = (index: number, storeDetails: PlanStores) => {
    return (
      <PhoneStoreCard even={index % 2 === 1 ? true : false}>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          lineHeight="normal"
          textAlign="left"
        >
          {storeDetails.storeCentralId + ' ' + storeDetails.name}
        </Typography>
        <Stack display="flex" flexDirection="row" alignItems="center">
          <PlaceIcon fontSize="small" htmlColor="#808080" />
          <Typography variant="caption" color="#808080">
            {storeDetails.city +
              ', ' +
              storeDetails.street +
              ' ' +
              storeDetails.homeNumber}
          </Typography>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="12px"
          justifyContent="flex-end"
          alignItems="center"
          fontWeight="600"
          marginBottom="4px"
        >
          {storeDetails.resultPercentValue}%
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          value={
            storeDetails.resultPercentValue <= 100
              ? storeDetails.resultPercentValue
              : 100
          }
        />
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="12px"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="400"
          marginTop="4px"
        >
          <div>
            {t('pages.game.phone.screens.storesScreen.resultValue')}{' '}
            {thousandsSeparator(storeDetails.resultValue)}
          </div>
          <div>
            {t('pages.game.phone.screens.storesScreen.planValue')}{' '}
            {thousandsSeparator(storeDetails.planValue)}
          </div>
        </Stack>
      </PhoneStoreCard>
    )
  }

  return (
    <>
      {loading && (
        <>
          <LoadingSpinner />
        </>
      )}
      {!loading && planStoresDetails !== null && (
        <>
          <PhoneAppBarDetails>
            <Stack
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              px="8px"
            >
              <IconButton onClick={() => setCurrentScreen('plans')}>
                <ArrowBackIcon htmlColor="#FFD748" />
              </IconButton>
              <PlanSwitcher
                planId={currentPlanId}
                plans={plans}
                setCurrentPlanId={setCurrentPlanId}
              />
              <IconButton>
                <InfoOutlinedIcon htmlColor="#FFD748" />
              </IconButton>
            </Stack>
          </PhoneAppBarDetails>
          <PhoneContent>
            <PhoneCard style={{ marginTop: '36px' }}>
              <Image
                imageId={planStoresDetails.imageId}
                style={{
                  width: '96px',
                  height: '40px',
                  objectFit: 'contain',
                  position: 'absolute',
                  left: 'calc(50% - 48px)',
                  top: '-20px',
                }}
              />
              <Stack
                display="flex"
                flexDirection="row"
                fontSize="12px"
                justifyContent="space-between"
                alignItems="center"
                fontWeight="600"
                marginTop="10px"
                marginBottom="4px"
              >
                <Stack display="flex" flexDirection="row" alignItems="center">
                  <CalendarIcon />
                  {planStoresDetails.timeScope}
                </Stack>
                <div>{planStoresDetails.resultPercentValue}%</div>
              </Stack>
              <BorderLinearProgress
                variant="determinate"
                value={
                  planStoresDetails.resultPercentValue <= 100
                    ? planStoresDetails.resultPercentValue
                    : 100
                }
              />
              <Stack
                display="flex"
                flexDirection="row"
                fontSize="12px"
                justifyContent="space-between"
                alignItems="center"
                fontWeight="400"
                marginTop="4px"
                marginBottom="16px"
              >
                <div>
                  {t('pages.game.phone.screens.storesScreen.resultValue')}{' '}
                  {thousandsSeparator(planStoresDetails.resultValue)}
                </div>
                <div>
                  {t('pages.game.phone.screens.storesScreen.planValue')}{' '}
                  {thousandsSeparator(planStoresDetails.planValue)}
                </div>
              </Stack>
              <Stack
                display="flex"
                flexDirection="row"
                fontSize="12px"
                justifyContent="flex-end"
                alignItems="center"
                fontWeight="600"
              >
                {t('pages.game.phone.screens.storesScreen.granted')}
                {isXpPointsVisible() && (
                  <>
                    <div style={{ fontSize: '16px', margin: '0 5px 0 7px' }}>
                      {planStoresDetails.xpValue}
                    </div>
                    <PpIcon />
                  </>
                )}
                {isVpPointsVisible() && (
                  <>
                    <div style={{ fontSize: '16px', margin: '0 5px 0 7px' }}>
                      {planStoresDetails.vpValue}
                    </div>
                    <VpIcon />
                  </>
                )}
              </Stack>
            </PhoneCard>
            <Stack
              display="flex"
              flexDirection="row"
              padding="0 16px 16px 16px"
              alignItems="flex-end"
            >
              {/* <FilterButton>
                <FilterAltIcon />
              </FilterButton> */}
              <FormControl
                style={
                  {
                    /*marginLeft: '16px'*/
                  }
                }
                fullWidth
              >
                <TextField
                  id="input-with-icon-textfield"
                  onChange={handleOnSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      height: '48px',
                    },
                  }}
                  variant="outlined"
                  style={{
                    background: '#fff',
                    height: '48px',
                  }}
                  placeholder={t(
                    'pages.game.phone.screens.storesScreen.search',
                  )}
                />
              </FormControl>
            </Stack>

            {storesList.map((p, i) => {
              return renderStore(i, p)
            })}
          </PhoneContent>
        </>
      )}
    </>
  )
}

export default StoresScreen
