import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { Option } from '../../../../store/types'
import { MultiSelect } from 'react-multi-select-component'
import { User } from '../../../../store/Auth/types'

interface ProductsToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  submitSearch: (value: string) => void
  value: string
  manufacturers: Option[]
  selectedManufacturers: Option[]
  setSelectedManufacturers: (manufacturers: Option[]) => void
  categories: Option[]
  selectedCategories: Option[]
  setSelectedCategories: (plans: Option[]) => void
  groups: Option[]
  selectedGroups: Option[]
  setSelectedGroups: (plans: Option[]) => void
  user: User
}

export default function ProductsToolbar(props: ProductsToolbarProps) {
  const { t } = useTranslation()

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl
          size="small"
          sx={{ minWidth: 180, marginRight: 1, zIndex: 6, display: 'none' }}
        >
          <label>{t('pages.products.toolbar.filters.manufacturers')}</label>
          <MultiSelect
            options={props.manufacturers}
            value={props.selectedManufacturers}
            onChange={props.setSelectedManufacturers}
            labelledBy={t('multiSelect.labelledBy')}
            overrideStrings={{
              allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
              clearSearch: t('multiSelect.clearSearch'),
              noOptions: t('multiSelect.noOptions'),
              search: t('multiSelect.search'),
              selectAll: t('multiSelect.selectAll'),
              selectAllFiltered: t('multiSelect.selectAllFiltered'),
              selectSomeItems: t('multiSelect.selectSomeItems'),
            }}
            valueRenderer={customValueRenderer}
          />
        </FormControl>
        <FormControl
          size="small"
          sx={{ minWidth: 180, marginRight: 1, zIndex: 6, display: 'none' }}
        >
          <label>{t('pages.products.toolbar.filters.categories')}</label>
          <MultiSelect
            options={props.categories}
            value={props.selectedCategories}
            onChange={props.setSelectedCategories}
            labelledBy={t('multiSelect.labelledBy')}
            overrideStrings={{
              allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
              clearSearch: t('multiSelect.clearSearch'),
              noOptions: t('multiSelect.noOptions'),
              search: t('multiSelect.search'),
              selectAll: t('multiSelect.selectAll'),
              selectAllFiltered: t('multiSelect.selectAllFiltered'),
              selectSomeItems: t('multiSelect.selectSomeItems'),
            }}
            valueRenderer={customValueRenderer}
          />
        </FormControl>
        <FormControl
          size="small"
          sx={{ minWidth: 180, marginRight: 1, zIndex: 6, display: 'none' }}
        >
          <label>{t('pages.products.toolbar.filters.groups')}</label>
          <MultiSelect
            options={props.groups}
            value={props.selectedGroups}
            onChange={props.setSelectedGroups}
            labelledBy={t('multiSelect.labelledBy')}
            overrideStrings={{
              allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
              clearSearch: t('multiSelect.clearSearch'),
              noOptions: t('multiSelect.noOptions'),
              search: t('multiSelect.search'),
              selectAll: t('multiSelect.selectAll'),
              selectAllFiltered: t('multiSelect.selectAllFiltered'),
              selectSomeItems: t('multiSelect.selectSomeItems'),
            }}
            valueRenderer={customValueRenderer}
          />
        </FormControl>
      </div>
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          size="small"
          value={props.value}
          onChange={props.onChange}
          placeholder={t('pages.products.table.search')}
          onKeyDown={onSearchKeyDown}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 100 }}
        />
        <PrimaryButton
          onClick={() => props.submitSearch(props.value)}
          style={{
            padding: '7px 5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          }}
        >
          {t('common.search')}
        </PrimaryButton>
      </div>
    </div>
  )
}
