import { FormError } from '../types'

export const enum UserTypeCode {
  ADM = 'ADM',
  ASM = 'ASM',
  SE = 'SE',
}

export type UserImport = {
  userId: number
  userCentralId: string | null
  userInternalId: string | null
  username: string
  firstname: string
  lastname: string
  companyId: number | null
  companyInternalId: string | null
  companyName: string | null
  regionId: number | null
  regionName: string | null
}

export type UserImportListReponse = {
  data: {
    users: UserImport[]
  }
}

export type User = {
  userId: number
  userCentralId: string
  userInternalId: string
  typeId: number
  typeName: string
  typeCode: UserTypeCode
  stateId: number
  stateName: string
  stateCode: string
  firstname: string
  lastname: string
  email: string
  mobile: string | null
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number | null
  regionName: string | null
  regionShortName: string | null
  lastLoginDate: string | null
  bankAccount: string | null
  applicationVersion: string | null
  applicationVersionId: number | null
  deviceOsVersion: string | null
  deviceModelName: string | null
  isVisible: boolean | string
}

export type UsersResponse = {
  data: {
    users: User[]
    totalCount: number
  }
}

export type UserAddParams = {
  companyId: number
  regionId: number
  firstname: string
  lastname: string
  centralId: string
  internalId: string | null
  username: string
  email: string
  mobile: string | null
  userTypeCode: UserTypeCode
}

export type UserAddErrors = {
  companyId: FormError
  regionId: FormError
  firstname: FormError
  lastname: FormError
  centralId: FormError
  internalId: FormError
  username: FormError
  email: FormError
  mobile: FormError
}

export type AddUserResponse = {
  data: {
    success: boolean
  }
}

export type UserEditParams = {
  userId: number
  companyId: number
  regionId: number
  firstname: string
  lastname: string
  username: string
  email: string
  mobile: string | null
  resendInvitationMail: boolean
}

export type UserEditErrors = {
  companyId: FormError
  regionId: FormError
  firstname: FormError
  lastname: FormError
  username: FormError
  email: FormError
  mobile: FormError
}

export type UserChangePointsErrors = {
  accountOperation: FormError
  period: FormError
  value: FormError
  name: FormError
}

export type EditUserResponse = {
  data: {
    success: boolean
  }
}

export type UserDetails = {
  userId: number
  userCentralId: string
  userInternalId: string | null
  firstname: string
  lastname: string
  username: string
  email: string | null
  mobile: string | null
  bankAccount: string | null
  userTypeId: number | null
  userTypeName: string | null
  userTypeCode: string | null
  userStateId: number
  userStateName: string
  userStateCode: string
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number
  regionName: string | null
  regionShortName: string | null
  lastLoginDate: string | null
}

export type UserDetailsResponse = {
  data: UserDetails
}

export type UserChangePointsParams = {
  users: { id: number }[]
  name: string
  operationTypeId: number
  operationValue: number
  periodId: number
}

export type UserChangePointsResponse = {
  data: {
    success: boolean
  }
}

export type UserDeactivateResponse = {
  data: {
    success: boolean
  }
}

export type UserHideResponse = {
  data: {
    success: boolean
  }
}

export type UserState = {
  id: number
  code: string
  name: string
  description: string
}

export type UserStateListResponse = {
  data: {
    userStates: UserState[]
  }
}

export type UserFilter = {
  id: number
  centralId: string
  internalId: string | null
  firstname: string
  lastname: string
  email: string
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number | null
  regionName: string | null
  regionShortName: string | null
}

export type UserListFilterResponse = {
  data: {
    users: UserFilter[]
  }
}
