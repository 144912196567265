import React, { useEffect, useState } from 'react'
import { Image, ImageContent, ImageTypeCode } from '../../../store/Image/types'
import { errorHandler } from '../../../helpers/errorHandler'
import ImageService from '../../../services/image.service'
import LoadingSpinner from '../../shared/LoadingSpinner'
import InlineEdit from '../../shared/InlineEdit'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { IconButton } from '@mui/material'
import { ReactComponent as NoLogo } from '../../../assets/images/icons/no_logo.svg'
import { ReactComponent as NoPhoto } from '../../../assets/images/icons/no_photo.svg'
import { MainImageContainer, MainImageContent } from '../styles'

type MainImageProps = {
  selectedImage: Image | null
  setRefresh: (refresh: boolean) => void
  setSelectedImage: React.Dispatch<React.SetStateAction<Image | null>>
  imageType: ImageTypeCode
}

const MainImage: React.FC<MainImageProps> = ({
  selectedImage,
  setRefresh,
  setSelectedImage,
  imageType,
}) => {
  const imagewidth = imageType === ImageTypeCode.ML ? '180px' : '225px'
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [imageName, setImageName] = useState<string>('')
  const [selectedImageContent, setSelectedImageContent] =
    useState<ImageContent | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const imageContentResponse = await ImageService.getImageContent(
          selectedImage!.imageId,
        )

        if (imageContentResponse.data) {
          setSelectedImageContent(imageContentResponse.data)
          setImageName(imageContentResponse.data.name)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (selectedImage) {
      fetchData()
    } else {
      setSelectedImageContent(null)
      setImageName('')
      setLoading(false)
    }
  }, [t, selectedImage])

  const saveImageName = async (name: string) => {
    if (name !== imageName) {
      setImageName(name)
      try {
        const setImageResponse = await ImageService.setImage({
          imageId: selectedImage!.imageId,
          name,
        })

        if (setImageResponse.data.success) {
          setRefresh(true)
          toast.success(t('messages.success.savedSuccessfully'))
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
  }

  return (
    <MainImageContainer imagewidth={imagewidth}>
      <MainImageContent imagewidth={imagewidth}>
        {loading && <LoadingSpinner />}
        {!loading && selectedImageContent && (
          <>
            <IconButton
              aria-label="delete"
              style={{
                position: 'absolute',
                top: 0,
                right: -45,
              }}
              onClick={() => {
                setSelectedImage(null)
                setSelectedImageContent(null)
                setImageName('')
              }}
            >
              <HighlightOffIcon />
            </IconButton>
            <img
              src={`data:${selectedImageContent.imageMimeType};base64,${selectedImageContent.imageContent}`}
              alt=""
              style={{
                width: 'calc(100% - 8px)',
                height: 'calc(100% - 8px)',
                objectFit: 'contain',
              }}
            />
          </>
        )}
        {!loading && !selectedImageContent && (
          <>
            {imageType === ImageTypeCode.ML && <NoLogo />}
            {imageType === ImageTypeCode.CD && <NoPhoto />}
            {imageType === ImageTypeCode.TMAL && <NoPhoto />}
            {imageType === ImageTypeCode.TMARI && <NoPhoto />}
            {imageType === ImageTypeCode.ATI && <NoPhoto />}
            {imageType === ImageTypeCode.AQQI && <NoPhoto />}
          </>
        )}
      </MainImageContent>
      {selectedImageContent && (
        <InlineEdit
          value={imageName}
          setValue={(name) => saveImageName(name)}
          maxLength={50}
          minLength={1}
        />
      )}
    </MainImageContainer>
  )
}

export default MainImage
