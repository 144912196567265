import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { Action } from '../../../../store/TradeAction/types'
import TradeActionsImport from '../../Import/tradeActions/TradeActionsImport'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

type ActionImportDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  action: Action
}

const ActionImportDialog: React.FunctionComponent<ActionImportDialogProps> = ({
  open,
  handleClose,
  action,
}) => {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState<number>(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    if (open) {
      setTabValue(0)
    }
  }, [open])

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          Dodawanie plików i danych do akcji: {action.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ justifyContent: 'space-around' }}
              scrollButtons="auto"
              variant="scrollable"
            >
              {action.actionBlocks.map((block) => (
                <Tab
                  key={`tab-${block.blockId}`}
                  label={block.blockName}
                  sx={{
                    textTransform: 'none',
                  }}
                />
              ))}
            </Tabs>
          </Box>
          {action.actionBlocks.map((block, index) => (
            <TabPanel
              value={tabValue}
              index={index}
              key={`tabpanel-${block.blockId}`}
            >
              <Stack textAlign="left">
                <TradeActionsImport blockId={block.blockId} />
              </Stack>
            </TabPanel>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="flex-end" width="100%">
          <SecondaryButton onClick={() => handleClose(true)}>
            {t('common.close')}
          </SecondaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default ActionImportDialog
