import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { QuizContainer } from './styles'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import AddIcon from '@mui/icons-material/Add'
import theme from '../../../../../theme'
import { grey } from '@mui/material/colors'
import DateTimePicker from '../../../../shared/DateTimePicker'
import { FormError } from '../../../../../store/types'
import {
  AssetDetails,
  CreateOrUpdateQuizParams,
  QuizErrors,
  QuizPoints,
} from '../../../../../store/Edubox/types'
import moment from 'moment'
import { toast } from 'react-toastify'
import { errorHandler } from '../../../../../helpers/errorHandler'
import EduboxService from '../../../../../services/edubox.service'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../../helpers/environment'

type QuizProps = {
  assetId: number | null
  assetDetails: AssetDetails | null
  isLoading?: boolean
  setQuizId: React.Dispatch<React.SetStateAction<number | null>>
}

type Score = {
  id?: number
  placeFrom: number | null
  placeTo: number | null
  awardVp: number | null
  awardXp: number | null
  awardEp: number | null
  errorPlaceFrom: boolean
  errorPlaceFromMessage: string | null
  errorPlaceTo: boolean
  errorPlaceToMessage: string | null
  errorAwardVp: boolean
  errorAwardVpMessage: string | null
  errorAwardXp: boolean
  errorAwardXpMessage: string | null
  errorAwardEp: boolean
  errorAwardEpMessage: string | null
}

const Quiz: FunctionComponent<QuizProps> = ({
  assetId,
  assetDetails,
  isLoading = false,
  setQuizId,
}) => {
  const SCORING_LIMIT_ROWS = 100
  const NAME_MAX_LENGTH = 60
  const { t } = useTranslation()

  const emptyScoring = {
    id: Math.random(),
    placeFrom: null,
    placeTo: null,
    awardVp: 0,
    awardXp: 0,
    awardEp: 0,
    errorPlaceFrom: false,
    errorPlaceFromMessage: null,
    errorPlaceTo: false,
    errorPlaceToMessage: null,
    errorAwardVp: false,
    errorAwardVpMessage: null,
    errorAwardXp: false,
    errorAwardXpMessage: null,
    errorAwardEp: false,
    errorAwardEpMessage: null,
  }

  const [scoring, setScoring] = useState<Score[]>([emptyScoring])
  const [name, setName] = useState(assetDetails?.quiz?.quizName || '')
  const [nameLength, setNameLength] = useState(
    assetDetails?.quiz?.quizName.length || 0,
  )
  const [savingStep, setSavingStep] = useState(false)
  const [stepExpanded, setStepExpanded] = useState(
    assetDetails?.quiz ? true : false,
  )
  const [assetAvailableFrom, setAssetAvailableFrom] = useState<Date | null>(
    null,
  )
  const [assetAvailableTo, setAssetAvailableTo] = useState<Date | null>(null)
  const [validFromDate, setValidFromDate] = useState<Date | null>(null)
  const [validFromTime, setValidFromTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [validToDate, setValidToDate] = useState<Date | null>(null)
  const [validToTime, setValidToTime] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const [minutes, setMinutes] = useState<number | null>(0)
  const [seconds, setSeconds] = useState<number | null>(0)
  const [length, setLength] = useState<number>(
    assetDetails?.quiz?.durationTime || 0,
  )

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultQuizFormErrors: QuizErrors = {
    name: defaultError,
    validFromDate: defaultError,
    validFromTime: defaultError,
    validToDate: defaultError,
    validToTime: defaultError,
    length: defaultError,
  }
  const [quizFormErrors, setQuizFormErrors] = useState<QuizErrors>(
    defaultQuizFormErrors,
  )

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= NAME_MAX_LENGTH) {
      setName(event.target.value)
      setNameLength(event.target.value.length)
    }
  }

  const addNewScoringRow = () => {
    if (scoring.length < SCORING_LIMIT_ROWS) {
      setScoring((prevScoring) => [...prevScoring, emptyScoring])
    }
  }

  const deleteScoringRow = (rowId: number) => {
    scoring.splice(rowId, 1)
    setScoring((prevScoring) => [...scoring])
  }

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) >= 0) {
      setMinutes(parseInt(event.target.value))
      setLength(parseInt(event.target.value) * 60 + (seconds || 0))
    } else {
      setMinutes(null)
      setLength(0)
    }
  }
  const handleSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value.length <= 2 &&
      parseInt(event.target.value) >= 0 &&
      parseInt(event.target.value) < 60
    ) {
      setSeconds(parseInt(event.target.value))
      setLength((minutes || 0) * 60 + parseInt(event.target.value))
    } else {
      setSeconds(null)
      setLength(0)
    }
  }

  const handleScoringPlaceFromChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedScoring = [...scoring]
    updatedScoring[rowId].placeFrom = parseInt(event.target.value)
    setScoring(updatedScoring)
  }
  const handleScoringPlaceToChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedScoring = [...scoring]
    updatedScoring[rowId].placeTo = parseInt(event.target.value)
    setScoring(updatedScoring)
  }
  const handleScoringAwardVpChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedScoring = [...scoring]
    updatedScoring[rowId].awardVp = parseInt(event.target.value)
    setScoring(updatedScoring)
  }
  const handleScoringAwardXpChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedScoring = [...scoring]
    updatedScoring[rowId].awardXp = parseInt(event.target.value)
    setScoring(updatedScoring)
  }
  const handleScoringAwardEpChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedScoring = [...scoring]
    updatedScoring[rowId].awardEp = parseInt(event.target.value)
    setScoring(updatedScoring)
  }

  useEffect(() => {
    if (assetDetails) {
      setAssetAvailableFrom(new Date(assetDetails.assetAvailableFrom))
      setAssetAvailableTo(new Date(assetDetails.assetAvailableTo))

      if (assetDetails.quiz) {
        setValidFromDate(
          new Date(assetDetails.quiz.quizAvailableFrom.slice(0, 10)),
        )
        setValidFromTime(
          new Date(
            new Date().setHours(
              parseInt(assetDetails.quiz.quizAvailableFrom.slice(11, 13)),
              parseInt(assetDetails.quiz.quizAvailableFrom.slice(14, 16)),
            ),
          ),
        )
        setValidToDate(new Date(assetDetails.quiz.quizAvailableTo.slice(0, 10)))
        setValidToTime(
          new Date(
            new Date().setHours(
              parseInt(assetDetails.quiz.quizAvailableTo.slice(11, 13)),
              parseInt(assetDetails.quiz.quizAvailableTo.slice(14, 16)),
            ),
          ),
        )

        setMinutes(Math.floor(assetDetails.quiz.durationTime / 60))
        setSeconds(assetDetails.quiz.durationTime % 60)
        setScoring(
          assetDetails.quiz.points.map((score) => ({
            placeFrom: score.startPlace,
            placeTo: score.endPlace,
            awardVp: score.vpReward,
            awardXp: score.xpReward,
            awardEp: score.epReward,
            errorPlaceFrom: false,
            errorPlaceFromMessage: null,
            errorPlaceTo: false,
            errorPlaceToMessage: null,
            errorAwardVp: false,
            errorAwardVpMessage: null,
            errorAwardXp: false,
            errorAwardXpMessage: null,
            errorAwardEp: false,
            errorAwardEpMessage: null,
          })),
        )
      }
    }
  }, [assetDetails])

  const saveForm = async () => {
    setQuizFormErrors(defaultQuizFormErrors)
    const quizFormErrors = defaultQuizFormErrors
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
    }

    if (name === '') {
      setQuizFormErrors({
        ...quizFormErrors,
        name: {
          error: true,
          message: t('pages.edubox.scoring.form.nameRequired'),
        },
      })
      return
    } else if (length === 0) {
      setQuizFormErrors({
        ...quizFormErrors,
        length: {
          error: true,
          message: t('pages.edubox.scoring.form.lengthRequired'),
        },
      })
      return
    } else if (
      !validFromDate ||
      !validFromTime ||
      validFromDate.toString() === 'Invalid Date' ||
      validFromTime.toString() === 'Invalid Date'
    ) {
      setQuizFormErrors({
        ...quizFormErrors,
        validFromDate: {
          error: true,
          message: t('pages.edubox.scoring.form.dateRequired'),
        },
      })
      return
    } else if (
      !validToDate ||
      !validToTime ||
      validToDate.toString() === 'Invalid Date' ||
      validToTime.toString() === 'Invalid Date'
    ) {
      setQuizFormErrors({
        ...quizFormErrors,
        validToDate: {
          error: true,
          message: t('pages.edubox.scoring.form.dateRequired'),
        },
      })
      return
    } else if (
      assetAvailableFrom &&
      validFromTime.toLocaleTimeString([], timeOptions) <
        assetAvailableFrom?.toLocaleTimeString([], timeOptions)
    ) {
      setQuizFormErrors({
        ...quizFormErrors,
        validFromDate: {
          error: true,
          message: t('pages.edubox.scoring.form.wrongFromTime'),
        },
      })
      return
    } else if (
      assetAvailableTo &&
      validToTime.toLocaleTimeString([], timeOptions) >
        assetAvailableTo?.toLocaleTimeString([], timeOptions)
    ) {
      setQuizFormErrors({
        ...quizFormErrors,
        validToDate: {
          error: true,
          message: t('pages.edubox.scoring.form.wrongToTime'),
        },
      })
      return
    }

    let hasErrors = false
    const updatedScoring = scoring.map((score, index) => {
      score = {
        ...score,
        errorPlaceFrom: false,
        errorPlaceFromMessage: null,
        errorPlaceTo: false,
        errorPlaceToMessage: null,
        errorAwardVp: false,
        errorAwardVpMessage: null,
        errorAwardXp: false,
        errorAwardXpMessage: null,
        errorAwardEp: false,
        errorAwardEpMessage: null,
      }
      if (score.placeFrom === null || isNaN(score.placeFrom)) {
        hasErrors = true
        return {
          ...score,
          errorPlaceFrom: true,
          errorPlaceFromMessage: 'Pole wymagane',
        }
      } else if (score.placeFrom < 1) {
        hasErrors = true
        return {
          ...score,
          errorPlaceFrom: true,
          errorPlaceFromMessage: 'Miejsce musi być większe od 0',
        }
      } else if (index === 0 && score.placeFrom !== 1) {
        hasErrors = true
        return {
          ...score,
          errorPlaceFrom: true,
          errorPlaceFromMessage: 'Miejsce musi równe 1',
        }
      } else if (score.placeTo === null || isNaN(score.placeTo)) {
        hasErrors = true
        return {
          ...score,
          errorPlaceTo: true,
          errorPlaceToMessage: 'Pole wymagane',
        }
      } else if (score.placeFrom > score.placeTo) {
        hasErrors = true
        return {
          ...score,
          errorPlaceFrom: true,
          errorPlaceTo: true,
          errorPlaceFromMessage: 'Nieprawidłowa wartość',
          errorPlaceToMessage: 'Nieprawidłowa wartość',
        }
      } else if (score.awardXp === null || isNaN(score.awardXp)) {
        hasErrors = true
        return {
          ...score,
          errorAwardXp: true,
          errorAwardXpMessage: 'Pole wymagane',
        }
      } else if (score.awardXp < 0) {
        hasErrors = true
        return {
          ...score,
          errorAwardXp: true,
          errorAwardXpMessage: 'Nagroda nie może być mniejsza niż 0',
        }
      } else if (score.awardVp === null || isNaN(score.awardVp)) {
        hasErrors = true
        return {
          ...score,
          errorAwardVp: true,
          errorAwardVpMessage: 'Pole wymagane',
        }
      } else if (score.awardVp < 0) {
        hasErrors = true
        return {
          ...score,
          errorAwardVp: true,
          errorAwardVpMessage: 'Nagroda nie może być mniejsza niż 0',
        }
      } else if (score.awardEp === null || isNaN(score.awardEp)) {
        hasErrors = true
        return {
          ...score,
          errorAwardEp: true,
          errorAwardEpMessage: 'Pole wymagane',
        }
      } else if (score.awardEp < 0) {
        hasErrors = true
        return {
          ...score,
          errorAwardEp: true,
          errorAwardEpMessage: 'Nagroda nie może być mniejsza niż 0',
        }
      } else if (
        scoring.length > 1 &&
        index > 0 &&
        scoring[index - 1].placeTo !== score.placeFrom - 1
      ) {
        hasErrors = true
        return {
          ...score,
          errorPlaceFrom: true,
          errorPlaceFromMessage: 'Nieprawidłowa wartość',
        }
      }
      return score
    })
    setScoring((prevScoring) => [...updatedScoring])

    if (!hasErrors && assetId) {
      const quizAvailableFrom =
        moment(validFromDate).format('YYYY-MM-DD') +
        ' ' +
        moment(validFromTime).format('HH:mm:ss')

      const quizAvailableTo =
        moment(validToDate).format('YYYY-MM-DD') +
        ' ' +
        moment(validToTime).format('HH:mm:ss')

      const points: QuizPoints[] = updatedScoring.map((score) => ({
        startPlace: score.placeFrom!,
        endPlace: score.placeTo!,
        xpReward: score.awardXp!,
        vpReward: score.awardVp!,
        epReward: score.awardEp!,
      }))

      const formParams: CreateOrUpdateQuizParams = {
        assetId,
        quizName: name,
        quizDescription: null,
        quizAvailableFrom,
        quizAvailableTo,
        durationTime: length,
        points,
      }

      try {
        setSavingStep(true)
        const createQuizResponse = await EduboxService.createOrUpdateQuiz(
          formParams,
        )

        if (createQuizResponse.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          setQuizId(createQuizResponse.data.quizId)
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep(false)
      }
    }
  }

  const clearForm = () => {
    setQuizFormErrors(defaultQuizFormErrors)
    setName('')
    setValidFromDate(null)
    setValidFromTime(new Date(new Date().setHours(0, 0, 0, 0)))
    setValidToDate(null)
    setValidToTime(new Date(new Date().setHours(23, 59, 59, 0)))
    setLength(0)
    setMinutes(0)
    setSeconds(0)
    setScoring([emptyScoring])
  }

  if (!stepExpanded) {
    return (
      <QuizContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">2</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography
              variant="subtitle1"
              fontWeight={'bold'}
              component="div"
              gutterBottom
            >
              {t('pages.edubox.scoring.addQuiz')}
            </Typography>

            <IconButton
              aria-label="delete"
              disabled={!assetId || isLoading}
              onClick={() => setStepExpanded(true)}
              size="large"
              className="add-button"
            >
              {isLoading && (
                <CircularProgress style={{ width: 20, height: 20 }} />
              )}
              {!isLoading && <AddIcon />}
            </IconButton>
          </Box>
        </Stack>
      </QuizContainer>
    )
  }

  return (
    <QuizContainer>
      <Stack direction={'row'}>
        <div className="step-line">
          <div className="step-circle">{2}</div>
          <div className="step-arrow"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={7}>
              <div className="step-title">
                {t('pages.edubox.scoring.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.edubox.scoring.subtitle')}
              </div>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 2 }}>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <label>{t('pages.edubox.scoring.form.quizName')}</label>
                <TextField
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  error={quizFormErrors.name.error}
                  helperText={
                    quizFormErrors.name.error && quizFormErrors.name.message
                  }
                />
                <div className="character-amount">
                  {nameLength}/{NAME_MAX_LENGTH}
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Grid item xs={12}>
                <label>{t('pages.edubox.scoring.form.duration')}</label>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={minutes}
                      onChange={handleMinutesChange}
                      type="number"
                      error={quizFormErrors.length.error}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={seconds}
                      onChange={handleSecondsChange}
                      type="number"
                      error={quizFormErrors.length.error}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {quizFormErrors.length.error && (
                  <FormHelperText error style={{ textAlign: 'right' }}>
                    {quizFormErrors.length.message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ maxWidth: 520, marginBottom: 4 }}>
            <Grid item xs={6}>
              <DateTimePicker
                label={t('pages.edubox.scoring.form.validFrom')}
                date={validFromDate}
                time={validFromTime}
                onDateChange={setValidFromDate}
                onTimeChange={setValidFromTime}
                minDate={assetAvailableFrom || undefined}
                maxDate={validToDate || undefined}
                error={quizFormErrors.validFromDate}
                style={{
                  width: 260,
                  marginRight: 8,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label={t('pages.edubox.scoring.form.validTo')}
                date={validToDate}
                time={validToTime}
                onDateChange={setValidToDate}
                onTimeChange={setValidToTime}
                minDate={validFromDate || undefined}
                maxDate={assetAvailableTo || undefined}
                error={quizFormErrors.validToDate}
                style={{
                  width: 260,
                  marginRight: 8,
                }}
              />
            </Grid>
          </Grid>
          <Grid container mb={1}>
            <Grid item xs={12} mb={2}>
              <label>{t('pages.edubox.scoring.addScoring')}</label>
            </Grid>
            {scoring.map((score, i) => (
              <Grid container key={`score-${i}-${score.id}`} mb={2}>
                <Grid item xs={2}>
                  <label>
                    <Typography variant="caption" display="block">
                      {t('pages.edubox.scoring.form.placeFrom')}
                    </Typography>
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={score.placeFrom}
                    error={score.errorPlaceFrom}
                    onChange={(e) => handleScoringPlaceFromChange(e, i)}
                    helperText={
                      score.errorPlaceFrom && score.errorPlaceFromMessage
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <label>
                    <Typography variant="caption" display="block">
                      {t('pages.edubox.scoring.form.placeTo')}
                    </Typography>
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="number"
                    value={score.placeTo}
                    error={score.errorPlaceTo}
                    onChange={(e) => handleScoringPlaceToChange(e, i)}
                    helperText={score.errorPlaceTo && score.errorPlaceToMessage}
                  />
                </Grid>

                {isXpPointsVisible() && (
                  <Grid item xs={2} sx={{ marginLeft: 3 }}>
                    <label>
                      <Typography variant="caption" display="block">
                        {t('pages.edubox.scoring.form.awardXp')}
                      </Typography>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                      value={score.awardXp}
                      error={score.errorAwardXp}
                      onChange={(e) => handleScoringAwardXpChange(e, i)}
                      helperText={
                        score.errorAwardXp && score.errorAwardXpMessage
                      }
                    />
                  </Grid>
                )}
                {isVpPointsVisible() && (
                  <Grid item xs={2}>
                    <label>
                      <Typography variant="caption" display="block">
                        {t('pages.edubox.scoring.form.awardVp')}
                      </Typography>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                      value={score.awardVp}
                      error={score.errorAwardVp}
                      onChange={(e) => handleScoringAwardVpChange(e, i)}
                      helperText={
                        score.errorAwardVp && score.errorAwardVpMessage
                      }
                    />
                  </Grid>
                )}
                {isEpPointsVisible() && (
                  <Grid item xs={2}>
                    <label>
                      <Typography variant="caption" display="block">
                        {t('pages.edubox.scoring.form.awardEp')}
                      </Typography>
                    </label>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      type="number"
                      value={score.awardEp}
                      error={score.errorAwardEp}
                      onChange={(e) => handleScoringAwardEpChange(e, i)}
                      helperText={
                        score.errorAwardEp && score.errorAwardEpMessage
                      }
                    />
                  </Grid>
                )}

                <Grid item xs={1} mt={2}>
                  {scoring.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteScoringRow(i)}
                    >
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          {scoring.length !== SCORING_LIMIT_ROWS && (
            <Stack
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={3}
              onClick={addNewScoringRow}
              style={{ cursor: 'pointer' }}
            >
              <IconButton
                aria-label="delete"
                size="small"
                style={{
                  backgroundColor: theme.colorsPalette.yellow.main,
                  marginRight: 10,
                }}
              >
                <AddIcon />
              </IconButton>
              <Typography variant="subtitle2" fontWeight="bold" component="div">
                {t('pages.edubox.scoring.form.addScoring')}
              </Typography>
            </Stack>
          )}
          <Divider sx={{ marginBottom: 1 }} />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
            className="buttons-container"
          >
            <Stack spacing={2} direction="row">
              <SecondaryButton onClick={clearForm} disabled={savingStep}>
                {t('common.reset')}
              </SecondaryButton>
              <PrimaryButton onClick={saveForm} disabled={savingStep}>
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </QuizContainer>
  )
}

export default Quiz
