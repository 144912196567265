import { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import { Grid } from '@mui/material'
import TradeActionList from './list/TradeActionList'
import TradeActionCreate from './create/TradeActionCreate'
import TradeActionUpdate from './update/TradeActionUpdate'
const TradeActions = () => {
  let { path } = useRouteMatch()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: '0 20px 0 20px',
            }}
          >
            <Switch>
            <Switch>
              <Route exact path={path}>
                <TradeActionList path={path} />
              </Route>
              <Route exact path={`${path}/create`}>
                <TradeActionCreate />
              </Route>
              <Route exact path={`${path}/update/:id`}>
                <TradeActionUpdate />
              </Route>
            </Switch>
            </Switch>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default TradeActions
