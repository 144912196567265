import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import { Option } from '../../../../store/types'
import { FormControl } from '@mui/material'
import { MultiSelect } from 'react-multi-select-component'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'

interface CompaniesToolbarProps {
  clearSearch: () => void
  submitSearch: (value: string) => void
  onChange: (event: { target: { value: string } }) => void
  setSelectedRegions: (regions: Option[]) => void
  selectedRegions: Option[]
  regions: Option[]
  value: string
}

export default function CompaniesToolbar(props: CompaniesToolbarProps) {
  const { t } = useTranslation()

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }
  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <FormControl
        size="small"
        sx={{ minWidth: 180, marginRight: 1, zIndex: 6 }}
      >
        <label>{t('pages.company.toolbar.filters.macroregions')}</label>
        <MultiSelect
          options={props.regions}
          value={props.selectedRegions}
          onChange={props.setSelectedRegions}
          labelledBy={t('multiSelect.labelledBy')}
          overrideStrings={{
            allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
            clearSearch: t('multiSelect.clearSearch'),
            noOptions: t('multiSelect.noOptions'),
            search: t('multiSelect.search'),
            selectAll: t('multiSelect.selectAll'),
            selectAllFiltered: t('multiSelect.selectAllFiltered'),
            selectSomeItems: t('multiSelect.selectSomeItems'),
          }}
          valueRenderer={customValueRenderer}
        />
      </FormControl>
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          size="small"
          value={props.value}
          onChange={props.onChange}
          placeholder={t('common.search')}
          onKeyDown={onSearchKeyDown}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 100 }}
        />
        <PrimaryButton
          onClick={() => props.submitSearch(props.value)}
          style={{
            padding: '7px 5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          }}
        >
          {t('common.search')}
        </PrimaryButton>
      </div>
    </div>
  )
}
