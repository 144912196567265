import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import ImageService from '../../../services/image.service'
import LoadingSpinner from '../../shared/LoadingSpinner'
import { Image, ImageTypeCode } from '../../../store/Image/types'
import ImageThumbnail from './ImageThumbnail'
import { IconButton, Pagination, Stack, TextField } from '@mui/material'
import PrimaryButton from '../../../styles/Buttons/PrimaryButton'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { AllImagesTitle, AllImagesContainer } from '../styles'

type AllImagesProps = {
  setSelectedImage: React.Dispatch<React.SetStateAction<Image | null>>
  selectedImage: Image | null
  refresh: boolean
  imageType: ImageTypeCode
}

const AllImages: React.FC<AllImagesProps> = ({
  setSelectedImage,
  selectedImage,
  refresh,
  imageType,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [images, setImages] = useState<Image[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState<string>('')

  const per = 24

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setPage(page)
    fetchImages(imageType, per, page, '')
  }

  const submitSearch = (text: string) => {
    setPage(1)
    fetchImages(imageType, per, 1, text)
  }

  const clearSearch = () => {
    setSearchText('')
    setPage(1)
    fetchImages(imageType, per, 1, '')
  }

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      submitSearch(searchText)
    }
  }

  const onSearchChange = (event: { target: { value: string } }) => {
    setSearchText(event.target.value)
  }

  const fetchImages = useCallback(
    async (
      imageTypeCode: string,
      per: number,
      page: number,
      search: string,
    ) => {
      try {
        const imageListResponse = await ImageService.getImageList({
          imageTypeCode,
          per,
          page,
          search,
        })

        if (imageListResponse.data.images) {
          setImages(imageListResponse.data.images)
          setTotalCount(imageListResponse.data.totalCount)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    },
    [t],
  )

  useEffect(() => {
    fetchImages(imageType, per, page, searchText)
    // eslint-disable-next-line
  }, [refresh, fetchImages, page, imageType])

  return (
    <>
      <AllImagesTitle>{t('imageLibrary.browse')}</AllImagesTitle>
      <AllImagesContainer>
        {loading && <LoadingSpinner />}
        {!loading && (
          <div style={{ marginBottom: 8 }}>
            <TextField
              variant="outlined"
              size="small"
              value={searchText}
              onChange={onSearchChange}
              placeholder={t('imageLibrary.searchPlaceholder')}
              onKeyDown={onSearchKeyDown}
              InputProps={{
                style: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                } as React.CSSProperties,
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: searchText ? 'visible' : 'hidden' }}
                    onClick={clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
              inputProps={{ maxLength: 100 }}
            />
            <PrimaryButton
              onClick={() => submitSearch(searchText)}
              style={{
                padding: '7px 5px',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                height: '40px',
              }}
            >
              {t('common.search')}
            </PrimaryButton>
          </div>
        )}
        {!loading && images.length === 0 && (
          <div>{t('imageLibrary.noPhotos')}</div>
        )}
        {!loading && images.length > 0 && (
          <div>
            <Stack display="flex" flexDirection="row" flexWrap="wrap">
              {images.map((image) => (
                <ImageThumbnail
                  key={image.imageId}
                  image={image}
                  selected={selectedImage?.imageId === image.imageId}
                  setSelectedImage={setSelectedImage}
                />
              ))}
            </Stack>
            <Stack
              spacing={2}
              mt={1}
              justifyContent="center"
              alignItems="center"
            >
              <Pagination
                count={Math.ceil(totalCount / per)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </div>
        )}
      </AllImagesContainer>
    </>
  )
}

export default AllImages
