import React from 'react'
import { Stack, IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { UserPlansPreview } from '../../../store/Game/types'

type PlanSwitcherProps = {
  planId: number
  plans: UserPlansPreview[]
  setCurrentPlanId: React.Dispatch<React.SetStateAction<number>>
}

const PlanSwitcher: React.FC<PlanSwitcherProps> = ({
  planId,
  plans,
  setCurrentPlanId,
}) => {
  const currentPlanArrayIndex = plans.findIndex(
    (plan) => plan.planId === planId,
  )
  const nextPlan = currentPlanArrayIndex + 1
  const prevPlan = currentPlanArrayIndex - 1

  return (
    <Stack display="flex" flexDirection="row" alignItems="center">
      <IconButton
        disabled={plans[prevPlan] === undefined}
        style={{
          opacity: plans[prevPlan] ? 1 : 0.3,
        }}
        onClick={() => {
          if (plans[prevPlan]) {
            setCurrentPlanId(plans[prevPlan].planId)
          }
        }}
      >
        <ChevronLeftIcon htmlColor="#FFD748" />
      </IconButton>
      <div style={{ textAlign: 'center' }}>
        {plans.find((plan) => plan.planId === planId)?.name}
      </div>
      <IconButton
        disabled={plans[nextPlan] === undefined}
        style={{
          opacity: plans[nextPlan] ? 1 : 0.3,
        }}
        onClick={() => {
          if (plans[nextPlan]) {
            setCurrentPlanId(plans[nextPlan].planId)
          }
        }}
      >
        <ChevronRightIcon htmlColor="#FFD748" />
      </IconButton>
    </Stack>
  )
}

export default PlanSwitcher
