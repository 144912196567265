import axios from 'axios'
import { OrdersResponse, OrderWeekResponse } from '../store/Order/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getOrders = async (
  companies: { id: number }[],
  users: { id: number }[],
  weeks: number[],
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<OrdersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/order/list',
    {
      companies,
      users,
      weeks,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getOrderWeek = async (): Promise<OrderWeekResponse> => {
  return await axios.get(API_URL + '/api/admin/store/order/week', {
    headers: authHeader(),
  })
}

const exportedObject = {
  getOrders,
  getOrderWeek,
}

export default exportedObject
