import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import {
  SelectChangeEvent,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Alert,
  IconButton,
} from '@mui/material'
import { errorHandler } from '../../../../helpers/errorHandler'
import {
  wrapSvgText,
  getSVGString,
  svgString2Image,
} from '../../../../helpers/utils'
import { Period } from '../../../../store/Period/types'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import PeriodService from '../../../../services/period.service'
import HomeService from '../../../../services/home.service'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'

import './PeriodRegionPlanChart.css'

type BranchData = {
  companyName: string
  planName: string
  value: number
  total: number
}

type LegendData = {
  name: string
  color: string
  visible: boolean
}

type PeriodRegionPlanChartProps = {}

const PeriodRegionPlanChart: React.FunctionComponent<
  PeriodRegionPlanChartProps
> = () => {
  const { t } = useTranslation()
  const chartEl = useRef<SVGSVGElement>(null)
  const [svgNode, setSvgNode] = useState<SVGSVGElement | null>(null)
  const [period, setPeriod] = useState('')
  const [periods, setPeriods] = useState<Period[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [filteredColors, setFilteredColors] = useState<string[]>([])
  const [categories, setCategories] = useState<string[]>([])
  const [legend, setLegend] = useState<LegendData[]>([])
  const [filteredReportData, setFilteredReportData] = useState<BranchData[]>([])
  const [reportData, setReportData] = useState<BranchData[]>([])

  const width = 1700
  const height = 300

  const handlePeriodChange = async (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string)

    const homePlanResultChartResponse =
      await HomeService.getHomePlanResultChart(parseInt(event.target.value))
    if (homePlanResultChartResponse.data.charts) {
      const chartData = homePlanResultChartResponse.data.charts.map((d) => {
        return {
          ...d,
          total: 0,
        }
      })

      const reducedData = chartData.map((ch) => {
        const total = chartData.reduce(
          (sum, cur) =>
            ch.companyName === cur.companyName ? sum + cur.value : sum,
          0,
        )

        return {
          ...ch,
          total,
        }
      })

      setReportData(reducedData)
      setFilteredReportData(reducedData)
      setFilteredColors(reducedData.map((rd) => rd.colorValue))
      const chartCategories: string[] = []
      chartData.forEach((el) => {
        if (!chartCategories.includes(el.planName)) {
          chartCategories.push(el.planName)
        }
      })

      setCategories(chartCategories)

      const legendData: LegendData[] = []
      chartData.forEach((el) => {
        if (!legendData.some((e) => e.name === el.planName)) {
          legendData.push({
            name: el.planName,
            color: el.colorValue,
            visible: true,
          })
        }
      })

      setLegend(legendData)
    }
  }

  const toggleVisibility = (name: string) => {
    let updatedLegend = legend.map((el) => {
      if (el.name === name) {
        el.visible = !el.visible
      }
      return el
    })
    setLegend(updatedLegend)

    const newCategoryList = legend.filter((el) => el.visible)
    const filteredPlans = newCategoryList.map((el) => el.name)
    setCategories(filteredPlans)

    const filteredData = reportData.filter((data) =>
      filteredPlans.includes(data.planName),
    )
    const reducedData = filteredData.map((ch) => {
      const total = filteredData.reduce(
        (sum, cur) =>
          ch.companyName === cur.companyName ? sum + cur.value : sum,
        0,
      )

      return {
        ...ch,
        total,
      }
    })

    setFilteredReportData(reducedData)

    const newColorList: string[] = []
    updatedLegend.forEach((plan, index) => {
      if (plan.visible) {
        newColorList.push(plan.color)
      }
    })

    setFilteredColors(newColorList)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const periodListResponse = await PeriodService.getPeriodList()
        let activePeriod = null

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          activePeriod = periodListResponse.data.periods.find(
            (period) => period.isActive,
          )
          setPeriod(activePeriod ? activePeriod.id.toString() : '')
        }

        if (activePeriod) {
          const homePlanResultChartResponse =
            await HomeService.getHomePlanResultChart(activePeriod.id)
          if (homePlanResultChartResponse.data.charts) {
            const chartData = homePlanResultChartResponse.data.charts.map(
              (d) => {
                return {
                  ...d,
                  total: 0,
                }
              },
            )

            const reducedData = chartData.map((ch) => {
              const total = chartData.reduce(
                (sum, cur) =>
                  ch.companyName === cur.companyName ? sum + cur.value : sum,
                0,
              )

              return {
                ...ch,
                total,
              }
            })

            setReportData(reducedData)
            setFilteredReportData(reducedData)
            setFilteredColors(reducedData.map((rd) => rd.colorValue))
            const chartCategories: string[] = []
            chartData.forEach((el) => {
              if (!chartCategories.includes(el.planName)) {
                chartCategories.push(el.planName)
              }
            })

            setCategories(chartCategories)

            const legendData: LegendData[] = []
            chartData.forEach((el) => {
              if (!legendData.some((e) => e.name === el.planName)) {
                legendData.push({
                  name: el.planName,
                  color: el.colorValue,
                  visible: true,
                })
              }
            })

            setLegend(legendData)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t])

  useEffect(() => {
    if (filteredReportData.length > 0) {
      setTimeout(() => {
        const margin = { top: 50, right: 20, bottom: 40, left: 20 }

        // let sortIndex = filteredReportData.map(function(d) {
        //   return d.region
        // })
        //sort data
        filteredReportData.sort(
          (a, b) => b.total - a.total,
          // return sortIndex.indexOf(a.region) - sortIndex.indexOf(b.region)
        )

        const x = (d: BranchData) => d.companyName // given d in data, returns the (ordinal) x-value
        const y = (d: BranchData) => d.value // given d in data, returns the (quantitative) y-value
        const z = (d: BranchData) => d.planName // given d in data, returns the (categorical) z-value

        // Compute values.
        const X = d3.map(filteredReportData, x)
        const Y = d3.map(filteredReportData, y)
        const Z = d3.map(filteredReportData, z)

        // Compute default domains, and unique the x- and z-domains.
        const xDomain = new d3.InternSet(X)
        const zDomain = new d3.InternSet(categories)
        const yDomain = [0, d3.max(Y) ?? 0]

        const xRange = [margin.left, width - margin.right]
        const yRange = [height - margin.bottom, margin.top]
        const xPadding = 0.3
        const zPadding = 0.1

        const yType = d3.scaleLinear

        const xScale = d3.scaleBand(xDomain, xRange).paddingInner(xPadding)
        const xzScale = d3
          .scaleBand(zDomain, [0, xScale.bandwidth()])
          .padding(zPadding)
        const yScale = yType(yDomain, yRange)
        const zScale = d3.scaleOrdinal(zDomain, filteredColors)
        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0)
        // const yAxis = d3.axisLeft(yScale).ticks(height / 60)

        // Omit any data not present in both the x- and z-domain.
        const I = d3
          .range(X.length)
          .filter((i) => xDomain.has(X[i]) && zDomain.has(Z[i]))

        // Compute titles.
        const formatValue = yScale.tickFormat(100)
        const title = (i: number) => `${X[i]}\n${Z[i]}\n${formatValue(Y[i])}`

        const svg = d3.select(chartEl.current)

        svg
          .attr('width', width)
          .attr('height', height)
          .attr('viewBox', `0 0 ${width} ${height}`)
          .attr('style', 'height: auto; height: intrinsic;')
        // .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')

        svg.selectAll('*').remove()

        svg
          .append('g')
          .attr('transform', `translate(${margin.left},0)`)
          // .call(yAxis)
          .call((g) => g.select('.domain').remove())
        // .call((g) =>
        //   g
        //     .selectAll('.tick line')
        //     .clone()
        //     .attr('x2', width - margin.left - margin.right)
        //     .attr('stroke-opacity', 0.1),
        // )
        // .call((g) =>
        //   g
        //     .append('text')
        //     .attr('x', -margin.left)
        //     .attr('y', 10)
        //     .attr('fill', 'currentColor')
        //     .attr('text-anchor', 'start')
        //     .text(yLabel),
        // )

        const bar = svg
          .append('g')
          .selectAll('rect')
          .data(I)
          .join('rect')
          .attr('x', (i) => (xScale(X[i]) ?? 0) + (xzScale(Z[i]) ?? 0))
          .attr('y', (i) => yScale(Y[i]))
          .attr('width', xzScale.bandwidth())
          // .transition()
          // .ease(d3.easeLinear)
          // .duration(100)
          // .delay(function (d, i) {
          //   return i * 20;
          // })
          .attr('height', (i) => yScale(0) - yScale(Y[i]))
          .attr('fill', (i) => zScale(Z[i]))
          .attr('rx', 2)
          .attr('ry', 2)

        if (title) bar.append('title').text(title)

        svg
          .append('g')
          .attr('class', 'bar-label-group')
          .selectAll('.bar-label')
          .data(I)
          .join('text')
          // .attr('x', (d, i) => (xScale(X[i]) ?? 0) + (xzScale(Z[i]) ?? 0))
          // .attr('y', (d, i) => yScale(Y[i]))
          // .attr('transform', 'rotate(90)')
          .attr('width', xScale.bandwidth())
          .attr(
            'transform',
            (d, i) =>
              `translate(${
                (xScale(X[i]) ?? 0) +
                (xzScale(Z[i]) ?? 0) +
                xzScale.bandwidth() / 2
              },${yScale(Y[i]) - 5}) rotate(-90)`,
          )
          .attr('dominant-baseline', 'middle')
          .text((i) => `${Y[i]}%`)

        svg
          .append('g')
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .call(xAxis)
          .selectAll('.tick text')
          .call((g) => wrapSvgText(g, xScale.bandwidth(), d3))

        svg.selectAll('.tick line').attr('y2', '0')

        setSvgNode(svg.node())
      }, 0)
    }
  }, [categories, filteredReportData, filteredColors])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div
          style={{
            background: '#f1f1f1',
            borderRadius: '3px',
            margin: '0 20px',
            padding: '15px 20px',
            height: '100%',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mb={2}
          >
            <FormControl
              className="margin-top-form"
              size="small"
              style={{ width: 300 }}
            >
              <label>
                {t('pages.home.periodRegionPlanChart.selectPeriod')}
              </label>
              <Select
                id="period-select"
                value={period}
                onChange={handlePeriodChange}
                style={{ background: '#fff' }}
              >
                {periods.map((period) => (
                  <MenuItem value={period.id} key={period.id}>
                    {period.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          {filteredReportData.length === 0 && (
            <Alert severity="info" variant="filled">
              {t('pages.home.periodRegionPlanChart.noDataToDisplay')}
            </Alert>
          )}
          {filteredReportData.length > 0 && (
            <div style={{ overflowY: 'auto' }}>
              <svg ref={chartEl} />
            </div>
          )}
          <Stack
            spacing={1}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <Stack flexDirection="row">
              {legend.length > 0 &&
                legend.map((el) => (
                  <Stack
                    key={el.name}
                    flexDirection="row"
                    alignItems="center"
                    mr={2}
                  >
                    <IconButton
                      onClick={() => toggleVisibility(el.name)}
                      size="small"
                    >
                      {el.visible ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: `${el.color}`,
                        borderRadius: 2,
                        marginRight: 5,
                      }}
                    />
                    {el.name}
                  </Stack>
                ))}
            </Stack>
            {filteredReportData.length > 0 && (
              <SecondaryButton
                onClick={() => {
                  var svgString = getSVGString(svgNode)
                  svgString2Image(svgString, 2 * width, 2 * height, 'png', save) // passes Blob and filesize String to the callback

                  function save(dataBlob: any, filesize: any) {
                    saveAs(
                      dataBlob,
                      `${t(
                        'pages.home.periodRegionPlanChart.exportFilename',
                      )}.png`,
                    ) // FileSaver.js function
                  }
                }}
              >
                {t('pages.home.periodRegionPlanChart.exportChartToPNG')}
              </SecondaryButton>
            )}
          </Stack>
        </div>
      )}
    </>
  )
}

export default PeriodRegionPlanChart
