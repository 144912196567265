import React, { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import './RealizationChart.css'
import { errorHandler } from '../../../helpers/errorHandler'
import LoadingSpinner from '../../shared/LoadingSpinner'

type RealizationChartProps = {
  currentLevel: number
  currentLevelPercentValue: number
}

const RealizationChart: React.FunctionComponent<RealizationChartProps> = ({
  currentLevel,
  currentLevelPercentValue,
}) => {
  const { t } = useTranslation()
  const chartEl = useRef<SVGSVGElement>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTimeout(() => {
          var backgroundArc = d3
            .arc()
            .innerRadius(35)
            .outerRadius(50)
            .startAngle(0)
            .endAngle(Math.PI * 2)

          var mainArc = d3
            .arc()
            .innerRadius(35)
            .outerRadius(50)
            .cornerRadius(20)
            .startAngle(0)
            .endAngle(function (d: any) {
              return (d / 100) * Math.PI * 2
            })

          var data = [currentLevelPercentValue] // percents.

          const svg = d3.select(chartEl.current)

          svg
            .attr('width', 100)
            .attr('height', 100)
            .attr('viewBox', `0 0 100 100`)
            .attr('style', 'height: auto; height: intrinsic;')

          svg.selectAll('*').remove()

          var charts = svg
            .selectAll('g')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', function (d, i) {
              return 'translate(' + (i * 100 + 50) + ', 50)'
            })

          charts
            .append('path')
            .attr('d', (d: any) => backgroundArc(d))
            .attr('fill', '#F5F2E3')

          charts
            .append('path')
            .attr('d', (d: any) => mainArc(d))
            .attr('fill', '#00813D')

          let legend = svg
            .selectAll('.legend-main')
            .data(data)
            .enter()
            .append('g')
            .attr('transform', function (d, i) {
              return 'translate(' + (i * 100 + 50) + ',' + 50 + ')'
            })
          legend
            .append('text')
            // .attr('x', 100)
            .attr('y', 5)
            .attr('text-anchor', 'middle')
            // .attr('dominant-baseline', 'central')
            .attr('class', 'legend-main')
            .text(function (d) {
              return currentLevel
            })
          legend
            .append('text')
            // .attr('x', 100)
            .attr('y', 15)
            .attr('text-anchor', 'middle')
            .attr('class', 'legend-sub')
            // .attr('dominant-baseline', 'central')
            .text(
              t('pages.game.phone.screens.dashboardScreen.level').toString(),
            )
        }, 0)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, currentLevelPercentValue, currentLevel])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && <svg ref={chartEl} />}
    </>
  )
}

export default RealizationChart
