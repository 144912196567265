import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { ReactComponent as PpIcon } from '../../../../assets/images/icons/pp_icon.svg'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { thousandsSeparator } from '../../../../helpers/utils'
import { styled } from '@mui/material/styles'
import GameService from '../../../../services/game.service'
import { toast } from 'react-toastify'
import { Player } from '../../../../store/Game/types'

type AddPointsDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  xpAmount: number
  player: Player
}

const StyledSlider = styled(Slider)({
  color: '#D4D4D',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#4D4D4D',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#4D4D4D',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

const AddPointsDialog: React.FunctionComponent<AddPointsDialogProps> = ({
  open,
  handleClose,
  xpAmount,
  player,
}) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState<boolean>(false)
  const [value, setValue] = React.useState<
    number | string | Array<number | string>
  >(1)

  const availablePoints = xpAmount

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleBlur = () => {
    if (value < 1) {
      setValue(1)
    } else if (value > xpAmount) {
      setValue(xpAmount)
    }
  }

  useEffect(() => {
    if (open) {
      setValue(1)
    }
  }, [open])

  const addPoints = async () => {
    try {
      setSaving(true)
      const addPointsResponse = await GameService.addUserGamePoints(
        player.id,
        parseInt(value.toString()),
        null,
        null,
      )
      if (addPointsResponse.data.success) {
        toast.success(t('pages.game.addPointsDialog.pointsAdded'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
      handleClose(true)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogContent>
        <DialogContentText color="#000" textAlign="center">
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            my="20px"
          >
            {t('pages.game.addPointsDialog.youHave')}:
            <Typography
              variant="subtitle1"
              component="span"
              fontWeight={800}
              mx={1}
            >
              {thousandsSeparator(availablePoints)}
            </Typography>
            <PpIcon />
          </Stack>
          <Typography variant="h6" component="span" fontWeight={800} mx={1}>
            {t('pages.game.addPointsDialog.setPointsFor')}
            <br />
            {player.firstname} {player.lastname}
          </Typography>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            mt="20px"
            mb="10px"
          >
            <TextField
              value={value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              variant="outlined"
              inputProps={{
                step: 1,
                min: 1,
                max: availablePoints,
                type: 'number',
                'aria-labelledby': 'input-slider',
                '::after': {
                  border: 0,
                },
              }}
            />
            <PpIcon style={{ marginLeft: '10px' }} />
          </Stack>
          <div style={{ width: '60%', margin: '0 auto' }}>
            <StyledSlider
              value={typeof value === 'number' ? value : 1}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              min={1}
              max={availablePoints}
            />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          border: '1px solid #DCDCDC',
        }}
      >
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton onClick={() => handleClose(false)} disabled={saving}>
            {t('common.close')}
          </SecondaryButton>
          <PrimaryButton onClick={addPoints} disabled={saving}>
            {t('pages.game.addPointsDialog.addPoints')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default AddPointsDialog
