import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { ActionContainer } from './styles'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import AddIcon from '@mui/icons-material/Add'
import theme from '../../../../../theme'
import {
  ActionBlockParameter,
  CreateOrUpdateBlockParams,
  ParameterType,
} from '../../../../../store/TradeAction/types'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import TradeActionService from '../../../../../services/tradeAction.service'

type CustomBlockProps = {
  actionId: number
  block: CreateOrUpdateBlockParams
  order: number
  handleBlockSave: (block: CreateOrUpdateBlockParams) => void
  handleBlockRemove: () => void
  parameterTypes: ParameterType[]
}

const CustomBlock: FunctionComponent<CustomBlockProps> = ({
  actionId,
  block,
  order,
  handleBlockSave,
  handleBlockRemove,
  parameterTypes,
}) => {
  const NUM_OF_PARAMS = 15
  const { t } = useTranslation()
  const [visibilitySwitch, setVisibilitySwitch] = useState(true)
  const [userIdSwitch, setUserIdSwitch] = useState(block.userFilter)
  const [name, setName] = useState(block.blockName || '')
  const [nameLength, setNameLength] = useState(block.blockName.length || 0)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isRemovingBlock, setIsRemovingBlock] = useState<boolean>(false)
  const [isRemovingParameter, setIsRemovingParameter] = useState<boolean>(false)

  const [nameValidation, setNameValidation] = useState(false)

  const emptyParameter = {
    parameterId: null,
    name: '',
    typeCode: 'TXT',
    error: false,
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setNameLength(event.target.value.length)
  }

  const handleVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVisibilitySwitch(event.target.checked)
  }

  const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserIdSwitch(event.target.checked)
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const [parameters, setParameters] = useState<ActionBlockParameter[]>(
    block.parameters.length > 0
      ? block.parameters
      : [
          {
            parameterId: null,
            name: '',
            typeCode: 'TXT',
            error: false,
          },
        ],
  )

  const addNewParameterRow = () => {
    if (parameters.length < NUM_OF_PARAMS) {
      setParameters((prevParameters) => [...prevParameters, emptyParameter])
    }
  }

  const deleteParameterRow = async (rowId: number) => {
    try {
      setIsRemovingParameter(true)
      const parameterId = parameters[rowId].parameterId
      if (parameterId && block.blockId) {
        const removeBlockResponse = await TradeActionService.removeParameter(
          block.blockId,
          parameterId,
        )

        if (removeBlockResponse.data.success) {
          toast.success(t('messages.success.parameterRemoved'))
        }
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsRemovingParameter(false)
      parameters.splice(rowId, 1)
      setParameters((prevParameters) => [...parameters])
    }
  }

  const handleParameterNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
  ) => {
    const updatedParameters = [...parameters]
    updatedParameters[rowId].name = event.target.value
    setParameters(updatedParameters)
  }

  const handleParameterTypeChange = (
    event: SelectChangeEvent<string>,
    rowId: number,
  ) => {
    const updatedParameters = [...parameters]
    updatedParameters[rowId].typeCode = event.target.value
    setParameters(updatedParameters)
  }

  const saveForm = async () => {
    setNameValidation(false)
    if (name === '') {
      setNameValidation(true)
      return
    }
    const updatedParameters = parameters.map((parameter) => {
      if (parameter.name.trim() === '') {
        return {
          ...parameter,
          error: true,
        }
      }
      return {
        ...parameter,
        error: false,
      }
    })
    setParameters((prevParameters) => [...updatedParameters])

    if (updatedParameters.find((param) => param.error)) {
      return
    }

    const formParams: CreateOrUpdateBlockParams = {
      actionId,
      blockId: block.blockId,
      blockName: name,
      isVisible: visibilitySwitch,
      userFilter: userIdSwitch,
      parameters: updatedParameters.map((param) => ({
        parameterId: param.parameterId,
        name: param.name,
        typeCode: param.typeCode,
      })),
    }

    try {
      setIsSaving(true)
      const createOrUpdateBlockResponse =
        await TradeActionService.createOrUpdateBlock(formParams)

      if (createOrUpdateBlockResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        const updatedParameters = createOrUpdateBlockResponse.data.parameters
        formParams.blockId = createOrUpdateBlockResponse.data.blockId
        formParams.parameters = updatedParameters
        setParameters(updatedParameters)
        handleBlockSave(formParams)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  const clearForm = () => {
    setNameValidation(false)
    setName('')
    setParameters([
      {
        parameterId: null,
        name: '',
        typeCode: 'TXT',
        error: false,
      },
    ])
  }

  const removeBlock = async () => {
    try {
      setIsRemovingBlock(true)
      if (block.blockId) {
        const removeBlockResponse = await TradeActionService.removeBlock(
          block.blockId,
          actionId,
        )

        if (removeBlockResponse.data.success) {
          toast.success(t('messages.success.blockRemoved'))
        }
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsRemovingBlock(false)
      handleBlockRemove()
    }
  }

  return (
    <ActionContainer>
      <Stack direction={'row'}>
        <div className="step-line">
          <div className="step-circle">{order + 2}</div>
          <div className="step-arrow"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={7}>
              <div className="step-title">
                {t('pages.tradeActions.customBlock.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.tradeActions.customBlock.subtitle')}
              </div>
            </Grid>
            <Grid item xs={5} className="switch-button">
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    value={visibilitySwitch}
                    onChange={handleVisibilityChange}
                    color="success"
                    checked={visibilitySwitch}
                  />
                }
                label={
                  <div className="visibility-label-container">
                    <div className="visibility">
                      {visibilitySwitch
                        ? t('pages.tradeActions.switchVisible')
                        : t('pages.tradeActions.switchNotVisible')}
                    </div>
                    <div className="visibility-label">
                      {t('pages.tradeActions.switchBlockLabel')}
                    </div>
                  </div>
                }
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ maxWidth: 180, marginBottom: 2 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label>
                  {t('pages.tradeActions.customBlock.form.blockTitle')}
                </label>
                <TextField
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  inputProps={{ maxLength: 15 }}
                  error={nameValidation}
                  helperText={
                    nameValidation &&
                    t('pages.tradeActions.customBlock.form.blockTitleRequired')
                  }
                />
                <div className="character-amount">{nameLength}/15</div>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mb={1}>
            <FormControlLabel
              value="start"
              control={
                <Switch
                  value={userIdSwitch}
                  onChange={handleUserIdChange}
                  color="success"
                  checked={userIdSwitch}
                />
              }
              label={
                userIdSwitch
                  ? t('pages.tradeActions.userIdSwitchOn')
                  : t('pages.tradeActions.userIdSwitchOff')
              }
              labelPlacement="end"
              sx={{ marginBottom: 2 }}
            />
            {parameters.map((parameter, i) => (
              <Grid
                container
                spacing={2}
                key={`parameter-${i}-${block.blockId}`}
                mb={2}
              >
                <Grid item xs={8}>
                  <label>
                    <Typography variant="caption" display="block">
                      {t('pages.tradeActions.customBlock.form.parameterName')}{' '}
                      {i + 1}
                    </Typography>
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={parameter.name}
                    error={parameter.error}
                    onChange={(e) => handleParameterNameChange(e, i)}
                    helperText={
                      parameter.error &&
                      t(
                        'pages.tradeActions.customBlock.form.parameterNameRequired',
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <label>
                    <Typography variant="caption" display="block">
                      {t('pages.tradeActions.customBlock.form.parameterType')}
                    </Typography>
                  </label>
                  <FormControl fullWidth error={parameter.error}>
                    <Select
                      id="type-select"
                      value={parameter.typeCode}
                      onChange={(e) => handleParameterTypeChange(e, i)}
                      size="small"
                      fullWidth
                      style={{ backgroundColor: '#fff' }}
                    >
                      {parameterTypes.map((parameterType) => (
                        <MenuItem
                          value={parameterType.code}
                          key={`parameter-${i}-${block.blockId}-${parameterType.code}`}
                        >
                          {parameterType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1} mt={2}>
                  {parameters.length > 1 && (
                    <IconButton
                      disabled={isRemovingParameter}
                      aria-label="delete"
                      onClick={() => deleteParameterRow(i)}
                    >
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
          {parameters.length !== NUM_OF_PARAMS && (
            <Stack
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={3}
              onClick={addNewParameterRow}
              style={{ cursor: 'pointer' }}
            >
              <IconButton
                aria-label="delete"
                size="small"
                style={{
                  backgroundColor: theme.colorsPalette.yellow.main,
                  marginRight: 10,
                }}
              >
                <AddIcon />
              </IconButton>
              <Typography variant="subtitle2" fontWeight="bold" component="div">
                {t('pages.tradeActions.customBlock.form.addNextParameter')}
              </Typography>
            </Stack>
          )}
          <Divider sx={{ marginBottom: 1 }} />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            width="100%"
            className="buttons-container"
          >
            <SecondaryButton
              onClick={handleClickOpenDialog}
              disabled={isSaving || isRemovingBlock}
            >
              {t('pages.tradeActions.customBlock.deleteBlock')}
            </SecondaryButton>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogContent>
                <DialogTitle>
                  {t('pages.tradeActions.customBlock.areYouSureToDeleteBlock')}
                </DialogTitle>
              </DialogContent>
              <DialogActions>
                <SecondaryButton onClick={handleCloseDialog}>
                  {t('common.cancel')}
                </SecondaryButton>
                <PrimaryButton onClick={removeBlock} autoFocus>
                  {t('common.yes')}
                </PrimaryButton>
              </DialogActions>
            </Dialog>

            <Stack spacing={2} direction="row">
              <SecondaryButton
                onClick={clearForm}
                disabled={isSaving || isRemovingBlock}
              >
                {t('common.clear')}
              </SecondaryButton>
              <PrimaryButton
                onClick={saveForm}
                disabled={isSaving || isRemovingBlock}
              >
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </ActionContainer>
  )
}

export default CustomBlock
