import React, { useEffect, useState } from 'react'
import { Accept } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { ImageTypeCode } from '../../store/Image/types'
import { errorHandler } from '../../helpers/errorHandler'
import ConfigService from '../../services/config.service'
import {
  ConfigurationParameter,
  ConfigurationParameterName,
} from '../../store/Config/types'
import LoadingSpinner from '../shared/LoadingSpinner'
import FileUploader from './partials/FileUploader'

type ImageLibraryProps = {
  imageType: ImageTypeCode
  selectedImageId: number | null
  setSelectedImageId: (id: number | null) => void
  showTitle?: boolean
}

const ImageLibrary: React.FC<ImageLibraryProps> = ({
  imageType,
  selectedImageId,
  setSelectedImageId,
  showTitle = true,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [configurationParameters, setConfigurationParameters] =
    useState<ConfigurationParameter | null>(null)

  const [accept, setAccept] = useState<Accept>({})
  const [maxWidth, setMaxWidth] = useState<number | null>(null)
  const [maxHeight, setMaxHeight] = useState<number | null>(null)
  const [maxNameLength, setMaxNameLength] = useState<number | null>(null)
  const [maxSize, setMaxSize] = useState<number>()
  const [mimeTypes, setMimeTypes] = useState<string[]>([])

  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        let parameterName: ConfigurationParameterName | undefined
        switch (imageType) {
          case ImageTypeCode.ML:
            parameterName = ConfigurationParameterName.ML
            break
          case ImageTypeCode.CD:
            parameterName = ConfigurationParameterName.CD
            break
          case ImageTypeCode.TMAL:
            parameterName = ConfigurationParameterName.TMAL
            break
          case ImageTypeCode.TMARI:
            parameterName = ConfigurationParameterName.TMARI
            break
          case ImageTypeCode.ATI:
            parameterName = ConfigurationParameterName.ATI
            break
          case ImageTypeCode.AQQI:
            parameterName = ConfigurationParameterName.AQQI
            break
        }
        if (parameterName) {
          const configParamsResponse =
            await ConfigService.getConfigurationParameter(parameterName)

          if (configParamsResponse.data) {
            setConfigurationParameters(configParamsResponse.data)

            const accept: Accept = {}
            configParamsResponse.data.mimeTypes.forEach((mimeType) => {
              accept[mimeType] = []
            })
            setAccept(accept)
            setMaxWidth(configParamsResponse.data.maxWidth)
            setMaxHeight(configParamsResponse.data.maxHeight)
            setMaxSize(configParamsResponse.data.maxSize)
            setMimeTypes(configParamsResponse.data.mimeTypes)
            setMaxNameLength(configParamsResponse.data.maxNameLength)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, imageType])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && configurationParameters && (
        <FileUploader
          imageType={imageType}
          accept={accept}
          maxWidth={maxWidth || 0}
          maxHeight={maxHeight || 0}
          maxSize={maxSize || 0}
          mimeTypes={mimeTypes}
          maxNameLength={maxNameLength || 0}
          selectedImageId={selectedImageId}
          setSelectedImageId={setSelectedImageId}
          showTitle={showTitle}
        />
      )}
    </>
  )
}

export default ImageLibrary
