import React, { FunctionComponent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import BasicInformation from '../partials/Steps/BasicInformation'
import Quiz from '../partials/Steps/Quiz'
import Questions from '../partials/Steps/Questions'
import BottomBar from '../partials/BottomBar'
import { useParams } from 'react-router-dom'
import { AssetDetails } from '../../../../store/Edubox/types'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import EduboxService from '../../../../services/edubox.service'

type QuizUpdateProps = {}

type QuizParams = {
  id: string
}

export type Block = {
  id: number | null
  name: string
  visibility: boolean
}

const QuizUpdate: FunctionComponent<QuizUpdateProps> = () => {
  const { t } = useTranslation()
  let { id } = useParams<QuizParams>()
  const [assetId, setAssetId] = useState<number | null>(null)
  const [quizId, setQuizId] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [assetDetails, setAssetDetails] = useState<AssetDetails | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setAssetId(parseInt(id))
      setLoading(true)
      try {
        const tradeActionDetailsResponse = await EduboxService.getAssetDetails(
          parseInt(id),
        )
        if (tradeActionDetailsResponse.data) {
          setAssetDetails(tradeActionDetailsResponse.data)
          if (tradeActionDetailsResponse.data.quiz?.quizId) {
            setQuizId(tradeActionDetailsResponse.data.quiz.quizId)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [id, t])

  if (!assetDetails) return null

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <Box>
          <Box sx={{ maxWidth: 800, margin: '0 auto 100px auto' }}>
            <BasicInformation
              setAssetId={setAssetId}
              assetId={assetId}
              assetDetails={assetDetails}
            />
            <Quiz
              assetId={assetId}
              assetDetails={assetDetails}
              setQuizId={setQuizId}
            />
            <Questions
              assetId={assetId}
              assetDetails={assetDetails}
              quizId={quizId}
            />
          </Box>
          <BottomBar />
        </Box>
      )}
    </>
  )
}

export default QuizUpdate
