import React, { useState } from 'react'
import { TextField } from '@mui/material'

type PlanInputProps = {
  value: string
  setValue: (value: string) => void
  disabled: boolean
}

const PlanInput: React.FC<PlanInputProps> = ({
  value,
  setValue,
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState<string>(value)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(Math.abs(parseInt(event.target.value)).toString())
    setValue(Math.abs(parseInt(event.target.value)).toString())
  }

  return (
    <TextField
      disabled={disabled}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        style: {
          padding: '3px 0px 3px 8px',
          fontSize: '0.9rem',
          backgroundColor: '#fff',
          textAlign: 'right',
        },
      }}
      type="number"
      value={inputValue}
      onChange={handleChange}
      size="small"
      sx={{
        '& .MuiInputBase-root': {
          borderRight: '1px solid #cdcdcd !important',
        },
        '& .MuiInputBase-root:hover': {
          borderRight: '1px solid #2e2e2e !important',
        },
      }}
    />
  )
}

export default PlanInput
