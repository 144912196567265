import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import { Grid } from '@mui/material'
import UsersList from './list/UsersList'
import { UserTypeCode } from '../../../store/User/types'
const Users = () => {
  let { path } = useRouteMatch()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: '0 20px 0 20px',
            }}
          >
            <Switch>
              <Route exact path={`${path}/ph`}>
                <UsersList type={UserTypeCode.SE} user={currentUser} />
              </Route>
              <Route exact path={`${path}/company-admins`}>
                <UsersList type={UserTypeCode.ADM} user={currentUser} />
              </Route>
              <Route exact path={`${path}/asm`}>
                <UsersList type={UserTypeCode.ASM} user={currentUser} />
              </Route>
            </Switch>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Users
