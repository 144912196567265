import React from 'react'
import { PlotData } from '../../../store/Game/types'
import Map0Img from '../../../assets/images/game/map/0.png'
import Map1Img from '../../../assets/images/game/map/1.png'
import Map2Img from '../../../assets/images/game/map/2.png'
import Map3Img from '../../../assets/images/game/map/3.png'
import Map4Img from '../../../assets/images/game/map/4.png'
import Map5Img from '../../../assets/images/game/map/5.png'
import Map6Img from '../../../assets/images/game/map/6.png'
import Map7Img from '../../../assets/images/game/map/7.png'
import Map8Img from '../../../assets/images/game/map/8.png'
import Map9Img from '../../../assets/images/game/map/9.png'
import Map10Img from '../../../assets/images/game/map/10.png'
import Map11Img from '../../../assets/images/game/map/11.png'
import Map12Img from '../../../assets/images/game/map/12.png'
import Map13Img from '../../../assets/images/game/map/13.png'
import Map14Img from '../../../assets/images/game/map/14.png'
import Map15Img from '../../../assets/images/game/map/15.png'
import Map16Img from '../../../assets/images/game/map/16.png'
import Map17Img from '../../../assets/images/game/map/17.png'
import Map18Img from '../../../assets/images/game/map/18.png'

import Plot0Img from '../../../assets/images/game/plot/0.png'
import Plot1Img from '../../../assets/images/game/plot/1.png'
import Plot2Img from '../../../assets/images/game/plot/2.png'
import Plot3Img from '../../../assets/images/game/plot/3.png'
import Plot4Img from '../../../assets/images/game/plot/4.png'
import Plot5Img from '../../../assets/images/game/plot/5.png'
import Plot6Img from '../../../assets/images/game/plot/6.png'
import Plot7Img from '../../../assets/images/game/plot/7.png'
import Plot8Img from '../../../assets/images/game/plot/8.png'
import Plot9Img from '../../../assets/images/game/plot/9.png'
import Plot10Img from '../../../assets/images/game/plot/10.png'
import Plot11Img from '../../../assets/images/game/plot/11.png'
import Plot12Img from '../../../assets/images/game/plot/12.png'
import Plot13Img from '../../../assets/images/game/plot/13.png'
import Plot14Img from '../../../assets/images/game/plot/14.png'
import Plot15Img from '../../../assets/images/game/plot/15.png'
import Plot16Img from '../../../assets/images/game/plot/16.png'
import Plot17Img from '../../../assets/images/game/plot/17.png'
import Plot18Img from '../../../assets/images/game/plot/18.png'

export const NUM_OF_LEVELS_PER_MAP = 1

export const MAP_STYLE = {
  position: 'relative',
  margin: '5px',
} as React.CSSProperties

export const MAP_IMG_STYLE = {
  height: '530px',
} as React.CSSProperties

export const MAPS_DATA = [
  {
    mapSrc: Map0Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map1Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map2Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map3Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map4Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map5Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map6Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map7Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map8Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map9Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map10Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map11Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map12Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map13Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map14Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map15Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map16Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map17Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map18Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
  {
    mapSrc: Map18Img,
    levelPositions: [{ top: 20, left: 193 }],
    levels: [],
  },
]

export const PLOT_DATA: PlotData[] = [
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot0Img,
    title: 'Rzeszów',
    content:
      'Dojeżdżasz do Rzeszowa.<br>Stolica podkarpacia posiada urok zielonego galicyjskiego miasteczka. Odwiedzisz po drodze muzeum dobranocek i marzysz, by zamieszkać w pałacu Lubomirskich.<br>Rzeszów zawsze był siedzibą przedstawicieli wielu znanych, polskich rodów o czym świadczy wiele pięknych zabytków.<br>Jeśli zaś sprawy przybiorą wyjątkowo zły obrót, znajdziesz schronienie pod niepozornym domku jednorodzinnym, gdzie znajduje się schron atomowy!<br>Zabierasz ze sobą statuetkę Pomnika Czynu Rewolucyjnego.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot1Img,
    title: 'Kraków',
    content:
      'Przybywasz do Krakowa, miasta które jest “naj” pod każdym względem. Drugie największe miasto w Polsce, trzykrotna stolica Polski. Znajduje się tu ponad 6000 zabytków i 2 miliony dzieł sztuki. Co godzinę z Wieży Mariackiej rozbrzmiewa hejnał w tonacji F-dur. To czego możecie nie wiedzieć, to fakt, że hejnał urywa się w pewnym momencie (bo hejnalista miał kiedyś zostać trafiony strzałą). I jest to jedyne miasto, które organizuje coroczny Marsz Jamników.<br>Ruszając w drogę zabierasz ze sobą fragmenty smoczych kości.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot2Img,
    title: 'Białystok',
    content:
      'Następny przystanek to miasto pełne zieleni. Jedną trzecią Białegostoku stanowią tereny zielone. Miasto leży na 7 wzgórzach, podobnie jak Rzym.<br> Zdobi je kilkadziesiąt majestatycznych i pięknych murali, rozsianych po całym mieście. To właśnie w Białymstoku narodziła się idea wspólnego języka – Esperanto.<br>W dalszą drogę zabierasz reprodukcję muralu Dziewczynka z konewką.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot3Img,
    title: 'Bielsko-Biała',
    content:
      'Tym razem odwiedzasz Bielsko-Białą. Miasto posiada aż cztery rynki. W przeszłości powstawał tu legendarny Fiat 126p.<br>Znajdujesz się na szlaku postaci bajkowych! Na pewno rozpoznajesz rzeźby ulubionych bohaterów kreskówek. To właśnie w Bielsku-Białej, w studiu Filmów Rysunkowych, swoje początki miały m.in. Bolek i Lolek oraz Reksio. Na pamiątkę zabierasz figurkę Reksia.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot4Img,
    title: 'Jelenia Góra',
    content:
      'Docierasz do Jeleniej Góry, stolicy gór Karkonoszy. Legenda mówi że osadę założył Bolesław Krzywousty wybierając się w te tereny na polowanie, ujrzał dostojnego Jelenia stojącego na wzgórzu.<br>Według wielu miejsce to jest siedzibą Liczyrzepy – króla gór! Jego legenda jest żywa nie tylko w Polsce, ale i w Czechach.<br>Jelenia Góra była niegdyś warownym miastem otoczonym murami i 36 basztami. Do dziś przetrwały jako zabytki dwie baszty: Grodzka oraz Zamkowa, która była więzieniem! W dalszą podroż zabierasz małą, wyrzeźbioną w drewnie figurkę Liczyrzepy.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot5Img,
    title: 'Września',
    content:
      'Kolejny przystanek – Września.<br>Zwiedzasz Wieżę Ciśnień na terenie stacji kolejowej. W Przeszłości we Wrześni znajdowały się aż trzy takie budowle!<br>Nazwa miasta Września pochodzi od wrzosu, niegdyś bujnie porastającego te okolice.<br>Zabierasz ze sobą bukiet wrzosów.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot6Img,
    title: 'Opole',
    content:
      'Docierasz do Opola. To jedno z najstarszych polskich miast, którego historia nie jest wcale krótsza od historii takich prastarych Polskich miast, jak Gniezno czy Kraków.<br>Jest wiele źródeł nazwy Opola. Jedni mówią, że mówią, że jego nazwa wywodzi się od słów “O!Pole”, które miał krzyknąć jeden z władców, gdy zobaczył wioskę. Inni twierdzą, że pochodzi od najstarszej jednostki terytorialnej, używanych przez Słowian.<br>W Opolu co roku odbywa się słynny festiwal muzyczny.<br>W dalszą drogę zabierasz bilet na Krajowy Festiwal Piosenki Polskiej.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot7Img,
    title: 'Zielona Góra',
    content:
      'Zielona Góra to Twój kolejny przystanek.<br>Nazwa miasta świetnie współgra z tym co zobaczysz. Ponad połowa terenu Zielonej góry to obszary zielone. Pięknie tu! Na centralnym wzgórzu możesz odwiedzić Park winny oraz jedyną w Polsce Palmiarnię.<br>Całe województwo lubuskie słynie z produkcji wina, dlatego też jego stolica nazywana jest polską stolicą wina. W mieście możesz zwiedzić muzeum poświęcone temu trunkowi. Mało tego, patronem miasta jest papież i męczennik, święty Urban, który jest również patronem winiarzy. W dalszą drogę zabierasz butelkę lokalnego wina.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot8Img,
    title: 'Gdańsk',
    content:
      'Kolej na Gdańsk. To właśnie tu króluje bursztyn, zwany inaczej „Złotem Bałtyku”. Zbierany jest w wielu zakątkach świata, ale to ten bałtycki cieszy się najlepszą opinią.<br>Od tysięcy lat bryłki tej skamieniałej żywicy fascynują poszukiwaczy i inspirują jubilerów.<br>Obecnie bursztyn wykorzystywany jest głównie jako kamień dekoracyjny. Kiedyś, jako cenny surowiec, stanowił także środek płatniczy. Przypisywano mu też właściwości lecznicze i magiczną moc.<br>Na pamiątkę zabierasz ze sobą bursztynowy amulet.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot9Img,
    title: 'Warszawa',
    content:
      'Jesteś w Warszawie przy ulicy Krzywe Koło. To tu zgodnie z legendą, w podziemiach jednej z kamienic mieszkał niezwyciężony potwór Bazyliszek. Nieszczęśnik, na którego skierował wzrok, zamieniał się w kamienny posąg.<br>Wielu śmiałków chciało zgładzić potwora, ale do zwycięstwa nie wystarczył miecz i tarcza. Pomysłowością wykazał się dopiero wędrowny krawczyk, który wyruszył do boju uzbrojony w zwierciadło.<br>Bazyliszek widząc swoje odbicie i spojrzenie skamieniał z przerażenia.<br>Krawczyk z emocji upuścił lustro, które rozprysło się na drobne kawałki.<br>W dalszą podróż ruszasz z kawałkiem legendarnego zwierciadła.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot10Img,
    title: 'Zamość',
    content:
      'Witaj w Zamościu, XVI-wiecznym mieście idealnym, zaprojektowanym przez włoskiego architekta. Zwanym Perłą Renesansu i Padwą Północy.<br>Znajduje się tu najstarsza polska apteka, która działa do dziś.<br>Zamość ma własną legendę o powstaniu idei szwedzkiego stołu. Jest związana z najazdem szwedzkim, podczas którego król nie chciał wpuścić wroga, ale gościnność nie pozwalała mu odmówić poczęstunku. Kazał wynieść suto nakryte stoły za mury. Aby znieważyć króla szwedzkiego zakazał podania krzeseł.<br>Do bagażu pakujesz bon na poczęstunek przy szwedzkim stole.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot11Img,
    title: 'Kielce',
    content:
      'Odwiedzasz jaskinię w środku miasta. To muszą być Kielce. Kadzielnia to kamieniołom czynny jeszcze w latach 60-tych, który rozbudowano o park i amfiteatr. Znajduje się tu największe skupisko jaskiń na kielecczyźnie. Jest ich 25!<br>Kielce są zagłębiem metalowym. Wytwarzano tu noże i scyzoryki.<br>Do plecaka pakujesz scyzoryk.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot12Img,
    title: 'Olsztyn',
    content:
      'Jesteś w Olsztynie, mieście położonym w sercu Warmii, w którego obrębie znajduje się aż 16 jezior. Olsztyn to miasto Mikołaja Kopernika, który pełnił tutaj funkcję administratora kapituły warmińskiej. W hołdzie wybitnemu astronomowi powstało tu słynne planetarium.<br>Patronem Olsztyna jest święty Jakub. Przez miasto przebiega polski odcinek trasy Camino de Santiago. Znakiem szlaku jest muszla świętego Jakuba – atrybut patrona Olsztyna i jednocześnie element olsztyńskiej flagi.W dalszą podróż ruszasz z muszlą świętego Jakuba.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot13Img,
    title: 'Poznań',
    content:
      'Witaj w Poznaniu. Jesteś na Starym rynku. Wybija południe. Widzisz ratusz z koziołkami. Na trąbce odgrywany jest hejnał Poznania.<br>Możesz odwiedzić rezerwat przyrody Meteoryt Morasko, gdzie znajduje się kilka kraterów powstałych po upadku meteorytów.<br>Atrybutem Poznania są pyry, inaczej mówiąc ziemniaki, które doczekały się nawet swojego pomnika.<br>Ruszasz dalej zabierając ze sobą rogala świętomarcińskiego.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot14Img,
    title: 'Toruń',
    content:
      'Docierasz do Torunia. Do miasta tysiąca zabytków, w którym od ponad 700 lat wypiekane są kultowe pierniki.<br>Legenda wspomina o córce toruńskiego młynarza Katarzynce, która miała przygotować smakołyki z okazji odwiedzin króla w Toruniu. Za radą pszczoły Katarzyna dodała miodu do chleba i ciast. Wypieki zdobyły tak wielką popularność w Europie, że do dziś nazywane są Katarzynkami.<br>Ruszając w dalszą drogę zabierasz piernikowe serce.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot15Img,
    title: 'Szczecin',
    content:
      'Jesteś w Szczecinie. To miasto bywa nazywane Paryżem Północy, za sprawą placów o gwiaździstym układzie.<br>Czy wiesz, że układ rond odwzorowuje pas Oriona?<br>To tutaj Szczecińska Fabryka Motocykli produkowała motocykl Junak.<br>Właśnie z tego miasta pochodzi szef wszystkich szefów: Krzysztof Jarzyna ze Szczecina, który doczekał się nawet swojego pomnika.<br>Jeśli nie znasz smaku paprykarza szczecińskiego czy smażonych pasztecików koniecznie musisz to zmienić.<br>Zabierasz ze sobą na drogę puszkę pożywnego paprykarza szczecińskiego',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot16Img,
    title: 'Gorzów',
    content:
      'Docierasz do Gorzowa Wielkopolskiego. Znajdujesz się przy Studni Czarownic. To tu w XVI wieku miały miejsce procesy czarownic. Studnia upamiętnia wszystkie skazane kobiety. W Gorzowie znajduje się dziewięć parków i aż osiem fontann.<br>Na Wełnianym Rynku spotykasz słynny tramwaj: Gorzowską Bimbę. Ta replika pojazdu z 1899 roku pełni dziś funkcję informacji turystycznej i kawiarenki.<br>Do kieszeni pakujesz breloczek z tramwajem Bimbą.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot17Img,
    title: 'Lublin',
    content:
      'Kierujesz się do Lublina. To jedno z miast, których czeka cię przejażdżka trolejbusem. Odwiedzasz też Zamek Królewski, gdzie zobaczysz legendarny stół z wypaloną Czarcią Łapą. Na końcu drogi skosztujesz piwa z lokalnego browaru Perła oraz lubelskich cebularzy.<br>Na pamiątkę zabierasz przepis na cebularz.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot18Img,
    title: 'Centrala',
    content:
      'Kliknij ikonę ze strzałką, aby wejść do biura i spotkać się z Prezesem Grupy MPT, który sprawdzi czy udało Ci się odwiedzić wszystkie miasta, zebrać z nich pamiątki oraz dowiedzieć się coś więcej na ich temat 🙂.<br>Odpowiedzi na te pytania nie powinny sprawić Ci większego problemu. Musisz jednak zdać ten „egzamin” aby otrzymać nagrody! Pamiętaj tylko, że jeśli się pomylisz, to musisz poczekać z kolejną próbą do następnego dnia. Sprawdzenie odpowiedzi to określony koszt Punktów Paliwa. Szczegóły przedstawi Ci Prezes 🙂.',
  },
  {
    style: {
      position: 'absolute',
      bottom: '15px',
      left: 'calc(50% - 80px)',
    },
    image: Plot18Img,
    title: 'Centrala',
    content:
      'Kliknij ikonę ze strzałką, aby wejść do biura i spotkać się z Prezesem Grupy MPT, który sprawdzi czy udało Ci się odwiedzić wszystkie miasta, zebrać z nich pamiątki oraz dowiedzieć się coś więcej na ich temat 🙂.<br>Odpowiedzi na te pytania nie powinny sprawić Ci większego problemu. Musisz jednak zdać ten „egzamin” aby otrzymać nagrody! Pamiętaj tylko, że jeśli się pomylisz, to musisz poczekać z kolejną próbą do następnego dnia. Sprawdzenie odpowiedzi to określony koszt Punktów Paliwa. Szczegóły przedstawi Ci Prezes 🙂.',
  },
]
