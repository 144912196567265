import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { handleNavigationClick } from '../../../../helpers/utils'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import Toolbar from '../partials/Toolbar'
import TableControlled from '../../../Table/TableControlled'
import { Column } from 'react-table'
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined'
import { ReactComponent as PreviewIcon } from '../../../../assets/images/icons/preview.svg'
import { ReactComponent as WarningIcon } from '../../../../assets/images/icons/warning.svg'
import { ReactComponent as AddUserIcon } from '../../../../assets/images/icons/user_add.svg'
import { ReactComponent as EditIcon } from '../../../../assets/images/icons/edit.svg'
import SportsScoreIcon from '@mui/icons-material/SportsScore'
import { errorHandler } from '../../../../helpers/errorHandler'
import EduboxService from '../../../../services/edubox.service'
import { Asset, QuizState, QuizStateCode } from '../../../../store/Edubox/types'
import AssetUsersDialog from '../partials/AssetUsersDialog'
import QuizCompleteDialog from '../partials/QuizCompleteDialog'

type EduboxListProps = {
  path: string
}

const EduboxList: FunctionComponent<EduboxListProps> = ({ path }) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'edubox-list'

  const [searchText, setSearchText] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [quizStateValue, setQuizStateValue] = useState<string>('all')
  const [quizStates, setQuizStates] = useState<QuizState[]>([])
  const [visibilityValue, setVisibilityValue] = useState<string>('all')

  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [refresh, setRefresh] = useState(false)
  const [assetUsersDialogOpen, setAssetUsersDialogOpen] = useState(false)
  // const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  // const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [pageCount, setPageCount] = useState(0)
  const [controlledPageIndex, setControlledPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [filteredActionsList, setFilteredActionsList] = useState<Asset[]>([])
  const [asset, setAsset] = useState<Asset | null>(null)
  // const [columnsVisibility, setColumnsVisibility] = useState<
  //   ColumnInstance<object>[]
  // >([])
  // const [selectedRows, setSelectedRows] = useState<Row<object>[]>([])
  const handleAssetUsersDialogClickOpen = (asset: Asset) => {
    setAsset(asset)
    setAssetUsersDialogOpen(true)
  }

  const handleAssetUsersDialogClose = (refreshTable: boolean = false) => {
    setAssetUsersDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }

  const [quizCompleteDialogOpen, setQuizCompleteDialogOpen] =
    useState<boolean>(false)
  const [quizId, setQuizId] = useState<number | null>(null)
  const [quizName, setQuizName] = useState<string>('')

  const handleQuizCompleteDialogClose = (refreshTable: boolean = false) => {
    setQuizCompleteDialogOpen(false)
    if (refreshTable) {
      setRefresh((prevState) => !prevState)
    }
  }
  const handleQuizCompleteDialogClickOpen = (quizId: number, name: string) => {
    setQuizId(quizId)
    setQuizName(name)
    setQuizCompleteDialogOpen(true)
  }

  const generateTableColumns = useCallback(
    (eduboxList: Asset[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.edubox.table.assetId').toString(),
          accessor: 'assetId',
          width: 70,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.edubox.table.name').toString(),
          accessor: 'name',
          width: 200,
          Cell: (params: any) => params.value,
        },
        // {
        //   Header: t('pages.edubox.table.description').toString(),
        //   accessor: 'description',
        //   width: 350,
        //   Cell: (params: any) => params.value,
        // },
        {
          Header: t('pages.edubox.table.assetAvailableFrom').toString(),
          accessor: 'assetAvailableFrom',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.edubox.table.assetAvailableTo').toString(),
          accessor: 'assetAvailableTo',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'isVisible',
          Header: t('pages.edubox.table.isVisible').toString(),
          width: 140,
          Cell: (params: any) =>
            params.value ? <>{t('common.yes')}</> : <>{t('common.no')}</>,
        },
        {
          accessor: 'users',
          Header: t('pages.edubox.table.users').toString(),
          width: 140,
          Cell: (params: any) =>
            params.value > 0 || params.value !== null ? (
              <>{params.value}</>
            ) : (
              <>{t('common.notSet')}</>
            ),
        },
        {
          accessor: 'isQuiz',
          Header: t('pages.edubox.table.isQuiz').toString(),
          width: 80,
          Cell: (params: any) =>
            params.value ? (
              <>{t('common.yes')}</>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t('common.no')} <WarningIcon />
              </div>
            ),
        },
        {
          Header: t('pages.edubox.table.quizAvailableFrom').toString(),
          accessor: 'quizAvailableFrom',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.edubox.table.quizAvailableTo').toString(),
          accessor: 'quizAvailableTo',
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'quizStateCode',
          Header: t('pages.edubox.table.quizStateCode').toString(),
          width: 140,
          Cell: (params: any) =>
            params.value ? t(`pages.edubox.quizStateCode.${params.value}`) : '',
        },
        {
          accessor: 'actions',
          Header: t('pages.edubox.table.actions').toString(),
          width: 200,
          disableSortBy: true,
          sticky: 'right',
          Cell: (params: any) => (
            <Grid container>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.edubox.table.edit')}`}>
                  <IconButton
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/update/${params.row.original.assetId}`,
                      )
                    }
                    size="small"
                    style={{
                      padding: 0,
                      opacity: !params.row.original.isEditable ? '.3' : '1',
                    }}
                    disabled={!params.row.original.isEditable}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.edubox.table.preview')}`}>
                  <IconButton
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/show/${params.row.original.assetId}`,
                      )
                    }
                    size="small"
                    style={{
                      padding: 0,
                    }}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.edubox.table.ranking')}`}>
                  <IconButton
                    onClick={() =>
                      handleNavigationClick(
                        `${path}/ranking/${params.row.original.quizId}`,
                      )
                    }
                    size="small"
                    style={{
                      padding: 0,
                      opacity: [
                        QuizStateCode.COMPLETED,
                        QuizStateCode.ACTIVE,
                        QuizStateCode.FINISHED,
                      ].includes(params.row.original.quizStateCode)
                        ? '1'
                        : '.3',
                    }}
                    disabled={
                      ![
                        QuizStateCode.COMPLETED,
                        QuizStateCode.ACTIVE,
                        QuizStateCode.FINISHED,
                      ].includes(params.row.original.quizStateCode)
                    }
                  >
                    <FormatListNumberedOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.edubox.table.import')}`}>
                  <IconButton
                    onClick={() =>
                      handleAssetUsersDialogClickOpen(params.row.original)
                    }
                    size="small"
                    style={{
                      padding: 0,
                      opacity: !params.row.original.isEditable ? '.3' : '1',
                    }}
                    disabled={!params.row.original.isEditable}
                  >
                    <AddUserIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                sx={{
                  width: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title={`${t('pages.edubox.table.settle')}`}>
                  <IconButton
                    onClick={() => {
                      handleQuizCompleteDialogClickOpen(
                        params.row.original.quizId,
                        params.row.original.name,
                      )
                    }}
                    size="small"
                    style={{
                      padding: 0,
                      opacity: !params.row.original.isQuizReadyToComplete
                        ? '.3'
                        : '1',
                    }}
                    disabled={!params.row.original.isQuizReadyToComplete}
                  >
                    <SportsScoreIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ),
        },
      )

      return columns
    },
    [t, path],
  )

  useEffect(() => {
    const fetchQuizStates = async () => {
      try {
        const quizStatesResponse = await EduboxService.getQuizStateList()

        if (quizStatesResponse.data.quizStates) {
          setQuizStates(quizStatesResponse.data.quizStates)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    fetchQuizStates()
  }, [t])

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          // setDownloadSortBy(sortColumn)
          // setDownloadSortOrder(sortDirection)

          const page = ++pageIndex
          const eduboxResponse = await EduboxService.getEduboxList(
            visibilityValue === 'all'
              ? null
              : visibilityValue === '1'
              ? true
              : false,
            quizStateValue === 'all' ? null : quizStateValue,
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (eduboxResponse.data.assets) {
            setTableColumns(generateTableColumns(eduboxResponse.data.assets))

            setFilteredActionsList(eduboxResponse.data.assets)

            setTotalCount(eduboxResponse.data.totalCount)
            setPageCount(Math.ceil(eduboxResponse.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [
      t,
      generateTableColumns,
      searchValue,
      quizStateValue,
      visibilityValue,
      refresh,
    ],
  )

  return (
    <>
      <Stack
        display="flex"
        alignContent="space-between"
        flexDirection="row"
        marginBottom={1}
      >
        <PrimaryButton
          variant="contained"
          onClick={() => handleNavigationClick(`${path}/create`)}
        >
          {t('pages.edubox.add')}
        </PrimaryButton>
      </Stack>
      <Toolbar
        value={searchText}
        onChange={(event: { target: { value: string } }) => {
          setSearchText(event.target.value)
        }}
        submitSearch={(searchValue) => {
          setSkipPageReset(false)
          setSearchValue(searchValue)
        }}
        clearSearch={() => {
          setSkipPageReset(false)
          setSearchText('')
          setSearchValue('')
        }}
        quizStates={quizStates}
        quizStateValue={quizStateValue}
        filterQuizState={(event: SelectChangeEvent) => {
          setSkipPageReset(false)
          setControlledPageIndex(0)
          setQuizStateValue(event.target.value)
        }}
        visibilityValue={visibilityValue}
        filterVisibility={(event: SelectChangeEvent) => {
          setSkipPageReset(false)
          setControlledPageIndex(0)
          setVisibilityValue(event.target.value)
        }}
      />
      <TableControlled
        name={tableName}
        columns={tableColumns}
        data={filteredActionsList}
        height="calc(100vh - 300px)"
        fetchData={fetchData}
        loading={tableLoading}
        pageIndex={controlledPageIndex}
        pageCount={pageCount}
        totalCount={totalCount}
        skipPageReset={skipPageReset}
        columnsVisibility={[]}
        toggleVisibility={() => {}}
      />
      {asset && (
        <AssetUsersDialog
          open={assetUsersDialogOpen}
          handleClose={handleAssetUsersDialogClose}
          asset={asset}
        />
      )}
      {quizId && (
        <QuizCompleteDialog
          open={quizCompleteDialogOpen}
          handleClose={handleQuizCompleteDialogClose}
          name={quizName}
          quizId={quizId}
        />
      )}
    </>
  )
}

export default EduboxList
