import axios from 'axios'
import authHeader from './authHeader'
import {
  AssetDetailsResponse,
  AssetTypeListResponse,
  CompleteQuizResponse,
  CreateOrUpdateAssetParams,
  CreateOrUpdateAssetResponse,
  CreateOrUpdateQuestionsParams,
  CreateOrUpdateQuestionsResponse,
  CreateOrUpdateQuizParams,
  CreateOrUpdateQuizResponse,
  EduboxListResponse,
  QuizPreviewResponse,
  QuizStateListResponse,
} from '../store/Edubox/types'

const API_URL = process.env.REACT_APP_API_URL

const getEduboxList = async (
  isVisible: boolean | null,
  quizStateCode: string | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<EduboxListResponse> => {
  return await axios.post(
    API_URL + '/api/admin/asset/list',
    {
      isVisible,
      quizStateCode,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const getQuizStateList = async (): Promise<QuizStateListResponse> => {
  return await axios.get(API_URL + `/api/admin/asset/quiz/state`, {
    headers: authHeader(),
  })
}

const getAssetTypeList = async (): Promise<AssetTypeListResponse> => {
  return await axios.get(API_URL + `/api/admin/asset/type`, {
    headers: authHeader(),
  })
}

const createOrUpdateAsset = async (
  params: CreateOrUpdateAssetParams,
): Promise<CreateOrUpdateAssetResponse> => {
  return await axios.post(API_URL + '/api/admin/asset', params, {
    headers: authHeader(),
  })
}

const createOrUpdateQuiz = async (
  params: CreateOrUpdateQuizParams,
): Promise<CreateOrUpdateQuizResponse> => {
  return await axios.post(API_URL + '/api/admin/quiz', params, {
    headers: authHeader(),
  })
}

const getAssetDetails = async (
  assetId: number,
): Promise<AssetDetailsResponse> => {
  return await axios.get(API_URL + `/api/admin/asset/preview/${assetId}`, {
    headers: authHeader(),
  })
}

const createOrUpdateQuestions = async (
  params: CreateOrUpdateQuestionsParams,
): Promise<CreateOrUpdateQuestionsResponse> => {
  return await axios.post(API_URL + '/api/admin/quiz/question', params, {
    headers: authHeader(),
  })
}

const getQuizPreview = async (
  quizId: number,
  quizParticipants: boolean | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<QuizPreviewResponse> => {
  return await axios.post(
    API_URL + '/api/admin/quiz/preview',
    {
      quizId,
      quizParticipants,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const completeQuiz = async (quizId: number): Promise<CompleteQuizResponse> => {
  return await axios.post(
    API_URL + '/api/admin/quiz/complete',
    {
      quizId,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getEduboxList,
  getQuizStateList,
  getAssetTypeList,
  createOrUpdateAsset,
  getAssetDetails,
  createOrUpdateQuiz,
  createOrUpdateQuestions,
  getQuizPreview,
  completeQuiz,
}

export default exportedObject
