import axios from 'axios'
import authHeader from './authHeader'
import {
  ActionsResponse,
  CreateOrUpdateInformationsParams,
  CreateOrUpdateInformationsResponse,
  ToggleActionResponse,
  ToggleActionBlockResponse,
  CreateOrUpdateRulesParams,
  CreateOrUpdateRulesResponse,
  ParameterTypesResponse,
  RemoveFileResponse,
  CreateOrUpdateBlockParams,
  CreateOrUpdateBlockResponse,
  RemoveBlockResponse,
  TradeActionDetailsResponse,
  RemoveParameterResponse,
  ActionGlobalParticipantsResponse,
  ActionImportDefinitionResponse,
  ActionCompanyParticipantsResponse,
  ActionCompaniesResponse,
  GetFileContentResponse,
} from '../store/TradeAction/types'

const API_URL = process.env.REACT_APP_API_URL

const createOrUpdateInformations = async (
  params: CreateOrUpdateInformationsParams,
): Promise<CreateOrUpdateInformationsResponse> => {
  return await axios.post(API_URL + '/api/admin/trade-actions', params, {
    headers: authHeader(),
  })
}

const createOrUpdateRules = async (
  params: CreateOrUpdateRulesParams,
): Promise<CreateOrUpdateRulesResponse> => {
  return await axios.post(API_URL + '/api/admin/trade-actions/rules', params, {
    headers: authHeader(),
  })
}

const createOrUpdateBlock = async (
  params: CreateOrUpdateBlockParams,
): Promise<CreateOrUpdateBlockResponse> => {
  return await axios.post(API_URL + '/api/admin/trade-actions/block', params, {
    headers: authHeader(),
  })
}

const removeBlock = async (
  blockId: number,
  actionId: number,
): Promise<RemoveBlockResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/block/remove',
    { blockId, actionId },
    {
      headers: authHeader(),
    },
  )
}

const removeParameter = async (
  blockId: number,
  parameterId: number,
): Promise<RemoveParameterResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/block/parameter/remove',
    { blockId, parameterId },
    {
      headers: authHeader(),
    },
  )
}

const removeFile = async (fileId: number): Promise<RemoveFileResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/file/remove',
    { fileId },
    {
      headers: authHeader(),
    },
  )
}

const getFileContent = async (
  fileId: number,
): Promise<GetFileContentResponse> => {
  return await axios.get(API_URL + `/api/admin/trade-actions/file/${fileId}`, {
    headers: authHeader(),
  })
}

const getActions = async (
  isVisible: boolean | null,
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<ActionsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/list',
    {
      isVisible,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const toggleAction = async (
  actionId: number,
): Promise<ToggleActionResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/toggle-action',
    { actionId },
    {
      headers: authHeader(),
    },
  )
}

const toggleActionBlock = async (
  actionId: number,
  blockId: number,
): Promise<ToggleActionBlockResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/toggle-action-block',
    { actionId, blockId },
    {
      headers: authHeader(),
    },
  )
}

const getParameterTypes = async (): Promise<ParameterTypesResponse> => {
  return await axios.get(
    API_URL + '/api/admin/trade-actions/block/parameter/type/list',
    {
      headers: authHeader(),
    },
  )
}

const getTradeActionDetails = async (
  actionId: number,
): Promise<TradeActionDetailsResponse> => {
  return await axios.get(API_URL + `/api/admin/trade-actions/${actionId}`, {
    headers: authHeader(),
  })
}

const setActionGlobalParticipants = async (
  actionId: number,
): Promise<ActionGlobalParticipantsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/participants/global',
    { actionId },
    {
      headers: authHeader(),
    },
  )
}

const setActionCompanyParticipants = async (
  actionId: number,
  companies: { id: number }[],
): Promise<ActionCompanyParticipantsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/trade-actions/participants/company',
    { actionId, companies },
    {
      headers: authHeader(),
    },
  )
}

const getActionCompanies = async (
  actionId: number,
): Promise<ActionCompaniesResponse> => {
  return await axios.get(
    API_URL + `/api/admin/trade-actions/companies/${actionId}`,
    {
      headers: authHeader(),
    },
  )
}

const getImportDefinition = async (
  blockId: number,
): Promise<ActionImportDefinitionResponse> => {
  return await axios.get(
    API_URL + `/api/admin/trade-actions/block/parameter/${blockId}`,
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getActions,
  createOrUpdateInformations,
  createOrUpdateRules,
  toggleAction,
  toggleActionBlock,
  getParameterTypes,
  removeFile,
  removeBlock,
  removeParameter,
  createOrUpdateBlock,
  getTradeActionDetails,
  setActionGlobalParticipants,
  getImportDefinition,
  setActionCompanyParticipants,
  getActionCompanies,
  getFileContent,
}

export default exportedObject
