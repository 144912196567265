export function isXpPointsVisible() {
  return window.env.XP_POINTS
}

export function isVpPointsVisible() {
  return window.env.VP_POINTS
}

export function isEpPointsVisible() {
  return window.env.EP_POINTS
}
