import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { ActionBlock } from '../../../../store/TradeAction/types'
import { errorHandler } from '../../../../helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import TradeActionService from '../../../../services/tradeAction.service'
import { GreenSwitch } from '../../../shared/GreenSwitch'

type BlockCellProps = {
  actionId: number
  actionBlock: ActionBlock
}

const BlockCell: React.FC<BlockCellProps> = ({ actionId, actionBlock }) => {
  const { t } = useTranslation()
  const [checked, setChecked] = React.useState(
    actionBlock ? (actionBlock.blockIsVisible ? true : false) : false,
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSaving(true)
    try {
      const toggleActionResponse = await TradeActionService.toggleActionBlock(
        actionId,
        actionBlock.blockId,
      )

      if (toggleActionResponse.data.success) {
        setChecked(!event.target.checked)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  return actionBlock ? (
    <Stack
      display="flex"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '90px',
        }}
      >
        {actionBlock.blockName}
        <br />
        <small>
          {actionBlock.lastImportDate
            ? actionBlock.lastImportDate.substring(0, 10)
            : ''}
        </small>
      </div>
      <GreenSwitch
        disabled={isSaving}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Stack>
  ) : (
    <></>
  )
}

export default BlockCell
