import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'
import { Grid } from '@mui/material'
import PeriodPlanResult from './PeriodPlanResult/PeriodPlanResult'
import PeriodRegionPlanChart from './PeriodRegionPlanChart/PeriodRegionPlanChart'
import UsersRanking from './UsersRanking/UsersRanking'
// import ChallengesStatistics from './ChallengesStatistics/ChallengesStatistics'

const Home = () => {
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser || !isLoggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} mb={2}>
          <UsersRanking />
        </Grid>
        <Grid item xs={12} mb={2}>
          <PeriodPlanResult />
        </Grid>
        <Grid item xs={12} mb={2}>
          <PeriodRegionPlanChart />
        </Grid>
        {/* <Grid item xs={12} mb={2}>
          <ChallengesStatistics />
        </Grid> */}
      </Grid>
    </>
  )
}

export default Home
