import axios from 'axios'
import { ProductsResponse } from '../store/Product/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getProducts = async (
  manufacturers: { id: number }[],
  categories: { id: number }[],
  groups: { id: number }[],
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
): Promise<ProductsResponse> => {
  return await axios.post(
    API_URL + '/api/admin/product/list',
    {
      manufacturers,
      categories,
      groups,
      search,
      sortBy,
      sortOrder,
      per,
      page,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getProducts,
}

export default exportedObject
