import React, { FunctionComponent, useEffect, useState } from 'react'

import MessengerService from '../../../services/messenger.service'
import {
  GroupConversationMessage,
  GroupConversationDetails,
} from '../../../store/Messenger/types'
import { formatBytes, getBase64, odmiana } from '../../../helpers/utils'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  AlertColor,
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import SendIcon from '@mui/icons-material/Send'
import LoadingSpinner from '../../shared/LoadingSpinner'

let attachmentLimitInMB = 10

const AttachmentContainer = styled('div')({
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})
const Input = styled('input')({
  display: 'none',
})
const AttachmentName = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '200px',
  textOverflow: 'ellipsis',
  textDecoration: 'underline',
})
const Container = styled('div')({
  bottom: 0,
})
const AttachedFile = styled('div')({
  background: '#dee2e6',
  padding: '2px 10px 2px 0',
  fontSize: '13px',
  height: '22px',
  display: 'flex',
  alignItems: 'center',
})
const StyledButton = styled(Button)({
  borderRadius: '50%',
  width: '64px',
  height: '64px',
  position: 'absolute',
  right: '20px',
  top: '-20px',
  '& .MuiButton-startIcon': {
    marginLeft: '8px',
  },
})

type MessengerChatGroupProps = {
  groupId: number
  onGroupEdit: (groupId: number) => void
  onUserSelect: (userId: number) => void
}

const MessengerChatGroup: FunctionComponent<MessengerChatGroupProps> = ({
  groupId,
  onGroupEdit,
  onUserSelect,
}) => {
  const { t } = useTranslation()
  const [conversationMessages, setConversationMessages] = useState<
    GroupConversationMessage[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingGroupDetails, setLoadingGroupDetails] = useState<boolean>(true)
  const [messageSending, setMessageSending] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messageError, setMessageError] = useState<boolean>(false)
  const [attachment, setAttachment] = useState<File | null>(null)
  const [notification, setNotification] = useState<boolean>(false)
  const [groupDetails, setGroupDetails] =
    useState<GroupConversationDetails | null>(null)
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
  const [snackbarData, setSnackbarData] = useState<{
    severity: AlertColor
    message: string
  } | null>(null)

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageError(false)
    setMessage(event.target.value)
  }

  const handleNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNotification(event.target.checked)
  }

  useEffect(() => {
    const fetchConversationMessages = async () => {
      try {
        const conversationMessagesResponse =
          await MessengerService.getGroupConversationMessages(groupId)
        setConversationMessages(conversationMessagesResponse.data.messages)
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoading(false)
      }
    }

    const fetchGroupDetails = async () => {
      setLoadingGroupDetails(true)
      try {
        const groupDetailsResponse =
          await MessengerService.getGroupConversationDetails(groupId)
        setGroupDetails(groupDetailsResponse.data)
      } catch (error) {
        const _content =
          // (error.response && error.response.data) ||
          (error as Error).message || (error as Error).toString()

        console.warn(_content)
      } finally {
        setLoadingGroupDetails(false)
      }
    }

    fetchConversationMessages()
    const interval = setInterval(() => {
      fetchConversationMessages()
    }, 3000)

    fetchGroupDetails()
    setAttachment(null)
    setNotification(false)
    setMessage('')

    return () => clearInterval(interval)
  }, [groupId])

  const handleMessageSend = async () => {
    setMessageError(false)
    if (message.length === 0 && !attachment) {
      setMessageError(true)
      setSnackbarData({
        severity: 'error',
        message: t('messenger.errors.emptyMessageOrNoAttachment'),
      })
      setOpenSnackbar(true)
      return
    }

    let attachmentFilename = null
    let attachmentContent = null

    try {
      setMessageSending(true)

      if (attachment) {
        attachmentFilename = attachment.name
        const data = await getBase64(attachment)

        if (typeof data === 'string') {
          attachmentContent = data.split(',')[1]
        }
      }

      await MessengerService.sendGroupConversationMessage(
        groupId,
        message.length === 0 ? null : message,
        attachmentFilename,
        attachmentContent,
        notification,
      )

      const conversationMessagesResponse =
        await MessengerService.getGroupConversationMessages(groupId)
      setConversationMessages(conversationMessagesResponse.data.messages)
    } catch (error) {
      const _content =
        // (error.response && error.response.data) ||
        (error as Error).message || (error as Error).toString()

      console.warn(_content)
    } finally {
      setMessageSending(false)
      setAttachment(null)
      setNotification(false)
      setMessage('')
    }
  }

  const getAttachment = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    attachmentUuid: string,
  ) => {
    try {
      const attachmentResponse = await MessengerService.getMessageAttachment(
        attachmentUuid,
      )
      const linkSource = `data:${attachmentResponse.data.mimeType};base64,${attachmentResponse.data.base64Content}`
      const downloadLink = document.createElement('a')
      const fileName = attachmentResponse.data.filename

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    } catch (error) {
      const _content =
        // (error.response && error.response.data) ||
        (error as Error).message || (error as Error).toString()

      console.warn(_content)
    } finally {
    }
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const fileSize = event.target.files[0].size / 1024 / 1024
      if (fileSize > attachmentLimitInMB) {
        setSnackbarData({
          severity: 'error',
          message: t('messenger.errors.attachmentSizeExceeded', {
            limit: attachmentLimitInMB,
          }),
        })
        setOpenSnackbar(true)
      } else {
        setAttachment(event.target.files[0] as File)
      }
      event.target.value = ''
    }
  }

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackbar(false)
  }

  const renderUserName = (firstname: string, lastname: string, id: number) => (
    <div onClick={() => onUserSelect(id)} style={{ cursor: 'pointer' }}>
      {firstname} {lastname} ({id})
    </div>
  )

  const chatBubbles = conversationMessages.map((obj) => (
    <div
      className={`talk-bubble tri-right ${
        obj.isMessageAuthor ? 'right' : 'left'
      }-top ${obj.isMessageAuthor ? 'right' : 'left'}`}
      key={obj.messageId}
    >
      <div
        style={{
          position: 'absolute',
          left: '0',
          top: '-20px',
          fontSize: '12px',
        }}
      >
        {obj.messageCreated.replace('T', ' ')}
      </div>
      <div
        style={{
          position: 'absolute',
          right: '0',
          top: '-20px',
          fontSize: '12px',
        }}
      >
        {obj.isMessageAuthor
          ? 'Ja'
          : renderUserName(
              obj.authorFirstname,
              obj.authorLastname,
              obj.authorId,
            )}
      </div>
      {obj.isNotification && (
        <div
          className={`${
            obj.isMessageAuthor ? 'notification-left' : 'notification-right'
          }`}
        >
          <NotificationsIcon style={{ fontSize: '20px', color: '#d70000' }} />
        </div>
      )}
      <div className="talktext">
        <Typography variant="body2">{obj.messageContent}</Typography>
        {obj.attachmentUuid && (
          <AttachmentContainer
            onClick={(e) => getAttachment(e, obj.attachmentUuid || '')}
          >
            <AttachFileIcon style={{ fontSize: '12px' }} />{' '}
            <AttachmentName variant="caption">
              {obj.attachmentName}
            </AttachmentName>
          </AttachmentContainer>
        )}
        {obj.attachmentSize && <small>{formatBytes(obj.attachmentSize)}</small>}
      </div>
    </div>
  ))

  return (
    <>
      <div
        style={{
          height: '105px',
          backgroundColor: '#e6e6e6',
          padding: '10px',
        }}
      >
        {loadingGroupDetails && <LoadingSpinner />}
        {!loadingGroupDetails && groupDetails && (
          <ListItem style={{ marginTop: '8px' }}>
            <ListItemAvatar>
              <Avatar
                style={{
                  border: '1px solid #e0e4e7',
                  width: '50px',
                  height: '50px',
                }}
                src={`data:${groupDetails.groupIconMimeType};base64,${groupDetails.groupIconContent}`}
              />
            </ListItemAvatar>
            <ListItemText
              primary={groupDetails.groupName}
              secondary={`${groupDetails.groupParticipantsAmount} ${odmiana(
                groupDetails.groupParticipantsAmount,
                t('messenger.person'),
                t('messenger.persons'),
                t('messenger.persons2'),
              )}`}
            />
            <IconButton
              aria-label="settings"
              size="small"
              onClick={() => onGroupEdit(groupDetails.id)}
            >
              <SettingsIcon fontSize="inherit" />
            </IconButton>
          </ListItem>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          height: `calc(100% - 106px - 182px - 54px ${
            attachment ? '- 22px' : ''
          })`,
          flexDirection: 'column-reverse',
          overflowY: 'auto',
          borderBottom: '1px solid #ced4da',
        }}
      >
        {loading && <LoadingSpinner />}
        {!loading && (
          <Container>
            {conversationMessages.length > 0 && chatBubbles}
          </Container>
        )}
      </div>
      <TextField
        id="standard-multiline-static"
        multiline
        placeholder={t('messenger.writeMessage')}
        helperText={`${message.length}/1000`}
        rows={7}
        maxRows={7}
        fullWidth
        style={{ padding: '5px 10px' }}
        inputProps={{ maxLength: 1000 }}
        InputProps={{
          style: {
            height: '150px',
            fontSize: '.85rem',
            lineHeight: '1.35rem',
            padding: '0 0 0 7px',
          },
        }}
        value={message}
        onChange={handleMessageChange}
        error={messageError}
      />
      {attachment && (
        <AttachedFile>
          <IconButton aria-label="delete" onClick={() => setAttachment(null)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
          <AttachFileIcon style={{ fontSize: '12px' }} />{' '}
          <AttachmentName variant="caption">{attachment.name}</AttachmentName>
        </AttachedFile>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          position: 'relative',
        }}
      >
        <Input id="contained-button-file" type="file" onChange={onFileChange} />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            component="span"
            size="small"
            color="inherit"
            startIcon={<AttachFileIcon />}
            style={{ textTransform: 'capitalize' }}
          >
            {t('messenger.attachment')}
          </Button>
        </label>
        <FormControlLabel
          control={
            <Checkbox
              checked={notification}
              onChange={handleNotificationChange}
              name="notification"
              color="primary"
            />
          }
          label={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.8125rem',
              }}
            >
              <NotificationsIcon style={{ fontSize: '20px' }} />{' '}
              {t('messenger.notification')}
            </div>
          }
          style={{ marginLeft: '10px' }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<SendIcon style={{ fontSize: '30px' }} />}
          onClick={handleMessageSend}
          disabled={messageSending}
        />
      </div>

      {snackbarData && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarData.severity}>
            {snackbarData.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default MessengerChatGroup
