import {
  Stack,
  FormControl,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  Select,
  MenuItem,
} from '@mui/material'
import {
  BorderLinearProgress,
  // FilterButton,
  PhoneAppBarDetails,
  PhoneCard,
  PhoneContent,
} from '../styles'
// import { ReactComponent as FilterAltIcon } from '../../../assets/images/game/icons/filter_alt_icon.svg'
import { ReactComponent as ChallengesIcon } from '../../../assets/images/game/icons/challenges_icon.svg'
import { ReactComponent as PpIcon } from '../../../assets/images/game/icons/pp_icon.svg'
import { ReactComponent as VpIcon } from '../../../assets/images/game/icons/vp_icon.svg'

import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useEffect, useState } from 'react'
import { errorHandler } from '../../../helpers/errorHandler'
import { Period } from '../../../store/Period/types'
import PeriodService from '../../../services/period.service'
import GameService from '../../../services/game.service'
import { UserChallengesPreview } from '../../../store/Game/types'
import Image from '../../shared/Image'
import moment from 'moment'
import {
  thousandsSeparator,
  arraySearch,
  sortFunction,
} from '../../../helpers/utils'
import {
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../helpers/environment'

type ChallengesScreenProps = {
  userId: number
}

const ChallengesScreen: React.FunctionComponent<ChallengesScreenProps> = ({
  userId,
}) => {
  const { t } = useTranslation()
  const [periods, setPeriods] = useState<Period[]>([])
  const [periodValue, setPeriodValue] = useState<string>('')
  const [challengesList, setChallengesList] = useState<UserChallengesPreview[]>(
    [],
  )

  const getChallengesListService = (periodId: string, userId: number) => {
    return GameService.getChallengesList(parseInt(periodId), userId)
  }

  const handlePeriodChange = async (event: SelectChangeEvent) => {
    setPeriodValue(event.target.value as string)
    const challengesListResponse = await getChallengesListService(
      event.target.value,
      userId,
    )
    if (challengesListResponse.data.challenges) {
      setChallengesList(
        sortFunction(challengesListResponse.data.challenges, 'name'),
      )
    }
  }

  const handleOnSearchChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let value = e.target.value
    const challengesListForSearch = await getChallengesListService(
      periodValue,
      userId,
    )
    let search = await arraySearch(
      challengesListForSearch.data.challenges,
      value,
      'name',
    )
    setChallengesList(sortFunction(search, 'name'))
  }

  const getChallengeStatus = (
    dateFrom: string,
    dateTo: string,
    completed: boolean,
  ) => {
    if (moment(new Date()).isBefore(dateFrom) && completed === false)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.notStarted',
      )
    if (moment(new Date()).isBetween(dateFrom, dateTo) && completed === false)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.ongoing',
      )
    if (moment(new Date()).isAfter(dateTo) && completed === false)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.finished',
      )
    if (moment(new Date()).isAfter(dateTo) && completed === true)
      return t(
        'pages.game.phone.screens.challengesScreen.challengeStatus.completed',
      )
  }

  const getDateDiff = (dateTo: string) => {
    const dateDiffrence: number = Math.round(
      (+new Date(dateTo) - +new Date()) / 8.64e7,
    )

    return dateDiffrence > 0 ? dateDiffrence : 0
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let activePeriod = null
        const periodListResponse = await PeriodService.getPeriodList()
        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
          activePeriod = periodListResponse.data.periods.find(
            (period) => period.isActive,
          )
          setPeriodValue(activePeriod ? activePeriod.id.toString() : '')
        }
        if (activePeriod !== null && activePeriod !== undefined) {
          setPeriodValue(activePeriod.id.toString())
        }
        if (activePeriod) {
          const challengesListResponse = await GameService.getChallengesList(
            activePeriod.id,
            userId,
          )
          if (challengesListResponse.data.challenges) {
            setChallengesList(
              sortFunction(challengesListResponse.data.challenges, 'name'),
            )
          }
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    fetchData()
  }, [t, userId])

  const renderChallengeCard = (challengeList: UserChallengesPreview) => {
    return (
      <PhoneCard>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="20px"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="700"
          marginBottom="20px"
        >
          <div>{challengeList.name}</div>
          <ChevronRightIcon />
        </Stack>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Image
            imageId={challengeList.imageId}
            style={{
              width: '230px',
              height: '150px',
              objectFit: 'contain',
            }}
          />
        </div>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="12px"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="400"
          marginTop="20px"
          paddingBottom="8px"
          borderBottom="1px solid #F5F2E3"
        >
          <div>
            {t('pages.game.phone.screens.challengesScreen.start')}{' '}
            <strong>
              {getDateDiff(challengeList.validFrom)}{' '}
              {getDateDiff(challengeList.validFrom) === 1
                ? ' ' + t('pages.game.phone.screens.challengesScreen.day')
                : ' ' + t('pages.game.phone.screens.challengesScreen.days')}
            </strong>
          </div>
          <div>
            {t('pages.game.phone.screens.challengesScreen.timeScope')}{' '}
            <strong>{challengeList.timeScope}</strong>
          </div>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="16px"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="700"
          marginTop="15px"
          marginBottom="4px"
        >
          <div>
            {getChallengeStatus(
              challengeList.validFrom,
              challengeList.validTo,
              challengeList.isCompleted,
            )}{' '}
            &#40;
            {challengeList.challengeLevelCode
              ? challengeList.challengeLevelCode
              : 'N/D'}
            &#41;
          </div>
          <div>{challengeList.challengePercentResultValue}%</div>
        </Stack>
        <BorderLinearProgress
          variant="determinate"
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor:
                challengeList.challengePercentResultValue >= 100
                  ? 'green'
                  : '#EE3042',
            },
          }}
          value={
            challengeList.challengePercentResultValue <= 100
              ? challengeList.challengePercentResultValue
              : 100
          }
        />
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="12px"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="400"
          marginTop="4px"
        >
          <div>
            {t('pages.game.phone.screens.challengesScreen.resultValue')}{' '}
            {thousandsSeparator(challengeList.challengeResultValue)}
          </div>
          <div>
            {t('pages.game.phone.screens.challengesScreen.planValue')}{' '}
            {thousandsSeparator(challengeList.challengeTargetValue)}
          </div>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="12px"
          justifyContent="flex-end"
          alignItems="center"
          fontWeight="600"
          paddingBottom="15px"
          borderBottom="1px solid #F5F2E3"
        >
          {t('pages.game.phone.screens.challengesScreen.granted')}
          {isXpPointsVisible() && (
            <>
              <div style={{ fontSize: '16px', margin: '0 5px 0 7px' }}>
                {challengeList.xpResult}
              </div>
              <PpIcon />
            </>
          )}
          {isVpPointsVisible() && (
            <>
              <div style={{ fontSize: '16px', margin: '0 5px 0 7px' }}>
                {challengeList.vpResult}
              </div>
              <VpIcon />
            </>
          )}
        </Stack>
      </PhoneCard>
    )
  }

  return (
    <>
      <PhoneAppBarDetails>
        <ChallengesIcon fill="#FFD748" />
        <div style={{ textAlign: 'center', paddingLeft: '10px' }}>
          {t('pages.game.phone.screens.challenges')}
        </div>
      </PhoneAppBarDetails>
      <PhoneContent>
        <Stack display="flex" padding="16px" alignItems="flex-end">
          {/* <FilterButton>
            <FilterAltIcon />
          </FilterButton> */}
          <FormControl style={{ marginLeft: '16px' }} fullWidth>
            <div
              style={{
                color: '#808080',
                fontSize: '12px',
                marginBottom: '3px',
              }}
            >
              {t('pages.game.phone.screens.challengesScreen.selectPeriod')}
            </div>
            <Select
              id="period-select"
              value={periodValue}
              onChange={handlePeriodChange}
              style={{ background: '#fff', height: 48, marginBottom: '3px' }}
              defaultValue={periodValue}
            >
              {periods.map((period) => (
                <MenuItem value={period.id} key={period.id}>
                  {period.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginLeft: '16px' }} fullWidth>
            <TextField
              id="input-with-icon-textfield"
              onChange={handleOnSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  height: '48px',
                },
              }}
              variant="outlined"
              style={{
                background: '#fff',
                height: '48px',
              }}
              placeholder={t(
                'pages.game.phone.screens.challengesScreen.search',
              )}
            />
          </FormControl>
        </Stack>
        {challengesList && (
          <>
            {challengesList.map((c) => {
              return renderChallengeCard(c)
            })}
          </>
        )}
      </PhoneContent>
    </>
  )
}

export default ChallengesScreen
