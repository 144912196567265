import React, { useState } from 'react'
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TradeActionService from '../../../../services/tradeAction.service'
import { errorHandler } from '../../../../helpers/errorHandler'

type StatusSwitcherProps = {
  actionId: number
  isActive: boolean
}

const StatusSwitcher: React.FC<StatusSwitcherProps> = ({
  actionId,
  isActive,
}) => {
  const { t } = useTranslation()
  const activeOrInactive = isActive ? 'active' : 'inactive'
  const [statusValue, setStatusValue] = useState<string>(activeOrInactive)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const getCurrentColor = (status: string) => {
    if (status === 'active') {
      return {
        backgroundColor: '#ECFED0',
        color: '#4D9B00',
      } as React.CSSProperties
    }
    return {
      backgroundColor: '#FED0D0',
      color: '#CF0000',
    } as React.CSSProperties
  }
  const [statusColor, setStatusColor] = useState<React.CSSProperties>(
    getCurrentColor(activeOrInactive),
  )

  const handleStatusChange = async (event: SelectChangeEvent) => {
    setIsSaving(true)
    try {
      const toggleActionResponse = await TradeActionService.toggleAction(
        actionId,
      )

      if (toggleActionResponse.data.success) {
        const targetValue = event.target.value
        setStatusValue(targetValue)
        setStatusColor(getCurrentColor(targetValue))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <FormControl
      size="small"
      sx={{
        fieldset: {
          border: '0 !important',
        },
      }}
    >
      <Select
        onChange={handleStatusChange}
        value={statusValue}
        style={statusColor}
        disabled={isSaving}
        SelectDisplayProps={{
          style: {
            fontSize: '14px',
            padding: '4px 32px 4px 10px',
          },
        }}
      >
        <MenuItem value="active">
          {t('pages.tradeActions.status.active')}
        </MenuItem>
        <MenuItem value="inactive">
          {t('pages.tradeActions.status.inactive')}
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default StatusSwitcher
