import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Select,
  FormHelperText,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import {
  UserEditErrors,
  UserEditParams,
  UserTypeCode,
} from '../../../../store/User/types'
import UserService from '../../../../services/user.service'
import CompanyService from '../../../../services/company.service'
import RegionService from '../../../../services/region.service'
import { validateEmail } from '../../../../helpers/utils'
import { toast } from 'react-toastify'
import { Company } from '../../../../store/Company/types'
import { Region } from '../../../../store/Region/types'

type UserEditDialogProps = {
  userId: number
  open: boolean
  handleClose: (refresh: boolean) => void
  type: UserTypeCode
}

const UserEditDialog: React.FunctionComponent<UserEditDialogProps> = ({
  userId,
  open,
  handleClose,
  type,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [saving, setSaving] = useState<boolean>(false)
  const [resendInvitationMail, setResendInvitationMail] =
    useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [mobile, setMobile] = useState<string | null>(null)

  const [companyId, setCompanyId] = useState<string>('')
  const [regionId, setRegionId] = useState<string>('')
  const [companies, setCompanies] = useState<Company[]>([])
  const [regions, setRegions] = useState<Region[]>([])

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: UserEditErrors = {
    companyId: defaultError,
    regionId: defaultError,
    firstname: defaultError,
    lastname: defaultError,
    username: defaultError,
    email: defaultError,
    mobile: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<UserEditErrors>(defaultFormErrors)

  const handleCompanyChange = (event: SelectChangeEvent) => {
    setCompanyId(event.target.value as string)
  }

  const handleRegionChange = (event: SelectChangeEvent) => {
    setRegionId(event.target.value as string)
  }

  const handleFirstnameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFirstname(event.target.value)
  }

  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value)
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMobile(event.target.value)
  }

  const handleResendInvitationMailChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setResendInvitationMail(event.target.checked)
  }

  const resetForm = () => {
    setFormErrors(defaultFormErrors)
    setCompanyId('')
    setRegionId('')
    setFirstname('')
    setLastname('')
    setUsername('')
    setEmail('')
    setMobile('')
    setResendInvitationMail(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // get companies
        const companiesResponse = await CompanyService.getCompanyList()

        if (companiesResponse.data.companies) {
          setCompanies(companiesResponse.data.companies)
        }
        // get regions
        const regionsResponse = await RegionService.getRegionList()

        if (regionsResponse.data.regions) {
          setRegions(regionsResponse.data.regions)
        }

        const userDetailsResponse = await UserService.getUserDetails(userId)

        setCompanyId(userDetailsResponse.data.companyId?.toString() || '')
        setRegionId(userDetailsResponse.data.regionId.toString())
        setFirstname(userDetailsResponse.data.firstname)
        setLastname(userDetailsResponse.data.lastname)
        setUsername(userDetailsResponse.data.username)
        setEmail(userDetailsResponse.data.email || '')
        setMobile(userDetailsResponse.data.mobile)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (open) {
      fetchData()
    }
  }, [open, t, userId])

  const onSave = async () => {
    setFormErrors(defaultFormErrors)
    const step1Errors = defaultFormErrors

    if (firstname.trim() === '') {
      setFormErrors({
        ...step1Errors,
        firstname: {
          error: true,
          message: t('pages.users.userEditDialog.errors.firstnameRequired'),
        },
      })
      return
    } else if (lastname.trim() === '') {
      setFormErrors({
        ...step1Errors,
        lastname: {
          error: true,
          message: t('pages.users.userEditDialog.errors.lastnameRequired'),
        },
      })
      return
    } else if (email.trim() === '') {
      setFormErrors({
        ...step1Errors,
        email: {
          error: true,
          message: t('pages.users.userEditDialog.errors.emailRequired'),
        },
      })
      return
    } else if (!validateEmail(email)) {
      setFormErrors({
        ...step1Errors,
        email: {
          error: true,
          message: t('pages.users.userEditDialog.errors.emailInvalid'),
        },
      })
      return
    } else if (username.trim() === '') {
      setFormErrors({
        ...step1Errors,
        username: {
          error: true,
          message: t('pages.users.userEditDialog.errors.usernameRequired'),
        },
      })
      return
    }

    const formParams: UserEditParams = {
      userId,
      companyId: parseInt(companyId),
      regionId: parseInt(regionId),
      firstname,
      lastname,
      username,
      email,
      mobile: mobile?.trim() === '' ? null : mobile,
      resendInvitationMail,
    }

    try {
      setSaving(true)
      const addUserResponse = await UserService.editUser(formParams)

      if (addUserResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        resetForm()
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.users.userEditDialog.title', {
            userTypeName: t(`userTypeCodes.${type}`),
          })}
        </Typography>
      </DialogTitle>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DialogContent>
          <DialogContentText>
            <FormControl
              fullWidth
              margin="dense"
              error={formErrors.companyId.error}
            >
              <label>{t('pages.users.userEditDialog.company')} *</label>
              <Select
                id="company-select"
                value={companyId}
                onChange={handleCompanyChange}
                size="small"
              >
                {companies.map((company) => (
                  <MenuItem
                    value={company.companyId}
                    key={`user-add-${company.companyId}`}
                  >
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.companyId.error && (
                <FormHelperText>
                  {formErrors.companyId.error && formErrors.companyId.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              margin="dense"
              error={formErrors.regionId.error}
            >
              <label>{t('pages.users.userEditDialog.region')} *</label>
              <Select
                id="region-select"
                value={regionId}
                onChange={handleRegionChange}
                size="small"
              >
                {regions.map((region) => (
                  <MenuItem
                    value={region.regionId}
                    key={`user-add-${region.regionId}`}
                  >
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.regionId.error && (
                <FormHelperText>
                  {formErrors.regionId.error && formErrors.regionId.message}
                </FormHelperText>
              )}
            </FormControl>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.firstname')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={firstname}
                    onChange={handleFirstnameChange}
                    error={formErrors.firstname.error}
                    helperText={
                      formErrors.firstname.error && formErrors.firstname.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.lastname')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={lastname}
                    onChange={handleLastnameChange}
                    error={formErrors.lastname.error}
                    helperText={
                      formErrors.lastname.error && formErrors.lastname.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.email')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={email}
                    onChange={handleEmailChange}
                    error={formErrors.email.error}
                    helperText={
                      formErrors.email.error && formErrors.email.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.username')} *</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={username}
                    onChange={handleUsernameChange}
                    error={formErrors.username.error}
                    helperText={
                      formErrors.username.error && formErrors.username.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="dense">
                  <label>{t('pages.users.userEditDialog.mobile')}</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={mobile}
                    onChange={handleMobileChange}
                    error={formErrors.mobile.error}
                    helperText={
                      formErrors.mobile.error && formErrors.mobile.message
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={resendInvitationMail}
                  onChange={handleResendInvitationMailChange}
                />
              }
              label={t('pages.users.userEditDialog.resendInvitationMail')}
            />
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              resetForm()
              handleClose(false)
            }}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSave} disabled={saving || loading}>
            {t('common.save')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default UserEditDialog
