import axios from 'axios'
import {
  ImageListResponse,
  ImageTypesReponse,
  ImageContentResponse,
  UserImageResponse,
  ImageListParams,
  SetImageParams,
  SetImageResponse,
} from '../store/Image/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getImageTypeList = (): Promise<ImageTypesReponse> => {
  return axios.get(API_URL + '/api/admin/image/type', {
    headers: authHeader(),
  })
}

const getImageList = (params: ImageListParams): Promise<ImageListResponse> => {
  return axios.post(API_URL + `/api/admin/image/list`, params, {
    headers: authHeader(),
  })
}

const getImageContent = (imageId: number): Promise<ImageContentResponse> => {
  return axios.get(API_URL + `/api/admin/image/${imageId}`, {
    headers: authHeader(),
  })
}

const getUserProfileImage = (userId: number): Promise<UserImageResponse> => {
  return axios.get(API_URL + `/api/admin/image/user/${userId}`, {
    headers: authHeader(),
  })
}

const setImage = (params: SetImageParams): Promise<SetImageResponse> => {
  return axios.post(API_URL + `/api/admin/image/set`, params, {
    headers: authHeader(),
  })
}

const exportedObject = {
  getImageTypeList,
  getImageList,
  getImageContent,
  getUserProfileImage,
  setImage,
}

export default exportedObject
