import { useEffect, useState } from 'react'
import { PhoneAppBarDetails, PhoneCard, PhoneContent } from '../styles'
import { ReactComponent as ActionsIcon } from '../../../assets/images/game/icons/actions_icon.svg'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import GameService from '../../../services/game.service'
import { TradeActionSummary } from '../../../store/Game/types'
import { Stack } from '@mui/material'
import Image from '../../shared/Image'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LoadingSpinner from '../../shared/LoadingSpinner'

type ActionsScreenProps = {
  userId: number
  setActionId: React.Dispatch<React.SetStateAction<number | null>>
}

const ActionsScreen: React.FunctionComponent<ActionsScreenProps> = ({
  userId,
  setActionId,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [tradeActions, setTradeActions] = useState<TradeActionSummary[]>([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const tradeActionsListResponse = await GameService.getTradeActionsList(
          userId,
        )
        setTradeActions(tradeActionsListResponse.data.tradeActions)
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, userId])

  const renderActionCard = (action: TradeActionSummary) => {
    return (
      <PhoneCard
        style={{ cursor: 'pointer' }}
        onClick={() => setActionId(action.actionId)}
      >
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="20px"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="700"
          marginBottom="20px"
        >
          <div>{action.actionName}</div>
          <ChevronRightIcon />
        </Stack>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Image
            imageId={action.actionImgId}
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
        <Stack
          display="flex"
          flexDirection="row"
          fontSize="12px"
          justifyContent="space-between"
          alignItems="center"
          fontWeight="400"
          marginTop="20px"
        >
          <div>
            {t('pages.game.phone.screens.actionsScreen.timePeriod')}:
            <br />
            <strong>
              {action.validFrom
                ? `${action.validFrom.substring(0, 10)} - `
                : '-'}
              {action.validTo ? action.validTo.substring(0, 10) : ''}
            </strong>
          </div>
          <div>
            {t('pages.game.phone.screens.actionsScreen.lastImportDate')}:
            <br />
            <strong>
              {action.lastImportDate
                ? action.lastImportDate.substring(0, 10)
                : '-'}
            </strong>
          </div>
        </Stack>
      </PhoneCard>
    )
  }

  return (
    <>
      <PhoneAppBarDetails>
        <ActionsIcon fill="#FFD748" />
        <div style={{ textAlign: 'center', paddingLeft: '10px' }}>
          {t('pages.game.phone.screens.actions')}
        </div>
      </PhoneAppBarDetails>
      <PhoneContent>
        {loading && <LoadingSpinner />}
        {!loading && (
          <Stack mt={'16px'}>
            {tradeActions.map((action) => renderActionCard(action))}
          </Stack>
        )}
      </PhoneContent>
    </>
  )
}

export default ActionsScreen
