import React, { useEffect, useState } from 'react'
import { Dialog } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TopBarIcons } from '../../assets/images/phone/top_bar_icons.svg'
import { ReactComponent as DashboardIcon } from '../../assets/images/game/icons/dashboard_icon.svg'
import { ReactComponent as PlansIcon } from '../../assets/images/game/icons/plans_icon.svg'
import { ReactComponent as ChallengesIcon } from '../../assets/images/game/icons/challenges_icon.svg'
import { ReactComponent as ActionsIcon } from '../../assets/images/game/icons/actions_icon.svg'
import {
  NavigationItem,
  Phone,
  PhoneBottomBar,
  PhoneContainer,
  PhoneTopBar,
} from './styles'
import PlansScreen from './screens/PlansScreen'
import DashboardScreen from './screens/DashboardScreen'
import ActionsScreen from './screens/ActionsScreen'
import ChallengesScreen from './screens/ChallengesScreen'
import StoresScreen from './screens/StoresScreen'
import { UserPlansPreview } from '../../store/Game/types'
import ActionDetailsScreen from './screens/ActionDetailsScreen'

type PhoneDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  userId: number
}

const PhoneDialog: React.FunctionComponent<PhoneDialogProps> = ({
  open,
  handleClose,
  userId,
}) => {
  const { t } = useTranslation()
  const [currentScreen, setCurrentScreen] = useState('dashboard')
  const [planId, setPlanId] = useState<number | null>(null)
  const [plans, setPlans] = useState<UserPlansPreview[]>([])
  const [actionId, setActionId] = useState<number | null>(null)

  useEffect(() => {
    if (open) {
      setCurrentScreen('dashboard')
    }
  }, [open])

  const renderNavigationItem = (
    name: string,
    icon: React.ReactNode,
    active = false,
  ) => {
    return (
      <NavigationItem active={active} onClick={() => setCurrentScreen(name)}>
        {icon}
        {t(`pages.game.phone.screens.${name}`)}
      </NavigationItem>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(false)}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '394px',
            height: '776px',
            background: 'transparent',
            boxShadow: 'none',
          },
        },
      }}
    >
      <Phone>
        <PhoneContainer>
          <PhoneTopBar>
            <TopBarIcons />
          </PhoneTopBar>
          {currentScreen === 'dashboard' && <DashboardScreen userId={userId} />}
          {currentScreen === 'plans' && (
            <PlansScreen
              userId={userId}
              setCurrentScreen={setCurrentScreen}
              setPlanId={setPlanId}
              handleSetPlans={setPlans}
            />
          )}
          {currentScreen === 'challenges' && (
            <ChallengesScreen userId={userId} />
          )}
          {currentScreen === 'actions' && (
            <ActionsScreen
              userId={userId}
              setActionId={(actionId) => {
                setActionId(actionId)
                setCurrentScreen('action-details')
              }}
            />
          )}
          {currentScreen === 'action-details' && (
            <ActionDetailsScreen
              actionId={actionId}
              userId={userId}
              setCurrentScreen={setCurrentScreen}
            />
          )}
          {currentScreen === 'stores' && planId !== null && (
            <StoresScreen
              plans={plans}
              planId={planId}
              userId={userId}
              setCurrentScreen={setCurrentScreen}
            />
          )}
          <PhoneBottomBar>
            {renderNavigationItem(
              'dashboard',
              <DashboardIcon />,
              currentScreen === 'dashboard',
            )}
            {renderNavigationItem(
              'plans',
              <PlansIcon />,
              currentScreen === 'plans',
            )}
            {renderNavigationItem(
              'challenges',
              <ChallengesIcon />,
              currentScreen === 'challenges',
            )}
            {renderNavigationItem(
              'actions',
              <ActionsIcon />,
              currentScreen === 'actions' || currentScreen === 'action-details',
            )}
          </PhoneBottomBar>
        </PhoneContainer>
      </Phone>
    </Dialog>
  )
}

export default PhoneDialog
