import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Asset } from '../../../../store/Edubox/types'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import GenericImport from '../../Import/partials/GenericImport'

type AssetUsersDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  asset: Asset
}

const AssetUsersDialog: React.FunctionComponent<AssetUsersDialogProps> = ({
  open,
  handleClose,
  asset,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(_, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.edubox.assetUsersDialog.title')}: {asset.name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack textAlign="left">
          <GenericImport name="user_assets" param1={asset.assetId} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="flex-end" width="100%">
          <SecondaryButton onClick={() => handleClose(true)}>
            {t('common.close')}
          </SecondaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default AssetUsersDialog
