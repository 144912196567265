import { AlertColor } from '@mui/material'
import { TFunction } from 'react-i18next'
import { ImportManufacturerResponse } from '../../../../../store/Import/types'

export const getManufacturersResponse = (
  importResponse: ImportManufacturerResponse,
  messageType: 'prepare' | 'accept',
  alertType: AlertColor,
  t: TFunction<'translation'>,
): {
  message: {
    type: AlertColor
    message: string
    hasSecondStep: boolean
  }
  additionalInfo?: React.ReactNode
} | null => {
  if (
    (importResponse.data.addedResults ||
      importResponse.data.addedResults === 0) &&
    (importResponse.data.updatedResults ||
      importResponse.data.updatedResults === 0)
  ) {
    const addedResults = importResponse.data.addedResults
    const updatedResults = importResponse.data.updatedResults

    return {
      message: {
        type: alertType,
        message: t(`import.messages.manufacturers.${messageType}Message`, {
          addedResults: addedResults.toLocaleString(),
          updatedResults: updatedResults.toLocaleString(),
        }),
        hasSecondStep: true,
      }
    }
  }
  return null
}
