import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Stack, AlertColor } from '@mui/material'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import ImportService from '../../../../services/import.service'
import { findDuplicates, isStringNumber } from '../../../../helpers/utils'
import FileUploader from '../partials/FileUploader'
import { getTradeActionsResponse } from './messages/tradeActionsResults'
import { ActionImportDefinitionColumn } from '../../../../store/TradeAction/types'

const { read, utils } = XLSX

type TradeActionsImportPrepareProps = {
  additionalParams?: {}
  importParameters: ActionImportDefinitionColumn[]
  handleSetAcceptResponse: (
    acceptResponse: {
      message: {
        type: AlertColor
        message: string
        hasSecondStep: boolean
      }
      additionalInfo?: React.ReactNode
    } | null,
  ) => void
}

const TradeActionsImportPrepare: React.FunctionComponent<
  TradeActionsImportPrepareProps
> = ({ additionalParams, importParameters, handleSetAcceptResponse }) => {
  const { t } = useTranslation()
  const [savingImport, setSavingImport] = useState<boolean>(false)
  const [importPrepareParams, setImportPrepareParams] = useState<any | null>(
    null,
  )
  const [alertOpen, setAlertOpen] = useState<boolean>(true)
  const [alertData, setAlertData] = useState({
    type: 'error' as AlertColor,
    message: '',
  })
  const [dataObj, setDataObj] = useState<any[]>([])
  const [file, setFile] = useState<File | null>(null)

  const onFileDrop = (file: File) => {
    handleSetAcceptResponse(null)

    if (file) {
      setFile(file)
      const reader = new FileReader()
      reader.onload = function (e) {
        if (e && e.target) {
          var data = e.target.result
          let readedData = read(data, { type: 'binary', codepage: 65001 })
          const wsname = readedData.SheetNames[0]
          const ws = readedData.Sheets[wsname]

          /* Convert array to json*/
          const initialParse = utils.sheet_to_json<any>(ws, {
            blankrows: false,
            defval: null,
            header: 1,
            raw: false,
          })

          // build header array based on import parameters
          const header = initialParse[0].map((headerValue: any, i: number) => {
            const foundParam = importParameters.find((param) =>
              param.columnName.includes(headerValue),
            )
            if (foundParam) {
              return foundParam.fieldName
            }
            return i
          })

          // parse again with proper headers
          const dataParse = utils.sheet_to_json<any>(ws, {
            blankrows: false,
            defval: null,
            header,
            raw: false,
          })

          setDataObj(dataParse)
        }
      }
      reader.readAsBinaryString(file)
    }
  }

  const saveImport = async () => {
    if (importPrepareParams) {
      try {
        setSavingImport(true)
        const tradeActionsResponse = await ImportService.tradeActions(
          importPrepareParams,
        )

        if (tradeActionsResponse.data.success) {
          const response = getTradeActionsResponse(
            tradeActionsResponse,
            'accept',
            'success',
            t,
          )
          if (response) {
            handleSetAcceptResponse(response)
          }
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setAlertOpen(false)
        setImportPrepareParams(null)
        setSavingImport(false)
      }
    }
  }

  useEffect(() => {
    setAlertOpen(false)
    setFile(null)
    setDataObj([])
  }, [importParameters])

  useEffect(() => {
    let importData: any[] = []
    let BreakException = {}
    let fileError = {
      type: 'info' as AlertColor,
      message: '',
    }

    setAlertOpen(false)
    setAlertData(fileError)
    setImportPrepareParams(null)

    try {
      let uniqueArr: any = {}
      dataObj.forEach((element, i) => {
        // check if required headers are present
        if (i === 0) {
          importParameters.forEach((param) => {
            if (
              !param.nullable &&
              !param.columnName.includes(element[param.fieldName])
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.columnMissing', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (param.unique) {
              if (!uniqueArr.hasOwnProperty(param.fieldName)) {
                Object.assign(uniqueArr, {
                  [param.fieldName]: [],
                })
              }
            }
          })
        } else {
          importParameters.forEach((param) => {
            if (
              param.columnType === 'string' &&
              param.length &&
              element[param.fieldName] &&
              element[param.fieldName].length > param.length
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.tooLongString', {
                  column: param.columnName[0],
                  length: param.length,
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (
              ['integer', 'number'].includes(param.columnType) &&
              !isStringNumber(element[param.fieldName])
              // !isNumber(parseInt(element[param.fieldName]))
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.wrongColumnType', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (!param.nullable && !element[param.fieldName]) {
              fileError = {
                type: 'error',
                message: t('import.messages.cellIsEmpty', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (param.unique) {
              if (uniqueArr.hasOwnProperty(param.fieldName)) {
                uniqueArr[param.fieldName].push(element[param.fieldName])
              }
            }
          })
        }

        let objectRow: any = {}
        importParameters.forEach((param) =>
          Object.assign(objectRow, {
            [param.fieldName]: element[param.fieldName] ?? null,
          }),
        )
        importData.push(objectRow)
      })

      for (const [key, valuesArr] of Object.entries(uniqueArr)) {
        const hasDuplicatedIds = findDuplicates(valuesArr as string[])
        if (hasDuplicatedIds.length > 0) {
          fileError = {
            type: 'error',
            message: t('import.messages.duplicatedIds', {
              column: importData[0][key],
              values: hasDuplicatedIds.join(', '),
            }),
          }
          throw BreakException
        }
      }
      importData.shift() // remove first el from arr
    } catch (e) {
      if (e !== BreakException) {
        throw e
      } else {
        importData = []
        setAlertOpen(true)
        setAlertData(fileError)
      }
    } finally {
      if (importData.length > 0) {
        setAlertData({
          type: 'info' as AlertColor,
          message: t('import.messages.fileReadyToImport', {
            length: importData.length,
          }),
        })

        const dataArrayToImport: any[] = []

        importData.forEach((data) => {
          const objectToImport: any = {}
          Object.entries(data).forEach((entry) => {
            if (entry[0] === 'userCentralId') {
              objectToImport['userCentralId'] = entry[1]
            } else {
            const foundParams = importParameters.find(
              (param) => param.fieldName === entry[0],
              )
              objectToImport[entry[0]] = {
                parameterId: foundParams?.parameterId,
                parameterType: foundParams?.returnType,
                parameterValue: entry[1],
              }
            }
          })
          dataArrayToImport.push(objectToImport)
        })

        setImportPrepareParams({
          ...additionalParams,
          data: dataArrayToImport,
        })
        setAlertOpen(true)
      }
    }
  }, [dataObj, t, file, importParameters, additionalParams])

  return (
    <>
      {importParameters && (
        <>
          <Stack>
            <FileUploader
              file={file}
              accept={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  [],
                'application/vnd.ms-excel': [],
              }}
              handleAcceptedFiles={(files) => onFileDrop(files[0] as File)}
            />
          </Stack>
          {alertOpen && (
            <Alert
              severity={alertData.type}
              sx={{ my: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {alertData.message}
            </Alert>
          )}
          {importPrepareParams && (
            <Stack
              className="buttons-container"
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              width="100%"
            >
              <PrimaryButton onClick={saveImport} disabled={savingImport}>
                {t('common.import')}
              </PrimaryButton>
            </Stack>
          )}
        </>
      )}
    </>
  )
}

export default TradeActionsImportPrepare
