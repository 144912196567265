import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { PlanContainer } from './styles'
import { grey } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import PlanService from '../../../../../services/plan.service'
import {
  CreateOrUpdatePlanStrategiesParams,
  Plan,
  Strategy,
} from '../../../../../store/Plan/types'
import LoadingSpinner from '../../../../shared/LoadingSpinner'

type PlanStrategiesProps = {
  planId: number | null
  planDetails?: Plan
}

const menuItemStyle = {
  whiteSpace: 'unset',
  wordBreak: 'break-word',
} as React.CSSProperties

const PlanStrategies: FunctionComponent<PlanStrategiesProps> = ({
  planId,
  planDetails,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [savingStep, setSavingStep] = useState(false)
  const [xpStrategies, setXpStrategies] = useState<Strategy[]>([])
  const [vpStrategies, setVpStrategies] = useState<Strategy[]>([])
  const [epStrategies, setEpStrategies] = useState<Strategy[]>([])
  const [xpStrategy, setXpStrategy] = useState<string>(
    planDetails?.xpStrategyId ? planDetails?.xpStrategyId.toString() : 'none',
  )
  const [vpStrategy, setVpStrategy] = useState<string>(
    planDetails?.vpStrategyId ? planDetails?.vpStrategyId.toString() : 'none',
  )
  const [epStrategy, setEpStrategy] = useState<string>(
    planDetails?.epStrategyId ? planDetails?.epStrategyId.toString() : 'none',
  )

  const handleXpStrategyChange = async (event: SelectChangeEvent) => {
    setXpStrategy(event.target.value as string)
  }

  const handleVpStrategyChange = async (event: SelectChangeEvent) => {
    setVpStrategy(event.target.value as string)
  }

  const handleEpStrategyChange = async (event: SelectChangeEvent) => {
    setEpStrategy(event.target.value as string)
  }

  useEffect(() => {
    const loadStrategies = async () => {
      setLoading(true)
      try {
        const xpStrategyResponse = await PlanService.getPlanStrategies('xp')
        if (xpStrategyResponse.data.strategies) {
          setXpStrategies(xpStrategyResponse.data.strategies)
        }
        const vpStrategyResponse = await PlanService.getPlanStrategies('vp')
        if (vpStrategyResponse.data.strategies) {
          setVpStrategies(vpStrategyResponse.data.strategies)
        }
        const epStrategyResponse = await PlanService.getPlanStrategies('ep')
        if (epStrategyResponse.data.strategies) {
          setEpStrategies(epStrategyResponse.data.strategies)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (planId) {
      loadStrategies()
    }
  }, [planId, t])

  if (!planId) {
    return (
      <PlanContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">2</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography variant="subtitle1" fontWeight={'bold'} component="div">
              {t('pages.plans.planStrategies.addStrategies')}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              dangerouslySetInnerHTML={{
                __html: t('pages.plans.planStrategies.addBlockCondition'),
              }}
            ></Typography>
            <IconButton
              aria-label="delete"
              disabled
              size="large"
              className="add-button"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      </PlanContainer>
    )
  }

  const clearForm = () => {
    setXpStrategy('none')
    setVpStrategy('none')
    setEpStrategy('none')
  }

  const saveForm = async () => {
    const formParams: CreateOrUpdatePlanStrategiesParams = {
      planId,
      xpStrategyId: xpStrategy === 'none' ? null : parseInt(xpStrategy),
      vpStrategyId: vpStrategy === 'none' ? null : parseInt(vpStrategy),
      epStrategyId: epStrategy === 'none' ? null : parseInt(epStrategy),
    }

    try {
      setSavingStep(true)
      const createInformationsResponse =
        await PlanService.createOrUpdatePlanStrategies(formParams)

      if (createInformationsResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSavingStep(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <PlanContainer>
          <Stack direction={'row'}>
            <div className="step-line">
              <div className="step-circle">2</div>
              <div className="step-arrow"></div>
            </div>
            <Box className="form-container">
              <Grid container sx={{ marginBottom: 4 }}>
                <Grid item xs={12}>
                  <div className="step-title">
                    {t('pages.plans.planStrategies.title')}
                  </div>
                  <div className="step-subtitle">
                    {t('pages.plans.planStrategies.subtitle')}
                  </div>
                </Grid>
              </Grid>
              <Grid container sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                  <FormControl size="small" fullWidth>
                    <label>{t('pages.plans.planStrategies.xp')}</label>
                    <Select
                      id="xp-select"
                      value={xpStrategy}
                      onChange={handleXpStrategyChange}
                      style={{ background: '#fff', width: '700px' }}
                    >
                      <MenuItem value={'none'}>
                        {t('pages.plans.planStrategies.none')}
                      </MenuItem>
                      {xpStrategies.map((strategy) => (
                        <MenuItem
                          value={strategy.id}
                          key={strategy.id}
                          style={menuItemStyle}
                        >
                          {strategy.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small" fullWidth>
                    <label>{t('pages.plans.planStrategies.vp')}</label>
                    <Select
                      id="vp-select"
                      value={vpStrategy}
                      onChange={handleVpStrategyChange}
                      style={{ background: '#fff', width: '700px' }}
                    >
                      <MenuItem value={'none'}>
                        {t('pages.plans.planStrategies.none')}
                      </MenuItem>
                      {vpStrategies.map((strategy) => (
                        <MenuItem
                          value={strategy.id}
                          key={strategy.id}
                          style={menuItemStyle}
                        >
                          {strategy.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl size="small" fullWidth>
                    <label>{t('pages.plans.planStrategies.ep')}</label>
                    <Select
                      id="ep-select"
                      value={epStrategy}
                      onChange={handleEpStrategyChange}
                      style={{ background: '#fff', width: '700px' }}
                    >
                      <MenuItem value={'none'}>
                        {t('pages.plans.planStrategies.none')}
                      </MenuItem>
                      {epStrategies.map((strategy) => (
                        <MenuItem
                          value={strategy.id}
                          key={strategy.id}
                          style={menuItemStyle}
                        >
                          {strategy.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: 1 }} />
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                width="100%"
                className="buttons-container"
              >
                <Stack spacing={2} direction="row">
                  <SecondaryButton onClick={clearForm} disabled={savingStep}>
                    {t('common.clear')}
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => {
                      saveForm()
                    }}
                    disabled={savingStep}
                  >
                    {t('common.save')}
                  </PrimaryButton>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </PlanContainer>
      )}
    </>
  )
}

export default PlanStrategies
