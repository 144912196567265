export const enum ImageTypeCode {
  ML = 'ML', // Logo producent
  CD = 'CD', // Grafika kafel wyzwania
  GBX = 'GBX', // Grafika historia konta
  TMAL = 'TMAL', // Logo głowne akcji trade marketingowej
  TMARI = 'TMARI', // Grafika umieszczana w zasadach akcji trade marketingowych
  ATI = 'ATI', // Miniatura materiału
  AQQI = 'AQQI', // Zdjęcie do pytania quizu
}

export type SetImageParams = {
  imageId: number | null
  name: string
  imageTypeCode?: string
  imageContent?: string
}

export type SetImageResponse = {
  data: {
    success: boolean
    imageId: number
  }
}

export type ImageListParams = {
  imageTypeCode: string
  per: number
  page: number
  search: string
}

export type Image = {
  imageId: number
  name: string
  size: number
}

export type ImageContent = {
  id: number
  name: string
  description: string
  imageContent: string
  imageMimeType: string
}

export type ImageListResponse = {
  data: {
    images: Image[]
    totalCount: number
  }
}

export type ImageContentResponse = {
  data: ImageContent
}

export type ImageType = {
  id: number
  name: string
  code: string
}

export type ImageTypesReponse = {
  data: {
    imageTypes: ImageType[]
  }
}

export type UserImage = {
  imageContent: string | null
  imageMimeType: string | null
}

export type UserImageResponse = {
  data: UserImage
}

export type ImageDetails = {
  imageContent: string | null
  imageMimeType: string | null
}

export type ImageDetailsResponse = {
  data: ImageDetails
}
