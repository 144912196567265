import axios from 'axios'
import {
  StoresImportData,
  StoresResponse,
  StoresImportResponse,
} from '../store/Store/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getStores = async (
  companies: { id: number }[],
  search: string | null,
  sortBy: string | null,
  sortOrder: string | null,
  per: number,
  page: number,
  isVisible: boolean | null,
  isActive: boolean | null,
): Promise<StoresResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/list',
    {
      companies,
      search,
      sortBy,
      sortOrder,
      per,
      page,
      isVisible,
      isActive,
    },
    {
      headers: authHeader(),
    },
  )
}

const importStores = async (
  data: StoresImportData[],
): Promise<StoresImportResponse> => {
  return await axios.post(
    API_URL + '/api/admin/store/import',
    {
      data,
    },
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getStores,
  importStores,
}

export default exportedObject
