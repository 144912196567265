import {
  Alert,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { QuizContainer } from './styles'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import AddIcon from '@mui/icons-material/Add'
import theme from '../../../../../theme'
import { grey } from '@mui/material/colors'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ErrorIcon from '@mui/icons-material/Error'
import {
  AssetDetails,
  CreateOrUpdateQuestionsParams,
  QuizQuestion,
} from '../../../../../store/Edubox/types'
import { toast } from 'react-toastify'
import { errorHandler } from '../../../../../helpers/errorHandler'
import EduboxService from '../../../../../services/edubox.service'
import QuestionWYSIWYG from '../QuestionWYSIWYG'
import htmlToDraft from 'html-to-draftjs'
import ImageLibrary from '../../../../ImageLibrary/ImageLibrary'
import { ImageTypeCode } from '../../../../../store/Image/types'

type QuestionsProps = {
  assetId: number | null
  assetDetails: AssetDetails | null
  quizId: number | null
}

type QuestionError = QuizQuestion & { error: boolean; message: string | null }

const Questions: FunctionComponent<QuestionsProps> = ({
  assetId,
  assetDetails,
  quizId,
}) => {
  const QUESTIONS_LIMIT = 100
  const ANSWERS_LIMIT = 100
  const { t } = useTranslation()
  const [stepExpanded, setStepExpanded] = useState(
    assetDetails && assetDetails.quiz && assetDetails.quiz.questions.length > 0
      ? true
      : false,
  )

  const emptyAnswer = { answerId: null, content: '', isCorrect: false }

  const emptyQuestion = {
    questionId: null,
    imageId: null,
    content: '',
    answers: [emptyAnswer],
  }

  const [questions, setQuestions] = useState<QuizQuestion[]>([emptyQuestion])
  const [questionsErrors, setQuestionsErrors] = useState<QuestionError[]>([])
  const [savingStep, setSavingStep] = useState(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [questionToDelete, setQuestionToDelete] = useState<{
    questionIndex: number
    questionId: number | null
  } | null>(null)
  const [expandedQuestion, setExpandedQuestion] = useState<string | false>(
    false,
  )

  const handleExpandedQuestionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedQuestion(isExpanded ? panel : false)
    }

  const addNewQuestionsRow = () => {
    if (questions.length < QUESTIONS_LIMIT) {
      setQuestions((prevQuestions) => [...prevQuestions, emptyQuestion])
      setExpandedQuestion(`question-${questions.length}`)
    }
  }

  const handleCorrectAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    questionIndex: number,
    answerIndex: number,
  ) => {
    questions[questionIndex].answers[answerIndex].isCorrect =
      event.target.checked
    setQuestions((prevQuestions) => [...questions])
  }

  const handleAnswerContentChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    questionIndex: number,
    answerIndex: number,
  ) => {
    questions[questionIndex].answers[answerIndex].content = event.target.value
    setQuestions((prevQuestions) => [...questions])
  }

  const addNewAnswerRow = (questionIndex: number) => {
    if (questions[questionIndex].answers.length < ANSWERS_LIMIT) {
      questions[questionIndex].answers.push(emptyAnswer)
      setQuestions((prevQuestions) => [...questions])
    }
  }

  const deleteAnswerRow = (questionIndex: number, answerIndex: number) => {
    questions[questionIndex].answers.splice(answerIndex, 1)
    setQuestions((prevQuestions) => [...questions])
  }

  const handleClickOpenDialog = (
    questionIndex: number,
    questionId: number | null,
  ) => {
    setQuestionToDelete({
      questionIndex,
      questionId,
    })
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    if (assetDetails) {
      if (assetDetails.quiz) {
        if (assetDetails.quiz.questions.length > 0) {
          setQuestions(assetDetails.quiz.questions)
        }
      }
    }
  }, [assetDetails])

  const deleteQuestion = () => {
    if (questionToDelete) {
      questions.splice(questionToDelete.questionIndex, 1)
      setQuestions((prevQuestions) => [...questions])

      if (questionToDelete.questionId) {
        saveForm()
      } else {
        handleCloseDialog()
      }
    }
  }

  const saveForm = async () => {
    setQuestionsErrors([])

    let hasErrors = false
    const errors = questions.map((question) => {
      if (
        htmlToDraft(question.content).contentBlocks[0].getText().trim()
          .length === 0
      ) {
        hasErrors = true
        return {
          ...question,
          error: true,
          message: t('pages.edubox.questions.errors.emptyQuestion'),
        }
      } else if (
        question.answers.find((answer) => answer.content.trim().length === 0)
      ) {
        hasErrors = true
        return {
          ...question,
          error: true,
          message: t('pages.edubox.questions.errors.emptyAnswer'),
        }
      } else if (!question.answers.find((answer) => answer.isCorrect)) {
        hasErrors = true
        return {
          ...question,
          error: true,
          message: t('pages.edubox.questions.errors.correctAnswerRequired'),
        }
      }
      return {
        ...question,
        error: false,
        message: null,
      }
    })

    setQuestionsErrors(errors)

    if (assetId && !hasErrors) {
      const formParams: CreateOrUpdateQuestionsParams = {
        assetId,
        questions,
      }

      try {
        setSavingStep(true)
        const createQestionsResponse =
          await EduboxService.createOrUpdateQuestions(formParams)

        if (createQestionsResponse.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          setQuestions(createQestionsResponse.data.questions)
          if (openDialog) {
            handleCloseDialog()
          }
          setExpandedQuestion(false)
        } else {
          toast.error(t('messages.error.generalError'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep(false)
      }
    }
  }

  const clearForm = () => {
    setQuestionsErrors([])
    setQuestions([emptyQuestion])
    setExpandedQuestion(false)
  }

  if (!stepExpanded) {
    return (
      <QuizContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">3</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography variant="subtitle1" fontWeight={'bold'} component="div">
              {t('pages.edubox.questions.title')}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              dangerouslySetInnerHTML={{
                __html: t('pages.edubox.questions.addScoring'),
              }}
            ></Typography>
            <IconButton
              aria-label="delete"
              disabled={!assetId || !quizId}
              onClick={() => {
                setStepExpanded(true)
                setExpandedQuestion('question-0')
              }}
              size="large"
              className="add-button"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      </QuizContainer>
    )
  }

  return (
    <QuizContainer>
      <Stack direction={'row'}>
        <div className="step-line">
          <div className="step-circle">{3}</div>
          <div className="step-arrow"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={7}>
              <div className="step-title">
                {t('pages.edubox.questions.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.edubox.questions.subtitle')}
              </div>
            </Grid>
          </Grid>
          <Grid container mb={1}>
            {questions.map((question, questionIndex) => (
              <Grid container key={`question-${questionIndex}`} mb={2}>
                <Grid item xs={12}>
                  <Accordion
                    expanded={expandedQuestion === `question-${questionIndex}`}
                    onChange={handleExpandedQuestionChange(
                      `question-${questionIndex}`,
                    )}
                    sx={{
                      boxShadow: 'none',
                      border:
                        questionsErrors[questionIndex] &&
                        questionsErrors[questionIndex].error
                          ? '1px solid red'
                          : 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                    >
                      {questionsErrors[questionIndex] &&
                        questionsErrors[questionIndex].error && (
                          <ErrorIcon color="error" sx={{ mr: 1 }} />
                        )}
                      <Typography>
                        {t('pages.edubox.questions.form.question')}{' '}
                        {questionIndex + 1}/{questions.length}
                      </Typography>
                      {expandedQuestion !== `question-${questionIndex}` && (
                        <Typography
                          variant="body2"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '250px',
                            lineHeight: '24px',
                            marginLeft: 10,
                            color: '#666',
                          }}
                        >
                          {htmlToDraft(question.content)
                            .contentBlocks[0].getText()
                            .substring(0, 50)}
                        </Typography>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {questionsErrors[questionIndex] &&
                        questionsErrors[questionIndex].error && (
                          <Alert severity="error" sx={{ mb: 2 }}>
                            {questionsErrors[questionIndex].message}
                          </Alert>
                        )}
                      <Grid container sx={{ marginBottom: 2 }}>
                        <QuestionWYSIWYG
                          questionContent={question.content}
                          setQuestionContent={(content) => {
                            questions[questionIndex].content = content
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ marginBottom: 2 }}>
                        <Accordion sx={{ backgroundColor: '#F1F1F1' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1b-content"
                          >
                            <Typography variant="caption" display="block">
                              {t('pages.edubox.questions.form.optionalImage')}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ImageLibrary
                              showTitle={false}
                              imageType={ImageTypeCode.AQQI}
                              selectedImageId={question.imageId}
                              setSelectedImageId={(id) =>
                                (questions[questionIndex].imageId = id)
                              }
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      {question.answers.map((answer, answerIndex) => (
                        <Grid
                          container
                          key={`answer-${questionIndex}-${answerIndex}`}
                        >
                          <Grid item xs={8}>
                            <label>
                              <Typography variant="caption" display="block">
                                {t('pages.edubox.questions.form.answer')}{' '}
                                {answerIndex + 1}/{question.answers.length}
                              </Typography>
                            </label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={answer.content}
                              onChange={(e) =>
                                handleAnswerContentChange(
                                  e,
                                  questionIndex,
                                  answerIndex,
                                )
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-end',
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={answer.isCorrect}
                                  onChange={(e) =>
                                    handleCorrectAnswerChange(
                                      e,
                                      questionIndex,
                                      answerIndex,
                                    )
                                  }
                                />
                              }
                              label={t(
                                'pages.edubox.questions.form.correctAnswer',
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-end',
                            }}
                          >
                            {question.answers.length > 1 && (
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  deleteAnswerRow(questionIndex, answerIndex)
                                }
                              >
                                <DeleteForeverOutlinedIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                      <Grid container>
                        <Grid item xs={12} sx={{ marginTop: 4 }}>
                          <Stack
                            display="flex"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            mb={3}
                            style={{ cursor: 'pointer' }}
                            onClick={() => addNewAnswerRow(questionIndex)}
                          >
                            <IconButton
                              aria-label="delete"
                              size="small"
                              style={{
                                backgroundColor:
                                  theme.colorsPalette.yellow.main,
                                marginRight: 10,
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                            <Typography
                              variant="subtitle2"
                              fontWeight="bold"
                              component="div"
                            >
                              {t('pages.edubox.questions.form.addAnswer')}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginBottom: 1 }} />
                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        width="100%"
                        className="buttons-container"
                      >
                        <Stack spacing={2} direction="row">
                          <SecondaryButton
                            onClick={() =>
                              handleClickOpenDialog(
                                questionIndex,
                                question.questionId,
                              )
                            }
                            disabled={savingStep}
                          >
                            {t('pages.edubox.questions.deleteQuestion')}
                          </SecondaryButton>
                          <PrimaryButton
                            onClick={saveForm}
                            disabled={savingStep}
                          >
                            {t('common.save')}
                          </PrimaryButton>
                        </Stack>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {questions.length !== QUESTIONS_LIMIT && (
            <Stack
              display="flex"
              direction="column"
              alignItems="center"
              justifyContent="center"
              mb={3}
              onClick={addNewQuestionsRow}
              style={{
                cursor: 'pointer',
                backgroundColor: '#ffffff',
                borderRadius: 5,
                paddingTop: '20px',
                marginBottom: '50px',
              }}
            >
              <Typography variant="subtitle2" fontWeight="bold" component="div">
                {t('pages.edubox.questions.addQuestion')}
              </Typography>
              <IconButton
                aria-label="delete"
                size="small"
                style={{
                  backgroundColor: theme.colorsPalette.yellow.main,
                  width: '48px',
                  height: '48px',
                  marginTop: 3,
                  marginBottom: '-25px',
                }}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          )}
          <Divider sx={{ marginBottom: 1 }} />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
            className="buttons-container"
          >
            <Stack spacing={2} direction="row">
              <SecondaryButton onClick={clearForm} disabled={savingStep}>
                {t('common.reset')}
              </SecondaryButton>
              <PrimaryButton onClick={saveForm} disabled={savingStep}>
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogTitle>
            {t('pages.edubox.questions.areYouSureToDelete', {
              questionNumber: questionToDelete?.questionIndex! + 1,
            })}
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleCloseDialog} disabled={savingStep}>
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton
            onClick={deleteQuestion}
            autoFocus
            disabled={savingStep}
          >
            {t('common.yes')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </QuizContainer>
  )
}

export default Questions
