import React, { useEffect, useState } from 'react'
import { Alert, Stack, AlertColor, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import ImportService from '../../../../services/import.service'
import { getManufacturersResponse } from './messages/manufacturers'
import { getStoresResponse } from './messages/stores'
import { getActionUsersResponse } from './messages/actionUsers'
import { getAssetUsersResponse } from './messages/assetUsers'
import { getOrdersResponse } from './messages/orders'
import { getProductsResponse } from './messages/products'

type ImportAcceptProps = {
  additionalParams?: {}
  importName: string
  acceptResponse: {
    message: {
      type: AlertColor
      message: string
      hasSecondStep: boolean
    }
    additionalInfo?: React.ReactNode
  } | null
  handleSetAcceptResponse: (acceptResponse: {
    message: {
      type: AlertColor
      message: string
      hasSecondStep: boolean
    }
    additionalInfo?: React.ReactNode
  }) => void
}

const ImportAccept: React.FunctionComponent<ImportAcceptProps> = ({
  importName,
  acceptResponse,
  handleSetAcceptResponse,
  additionalParams,
}) => {
  const { t } = useTranslation()
  const [acceptingImport, setAcceptingImport] = useState<boolean>(false)
  const [isStepVisible, setIsStepVisible] = useState<boolean>(false)
  const [importFinished, setImportFinished] = useState<boolean>(false)

  const acceptImport = async () => {
    try {
      setAcceptingImport(true)
      switch (importName) {
        case 'manufacturers':
          const importManufacturersResponse =
            await ImportService.acceptManufacturer()

          if (importManufacturersResponse.data.success) {
            const response = getManufacturersResponse(
              importManufacturersResponse,
              'accept',
              'success',
              t,
            )
            if (response) {
              handleSetAcceptResponse(response)
            }
          } else {
            toast.error(t('messages.error.generalError'))
          }
          break

        case 'products':
          const importProductsResponse = await ImportService.acceptProduct()

          if (importProductsResponse.data.success) {
            const response = getProductsResponse(
              importProductsResponse,
              'accept',
              'success',
              t,
            )
            if (response) {
              handleSetAcceptResponse(response)
            }
          } else {
            toast.error(t('messages.error.generalError'))
          }
          break

        case 'stores':
          const importStoresResponse = await ImportService.acceptStore()

          if (importStoresResponse.data.success) {
            const response = getStoresResponse(
              importStoresResponse,
              'accept',
              'success',
              t,
            )
            if (response) {
              handleSetAcceptResponse(response)
            }
          } else {
            toast.error(t('messages.error.generalError'))
          }
          break

        case 'trade_action_users':
          const importActionUsersResponse =
            await ImportService.acceptActionUsers({ ...additionalParams })

          if (importActionUsersResponse.data.success) {
            const response = getActionUsersResponse(
              importActionUsersResponse,
              'accept',
              'success',
              t,
            )
            if (response) {
              handleSetAcceptResponse(response)
            }
          } else {
            toast.error(t('messages.error.generalError'))
          }
          break

        case 'user_assets':
          const importAssetUsersResponse = await ImportService.acceptAssetUsers(
            { ...additionalParams },
          )

          if (importAssetUsersResponse.data.success) {
            const response = getAssetUsersResponse(
              importAssetUsersResponse,
              'accept',
              'success',
              t,
            )
            if (response) {
              handleSetAcceptResponse(response)
            }
          } else {
            toast.error(t('messages.error.generalError'))
          }
          break

        case 'store_orders':
          const importOrdersResponse = await ImportService.acceptOrders()

          if (importOrdersResponse.data.success) {
            const response = getOrdersResponse(
              importOrdersResponse,
              'accept',
              'success',
              t,
            )
            if (response) {
              handleSetAcceptResponse(response)
            }
          } else {
            toast.error(t('messages.error.generalError'))
          }
          break

        default:
          break
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setAcceptingImport(false)
      setImportFinished(true)
    }
  }

  useEffect(() => {
    setIsStepVisible(true)
    setImportFinished(false)
  }, [acceptResponse])

  return (
    <>
      {isStepVisible && (
        <>
          {acceptResponse && (
            <Alert
              severity={acceptResponse.message.type}
              sx={{ my: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {acceptResponse.message.message}
            </Alert>
          )}
          {acceptResponse?.additionalInfo}
          <Stack
            className="buttons-container"
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
          >
            {acceptResponse &&
              acceptResponse.message.hasSecondStep &&
              !importFinished && (
                <PrimaryButton
                  onClick={acceptImport}
                  disabled={acceptingImport}
                >
                  {acceptingImport
                    ? t('common.confirming')
                    : t('common.confirm')}
                  {acceptingImport && (
                    <CircularProgress
                      size={18}
                      style={{ marginLeft: '1rem' }}
                    />
                  )}
                </PrimaryButton>
              )}
          </Stack>
        </>
      )}
    </>
  )
}

export default ImportAccept
