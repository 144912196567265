import { useTranslation } from 'react-i18next'
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { Period } from '../../../../store/Period/types'
import { Option } from '../../../../store/types'
import { isCompanyAdmin } from '../../../../helpers/checkRole'
import { User } from '../../../../store/Auth/types'
import { PlanFilter } from '../../../../store/Plan/types'

interface UsersPlansUpdateToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  submitSearch: (value: string) => void
  value: string
  filterPeriod: (event: SelectChangeEvent) => void
  periodValue: string
  periods: Period[]
  filterPlan: (event: SelectChangeEvent) => void
  planValue: PlanFilter
  plans: PlanFilter[]
  filterCompany: (event: SelectChangeEvent) => void
  companyValue: string
  companies: Option[]
  user: User
}

export default function UsersPlansUpdateToolbar(
  props: UsersPlansUpdateToolbarProps,
) {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 10,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.userPlansUpdate.toolbar.filters.period')}</label>
          <Select
            id="period-select"
            onChange={props.filterPeriod}
            value={props.periodValue}
            defaultValue={props.periodValue}
          >
            {props.periods.map((period) => (
              <MenuItem value={period.id} key={period.id}>
                {period.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.userPlansUpdate.toolbar.filters.plan')}</label>
          <Select
            id="plan-select"
            onChange={props.filterPlan}
            value={props.planValue.planId.toString()}
            defaultValue={props.planValue.planId.toString()}
          >
            {props.plans.map((plan) => (
              <MenuItem value={plan.planId} key={plan.planId}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isCompanyAdmin(props.user) && (
          <FormControl size="small" sx={{ marginRight: 1 }}>
            <label>{t('pages.userPlansUpdate.toolbar.filters.company')}</label>
            <Select
              id="company-select"
              onChange={props.filterCompany}
              value={props.companyValue}
              defaultValue={props.companyValue}
            >
              {props.companies.map((company) => (
                <MenuItem value={company.value} key={company.key}>
                  {company.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  )
}
