import { AlertColor } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../helpers/errorHandler'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import ImportAccept from '../partials/ImportAccept'
import ImportColumns from '../partials/ImportColumns'
import TradeActionService from '../../../../services/tradeAction.service'
import TradeActionsImportPrepare from './TradeActionsImportPrepare'

type TradeActionsImportProps = {
  blockId: number
}

const TradeActionsImport: React.FC<TradeActionsImportProps> = ({ blockId }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [importParameters, setImportParameters] = useState<any[]>([])
  const [additionalParams, setAdditionalParams] = useState({})
  const [acceptResponse, setAcceptResponse] = useState<{
    message: {
      type: AlertColor
      message: string
      hasSecondStep: boolean
    }
    additionalInfo?: React.ReactNode
  } | null>(null)

  useEffect(() => {
    if (blockId) {
      setAdditionalParams({
        blockId,
      })
    }
  }, [blockId])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const importParametersResponse =
          await TradeActionService.getImportDefinition(blockId)
        if (importParametersResponse.data) {
          setImportParameters(importParametersResponse.data.columns)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    setAcceptResponse(null)
  }, [t, blockId])

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <ImportColumns importParameters={importParameters} />
          <TradeActionsImportPrepare
            importParameters={importParameters}
            handleSetAcceptResponse={setAcceptResponse}
            additionalParams={additionalParams}
          />
          <ImportAccept
            importName={''}
            acceptResponse={acceptResponse}
            handleSetAcceptResponse={setAcceptResponse}
            additionalParams={additionalParams}
          />
        </>
      )}
    </>
  )
}

export default TradeActionsImport
