import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer-hook'
import { FunctionComponent, useEffect, useState } from 'react'
import moment from 'moment'

type ChallengeCountdownTimerProps = {
  challengeId: number | null
  expiryTimestamp: Date
  setIsSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>
  refreshTimer: boolean
  setRefreshTimer: React.Dispatch<React.SetStateAction<boolean>>
}

const ChallengeCountdownTimer: FunctionComponent<
  ChallengeCountdownTimerProps
> = ({ expiryTimestamp, setIsSaveDisabled, refreshTimer, setRefreshTimer }) => {
  const { seconds, minutes, hours, days, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setIsSaveDisabled(true)
    },
  })
  const { t } = useTranslation()
  const [timerVisible, setTimerVisible] = useState<boolean>(false)
  useEffect(() => {
    setTimerVisible(false)
    const startCounter: number =
      moment(expiryTimestamp).diff(moment()) - 3600000
    restart(expiryTimestamp)
    setTimeout(() => {
      setTimerVisible(true)
    }, startCounter)
    setRefreshTimer(false)
  }, [t, refreshTimer, expiryTimestamp, setRefreshTimer]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {timerVisible && days === 0 && hours === 0 && (
        <div
          style={{
            textAlign: 'left',
            display: 'flex',
            alignItems: 'flex-end',
            color: '#fff',
          }}
        >
          <div>{t('pages.challenge.countdownTimer.timeLeft')}</div>
          <div style={{ fontWeight: 'bold', marginLeft: '2px' }}>
            <span>{minutes >= 10 ? minutes : `0${minutes}`}:</span>
            <span>{seconds >= 10 ? seconds : `0${seconds}`}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default ChallengeCountdownTimer
