import { styled } from '@mui/material/styles'

export const PlanContainer = styled('div')(({ theme }) => ({
  '.step-line': {
    borderLeft: '4px solid #373739',
    position: 'relative',
    width: 40,
    paddingTop: 40,
    '&.inactive': {
      borderLeft: '4px solid transparent',
    },
    '&.first': {
      marginTop: 40,
      paddingTop: 0,
    },
  },
  '.step-circle': {
    borderRadius: '50%',
    width: 40,
    height: 40,
    backgroundColor: '#373739',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    position: 'absolute',
    left: -22,
    color: '#fff',
    '&.inactive': {
      backgroundColor: '#9E9E9E',
    },
  },
  '.step-arrow': {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '15px 0 15px 20px',
    borderColor: 'transparent transparent transparent #373739',
    position: 'absolute',
    left: 5,
    top: 45,
    '&.inactive': {
      borderColor: 'transparent transparent transparent #9E9E9E',
    },
    '&.first': {
      top: 5,
    },
  },
  '.form-container': {
    width: '100%',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
    backgroundColor: theme.colorsPalette.gray.gray1,
    '.step-title': {
      fontSize: 20,
      fontWeight: 'bolder',
    },
    '.step-subtitle': {
      fontSize: 12,
      fontWeight: 'bolder',
    },
    '.visibility-label-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      '.visibility': {
        fontSize: 20,
        fontWeight: 'bolder',
        '&-label': {
          fontSize: 11,
          color: theme.colorsPalette.gray.gray4,
        },
      },
    },
    '.add-button': {
      position: 'absolute',
      backgroundColor: theme.colorsPalette.yellow.main,
      left: 'calc(50% - 24px)',
    },
    '.switch-button': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    '.character-amount': {
      fontSize: 11,
      color: theme.colorsPalette.gray.gray4,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    input: {
      backgroundColor: '#fff',
    },
    '.toolbarClassName': {
      borderTop: '1px solid rgba(0, 0, 0, 0.23) !important',
      borderLeft: '1px solid rgba(0, 0, 0, 0.23) !important',
      borderRight: '1px solid rgba(0, 0, 0, 0.23) !important',
      borderBottom: '0 !important',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
      padding: '5px 5px 0 5px !important',
      justifyContent: 'flex-end !important',
      marginBottom: 0,
      '.rdw-option-wrapper': {
        padding: '2px',
      },
    },
    '.editorClassName': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      height: '200px !important',
      padding: '0 8px',
      backgroundColor: '#fff',
    },
    '.bottomBar-buttons': {
      textDecoration: 'none !important',
    },
  },
}))
