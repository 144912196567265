import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import ChallengeService from '../../../../../services/challenge.service'
import CompanyService from '../../../../../services/company.service'
import PeriodService from '../../../../../services/period.service'
import { Period } from '../../../../../store/Period/types'
// import { ImageTypeCode } from '../../../../../store/Image/types'
import { MultiSelect } from 'react-multi-select-component'
import { RootStateOrAny, useSelector } from 'react-redux'
import { isCentralAdmin, isSuperAdmin } from '../../../../../helpers/checkRole'
import {
  ChallengeDetails,
  ChallengeParameters,
  ChallengeStep1Errors,
  ChallengeStep1RequestParams,
} from '../../../../../store/Challenge/types'
import { FormError } from '../../../../../store/types'
import moment from 'moment'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import LoadingSpinner from '../../../../shared/LoadingSpinner'
import { Option } from '../../../../../store/types'
import DateTimePicker from '../../../../shared/DateTimePicker'
import { StepContainer } from './styles'
// import ImageLibrary from '../../../../ImageLibrary/ImageLibrary'

type ChallengeStep1FormProps = {
  challengeId: number | null
  onSetChallengeId: (challengeId: number) => void
  challengeDetails?: ChallengeDetails
  challengeParameters: ChallengeParameters
  isSaveDisabled: boolean
  setRefreshTimer: React.Dispatch<React.SetStateAction<boolean>>
}

const ChallengeStep1Form: FunctionComponent<ChallengeStep1FormProps> = ({
  challengeId,
  onSetChallengeId,
  challengeDetails,
  challengeParameters,
  isSaveDisabled,
  setRefreshTimer,
}) => {
  const { t } = useTranslation()
  const hasFetchedData = useRef(false)
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  const [period, setPeriod] = useState('')
  const [periods, setPeriods] = useState<Period[]>([])
  const [companies, setCompanies] = useState<Option[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<Option[]>([])
  const [loadingStep1, setLoadingStep1] = useState<boolean>(true)
  const [savingStep1, setSavingStep1] = useState<boolean>(false)
  const [availableFromDate, setAvailableFromDate] = useState<Date | null>(null)
  const [availableFromTime, setAvailableFromTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [availableToDate, setAvailableToDate] = useState<Date | null>(null)
  const [availableToTime, setAvailableToTime] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const [validFromDate, setValidFromDate] = useState<Date | null>(null)
  const [validFromTime, setValidFromTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [validToDate, setValidToDate] = useState<Date | null>(null)
  const [validToTime, setValidToTime] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const [logoId, setLogoId] = useState<number | null>(null)
  const [challengeName, setChallengeName] = useState<string>('')
  const [challengeNameLength, setChallengeNameLength] = useState<number>(0)

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultStep1FormErrors: ChallengeStep1Errors = {
    period: defaultError,
    companies: defaultError,
    name: defaultError,
    logo: defaultError,
    availableFromDate: defaultError,
    availableToDate: defaultError,
    validFromDate: defaultError,
    validToDate: defaultError,
    availableFromTime: defaultError,
    availableToTime: defaultError,
    validFromTime: defaultError,
    validToTime: defaultError,
  }
  const [step1FormErrors, setStep1FormErrors] = useState<ChallengeStep1Errors>(
    defaultStep1FormErrors,
  )

  const handleChallengeNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length <= challengeParameters.nameMaxLength) {
      setChallengeNameLength(event.target.value.length)
      setChallengeName(event.target.value)
    }
  }

  const handlePeriodChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const periodListResponse = await PeriodService.getPeriodList()

        if (periodListResponse.data.periods) {
          setPeriods(periodListResponse.data.periods)
        }
        if (isSuperAdmin(currentUser) || isCentralAdmin(currentUser)) {
          const companyListResponse = await CompanyService.getCompanyList()

          if (companyListResponse.data.companies) {
            const multiSelectOptions: Option[] = []
            companyListResponse.data.companies.forEach((company) =>
              multiSelectOptions.push({
                value: company.companyId,
                label: company.name,
              }),
            )
            setCompanies(multiSelectOptions)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoadingStep1(false)
      }
    }
    fetchData()
  }, [currentUser, t])

  useEffect(() => {
    if (!loadingStep1 && !hasFetchedData.current) {
      // load data if update
      if (challengeDetails) {
        setPeriod(challengeDetails.periodId.toString())
        if (challengeDetails.companies.length > 0) {
          const filteredCompanies: Option[] = []
          companies.forEach((company) => {
            challengeDetails.companies.forEach((el) => {
              if (el.id === company.value) {
                filteredCompanies.push({
                  value: company.value,
                  label: company.label,
                })
              }
            })
          })
          setSelectedCompanies(filteredCompanies)
        }
        setChallengeName(challengeDetails.name)
        if (challengeDetails.logoId) {
          setLogoId(challengeDetails.logoId)
        }
        setAvailableFromDate(
          new Date(challengeDetails.availableFrom.slice(0, 10)),
        )
        setAvailableFromTime(
          new Date(
            new Date().setHours(
              parseInt(challengeDetails.availableFrom.slice(11, 13)),
              parseInt(challengeDetails.availableFrom.slice(14, 16)),
            ),
          ),
        )
        setAvailableToDate(new Date(challengeDetails.availableTo.slice(0, 10)))
        setAvailableToTime(
          new Date(
            new Date().setHours(
              parseInt(challengeDetails.availableTo.slice(11, 13)),
              parseInt(challengeDetails.availableTo.slice(14, 16)),
            ),
          ),
        )
        setValidFromDate(new Date(challengeDetails.validFrom.slice(0, 10)))
        setValidFromTime(
          new Date(
            new Date().setHours(
              parseInt(challengeDetails.validFrom.slice(11, 13)),
              parseInt(challengeDetails.validFrom.slice(14, 16)),
            ),
          ),
        )
        setValidToDate(new Date(challengeDetails.validTo.slice(0, 10)))
        setValidToTime(
          new Date(
            new Date().setHours(
              parseInt(challengeDetails.validTo.slice(11, 13)),
              parseInt(challengeDetails.validTo.slice(14, 16)),
            ),
          ),
        )

        hasFetchedData.current = true
      }
    }
  }, [loadingStep1, challengeDetails, companies])

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  const resetStep1Form = () => {
    setStep1FormErrors(defaultStep1FormErrors)
    setPeriod('')
    setSelectedCompanies([])
    setChallengeName('')
    setLogoId(null)
    setAvailableFromDate(null)
    setAvailableFromTime(new Date(new Date().setHours(0, 0, 0, 0)))
    setAvailableToDate(null)
    setAvailableToTime(new Date(new Date().setHours(23, 59, 59, 0)))
    setValidFromDate(null)
    setValidFromTime(new Date(new Date().setHours(0, 0, 0, 0)))
    setValidToDate(null)
    setValidToTime(new Date(new Date().setHours(23, 59, 59, 0)))
  }

  const saveStep1Form = async () => {
    setStep1FormErrors(defaultStep1FormErrors)
    const step1Errors = defaultStep1FormErrors
    if (challengeName === '') {
      setStep1FormErrors({
        ...step1Errors,
        name: {
          error: true,
          message: t('pages.challenge.step1.errors.nameRequired'),
        },
      })
      return
    } else if (period === '') {
      setStep1FormErrors({
        ...step1Errors,
        period: {
          error: true,
          message: t('pages.challenge.step1.errors.periodRequired'),
        },
      })
      return
    } else if (
      (isSuperAdmin(currentUser) || isCentralAdmin(currentUser)) &&
      selectedCompanies.length === 0
    ) {
      setStep1FormErrors({
        ...step1Errors,
        companies: {
          error: true,
          message: t('pages.challenge.step1.errors.companiesRequired'),
        },
      })
      return
    } else if (
      !availableFromDate ||
      !availableFromTime ||
      availableFromDate.toString() === 'Invalid Date' ||
      availableFromTime.toString() === 'Invalid Date'
    ) {
      setStep1FormErrors({
        ...step1Errors,
        availableFromDate: {
          error: true,
          message: t('pages.challenge.step1.errors.availableFromDateRequired'),
        },
      })
      return
    } else if (
      moment(availableFromDate).isSame(new Date(), 'day') &&
      moment(availableFromTime).isBefore(moment(new Date()).add(1, 'hour'))
    ) {
      setStep1FormErrors({
        ...step1Errors,
        availableFromDate: {
          error: true,
          message: t(
            'pages.challenge.step1.errors.availableDateFromRestrictions',
          ),
        },
      })
      return
    } else if (
      !availableToDate ||
      !availableToTime ||
      availableToDate.toString() === 'Invalid Date' ||
      availableToTime.toString() === 'Invalid Date'
    ) {
      setStep1FormErrors({
        ...step1Errors,
        availableToDate: {
          error: true,
          message: t('pages.challenge.step1.errors.availableToDateRequired'),
        },
      })
      return
    } else if (
      moment(availableToDate).isSame(availableFromDate) &&
      moment(availableToTime).isBefore(availableFromTime)
    ) {
      setStep1FormErrors({
        ...step1Errors,
        availableToDate: {
          error: true,
          message: t('pages.challenge.step1.errors.dateToRestrictions'),
        },
      })
      return
    } else if (
      !validFromDate ||
      !validFromTime ||
      validFromDate.toString() === 'Invalid Date' ||
      validFromTime.toString() === 'Invalid Date'
    ) {
      setStep1FormErrors({
        ...step1Errors,
        validFromDate: {
          error: true,
          message: t('pages.challenge.step1.errors.validFromDateRequired'),
        },
      })
      return
    } else if (
      !validToDate ||
      !validToTime ||
      validToDate.toString() === 'Invalid Date' ||
      validToTime.toString() === 'Invalid Date'
    ) {
      setStep1FormErrors({
        ...step1Errors,
        validToDate: {
          error: true,
          message: t('pages.challenge.step1.errors.validToDateRequired'),
        },
      })
      return
    } else if (
      moment(validToDate).isSame(validFromDate) &&
      moment(validToTime).isBefore(validFromTime)
    ) {
      setStep1FormErrors({
        ...step1Errors,
        validToDate: {
          error: true,
          message: t('pages.challenge.step1.errors.dateToRestrictions'),
        },
      })
      return
    }
    const availableFrom =
      moment(availableFromDate).format('YYYY-MM-DD') +
      ' ' +
      moment(availableFromTime).format('HH:mm:ss')

    const availableTo =
      moment(availableToDate).format('YYYY-MM-DD') +
      ' ' +
      moment(availableToTime).format('HH:mm:ss')

    const validFrom =
      moment(validFromDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validFromTime).format('HH:mm:ss')

    const validTo =
      moment(validToDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validToTime).format('HH:mm:ss')

    const formParams: ChallengeStep1RequestParams = {
      challengeId,
      periodId: parseInt(period),
      name: challengeName,
      logoId: logoId,
      availableFrom,
      availableTo,
      validFrom,
      validTo,
      companies: selectedCompanies.map((company) => {
        return { id: company.value }
      }),
    }

    try {
      setSavingStep1(true)
      const createOrUpdateChallengeStep1Response =
        await ChallengeService.createOrUpdateChallengeStep1(formParams)

      if (createOrUpdateChallengeStep1Response.data.success) {
        onSetChallengeId(createOrUpdateChallengeStep1Response.data.challengeId)
        toast.success(t('messages.success.savedSuccessfully'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSavingStep1(false)
      setRefreshTimer(true)
    }
  }

  return (
    <StepContainer>
      <Stack direction={'row'}>
        <div className="step-line first">
          <div className="step-circle">1</div>
          <div className="step-arrow first"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <div className="step-title">
                {t('pages.challenge.step1.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.challenge.step1.subtitle')}
              </div>
            </Grid>
          </Grid>
          {loadingStep1 && <LoadingSpinner />}
          {!loadingStep1 && (
            <Box
              className="form-style"
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
              noValidate
              autoComplete="off"
            >
              <Grid container sx={{ marginBottom: 4 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <label>{t('pages.challenge.step1.name')}</label>
                    <TextField
                      variant="outlined"
                      fullWidth
                      style={{
                        margin: 0,
                      }}
                      size="small"
                      value={challengeName}
                      onChange={handleChallengeNameChange}
                      error={step1FormErrors.name.error}
                      helperText={
                        step1FormErrors.name.error &&
                        step1FormErrors.name.message
                      }
                    />
                    <div className="character-amount">
                      {challengeNameLength}/{challengeParameters.nameMaxLength}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid spacing={2} container sx={{ marginBottom: 4 }}>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    error={step1FormErrors.period.error}
                  >
                    <label>{t('pages.challenge.step1.period')}</label>
                    <Select
                      id="period-select"
                      value={period}
                      onChange={handlePeriodChange}
                    >
                      {periods.map((period) => (
                        <MenuItem value={period.id} key={period.id}>
                          {period.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {step1FormErrors.period.error && (
                      <FormHelperText>
                        {step1FormErrors.period.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  {(isSuperAdmin(currentUser) ||
                    isCentralAdmin(currentUser)) && (
                    <FormControl
                      fullWidth
                      size="small"
                      error={step1FormErrors.companies.error}
                    >
                      <label>{t('pages.challenge.step1.companies')}</label>
                      <MultiSelect
                        options={companies}
                        value={selectedCompanies}
                        onChange={setSelectedCompanies}
                        labelledBy={t('multiSelect.labelledBy')}
                        overrideStrings={{
                          allItemsAreSelected: t(
                            'multiSelect.allItemsAreSelected',
                          ),
                          clearSearch: t('multiSelect.clearSearch'),
                          noOptions: t('multiSelect.noOptions'),
                          search: t('multiSelect.search'),
                          selectAll: t('multiSelect.selectAll'),
                          selectAllFiltered: t('multiSelect.selectAllFiltered'),
                          selectSomeItems: t('multiSelect.selectSomeItems'),
                        }}
                        valueRenderer={customValueRenderer}
                      />
                      {step1FormErrors.companies.error && (
                        <FormHelperText>
                          {step1FormErrors.companies.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Grid>
              </Grid>

              <Grid spacing={2} container sx={{ marginBottom: 4 }}>
                <Grid className="form-grid2_item" item md={6}>
                  <DateTimePicker
                    label={t('pages.challenge.step1.availableFrom')}
                    date={availableFromDate}
                    time={availableFromTime}
                    onDateChange={setAvailableFromDate}
                    onTimeChange={setAvailableFromTime}
                    minDate={new Date()}
                    maxDate={availableToDate || undefined}
                    error={step1FormErrors.availableFromDate}
                  />
                </Grid>
                <Grid className="form-grid2_item" item md={6}>
                  <DateTimePicker
                    label={t('pages.challenge.step1.availableTo')}
                    date={availableToDate}
                    time={availableToTime}
                    onDateChange={setAvailableToDate}
                    onTimeChange={setAvailableToTime}
                    minDate={availableFromDate || undefined}
                    error={step1FormErrors.availableToDate}
                  />
                </Grid>
                <Grid className="form-grid2_item" item md={6}>
                  <DateTimePicker
                    label={t('pages.challenge.step1.validFrom')}
                    date={validFromDate}
                    time={validFromTime}
                    onDateChange={setValidFromDate}
                    onTimeChange={setValidFromTime}
                    maxDate={validToDate || undefined}
                    error={step1FormErrors.validFromDate}
                  />
                </Grid>
                <Grid className="form-grid2_item" item md={6}>
                  <DateTimePicker
                    label={t('pages.challenge.step1.validTo')}
                    date={validToDate}
                    time={validToTime}
                    onDateChange={setValidToDate}
                    onTimeChange={setValidToTime}
                    minDate={validFromDate || undefined}
                    error={step1FormErrors.validToDate}
                  />
                </Grid>
              </Grid>
              {/* <Grid container sx={{ marginBottom: 4 }}>
                <Grid item xs={12}>
                  <ImageLibrary
                    imageType={ImageTypeCode.ML}
                    selectedImageId={challengeDetails?.logoId || null}
                    setSelectedImageId={(id) => setLogoId(id)}
                  />
                </Grid>
              </Grid> */}
              <Stack
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                width="100%"
                className="buttons-container"
              >
                <Stack spacing={2} direction="row">
                  <SecondaryButton
                    onClick={resetStep1Form}
                    disabled={savingStep1}
                  >
                    {t('common.reset')}
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={saveStep1Form}
                    disabled={
                      savingStep1 ||
                      isSaveDisabled ||
                      (challengeDetails && !challengeDetails?.isEditable)
                    }
                  >
                    {t('common.save')}
                  </PrimaryButton>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
    </StepContainer>
  )
}

export default ChallengeStep1Form
