import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import ImageService from '../../../services/image.service'
import LoadingSpinner from '../../shared/LoadingSpinner'
import { Image, ImageTypeCode } from '../../../store/Image/types'
import ImageThumbnail from './ImageThumbnail'
import { SuggestedImagesContainer, SuggestedImagesTitle } from '../styles'

type SuggestedImagesProps = {
  setSelectedImage: React.Dispatch<React.SetStateAction<Image | null>>
  selectedImage: Image | null
  refresh: boolean
  imageType: ImageTypeCode
}

const SuggestedImages: React.FC<SuggestedImagesProps> = ({
  setSelectedImage,
  selectedImage,
  refresh,
  imageType,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [suggestedImages, setSuggestedImages] = useState<Image[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const imageListResponse = await ImageService.getImageList({
          imageTypeCode: imageType,
          per: 6,
          page: 1,
          search: '',
        })

        if (imageListResponse.data.images) {
          setSuggestedImages(imageListResponse.data.images)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, refresh, imageType])

  return (
    <>
      <SuggestedImagesTitle>
        {t(`imageLibrary.chooseFromSuggested.${imageType}`)}
      </SuggestedImagesTitle>
      <SuggestedImagesContainer>
        {loading && <LoadingSpinner />}
        {!loading &&
          suggestedImages.map((suggestedImage) => (
            <ImageThumbnail
              key={suggestedImage.imageId}
              image={suggestedImage}
              selected={selectedImage?.imageId === suggestedImage.imageId}
              setSelectedImage={setSelectedImage}
            />
          ))}
      </SuggestedImagesContainer>
    </>
  )
}

export default SuggestedImages
