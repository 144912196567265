import React from 'react'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { MultiSelect } from 'react-multi-select-component'
import { isCompanyAdmin } from '../../../../helpers/checkRole'
import { User } from '../../../../store/Auth/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { Option } from '../../../../store/types'
import DateTimePicker from '../../../shared/DateTimePicker'
import moment from 'moment'
import { UserState, UserTypeCode } from '../../../../store/User/types'
import { ApplicationVersion } from '../../../../store/Config/types'

interface UsersToolbarProps {
  clearSearch: () => void
  onChange: (event: { target: { value: string } }) => void
  setSelectedCompanies: (companies: Option[]) => void
  submitSearch: (value: string) => void
  value: string
  companies: Option[]
  selectedCompanies: Option[]
  user: User
  userStates: UserState[]
  filterUserState: (event: SelectChangeEvent) => void
  userStateValue: string
  applicationReleases: ApplicationVersion[]
  filterApplicationRelease: (event: SelectChangeEvent) => void
  applicationReleaseValue: string
  lastLoginDateFrom: Date | null
  lastLoginTimeFrom: Date | null
  lastLoginDateTo: Date | null
  lastLoginTimeTo: Date | null
  setLastLoginDateFrom: React.Dispatch<React.SetStateAction<Date | null>>
  setLastLoginTimeFrom: React.Dispatch<React.SetStateAction<Date | null>>
  setLastLoginDateTo: React.Dispatch<React.SetStateAction<Date | null>>
  setLastLoginTimeTo: React.Dispatch<React.SetStateAction<Date | null>>
  companiesActive: boolean
  handleCompaniesActiveChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  type: UserTypeCode
}

export default function UsersToolbar(props: UsersToolbarProps) {
  const { t } = useTranslation()

  const customValueRenderer = (selected: { label: any }[], _options: any) => {
    return selected.length
      ? t('multiSelect.selectedItems', { length: selected.length })
      : t('multiSelect.select')
  }

  const onSearchKeyDown = (e: { keyCode: number }) => {
    // on enter press
    if (e.keyCode === 13) {
      props.submitSearch(props.value)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
        marginTop: 0,
        alignItems: 'flex-end',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl size="small" sx={{ marginRight: 1 }}>
          <label>{t('pages.users.toolbar.filters.status')}</label>
          <Select
            id="status-select"
            onChange={props.filterUserState}
            value={props.userStateValue}
            defaultValue={props.userStateValue}
          >
            <MenuItem value="all">
              {t('pages.users.toolbar.filters.all')}
            </MenuItem>
            {props.userStates.map((userState) => (
              <MenuItem value={userState.id.toString()} key={userState.id}>
                {t(`pages.users.status.${userState.code}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isCompanyAdmin(props.user) && (
          <FormControl
            size="small"
            sx={{ minWidth: 180, marginRight: 1, zIndex: 6 }}
          >
            {props.type === UserTypeCode.ADM ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.companiesActive}
                    onChange={props.handleCompaniesActiveChange}
                    size="small"
                    sx={{ py: 0 }}
                  />
                }
                label={t('pages.users.toolbar.filters.companies')}
              />
            ) : (
              <label>{t('pages.users.toolbar.filters.companies')}</label>
            )}
            <div
              style={{
                opacity:
                  props.companiesActive || props.type !== UserTypeCode.ADM
                    ? 1
                    : 0.5,
              }}
            >
              <MultiSelect
                options={props.companies}
                value={props.selectedCompanies}
                onChange={props.setSelectedCompanies}
                labelledBy={t('multiSelect.labelledBy')}
                overrideStrings={{
                  allItemsAreSelected: t('multiSelect.allItemsAreSelected'),
                  clearSearch: t('multiSelect.clearSearch'),
                  noOptions: t('multiSelect.noOptions'),
                  search: t('multiSelect.search'),
                  selectAll: t('multiSelect.selectAll'),
                  selectAllFiltered: t('multiSelect.selectAllFiltered'),
                  selectSomeItems: t('multiSelect.selectSomeItems'),
                }}
                valueRenderer={customValueRenderer}
                disabled={
                  !props.companiesActive && props.type === UserTypeCode.ADM
                }
              />
            </div>
          </FormControl>
        )}
        <DateTimePicker
          label={t('pages.users.toolbar.filters.lastLoginDateFrom')}
          date={props.lastLoginDateFrom}
          time={props.lastLoginTimeFrom}
          onDateChange={props.setLastLoginDateFrom}
          onTimeChange={props.setLastLoginTimeFrom}
          maxDate={props.lastLoginDateTo || undefined}
          style={{
            width: 260,
            marginRight: 8,
          }}
        />
        <DateTimePicker
          label={t('pages.users.toolbar.filters.lastLoginDateTo')}
          date={props.lastLoginDateTo}
          time={props.lastLoginTimeTo}
          onDateChange={props.setLastLoginDateTo}
          onTimeChange={props.setLastLoginTimeTo}
          minDate={props.lastLoginDateFrom || undefined}
          maxDate={moment(new Date()).add(1, 'day').toDate()}
          style={{
            width: 260,
            marginRight: 8,
          }}
        />
        {props.type === UserTypeCode.SE && (
          <FormControl size="small" sx={{ marginRight: 1 }}>
            <label>{t('pages.users.toolbar.filters.release')}</label>
            <Select
              id="release-select"
              onChange={props.filterApplicationRelease}
              value={props.applicationReleaseValue}
              defaultValue={props.applicationReleaseValue}
            >
              <MenuItem value="all">
                {t('pages.users.toolbar.filters.all')}
              </MenuItem>
              {props.applicationReleases.map((applicationRelease) => (
                <MenuItem
                  value={applicationRelease.applicationVersionId.toString()}
                  key={applicationRelease.applicationVersionId}
                >
                  {applicationRelease.applicationVersion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <TextField
          variant="outlined"
          size="small"
          value={props.value}
          onChange={props.onChange}
          placeholder={t('pages.users.table.search')}
          onKeyDown={onSearchKeyDown}
          InputProps={{
            style: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            } as React.CSSProperties,
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          inputProps={{ maxLength: 100 }}
        />
        <PrimaryButton
          onClick={() => props.submitSearch(props.value)}
          style={{
            padding: '7px 5px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            height: '40px',
          }}
        >
          {t('common.search')}
        </PrimaryButton>
      </div>
    </div>
  )
}
