import axios from 'axios'
import authHeader from './authHeader'
import {
  ImportParametersResponse,
  PrepareManufacturerParams,
  ImportManufacturerResponse,
  PrepareUserChallengePlanParams,
  ImportUserChallengePlanResponse,
  ImportUserChallengeResultResponse,
  PrepareUserChallengeResultParams,
  PrepareStoreParams,
  ImportStoreResponse,
  PrepareUserPlanParams,
  ImportUserPlanResponse,
  PrepareStorePlanResultParams,
  ImportStorePlanResultResponse,
  ImportStorePlanResponse,
  PrepareStorePlanParams,
  StorePeriodParams,
  ImportStorePeriodResponse,
  PrepareActionUsersParams,
  ImportActionUsersResponse,
  ImportTradeActionResponse,
  TradeActionParams,
  PrepareAssetUsersParams,
  ImportAssetUsersResponse,
  PrepareChallengeSpResultParams,
  ImportChallengeSpResultResponse,
  ImportOrdersResponse,
  PrepareOrdersParams,
  PrepareProductParams,
  ImportProductResponse,
} from '../store/Import/types'

const API_URL = process.env.REACT_APP_API_URL

const getImportParameters = async (
  importName: string,
): Promise<ImportParametersResponse> => {
  return await axios.get(
    API_URL + `/api/admin/import/definition/${importName.toUpperCase()}`,
    {
      headers: authHeader(),
    },
  )
}

const userPlans = async (
  params: PrepareUserPlanParams,
): Promise<ImportUserPlanResponse> => {
  return await axios.post(API_URL + '/api/admin/import/user/plan', params, {
    headers: authHeader(),
  })
}

const storePlans = async (
  params: PrepareStorePlanParams,
): Promise<ImportStorePlanResponse> => {
  return await axios.post(API_URL + '/api/admin/import/store/plan', params, {
    headers: authHeader(),
  })
}

const storePlanResults = async (
  params: PrepareStorePlanResultParams,
): Promise<ImportStorePlanResultResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/store/plan-result',
    params,
    {
      headers: authHeader(),
    },
  )
}

const userChallengePlans = async (
  params: PrepareUserChallengePlanParams,
): Promise<ImportUserChallengePlanResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/challenge/plan',
    params,
    {
      headers: authHeader(),
    },
  )
}

const userChallengeResults = async (
  params: PrepareUserChallengeResultParams,
): Promise<ImportUserChallengeResultResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/challenge/result',
    params,
    {
      headers: authHeader(),
    },
  )
}

const challengeSpResults = async (
  params: PrepareChallengeSpResultParams,
): Promise<ImportChallengeSpResultResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/challenge/unit/result',
    params,
    {
      headers: authHeader(),
    },
  )
}

const prepareManufacturer = async (
  params: PrepareManufacturerParams,
): Promise<ImportManufacturerResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/manufacturer/prepare',
    params,
    {
      headers: authHeader(),
    },
  )
}

const acceptManufacturer = async (): Promise<ImportManufacturerResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/manufacturer/accept',
    {},
    {
      headers: authHeader(),
    },
  )
}

const prepareProduct = async (
  params: PrepareProductParams,
): Promise<ImportProductResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/product/prepare',
    params,
    {
      headers: authHeader(),
    },
  )
}

const acceptProduct = async (): Promise<ImportProductResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/product/accept',
    {},
    {
      headers: authHeader(),
    },
  )
}

const prepareStore = async (
  params: PrepareStoreParams,
): Promise<ImportStoreResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/store/prepare_store',
    params,
    {
      headers: authHeader(),
    },
  )
}

const acceptStore = async (): Promise<ImportStoreResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/store/accept_store',
    {},
    {
      headers: authHeader(),
    },
  )
}

const storePeriods = async (
  params: StorePeriodParams,
): Promise<ImportStorePeriodResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/store/store-period',
    params,
    {
      headers: authHeader(),
    },
  )
}

const prepareActionUsers = async (
  params: PrepareActionUsersParams,
): Promise<ImportActionUsersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/trade-actions/prepare-users',
    params,
    {
      headers: authHeader(),
    },
  )
}

const acceptActionUsers =
  async (params: {}): Promise<ImportActionUsersResponse> => {
    return await axios.post(
      API_URL + '/api/admin/import/trade-actions/accept-users',
      params,
      {
        headers: authHeader(),
      },
    )
  }

const tradeActions = async (
  params: TradeActionParams,
): Promise<ImportTradeActionResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/trade-actions/block',
    params,
    {
      headers: authHeader(),
    },
  )
}

const prepareAssetUsers = async (
  params: PrepareAssetUsersParams,
): Promise<ImportAssetUsersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/user-assets/prepare',
    params,
    {
      headers: authHeader(),
    },
  )
}

const acceptAssetUsers =
  async (params: {}): Promise<ImportAssetUsersResponse> => {
    return await axios.post(
      API_URL + '/api/admin/import/user-assets/accept',
      params,
      {
        headers: authHeader(),
      },
    )
  }

const prepareOrders = async (
  params: PrepareOrdersParams,
): Promise<ImportOrdersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/store/order/prepare',
    params,
    {
      headers: authHeader(),
    },
  )
}

const acceptOrders = async (): Promise<ImportOrdersResponse> => {
  return await axios.post(
    API_URL + '/api/admin/import/store/order/accept',
    {},
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getImportParameters,
  prepareManufacturer,
  acceptManufacturer,
  prepareProduct,
  acceptProduct,
  userChallengePlans,
  userChallengeResults,
  challengeSpResults,
  prepareStore,
  acceptStore,
  userPlans,
  storePlans,
  storePlanResults,
  storePeriods,
  prepareActionUsers,
  acceptActionUsers,
  tradeActions,
  prepareAssetUsers,
  acceptAssetUsers,
  prepareOrders,
  acceptOrders,
}

export default exportedObject
