import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DateTimePicker from '../../../../shared/DateTimePicker'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../../store/types'
import {
  AssetDetails,
  AssetFile,
  AssetType,
  AssetTypeCode,
  BasicInformationErrors,
  CreateOrUpdateAssetParams,
} from '../../../../../store/Edubox/types'
import { QuizContainer } from './styles'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { SelectChangeEvent } from '@mui/material/Select'
import ImageLibrary from '../../../../ImageLibrary/ImageLibrary'
import { ImageTypeCode } from '../../../../../store/Image/types'
import { errorHandler } from '../../../../../helpers/errorHandler'
import EduboxService from '../../../../../services/edubox.service'
import draftToHtml from 'draftjs-to-html'
import FileUploader from '../FileUploader'
import FileList from '../FileList'
import { getBase64 } from '../../../../../helpers/utils'
import moment from 'moment'
import { toast } from 'react-toastify'
import htmlToDraft from 'html-to-draftjs'

const NAME_MAX_LENGTH = 60
const EDITOR_MAX_LENGTH = 1000

type BasicInformationProps = {
  assetId: number | null
  setAssetId: React.Dispatch<React.SetStateAction<number | null>>
  assetDetails?: AssetDetails
}

const BasicInformation: FunctionComponent<BasicInformationProps> = ({
  assetId,
  setAssetId,
  assetDetails,
}) => {
  const { t } = useTranslation()
  const [name, setName] = useState(assetDetails?.name || '')
  const [savingStep, setSavingStep] = useState(false)
  const [visibilitySwitch, setVisibilitySwitch] = useState(
    assetDetails ? (assetDetails.isVisible ? true : false) : true,
  )
  const [mainImageId, setMainImageId] = useState<number | null>(null)
  const [nameLength, setNameLength] = useState(assetDetails?.name.length || 0)
  const [validFromDate, setValidFromDate] = useState<Date | null>(null)
  const [validFromTime, setValidFromTime] = useState<Date | null>(
    new Date(new Date().setHours(0, 0, 0, 0)),
  )
  const [validToDate, setValidToDate] = useState<Date | null>(null)
  const [validToTime, setValidToTime] = useState<Date | null>(
    new Date(new Date().setHours(23, 59, 59, 0)),
  )

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )
  const [descriptionLength, setDescriptionLength] = useState<number>(0)
  const [description, setDescription] = useState<string | null>(null)

  const [materialType, setMaterialType] = useState<string>(
    assetDetails?.assetTypeCode || '0',
  )
  const [videoUrl, setVideoUrl] = useState('')

  const [minutes, setMinutes] = useState<number | null>(0)
  const [seconds, setSeconds] = useState<number | null>(0)
  const [assetTypes, setAssetTypes] = useState<AssetType[]>([])
  const [length, setLength] = useState<number>(assetDetails?.length || 0)
  const [slides, setSlides] = useState<number>(0)
  const [files, setFiles] = useState<AssetFile[]>([])
  const [orientation, setOrientation] = useState<string>(
    assetDetails?.orientation.toLocaleLowerCase() || 'vertical',
  )

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultBasicInformationFormErrors: BasicInformationErrors = {
    name: defaultError,
    validFromDate: defaultError,
    validFromTime: defaultError,
    validToDate: defaultError,
    validToTime: defaultError,
    mainImage: defaultError,
    materialType: defaultError,
    videoUrl: defaultError,
    videoLength: defaultError,
    assetFile: defaultError,
    slidesLength: defaultError,
  }
  const [basicInformationFormErrors, setBasicInformationFormErrors] =
    useState<BasicInformationErrors>(defaultBasicInformationFormErrors)

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= NAME_MAX_LENGTH) {
      setName(event.target.value)
      setNameLength(event.target.value.length)
    }
  }

  const handleMaterialTypeChange = (event: SelectChangeEvent) => {
    setMaterialType(event.target.value as string)
    setLength(0)
    setFiles([])
    setVideoUrl('')
    setSlides(0)
    setMinutes(0)
    setSeconds(0)
  }

  const handleOrientationChange = (event: SelectChangeEvent) => {
    setOrientation(event.target.value as string)
  }

  const handleVideoUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideoUrl(event.target.value)
  }

  const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) >= 0) {
      setMinutes(parseInt(event.target.value))
      setLength(parseInt(event.target.value) * 60 + (seconds || 0))
    } else {
      setMinutes(null)
      setLength(0)
    }
  }
  const handleSecondsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value.length <= 2 &&
      parseInt(event.target.value) >= 0 &&
      parseInt(event.target.value) < 60
    ) {
      setSeconds(parseInt(event.target.value))
      setLength((minutes || 0) * 60 + parseInt(event.target.value))
    } else {
      setSeconds(null)
      setLength(0)
    }
  }

  const handleSlidesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlides(parseInt(event.target.value))
    setLength(parseInt(event.target.value))
  }

  const handleVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVisibilitySwitch(event.target.checked)
  }

  const handleAcceptedFiles = async (
    acceptedFiles: File[] | Blob[] | MediaSource[],
  ) => {
    const filesToAdd: AssetFile[] = []
    for (const acceptedFile of acceptedFiles) {
      const fileContent = await getBase64(acceptedFile as Blob)
      filesToAdd.push({
        contentId: null,
        contentName: (acceptedFile as File).name,
        content: fileContent?.toString().split('base64,')[1] || '',
      })
    }
    setFiles((prevState) => filesToAdd)
  }

  useEffect(() => {
    if (assetDetails) {
      if (assetDetails.description) {
        const blocksFromHTML = htmlToDraft(assetDetails.description)
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )
        setEditorState(EditorState.createWithContent(content))
        setDescription(assetDetails.description)
      }

      setValidFromDate(new Date(assetDetails.assetAvailableFrom.slice(0, 10)))
      setValidFromTime(
        new Date(
          new Date().setHours(
            parseInt(assetDetails.assetAvailableFrom.slice(11, 13)),
            parseInt(assetDetails.assetAvailableFrom.slice(14, 16)),
          ),
        ),
      )
      setValidToDate(new Date(assetDetails.assetAvailableTo.slice(0, 10)))
      setValidToTime(
        new Date(
          new Date().setHours(
            parseInt(assetDetails.assetAvailableTo.slice(11, 13)),
            parseInt(assetDetails.assetAvailableTo.slice(14, 16)),
          ),
        ),
      )

      if (assetDetails.assetTypeCode === AssetTypeCode.VIDEO) {
        setVideoUrl(assetDetails.content || '')
        setMinutes(Math.floor(assetDetails.length / 60))
        setSeconds(assetDetails.length % 60)
      }
      if (assetDetails.assetTypeCode === AssetTypeCode.PDF) {
        setSlides(assetDetails.length)
        setFiles([
          {
            contentId: null,
            contentName: assetDetails.contentName,
            content: null,
          },
        ])
      }
    }
  }, [assetDetails])

  useEffect(() => {
    setDescriptionLength(
      editorState.getCurrentContent().getPlainText('').length,
    )
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  const onEditorStateChange = (currentEditorState: EditorState) => {
    const contentState = currentEditorState.getCurrentContent()
    const oldContent = editorState.getCurrentContent()
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <= EDITOR_MAX_LENGTH
    ) {
      setEditorState(currentEditorState)
    } else {
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      )
      setEditorState(newEditorState)
    }
  }

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        const assetTypesResponse = await EduboxService.getAssetTypeList()

        if (assetTypesResponse.data.assetTypes) {
          setAssetTypes(assetTypesResponse.data.assetTypes)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    fetchAssetTypes()
  }, [t])

  const saveForm = async () => {
    setBasicInformationFormErrors(defaultBasicInformationFormErrors)
    const basicInformationFormErrors = defaultBasicInformationFormErrors

    if (name === '') {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        name: {
          error: true,
          message: t('pages.edubox.basicInformation.form.nameRequired'),
        },
      })
      return
    } else if (
      !validFromDate ||
      !validFromTime ||
      validFromDate.toString() === 'Invalid Date' ||
      validFromTime.toString() === 'Invalid Date'
    ) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        validFromDate: {
          error: true,
          message: t('pages.edubox.basicInformation.form.dateRequired'),
        },
      })
      return
    } else if (
      !validToDate ||
      !validToTime ||
      validToDate.toString() === 'Invalid Date' ||
      validToTime.toString() === 'Invalid Date'
    ) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        validToDate: {
          error: true,
          message: t('pages.edubox.basicInformation.form.dateRequired'),
        },
      })
      return
    } else if (
      moment(validFromDate).isSame(new Date(), 'day') &&
      moment(validFromTime).isBefore(moment(new Date()).add(1, 'hour'))
    ) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        validFromDate: {
          error: true,
          message: t(
            'pages.edubox.basicInformation.form.validFromDateRestrictions',
          ),
        },
      })
      return
    } else if (!mainImageId) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        mainImage: {
          error: true,
          message: t('pages.edubox.basicInformation.form.imageRequired'),
        },
      })
      return
    } else if (materialType === '0') {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        materialType: {
          error: true,
          message: t('pages.edubox.basicInformation.form.materialTypeRequired'),
        },
      })
      return
    } else if (materialType === AssetTypeCode.VIDEO && videoUrl.length === 0) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        videoUrl: {
          error: true,
          message: t('pages.edubox.basicInformation.form.videoUrlRequired'),
        },
      })
      return
    } else if (materialType === AssetTypeCode.VIDEO && length === 0) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        videoLength: {
          error: true,
          message: t('pages.edubox.basicInformation.form.videoLengthRequired'),
        },
      })
      return
    } else if (materialType === AssetTypeCode.PDF && files.length === 0) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        assetFile: {
          error: true,
          message: t('pages.edubox.basicInformation.form.assetFileRequired'),
        },
      })
      return
    } else if (materialType === AssetTypeCode.PDF && length === 0) {
      setBasicInformationFormErrors({
        ...basicInformationFormErrors,
        slidesLength: {
          error: true,
          message: t('pages.edubox.basicInformation.form.slidesLengthRequired'),
        },
      })
      return
    }

    const assetAvailableFrom =
      moment(validFromDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validFromTime).format('HH:mm:ss')

    const assetAvailableTo =
      moment(validToDate).format('YYYY-MM-DD') +
      ' ' +
      moment(validToTime).format('HH:mm:ss')

    const assetTypeId = assetTypes.find(
      (assetType) => assetType.code === materialType,
    )?.id
    let contentName = ''
    let content = null

    if (materialType === AssetTypeCode.PDF) {
      contentName = files[0].contentName
      content = files[0].content || null
    }

    if (materialType === AssetTypeCode.VIDEO) {
      contentName = 'video'
      content = videoUrl
    }

    if (assetTypeId) {
      const formParams: CreateOrUpdateAssetParams = {
        assetId,
        assetTypeId,
        assetAvailableFrom,
        assetAvailableTo,
        contentName,
        content,
        length,
        orientation,
        name,
        description: descriptionLength === 0 ? null : description,
        isVisible: visibilitySwitch,
        imageId: mainImageId,
      }

      try {
        setSavingStep(true)
        const createInformationsResponse =
          await EduboxService.createOrUpdateAsset(formParams)

        if (createInformationsResponse.data.success) {
          setAssetId(createInformationsResponse.data.assetId)
          toast.success(t('messages.success.savedSuccessfully'))
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep(false)
      }
    }
  }

  const clearForm = () => {
    setBasicInformationFormErrors(defaultBasicInformationFormErrors)
    setName('')
    setEditorState(EditorState.createEmpty())
    setDescription(null)
    setMainImageId(null)
    setValidFromDate(null)
    setValidFromTime(new Date(new Date().setHours(0, 0, 0, 0)))
    setValidToDate(null)
    setValidToTime(new Date(new Date().setHours(23, 59, 59, 0)))
    setMaterialType('0')
    setLength(0)
    setFiles([])
    setVideoUrl('')
    setSlides(0)
    setMinutes(0)
    setSeconds(0)
  }

  return (
    <QuizContainer>
      <Stack direction={'row'}>
        <div className="step-line first">
          <div className="step-circle">1</div>
          <div className="step-arrow first"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={7}>
              <div className="step-title">
                {t('pages.edubox.basicInformation.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.edubox.basicInformation.subtitle')}
              </div>
            </Grid>
            <Grid item xs={5} className="switch-button">
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    value={visibilitySwitch}
                    onChange={handleVisibilityChange}
                    color="success"
                    checked={visibilitySwitch}
                  />
                }
                label={
                  <div className="visibility-label-container">
                    <div className="visibility">
                      {visibilitySwitch
                        ? t('pages.edubox.switchActive')
                        : t('pages.edubox.switchNotActive')}
                    </div>
                    <div className="visibility-label">
                      {visibilitySwitch
                        ? t('pages.edubox.deactivate')
                        : t('pages.edubox.activate')}
                    </div>
                  </div>
                }
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Grid container sx={{ maxWidth: 520, marginBottom: 4 }}>
            <Grid item xs={12} sx={{ marginBottom: 4 }}>
              <FormControl fullWidth>
                <label>{t('pages.edubox.basicInformation.form.name')}</label>
                <TextField
                  variant="outlined"
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  error={basicInformationFormErrors.name.error}
                  helperText={
                    basicInformationFormErrors.name.error &&
                    basicInformationFormErrors.name.message
                  }
                />
                <div className="character-amount">
                  {nameLength}/{NAME_MAX_LENGTH}
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label={t('pages.edubox.basicInformation.form.validFrom')}
                date={validFromDate}
                time={validFromTime}
                onDateChange={setValidFromDate}
                onTimeChange={setValidFromTime}
                minDate={new Date()}
                maxDate={validToDate || undefined}
                error={basicInformationFormErrors.validFromDate}
                style={{
                  width: 260,
                  marginRight: 8,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label={t('pages.edubox.basicInformation.form.validTo')}
                date={validToDate}
                time={validToTime}
                onDateChange={setValidToDate}
                onTimeChange={setValidToTime}
                minDate={validFromDate || undefined}
                error={basicInformationFormErrors.validToDate}
                style={{
                  width: 260,
                  marginRight: 8,
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <label>
                {t('pages.edubox.basicInformation.form.description')}
              </label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: ['inline', 'list', 'colorPicker', 'emoji'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                  },
                }}
              />
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <div className="character-amount">
                  {descriptionLength} / {EDITOR_MAX_LENGTH}
                </div>
              </Stack>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <ImageLibrary
                imageType={ImageTypeCode.ATI}
                selectedImageId={assetDetails?.imageId || null}
                setSelectedImageId={setMainImageId}
              />
              {basicInformationFormErrors.mainImage.error && !mainImageId && (
                <FormHelperText error>
                  {basicInformationFormErrors.mainImage.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <FormControl
                size="small"
                sx={{ marginRight: 1 }}
                error={basicInformationFormErrors.materialType.error}
              >
                <label>
                  {t('pages.edubox.basicInformation.form.materialType')}
                </label>
                <Select
                  id="select-asset-type"
                  className="select-asset-type"
                  value={materialType}
                  onChange={handleMaterialTypeChange}
                >
                  <MenuItem value="0" disabled>
                    {t('pages.edubox.basicInformation.form.selectMaterialType')}
                  </MenuItem>
                  {assetTypes.map((assetType) => (
                    <MenuItem value={assetType.code} key={assetType.id}>
                      {t(
                        `pages.edubox.basicInformation.form.${assetType.code}`,
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {basicInformationFormErrors.materialType.error && (
                <FormHelperText error>
                  {basicInformationFormErrors.materialType.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          {materialType === AssetTypeCode.PDF && (
            <Grid container sx={{ marginBottom: 4 }}>
              <Grid item xs={12} sx={{ marginBottom: 2 }}>
                <FileUploader
                  label={t('pages.edubox.fileUploader.dragAndDropFile')}
                  buttonLabel={t('pages.edubox.fileUploader.addFileLabel')}
                  hintLabel={t('pages.edubox.fileUploader.fileHint')}
                  accept={{
                    'application/pdf': [],
                  }}
                  handleAcceptedFiles={handleAcceptedFiles}
                  maxSize={25000000} // 25MB
                  maxFiles={1}
                />
                <FileList files={files} setFiles={setFiles} />
                {basicInformationFormErrors.assetFile.error && (
                  <FormHelperText error>
                    {basicInformationFormErrors.assetFile.message}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <label>
                    {t('pages.edubox.basicInformation.form.slidesNumber')}
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={slides}
                    onChange={handleSlidesChange}
                    type="number"
                    error={basicInformationFormErrors.slidesLength.error}
                    helperText={
                      basicInformationFormErrors.slidesLength.error &&
                      basicInformationFormErrors.slidesLength.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item ml={1}>
                <FormControl size="small" sx={{ marginRight: 1 }}>
                  <label>
                    {t('pages.edubox.basicInformation.form.orientation')}
                  </label>
                  <Select
                    className="select-orientation"
                    value={orientation}
                    defaultValue={orientation}
                    onChange={handleOrientationChange}
                  >
                    <MenuItem value="vertical">
                      {t('pages.edubox.basicInformation.form.vertical')}
                    </MenuItem>
                    <MenuItem value="horizontal">
                      {t('pages.edubox.basicInformation.form.horizontal')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {materialType === AssetTypeCode.VIDEO && (
            <Grid container sx={{ marginBottom: 4 }}>
              <Grid item xs={12} sx={{ marginBottom: 4 }}>
                <FormControl fullWidth>
                  <label>
                    {t('pages.edubox.basicInformation.form.videoUrl')}
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={videoUrl}
                    onChange={handleVideoUrlChange}
                    error={basicInformationFormErrors.videoUrl.error}
                    helperText={
                      basicInformationFormErrors.videoUrl.error &&
                      basicInformationFormErrors.videoUrl.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <label>
                    {t('pages.edubox.basicInformation.form.minutes')}
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={minutes}
                    onChange={handleMinutesChange}
                    type="number"
                    error={basicInformationFormErrors.videoLength.error}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <FormControl fullWidth>
                  <label>
                    {t('pages.edubox.basicInformation.form.seconds')}
                  </label>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={seconds}
                    onChange={handleSecondsChange}
                    type="number"
                    error={basicInformationFormErrors.videoLength.error}
                  />
                </FormControl>
              </Grid>
              <Grid item ml={1}>
                <FormControl size="small" sx={{ marginRight: 1 }}>
                  <label>
                    {t('pages.edubox.basicInformation.form.orientation')}
                  </label>
                  <Select
                    className="select-orientation"
                    value={orientation}
                    defaultValue={orientation}
                    onChange={handleOrientationChange}
                  >
                    <MenuItem value="vertical">
                      {t('pages.edubox.basicInformation.form.vertical')}
                    </MenuItem>
                    <MenuItem value="horizontal">
                      {t('pages.edubox.basicInformation.form.horizontal')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {basicInformationFormErrors.videoLength.error && (
                  <FormHelperText error>
                    {basicInformationFormErrors.videoLength.message}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          )}

          <Divider sx={{ marginBottom: 1 }} />
          <Stack
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
            className="buttons-container"
          >
            <Stack spacing={2} direction="row">
              <SecondaryButton onClick={clearForm} disabled={savingStep}>
                {t('common.reset')}
              </SecondaryButton>
              <PrimaryButton onClick={saveForm} disabled={savingStep}>
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </QuizContainer>
  )
}

export default BasicInformation
