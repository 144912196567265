import React, { useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { ImageTypeCode } from '../../../../../store/Image/types'
// import { FormError } from '../../../../../store/types'
import {
  ChallengeDetails,
  ChallengeParameters,
  // ChallengeStep2Errors,
  ChallengeStep2RequestParams,
} from '../../../../../store/Challenge/types'
import ChallengeService from '../../../../../services/challenge.service'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import { StepContainer } from './styles'
import { grey } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'
import ImageLibrary from '../../../../ImageLibrary/ImageLibrary'
// import { validateEan } from '../../../../../helpers/utils'

type ChallengeStep2FormProps = {
  challengeId: number | null
  challengeDetails?: ChallengeDetails
  challengeParameters: ChallengeParameters
  setIsStep2Saved: React.Dispatch<React.SetStateAction<boolean>>
  isSaveDisabled: boolean
}

const ChallengeStep2Form: React.FunctionComponent<ChallengeStep2FormProps> = ({
  challengeId,
  challengeDetails,
  challengeParameters,
  setIsStep2Saved,
  isSaveDisabled,
}) => {
  const { t } = useTranslation()
  const hasFetchedData = useRef(false)
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  )
  const [description, setDescription] = useState<string | null>(null)
  const [descriptionLength, setDescriptionLength] = useState<number>(0)
  const [imageId, setImageId] = useState<number | null>(null)
  const [savingStep2, setSavingStep2] = useState<boolean>(false)

  // errors
  // const defaultError: FormError = {
  //   error: false,
  //   message: '',
  // }
  // const defaultStep2FormErrors: ChallengeStep2Errors = {
  //   image: defaultError,
  // }
  // const [step2FormErrors, setStep2FormErrors] = useState<ChallengeStep2Errors>(
  //   defaultStep2FormErrors,
  // )

  useEffect(() => {
    setDescriptionLength(
      editorState.getCurrentContent().getPlainText('').length,
    )
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  const onEditorStateChange = (currentEditorState: EditorState) => {
    const contentState = currentEditorState.getCurrentContent()
    const oldContent = editorState.getCurrentContent()
    if (
      contentState === oldContent ||
      contentState.getPlainText().length <=
        challengeParameters.descriptionMaxLength
    ) {
      setEditorState(currentEditorState)
    } else {
      const newEditorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editorState,
          ContentState.createFromText(oldContent.getPlainText()),
          'delete-character',
        ),
      )
      setEditorState(newEditorState)
    }
  }
  const resetStep2Form = () => {
    // setStep2FormErrors(defaultStep2FormErrors)
    setEditorState(EditorState.createEmpty())
    setDescription(null)
    setImageId(null)
  }

  const saveStep2Form = async () => {
    // setStep2FormErrors(defaultStep2FormErrors)
    // const step2Errors = defaultStep2FormErrors

    if (challengeId) {
      const formParams: ChallengeStep2RequestParams = {
        challengeId,
        description,
        imageId: imageId,
      }

      try {
        setSavingStep2(true)
        const createOrUpdateChallengeStep2Response =
          await ChallengeService.updateChallengeStep2(formParams)

        if (createOrUpdateChallengeStep2Response.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          setIsStep2Saved(true)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep2(false)
      }
    }
  }

  useEffect(() => {
    // load data if update
    if (challengeDetails && !hasFetchedData.current) {
      if (challengeDetails.description) {
        const blocksFromHTML = htmlToDraft(challengeDetails.description)
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        )
        setEditorState(EditorState.createWithContent(content))
        setDescription(challengeDetails.description)
      }
      hasFetchedData.current = true
    }
  }, [challengeDetails])

  if (!challengeId) {
    return (
      <StepContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">2</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography variant="subtitle2" fontWeight={'bold'} component="div">
              {t('pages.challenge.step2.title')}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              dangerouslySetInnerHTML={{
                __html: t('pages.challenge.step2.subtitle'),
              }}
            ></Typography>
            <IconButton
              aria-label="delete"
              disabled
              size="large"
              className="add-button"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      </StepContainer>
    )
  }
  return (
    <StepContainer>
      <Stack direction={'row'}>
        <div className="step-line first">
          <div className="step-circle">2</div>
          <div className="step-arrow first"></div>
        </div>
        <Box
          className="form-container"
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={12}>
              <div className="step-title">
                {t('pages.challenge.step2.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.challenge.step2.subtitle')}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <label>{t('pages.challenge.step2.description')}</label>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ['inline', 'list', 'colorPicker', 'emoji'],
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                  }}
                />
                <div className="character-amount">
                  {descriptionLength}/{challengeParameters.descriptionMaxLength}
                </div>
              </FormControl>
            </Grid>
            <Grid item sm={12} mb={2}>
              <ImageLibrary
                imageType={ImageTypeCode.CD}
                selectedImageId={challengeDetails?.imageId || null}
                setSelectedImageId={(id) => setImageId(id)}
              />
            </Grid>
          </Grid>

          <Stack
            className="buttons-container"
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            width="100%"
          >
            <SecondaryButton onClick={resetStep2Form} disabled={savingStep2}>
              {t('common.reset')}
            </SecondaryButton>
            <PrimaryButton
              onClick={saveStep2Form}
              disabled={
                savingStep2 ||
                isSaveDisabled ||
                !challengeId ||
                (challengeDetails && !challengeDetails?.isEditable)
              }
            >
              {t('common.save')}
            </PrimaryButton>
          </Stack>
        </Box>
      </Stack>
    </StepContainer>
  )
}

export default ChallengeStep2Form
