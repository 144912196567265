import { styled } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

const FileUploaderContainer = styled('div')(({ theme }) => ({
  '.file-uploader': {
    transition: 'background-color 0.5s ease',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 70,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colorsPalette.gray.gray3,
    borderRadius: 5,
    fontSize: 14,
    '&.error': {
      backgroundColor: '#CF0000',
      color: '#fff',
      textAlign: 'center',
      '.drag-drop-hint': {
        color: '#fff',
      },
    },
    '.drop-btn': {
      backgroundColor: theme.colorsPalette.orange.dark,
      padding: '5px 10px 5px 10px',
      borderRadius: 5,
      fontWeight: 'bolder',
      cursor: 'pointer',
    },
    '.drag-drop-hint': {
      fontSize: 11,
      marginTop: 10,
      color: theme.colorsPalette.gray.gray4,
      textAlign: 'center',
    },
  },
}))

type FileUploaderProps = {
  file: File | null
  accept: Accept
  maxSize?: number
  handleAcceptedFiles: (files: File[] | Blob[] | MediaSource[]) => void
}

const FileUploader: React.FC<FileUploaderProps> = ({
  file,
  accept,
  maxSize = 100000000,
  handleAcceptedFiles,
}) => {
  const [error, setError] = useState<string | null>(null)

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((file: File | Blob | MediaSource) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      handleAcceptedFiles(acceptedFiles)
    }

    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach((file: any) => {
        setError(file.errors[0].code)
        setTimeout(() => {
          setError(null)
        }, 3000)
      })
    }
  }, [handleAcceptedFiles])

  const { t } = useTranslation()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept,
    maxSize,
  })

  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'file-too-large':
        return t('import.fileUploader.fileTooLarge')
      case 'file-invalid-type':
        return t('import.fileUploader.fileInvalidType')

      default:
        return t('import.fileUploader.generalError')
    }
  }

  return (
    <>
      <FileUploaderContainer>
        <div
          className={error ? 'file-uploader error' : 'file-uploader'}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <div>
              <strong>{t('import.fileUploader.dropHere')}</strong>
            </div>
          ) : error ? (
            <div>
              <div>
                <strong>{getErrorMessage(error)}</strong>
              </div>
              {t('import.fileUploader.hintLabel')}
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              {file && (
                <div>
                  <strong>{file.name}</strong>{' '}
                  <span className="drop-btn">
                    {t('import.fileUploader.changeFileLabel')}
                  </span>
                </div>
              )}
              {!file && (
                <div>
                  <strong>{t('import.fileUploader.dragAndDropFile')}</strong>{' '}
                  {t('import.fileUploader.or')}{' '}
                  <span className="drop-btn">
                    {t('import.fileUploader.addFileLabel')}
                  </span>
                </div>
              )}
              {t('import.fileUploader.hintLabel')}
            </div>
          )}
        </div>
      </FileUploaderContainer>
    </>
  )
}

export default FileUploader
