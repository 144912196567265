import React, { useEffect, useState } from 'react'
import { Button, Dialog, Stack, Typography } from '@mui/material'
import { ReactComponent as CalendarIcon } from '../../../assets/images/game/icons/calendar_icon.svg'
import Image from '../../shared/Image'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../helpers/errorHandler'
import LoadingSpinner from '../../shared/LoadingSpinner'
import GameService from '../../../services/game.service'
import { UserPlanDetails } from '../../../store/Game/types'

type PlanDetailsDialogProps = {
  planId: number | null
  open: boolean
  handleClose: (refresh: boolean) => void
}

const PlanDetailsDialog: React.FunctionComponent<PlanDetailsDialogProps> = ({
  planId,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [planDetails, setPlanDetails] = useState<UserPlanDetails | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (planId) {
          const userPlansDetailsResponse = await GameService.getPlanDetails(
            planId,
          )
          if (userPlansDetailsResponse.data) {
            setPlanDetails(userPlansDetailsResponse.data)
          }
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, planId])
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && planDetails && (
        <Dialog
          maxWidth="xs"
          open={open}
          onClose={() => handleClose(false)}
          container={document.getElementsByClassName('plans-phone-content')[0]}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '340px',
                maxHeight: '500px',
                background: '#F1F1F1',
                padding: '16px',
              },
            },
          }}
        >
          <Image
            imageId={planDetails.imageId}
            style={{
              width: '130px',
              height: '60px',
              objectFit: 'contain',
              margin: '0 auto',
            }}
          />
          <Typography
            variant="h6"
            mt="20px"
            mb="12px"
            fontWeight="700"
            textAlign="center"
          >
            {planDetails.name}
          </Typography>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            color="#808080"
            fontSize="12px"
          >
            <div>
              {t(
                'pages.game.phone.screens.plansScreen.planDetailsDialog.timeScope',
              )}
            </div>
            <div>
              {t(
                'pages.game.phone.screens.plansScreen.planDetailsDialog.periodName',
              )}
            </div>
          </Stack>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            fontSize="12px"
            fontWeight="600"
          >
            <Stack display="flex" flexDirection="row" alignItems="center">
              <CalendarIcon />
              {planDetails.timeScope}
            </Stack>
            <div>{planDetails.periodName}</div>
          </Stack>
          <Typography variant="caption" color="#808080" mt="26px">
            {t(
              'pages.game.phone.screens.plansScreen.planDetailsDialog.description',
            )}
          </Typography>
          {planDetails.description && (
            <>
              <div
                style={{ fontSize: '14px', lineHeight: '21px' }}
                dangerouslySetInnerHTML={{ __html: planDetails.description }}
              ></div>
            </>
          )}
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleClose(false)}
              disableElevation
              size="large"
              style={{
                background: '#FFD748',
                color: '#000',
              }}
            >
              {t('common.ok')}
            </Button>
          </div>
        </Dialog>
      )}
    </>
  )
}

export default PlanDetailsDialog
