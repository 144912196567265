import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import { User } from '../../../../store/User/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import UserService from '../../../../services/user.service'
import { toast } from 'react-toastify'

type UserHideDialogProps = {
  users: Pick<User, 'userId'>[]
  open: boolean
  handleClose: (refresh: boolean) => void
}

const UserHideDialog: React.FunctionComponent<
  UserHideDialogProps
> = ({ users, open, handleClose }) => {
  const { t } = useTranslation()
  const [saving, setSaving] = useState<boolean>(false)

  const onConfirm = async () => {
    try {
      setSaving(true)
      const userHideResponse = await UserService.userHide(
        users.map((user) => ({ id: user.userId })),
      )

      if (userHideResponse.data.success) {
        toast.success(t('messages.success.savedSuccessfully'))
        handleClose(true)
      } else {
        toast.error(t('messages.error.generalError'))
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setSaving(false)
    }
  }

  const renderUserIds = (users: Pick<User, 'userId'>[]) =>
    users.map((user) => user.userId).join(', ')

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      <DialogTitle>
        <Typography variant="body1" fontWeight="bold">
          {t('pages.users.userHideDialog.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            {t('pages.users.userHideDialog.forUsers')}:{' '}
            {renderUserIds(users)}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" justifyContent="space-between" width="100%">
          <SecondaryButton
            onClick={() => {
              handleClose(false)
            }}
            disabled={saving}
          >
            {t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onConfirm} disabled={saving}>
            {t('pages.users.userHideDialog.submit')}
          </PrimaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default UserHideDialog
