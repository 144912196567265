import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ProductsToolbar from '../partials/ProductsToolbar'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import * as XLSX from 'xlsx'
import { errorHandler } from '../../../../helpers/errorHandler'
import { Column, ColumnInstance } from 'react-table'
import TableControlled from '../../../Table/TableControlled'
import ProductService from '../../../../services/product.service'
import { getHiddenColumns } from '../../../../helpers/utils'
import { Option } from '../../../../store/types'
import { User } from '../../../../store/Auth/types'
import { pick } from 'lodash'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import ImportDialog from '../../../shared/ImportDialog'
import { Product } from '../../../../store/Product/types'

type ProductsListProps = {
  path: string
  user: User
}

const ProductsList: React.FC<ProductsListProps> = ({ path, user }) => {
  const { t } = useTranslation()
  const fetchIdRef = useRef(0)
  const tableName = 'products'

  const [loading, setLoading] = useState<boolean>(true)
  const [searchText, setSearchText] = useState<string>('')

  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [skipPageReset, setSkipPageReset] = useState(true)
  const [tableColumns, setTableColumns] = useState<Array<Column<object>>>([])
  const [pageCount, setPageCount] = useState(0)
  const [totalCount, setTotalCount] = useState(0)

  const [filteredProductsList, setFilteredProductsList] = useState<Product[]>(
    [],
  )
  const [searchValue, setSearchValue] = useState<string>('')
  const [manufacturers, setManufacturers] = useState<Option[]>([])
  const [selectedManufacturers, setSelectedManufacturers] = useState<Option[]>(
    [],
  )
  const [categories, setCategories] = useState<Option[]>([])
  const [selectedCategories, setSelectedCategories] = useState<Option[]>([])
  const [groups, setGroups] = useState<Option[]>([])
  const [selectedGroups, setSelectedGroups] = useState<Option[]>([])

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadSortBy, setDownloadSortBy] = useState<string>('')
  const [downloadSortOrder, setDownloadSortOrder] = useState<string>('')
  const [columnsVisibility, setColumnsVisibility] = useState<
    ColumnInstance<object>[]
  >([])
  const [openImportDialog, setImportDialogOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [importName, setImportName] = useState<string>('')
  const [importTitle, setImportTitle] = useState<string>('')

  const handleImportDialogClose = () => {
    setImportDialogOpen(false)
    setRefresh((prevState) => !prevState)
  }

  const handleImportDialogClickOpen = (name: string, title: string) => {
    setImportName(name)
    setImportTitle(title)
    setImportDialogOpen(true)
  }

  const generateTableColumns = useCallback(
    (products: Product[]) => {
      const columns = []
      columns.push(
        {
          Header: t('pages.products.table.centralId').toString(),
          accessor: 'centralId',
          width: 120,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.products.table.name').toString(),
          accessor: 'name',
          width: 300,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.products.table.manufacturerCentralId').toString(),
          accessor: 'manufacturerCentralId',
          width: 140,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.products.table.manufacturerName').toString(),
          accessor: 'manufacturerName',
          width: 180,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'categoryName',
          Header: t('pages.products.table.categoryName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          accessor: 'groupName',
          Header: t('pages.products.table.groupName').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
        {
          Header: t('pages.products.table.isActive').toString(),
          accessor: 'isActive',
          width: 150,
          Cell: (params: any) => (
            <>
              {params.row.original.isActive ? t('common.yes') : t('common.no')}
            </>
          ),
        },
        {
          accessor: 'isVisible',
          Header: t('pages.products.table.isVisible').toString(),
          width: 150,
          Cell: (params: any) => (
            <>
              {params.row.original.isVisible ? t('common.yes') : t('common.no')}
            </>
          ),
        },
        {
          accessor: 'modified',
          Header: t('pages.products.table.modified').toString(),
          width: 150,
          Cell: (params: any) => params.value,
        },
      )

      return columns
    },
    [t],
  )

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy }) => {
      if (refresh) {
      }
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        setTableLoading(true)
        try {
          let sortColumn = ''
          let sortDirection = ''
          if (sortBy.length) {
            sortColumn = sortBy[0].id
            sortDirection = sortBy[0].desc ? 'DESC' : 'ASC'
          }

          setDownloadSortBy(sortColumn)
          setDownloadSortOrder(sortDirection)
          const page = ++pageIndex
          const response = await ProductService.getProducts(
            [],
            [],
            [],
            searchValue,
            sortColumn,
            sortDirection,
            pageSize,
            page,
          )

          if (response.data.products) {
            setTableColumns(generateTableColumns(response.data.products))

            setFilteredProductsList(response.data.products)

            setTotalCount(response.data.totalCount)
            setPageCount(Math.ceil(response.data.totalCount / pageSize))
          }
        } catch (error) {
          errorHandler(error, t)
        } finally {
          setSkipPageReset(true)
          setTableLoading(false)
        }
      }
    },
    [
      t,
      searchValue,
      generateTableColumns,
      // selectedGroups,
      // selectedManufacturers,
      // selectedCategories,
      refresh,
    ],
  )

  useEffect(() => {
    const fetchFiltersData = async () => {
      try {
        setLoading(true)
        // filters data to fetch
        setManufacturers([])
        setGroups([])
        setCategories([])
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchFiltersData()
  }, [t])

  const downloadXLSX = async (name: string) => {
    const fileName = `${name}.xlsx`
    try {
      setIsDownloading(true)

      const response = await ProductService.getProducts(
        [],
        [],
        [],
        searchValue,
        downloadSortBy,
        downloadSortOrder,
        100000,
        1,
      )

      const dataProducts = response.data.products
      if (dataProducts) {
        // remove hidden columns for xlsx
        let visibleColumns = columnsVisibility
          .filter((col) => col.isVisible)
          .map((col2) => col2.id)

        if (visibleColumns.length === 0) {
          visibleColumns = [
            'centralId',
            'name',
            'manufacturerCentralId',
            'manufacturerName',
            'categoryName',
            'groupName',
            'isActive',
            'isVisible',
            'modified',
          ]
        }

        const hiddenColumns = getHiddenColumns(tableName, visibleColumns)
        visibleColumns = visibleColumns.filter(
          (c) => !hiddenColumns.includes(c),
        )

        const filteredProductsList = dataProducts.map(
          (product) => {
            product.isActive = product.isActive
              ? t('common.yes').toString()
              : t('common.no').toString()
            product.isVisible = product.isVisible
              ? t('common.yes').toString()
              : t('common.no').toString()

            return pick(product, visibleColumns)
          },
        )

        const translatedHeaders = {
          centralId: t('excel.products.centralId'),
          name: t('excel.products.name'),
          manufacturerCentralId: t('excel.products.manufacturerCentralId'),
          manufacturerName: t('excel.products.manufacturerName'),
          categoryName: t('excel.products.categoryName'),
          groupName: t('excel.products.groupName'),
          isActive: t('excel.products.isActive'),
          isVisible: t('excel.products.isVisible'),
          modified: t('excel.products.modified'),
        }

        const headers = [
          Object.keys(filteredProductsList[0]).map(
            (key) => (translatedHeaders as any)[key],
          ),
        ]

        //Had to create a new workbook and then add the header
        const ws: XLSX.WorkSheet = XLSX.utils.book_new()
        XLSX.utils.sheet_add_aoa(ws, headers)

        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, filteredProductsList, {
          origin: 'A2',
          skipHeader: true,
        })

        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredStoresData)
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, name)

        XLSX.writeFile(wb, fileName)
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack
            display="flex"
            alignContent="space-between"
            flexDirection="row"
            marginBottom={1}
          >
            <PrimaryButton
              variant="contained"
              onClick={() =>
                handleImportDialogClickOpen(
                  'products',
                  t('pages.products.import'),
                )
              }
            >
              {t('pages.products.import')}
            </PrimaryButton>
            <SecondaryButton
              variant="contained"
              onClick={() => downloadXLSX('products')}
              sx={{ marginLeft: 'auto' }}
              disabled={isDownloading}
            >
              {isDownloading && (
                <CircularProgress
                  style={{ height: 12, width: 12, marginRight: 10 }}
                />
              )}
              {isDownloading
                ? t('common.generatingFile')
                : t('common.downloadTableAsXLSX')}
            </SecondaryButton>
          </Stack>

          <ProductsToolbar
            value={searchText}
            manufacturers={manufacturers}
            selectedManufacturers={selectedManufacturers}
            categories={categories}
            selectedCategories={selectedCategories}
            groups={groups}
            selectedGroups={selectedGroups}
            onChange={(event: { target: { value: string } }) => {
              setSearchText(event.target.value)
            }}
            submitSearch={(searchValue) => {
              setSkipPageReset(false)
              setSearchValue(searchValue)
            }}
            clearSearch={() => {
              setSkipPageReset(false)
              setSearchText('')
              setSearchValue('')
            }}
            setSelectedManufacturers={(manufacturers: Option[]) => {
              setSelectedManufacturers(manufacturers)
              setSkipPageReset(false)
            }}
            setSelectedCategories={(categories: Option[]) => {
              setSelectedCategories(categories)
              setSkipPageReset(false)
            }}
            setSelectedGroups={(groups: Option[]) => {
              setSelectedGroups(groups)
              setSkipPageReset(false)
            }}
            user={user}
          />
          <TableControlled
            name={tableName}
            columns={tableColumns}
            data={filteredProductsList}
            height="calc(100vh - 280px)"
            fetchData={fetchData}
            loading={tableLoading}
            pageIndex={0}
            pageCount={pageCount}
            totalCount={totalCount}
            skipPageReset={skipPageReset}
            columnsVisibility={[]}
            toggleVisibility={setColumnsVisibility}
          />
          <ImportDialog
            open={openImportDialog}
            handleClose={handleImportDialogClose}
            name={importName}
            title={importTitle}
          />
        </>
      )}
    </>
  )
}

export default ProductsList
