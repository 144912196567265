import React, { useCallback, useEffect, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import {
  FileUploaderContainer,
  ImageLibraryContainer,
  ImageLibraryContent,
  ImageLibraryTitle,
  StyledButton,
} from '../styles'
import { Image as ImageAlt, ImageTypeCode } from '../../../store/Image/types'
import { errorHandler } from '../../../helpers/errorHandler'
import ImageService from '../../../services/image.service'
import SuggestedImages from './SuggestedImages'
import AllImages from './AllImages'
import MainImage from './MainImage'
import { toast } from 'react-toastify'
import ImageResizer from '../../ImageResizer/ImageResizer'
import { formatBytes } from '../../../helpers/utils'

type FileUploaderProps = {
  imageType: ImageTypeCode
  accept: Accept
  maxWidth: number
  maxHeight: number
  maxSize: number
  mimeTypes: string[]
  maxNameLength: number
  selectedImageId: number | null
  setSelectedImageId: (id: number | null) => void
  showTitle: boolean
}

const FileUploader: React.FC<FileUploaderProps> = ({
  imageType,
  accept,
  maxWidth,
  maxHeight,
  maxSize,
  mimeTypes,
  maxNameLength,
  selectedImageId,
  setSelectedImageId,
  showTitle,
}) => {
  const [refresh, setRefresh] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const [selectedImage, setSelectedImage] = useState<ImageAlt | null>(null)
  const [showFullLibrary, setShowFullLibrary] = useState<boolean>(false)
  const [imageToResize, setImageToResize] = useState<File>()
  const [resizedImage, setResizedImage] = useState<
    string | File | Blob | ProgressEvent<FileReader>
  >('')

  const onDropHandler = (acceptedFiles: File[] | Blob[] | MediaSource[]) => {
    acceptedFiles.map((file) => {
      const i = new Image()
      const mainImage = file as any

      i.onload = () => {
        let reader = new FileReader()
        reader.readAsDataURL(mainImage)
        reader.onload = async () => {
          if (i.width < 480) {
            setError('wrong-dimensions')
            setTimeout(() => {
              setError(null)
            }, 3000)
          } else {
            setImageToResize(file as File)
          }
        }
      }

      i.src = mainImage.preview
      return i
    })
  }

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.map((file: File | Blob | MediaSource) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      onDropHandler(acceptedFiles)
    }

    if (rejectedFiles.length > 0) {
      rejectedFiles.forEach((file: any) => {
        setError(file.errors[0].code)
        setTimeout(() => {
          setError(null)
        }, 3000)
      })
    }
  }, [])

  const { t } = useTranslation()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept,
    maxSize,
  })

  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'file-too-large':
        return t('imageLibrary.messages.fileTooLarge')
      case 'file-invalid-type':
        return t('imageLibrary.messages.fileInvalidType')
      case 'wrong-dimensions':
        return t('imageLibrary.messages.wrongDimensions')

      default:
        return t('imageLibrary.messages.generalError')
    }
  }

  const renderHintLabel = () => {
    return (
      <div className="drag-drop-hint">
        {t('imageLibrary.imageHint', {
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          maxSize: formatBytes(maxSize || 0),
          mimeTypes: mimeTypes
            .map((mimeType) => mimeType.split('image/')[1].toUpperCase())
            .join(', '),
        })}
      </div>
    )
  }

  useEffect(() => {
    const uploadImage = async (name: string, imageContent: string) => {
      try {
        const setImageResponse = await ImageService.setImage({
          imageId: null,
          name,
          imageTypeCode: imageType,
          imageContent,
        })

        if (setImageResponse.data.success) {
          setRefresh((prevState) => !prevState)
          setSelectedImage({
            imageId: setImageResponse.data.imageId,
            name,
            size: 0,
          })
          toast.success(t('imageLibrary.messages.imageUploaded'))
          setImageToResize(undefined)
          setResizedImage('')
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }

    if (resizedImage !== '' && imageToResize) {
      uploadImage(
        imageToResize.name.substring(0, maxNameLength),
        (resizedImage as string).split('base64,')[1],
      )
    }
  }, [resizedImage, imageToResize, maxNameLength, imageType, t])

  useEffect(() => {
    setSelectedImageId(selectedImage?.imageId || null)
  }, [selectedImage, setSelectedImageId])

  useEffect(() => {
    if (selectedImageId) {
      setSelectedImage({
        imageId: selectedImageId,
        name: '',
        size: 0,
      })
    } else {
      setSelectedImage(null)
    }
  }, [selectedImageId])

  return (
    <>
      {showTitle && (
        <ImageLibraryTitle>
          {t(`imageLibrary.title.${imageType}`)}
        </ImageLibraryTitle>
      )}
      <ImageLibraryContainer>
        <ImageLibraryContent>
          <MainImage
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            setRefresh={(refresh) => {
              if (refresh) {
                setRefresh((prevState) => !prevState)
              }
            }}
            imageType={imageType}
          />
          {!showFullLibrary && (
            <SuggestedImages
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              refresh={refresh}
              imageType={imageType}
            />
          )}
          {showFullLibrary && (
            <AllImages
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              refresh={refresh}
              imageType={imageType}
            />
          )}
          <StyledButton
            onClick={() => setShowFullLibrary((prevState) => !prevState)}
          >
            {showFullLibrary
              ? t('imageLibrary.buttons.close')
              : t('imageLibrary.buttons.browse')}
          </StyledButton>
        </ImageLibraryContent>
        <FileUploaderContainer>
          <div
            className={error ? 'file-uploader error' : 'file-uploader'}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div>
                <strong>{t('imageLibrary.dropHere')}</strong>
              </div>
            ) : error ? (
              <div>
                <div>
                  <strong>{getErrorMessage(error)}</strong>
                </div>
                {renderHintLabel()}
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <div>
                  <strong>{t('imageLibrary.dragAndDropFile')}</strong>{' '}
                  {t('imageLibrary.or')}{' '}
                  <span className="drop-btn">
                    {t('imageLibrary.addFileLabel')}
                  </span>
                </div>
                {renderHintLabel()}
              </div>
            )}
          </div>
        </FileUploaderContainer>
      </ImageLibraryContainer>
      {imageToResize && resizedImage === '' && (
        <ImageResizer
          imageToResize={imageToResize}
          onImageResized={(croppedImage) => setResizedImage(croppedImage)}
          compressFormat={ImageTypeCode.ML ? 'PNG' : 'JPEG'}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
        />
      )}
    </>
  )
}

export default FileUploader
