import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'

type PlotDialogProps = {
  open: boolean
  handleClose: (refresh: boolean) => void
  image: string | null
  title: string | null
  content: string | null
}

const PlotDialog: React.FunctionComponent<PlotDialogProps> = ({
  open,
  handleClose,
  image,
  title,
  content,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={() => handleClose(false)}
    >
      <DialogContent>
        <DialogContentText color="#000" textAlign="center">
          {image && (
            <img
              src={image}
              alt=""
              style={{ maxHeight: '200px', maxWidth: '100%' }}
            />
          )}
          {title && <h4>{title}</h4>}
          {content && (
            <div
              style={{ fontSize: '14px', lineHeight: '24px' }}
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          border: '1px solid #DCDCDC',
        }}
      >
        <Stack flexDirection="row" justifyContent="center" width="100%">
          <SecondaryButton onClick={() => handleClose(false)}>
            {t('common.ok')}
          </SecondaryButton>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default PlotDialog
