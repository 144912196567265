import React, { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

type FileUploaderProps = {
  label: string
  buttonLabel: string
  hintLabel: string
  accept?: Accept
  maxFiles?: number
  maxSize?: number
  handleAcceptedFiles: (files: File[] | Blob[] | MediaSource[]) => void
}

const FileUploader: React.FC<FileUploaderProps> = ({
  label,
  buttonLabel,
  hintLabel,
  accept,
  maxFiles,
  maxSize,
  handleAcceptedFiles,
}) => {
  // const [files, setFiles] = useState([])
  const [error, setError] = useState<string | null>(null)
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        // setFiles(
        acceptedFiles.map((file: File | Blob | MediaSource) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        )
        // )
        handleAcceptedFiles(acceptedFiles)
      }

      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file: any) => {
          setError(file.errors[0].code)
          setTimeout(() => {
            setError(null)
          }, 3000)
        })
      }
    },
    [handleAcceptedFiles],
  )

  const { t } = useTranslation()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
  })

  const getErrorMessage = (errorCode: string) => {
    switch (errorCode) {
      case 'file-too-large':
        return 'Plik jest zbyt duży'
      case 'file-invalid-type':
        return 'Niewłaściwy typ pliku'

      default:
        return 'Coś poszło nie tak...'
    }
  }

  return (
    <div>
      <div
        className={error ? 'files-uploader error' : 'files-uploader'}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <div>
            <strong>{t('pages.edubox.fileUploader.dropHere')}</strong>
          </div>
        ) : error ? (
          <div>
            <div>
              <strong>{getErrorMessage(error)}</strong>
            </div>
            <div className="drag-drop-hint">{hintLabel}</div>
          </div>
        ) : (
          <div>
            <div>
              <strong>{label}</strong> {t('pages.edubox.fileUploader.or')}{' '}
              <span className="drop-btn">{buttonLabel}</span>
            </div>
            <div className="drag-drop-hint">{hintLabel}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FileUploader
