import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, ImageContent } from '../../../store/Image/types'
import { errorHandler } from '../../../helpers/errorHandler'
import ImageService from '../../../services/image.service'
import LoadingSpinner from '../../shared/LoadingSpinner'
import {
  ImageThumbnailContainer,
  ImageThumbnailContent,
  ImageThumbnailName,
} from '../styles'

type ImageThumbnailProps = {
  image: Image
  setSelectedImage: React.Dispatch<React.SetStateAction<Image | null>>
  selected: boolean
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
  image,
  setSelectedImage,
  selected = false,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadedImage, setLoadedImage] = useState<ImageContent | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const imageContentResponse = await ImageService.getImageContent(
          image.imageId,
        )

        if (imageContentResponse.data) {
          setLoadedImage(imageContentResponse.data)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [t, image.imageId])

  return (
    <ImageThumbnailContainer onClick={() => setSelectedImage(image)}>
      <ImageThumbnailContent selected={selected}>
        {loading && <LoadingSpinner />}
        {!loading && loadedImage && (
          <img
            src={`data:${loadedImage.imageMimeType};base64,${loadedImage.imageContent}`}
            alt=""
            style={{
              width: 'calc(100% - 4px)',
              height: 'calc(100% - 4px)',
              objectFit: 'contain',
            }}
          />
        )}
      </ImageThumbnailContent>
      <ImageThumbnailName>{image.name}</ImageThumbnailName>
    </ImageThumbnailContainer>
  )
}

export default ImageThumbnail
