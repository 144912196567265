import { grey } from '@mui/material/colors'
import React, { useState } from 'react'
import InlineEdit from '../../../shared/InlineEdit'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AssetFile } from '../../../../store/Edubox/types'

type FileListProps = {
  files: AssetFile[]
  setFiles: React.Dispatch<React.SetStateAction<AssetFile[]>>
}

const FileList: React.FC<FileListProps> = ({ files, setFiles }) => {
  const { t } = useTranslation()
  const [fileKey, setFileKey] = React.useState(0)
  const [removingFile, setRemovingFile] = React.useState<boolean>(false)

  if (files.length === 0) {
    return <></>
  }

  return (
    <div>
      <div
        style={{
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14px',
          color: grey[800],
          marginTop: 10,
        }}
      >
        {t('pages.edubox.basicInformation.form.fileNameInApp')}
      </div>
      {files.map((file, i) => (
        <div
          key={`file-${i}-${fileKey}-${file.contentName}`}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <div style={{ width: '100%' }}>
            <FileNameEdit
              fileName={file.contentName}
              setFileName={(name) => {
                files[i].contentName = name
                setFiles(files)
              }}
            />
          </div>
          <IconButton
            size="small"
            disabled={removingFile}
            onClick={() => {
              setRemovingFile(false)
              files.splice(i, 1)
              setFiles(files)
              setFileKey((key) => key + 1)
            }}
          >
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </div>
      ))}
    </div>
  )
}

type FileNameEditProps = {
  fileName: string
  setFileName: (fileName: string) => void
}

const FileNameEdit: React.FC<FileNameEditProps> = ({
  fileName,
  setFileName,
}) => {
  const [name, setName] = useState<string>(fileName)

  return (
    <InlineEdit
      value={name}
      setValue={(name) => {
        setName(name)
        setFileName(name)
      }}
      maxLength={50}
      minLength={1}
    />
  )
}

export default FileList
