import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Typography,
  FormControl,
  TextField,
  styled,
} from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import LoadingSpinner from '../../../shared/LoadingSpinner'
import { errorHandler } from '../../../../helpers/errorHandler'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { FormError } from '../../../../store/types'
import PlanService from '../../../../services/plan.service'
import { toast } from 'react-toastify'
import Box from '@mui/material/Box'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'

type PlanForecastProps = {
  plans: { id: number }[]
  open: boolean
  handleClose: (refresh: boolean) => void
}
type PlanForecastErrors = {
  timeExpiration: FormError
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  marginTop: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.colorsPalette.gray.gray2,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.colorsPalette.orange.medium,
  },
}))

const PlanForecastDialog: React.FunctionComponent<PlanForecastProps> = ({
  plans,
  open,
  handleClose,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [timeExpiration, setTimeExpiration] = useState<string>('')

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultFormErrors: PlanForecastErrors = {
    timeExpiration: defaultError,
  }
  const [formErrors, setFormErrors] =
    useState<PlanForecastErrors>(defaultFormErrors)

  const handleTimeExpirationChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (parseFloat(event.target.value) < 0) {
      setTimeExpiration('0')
    } else if (parseFloat(event.target.value) > 100) {
      setTimeExpiration('100')
    } else {
      setTimeExpiration(event.target.value.replace(/,/, '.'))
    }
  }

  const onSubmit = async () => {
    const plansForecastErrors = defaultFormErrors
    const re = /[A-z!@#$%/^(/*)]/
    if (re.test(timeExpiration)) {
      setFormErrors({
        ...plansForecastErrors,
        timeExpiration: {
          error: true,
          message: 'Nieprawidłowa wartość',
        },
      })
    } else {
      try {
        setSaving(true)
        setLoading(true)
        const plansForecast = await PlanService.setPlansForecast(
          plans,
          parseFloat(timeExpiration),
        )
        if (plansForecast.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          handleClose(true)
        }
      } catch (error) {
        errorHandler(error, t)
        toast.error(t('messages.error.generalError'))
      } finally {
        setLoading(false)
        setSaving(false)
      }
    }
  }

  const renderPlanIds = (plans: { id: number }[]) =>
    plans.map((plan) => plan.id).join(', ')

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return
        handleClose(false)
      }}
    >
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <DialogTitle>
            <Typography variant="body1" fontWeight="bold">
              {t('pages.plans.planForecastDialog.title')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1">
                {t('pages.plans.planForecastDialog.forPlans', {
                  length: plans.length,
                })}
                : {renderPlanIds(plans)}
              </Typography>
              <FormControl fullWidth margin="dense">
                <label>{t('pages.plans.planForecastDialog.formLabel')}</label>
                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  name="title"
                  value={timeExpiration || ''}
                  onChange={handleTimeExpirationChange}
                  inputProps={{ maxLength: 50 }}
                  error={formErrors.timeExpiration.error}
                  helperText={
                    formErrors.timeExpiration.error &&
                    formErrors.timeExpiration.message
                  }
                />
              </FormControl>
              <Box sx={{ width: '100%' }}>
                <BorderLinearProgress
                  variant="determinate"
                  className="forecast-progressbar"
                  sx={{ backgroudColor: 'pink' }}
                  value={timeExpiration !== '' ? parseFloat(timeExpiration) : 0}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <SecondaryButton
                onClick={() => {
                  handleClose(false)
                }}
              >
                {t('common.cancel')}
              </SecondaryButton>
              <PrimaryButton
                disabled={saving || loading}
                onClick={() => {
                  onSubmit()
                }}
              >
                {t('pages.plans.planForecastDialog.buttonLabel')}
              </PrimaryButton>
            </Stack>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default PlanForecastDialog
