import React, { useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { checkAuth } from '../../../../helpers/checkAuth'
import { Alert, AlertColor, Grid, Stack, Typography } from '@mui/material'
import { User } from '../../../../store/Auth/types'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../styles/Buttons/SecondaryButton'
import * as XLSX from 'xlsx'
import { StoresImportData } from '../../../../store/Store/types'
import PrimaryButton from '../../../../styles/Buttons/PrimaryButton'
import { errorHandler } from '../../../../helpers/errorHandler'
import StoreService from '../../../../services/store.service'

const { read, utils } = XLSX

const Input = styled('input')({
  display: 'none',
})

type StoresImportProps = {
  user: User
}

const StoresImport: React.FC<StoresImportProps> = ({ user }) => {
  const { t } = useTranslation()
  const [dataObj, setDataObj] = useState<StoresImportData[]>([])
  const [file, setFile] = useState<File | null>(null)
  const uploadInputRef = useRef<HTMLInputElement>(null)
  const [savingImport, setSavingImport] = useState<boolean>(false)

  const [acceptMessage, setAcceptMessage] = useState<{
    type: AlertColor
    message: string
  } | null>(null)
  const [updatedStores, setUpdatedStores] = useState<
    { storeCentralId: string }[]
  >([])
  const [addedStores, setAddedStores] = useState<{ storeCentralId: string }[]>(
    [],
  )

  useEffect(() => {
    if (user) {
      checkAuth(user.token)
    }
  }, [user])

  if (!user) {
    return <Redirect to="/login" />
  }

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setAcceptMessage(null)
    setUpdatedStores([])
    setAddedStores([])

    if (e && e.target && e.target.files && e.target.files.length > 0) {
      var files = e.target.files,
        f = files[0]
      setFile(f)
      var reader = new FileReader()
      reader.onload = function (e) {
        if (e && e.target) {
          var data = e.target.result
          let readedData = read(data, { type: 'binary', codepage: 65001 })
          const wsname = readedData.SheetNames[0]
          const ws = readedData.Sheets[wsname]

          /* Convert array to json*/
          const dataParse = utils.sheet_to_json<StoresImportData>(ws, {
            blankrows: false,
            defval: null,
            raw: false,
            header: [
              'centralId', // A
              'B', // B
              'companyInternalId', // C
              'internalId', // D
              'storeName', // E
              'storePostalCode', // F
              'storeCity', // G
              'storeStreet', // H
              'userInternalId', // I
              'J', // J
              'storeNip', // K
              // 'storeHomeNumber',
            ],
          })
          const matchedData: StoresImportData[] = dataParse.map((row) => {
            return {
              centralId: row.centralId,
              companyInternalId: row.companyInternalId,
              internalId: row.internalId,
              storeName: row.storeName,
              storePostalCode: row.storePostalCode,
              storeCity: row.storeCity,
              storeStreet: row.storeStreet,
              userInternalId: row.userInternalId,
              storeNip: row.storeNip,
              storeHomeNumber: null,
            }
          })
          matchedData.shift() // remove first el from arr
          setDataObj(matchedData)
        }
      }
      reader.readAsBinaryString(f)
      e.target.value = ''
    }
  }

  const handleCancelImport = () => {
    setFile(null)
    setDataObj([])
  }

  const saveImport = async () => {
    try {
      setSavingImport(true)
      const importResponse = await StoreService.importStores(dataObj)

      if (importResponse.data.success) {
        if (
          (importResponse.data.addedStoresAmount ||
            importResponse.data.addedStoresAmount === 0) &&
          (importResponse.data.updatedStoresAmount ||
            importResponse.data.updatedStoresAmount === 0)
        ) {
          const addedStoresAmount = importResponse.data.addedStoresAmount
          const updatedStoresAmount = importResponse.data.updatedStoresAmount

          setAcceptMessage({
            type: 'success',
            message: t('pages.stores.import.importSuccess', {
              addedStoresAmount: addedStoresAmount.toLocaleString(),
              updatedStoresAmount: updatedStoresAmount.toLocaleString(),
            }),
          })
        }
        if (importResponse.data.updatedStores) {
          setUpdatedStores(
            importResponse.data.updatedStores.map((store) => {
              return {
                storeCentralId: store,
              }
            }),
          )
        }
        if (importResponse.data.addedStores) {
          setAddedStores(
            importResponse.data.addedStores.map((store) => {
              return {
                storeCentralId: store,
              }
            }),
          )
        }
      } else {
        setAcceptMessage({
          type: 'error',
          message:
            importResponse.data.message || t('messages.error.generalError'),
        })
      }
    } catch (error) {
      errorHandler(error, t)
    } finally {
      setFile(null)
      setSavingImport(false)
    }
  }

  const downloadXLSX = (data: any, name: string) => {
    const fileName = `${name}.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, name)

    XLSX.writeFile(wb, fileName)
  }

  return (
    <div style={{ padding: '0 20px' }}>
      <Grid className="challenges-main-grid" container spacing={2}>
        <Grid className="challenges-main-grid_item" item md={12}>
          <Typography variant="h5" gutterBottom component="div">
            {t('pages.stores.import.title')}
          </Typography>
          <Input
            ref={uploadInputRef}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
            id="contained-button-file"
            type="file"
            onChange={onFileChange}
          />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <label htmlFor="contained-button-file">
              <SecondaryButton
                onClick={() =>
                  uploadInputRef.current && uploadInputRef.current.click()
                }
                size="small"
                style={{ padding: '5px 10px' }}
              >
                {file
                  ? t('pages.stores.import.changeFile')
                  : t('pages.stores.import.selectFile')}
              </SecondaryButton>
            </label>
            {file && (
              <Typography variant="caption" ml={1}>
                {file.name}
              </Typography>
            )}
          </Stack>
          <Stack
            className="buttons-container"
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            width="100%"
            marginTop={2}
          >
            <SecondaryButton
              onClick={handleCancelImport}
              disabled={savingImport || !file}
            >
              {t('common.cancel')}
            </SecondaryButton>
            <PrimaryButton
              onClick={saveImport}
              disabled={savingImport || !file}
            >
              {t('common.import')}
            </PrimaryButton>
          </Stack>
          {acceptMessage && (
            <Alert
              severity={acceptMessage.type}
              sx={{ my: 2 }}
              style={{ whiteSpace: 'pre-line' }}
            >
              {acceptMessage.message}
              {addedStores.length > 0 && (
                <SecondaryButton
                  onClick={() => downloadXLSX(addedStores, 'dodane')}
                  size="small"
                  style={{ display: 'block', marginTop: '10px' }}
                >
                  {t('pages.stores.import.downloadAddedStores')}
                </SecondaryButton>
              )}
              {updatedStores.length > 0 && (
                <SecondaryButton
                  onClick={() => downloadXLSX(updatedStores, 'zaktualizowane')}
                  size="small"
                  style={{ display: 'block', marginTop: '10px' }}
                >
                  {t('pages.stores.import.downloadUpdatedStores')}
                </SecondaryButton>
              )}
            </Alert>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default StoresImport
