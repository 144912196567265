import { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'

import { Grid } from '@mui/material'
import EduboxList from './list/EduboxList'
import QuizCreate from './create/QuizCreate'
import QuizRankingList from './ranking/QuizRankingList'
import QuizUpdate from './update/QuizUpdate'
import QuizShow from './show/QuizShow'

const Edubox = () => {
  let { path } = useRouteMatch()
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: '0 20px 0 20px',
            }}
          >
            <Switch>
              <Switch>
                <Route exact path={path}>
                  <EduboxList path={path} />
                </Route>
                <Route exact path={`${path}/create`}>
                  <QuizCreate />
                </Route>
                <Route exact path={`${path}/ranking/:quizId`}>
                  <QuizRankingList path={path} />
                </Route>
                <Route exact path={`${path}/update/:id`}>
                  <QuizUpdate />
                </Route>
                <Route exact path={`${path}/show/:id`}>
                  <QuizShow />
                </Route>
              </Switch>
            </Switch>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Edubox
