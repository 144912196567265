import React from 'react'
import {
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  TextField,
} from '@mui/material'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import { useTranslation } from 'react-i18next'
import { ChallengeStep4Points } from '../../../../store/Challenge/types'
import AmateurImg from '../../../../assets/images/other/amateur.png'
import ExpertImg from '../../../../assets/images/other/expert.png'
import MasterImg from '../../../../assets/images/other/master.png'
import {
  isEpPointsVisible,
  isVpPointsVisible,
  isXpPointsVisible,
} from '../../../../helpers/environment'

type ChallengeLevelFormProps = {
  level: [string, boolean]
  points: ChallengeStep4Points
  penaltyVisibility: boolean
  handleLevelChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleLevelPointsChange: (
    levelName: string,
    points: ChallengeStep4Points,
  ) => void
}

const ChallengeLevelForm: React.FunctionComponent<ChallengeLevelFormProps> = ({
  level,
  points,
  penaltyVisibility,
  handleLevelChange,
  handleLevelPointsChange,
}) => {
  const { t } = useTranslation()
  return (
    <Grid container>
      <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={level[1]}
              onChange={handleLevelChange}
              name={level[0]}
            />
          }
          label={
            <>
              {level[0] === 'amateur' && (
                <>
                  <img style={{ width: 130 }} src={AmateurImg} alt="" />
                </>
              )}
              {level[0] === 'expert' && (
                <>
                  <img style={{ width: 130 }} src={ExpertImg} alt="" />
                </>
              )}
              {level[0] === 'master' && (
                <>
                  <img style={{ width: 130 }} src={MasterImg} alt="" />
                </>
              )}
            </>
          }
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container marginBottom={2}>
          <Grid container>
            <Grid
              item
              xs={1}
              sx={{ display: 'flex', alignItems: 'center', paddingTop: 3 }}
            >
              <ThumbUpOffAltIcon />
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={1}>
                {isXpPointsVisible() && (
                  <Grid item xs={4}>
                    <FormControl>
                      <label>{t('pages.challenge.step4.xp')}</label>
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        disabled={!level[1]}
                        name="xpPlus"
                        value={points.xpPlus}
                        onChange={(event) =>
                          handleLevelPointsChange(level[0], {
                            ...points,
                            [event.target.name]: Math.abs(
                              parseInt(event.target.value),
                            ),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
                {isVpPointsVisible() && (
                  <Grid item xs={4}>
                    <FormControl>
                      <label>{t('pages.challenge.step4.vp')}</label>
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        disabled={!level[1]}
                        name="vpPlus"
                        value={points.vpPlus}
                        onChange={(event) =>
                          handleLevelPointsChange(level[0], {
                            ...points,
                            [event.target.name]: Math.abs(
                              parseInt(event.target.value),
                            ),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
                {isEpPointsVisible() && (
                  <Grid item xs={4}>
                    <FormControl>
                      <label>{t('pages.challenge.step4.ep')}</label>
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        disabled={!level[1]}
                        name="epPlus"
                        value={points.epPlus}
                        onChange={(event) =>
                          handleLevelPointsChange(level[0], {
                            ...points,
                            [event.target.name]: Math.abs(
                              parseInt(event.target.value),
                            ),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {penaltyVisibility && (
            <Grid container>
              <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <ThumbDownOffAltIcon color="error" />
              </Grid>
              <Grid item xs={11}>
                <Grid container spacing={1}>
                  {isXpPointsVisible() && (
                    <Grid item xs={4}>
                      <FormControl>
                        <TextField
                          type="number"
                          variant="outlined"
                          size="small"
                          error
                          disabled={!level[1]}
                          name="xpMinus"
                          value={points.xpMinus}
                          onChange={(event) =>
                            handleLevelPointsChange(level[0], {
                              ...points,
                              [event.target.name]: Math.abs(
                                parseInt(event.target.value),
                              ),
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {isVpPointsVisible() && (
                    <Grid item xs={4}>
                      <FormControl>
                        <TextField
                          type="number"
                          variant="outlined"
                          size="small"
                          error
                          disabled={!level[1]}
                          name="vpMinus"
                          value={points.vpMinus}
                          onChange={(event) =>
                            handleLevelPointsChange(level[0], {
                              ...points,
                              [event.target.name]: Math.abs(
                                parseInt(event.target.value),
                              ),
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {isEpPointsVisible() && (
                    <Grid item xs={4}>
                      <FormControl>
                        <TextField
                          type="number"
                          variant="outlined"
                          size="small"
                          error
                          disabled={!level[1]}
                          name="epMinus"
                          value={points.epMinus}
                          onChange={(event) =>
                            handleLevelPointsChange(level[0], {
                              ...points,
                              [event.target.name]: Math.abs(
                                parseInt(event.target.value),
                              ),
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ChallengeLevelForm
