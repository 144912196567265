import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import { FormError } from '../../../../../store/types'
import {
  ChallengeDetails,
  ChallengeLevels,
  ChallengeStep3Errors,
  ChallengeStep3LevelPoints,
  ChallengeStep4RequestParams,
} from '../../../../../store/Challenge/types'
import ChallengeService from '../../../../../services/challenge.service'
import PrimaryButton from '../../../../../styles/Buttons/PrimaryButton'
import SecondaryButton from '../../../../../styles/Buttons/SecondaryButton'
import ChallengeLevelForm from '../ChallengeLevelForm'
import { useTranslation } from 'react-i18next'
import { errorHandler } from '../../../../../helpers/errorHandler'
import { toast } from 'react-toastify'
import { StepContainer } from './styles'
import { grey } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'

type ChallengeStep4FormProps = {
  challengeId: number | null
  challengeUnitId: number | null
  challengeDetails?: ChallengeDetails
  isStep3Saved: boolean
  setIsStep4Saved: React.Dispatch<React.SetStateAction<boolean>>
  setChallengeLevels: React.Dispatch<
    React.SetStateAction<ChallengeLevels | null>
  >
  isSaveDisabled: boolean
}

const ChallengeStep4Form: React.FunctionComponent<ChallengeStep4FormProps> = ({
  challengeId,
  challengeUnitId,
  challengeDetails,
  isStep3Saved,
  setIsStep4Saved,
  setChallengeLevels,
  isSaveDisabled,
}) => {
  const { t } = useTranslation()
  const [penaltyVisibilitySwitch, setPenaltyVisibilitySwitch] =
    useState<boolean>(true)
  const [savingStep4, setSavingStep4] = useState<boolean>(false)
  const [levels, setLevels] = useState<ChallengeLevels>({
    amateur: false,
    expert: false,
    master: false,
  })

  const defaultPoints = {
    xpMinus: 0,
    vpMinus: 0,
    epMinus: 0,
    xpPlus: 0,
    vpPlus: 0,
    epPlus: 0,
  }
  const [points, setPoints] = useState<ChallengeStep3LevelPoints>({
    amateur: defaultPoints,
    expert: defaultPoints,
    master: defaultPoints,
  })

  // errors
  const defaultError: FormError = {
    error: false,
    message: '',
  }
  const defaultStep4FormErrors: ChallengeStep3Errors = {
    level: defaultError,
    unit: defaultError,
  }
  const [step4FormErrors, setStep4FormErrors] = useState<ChallengeStep3Errors>(
    defaultStep4FormErrors,
  )

  const handlePenaltyVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPenaltyVisibilitySwitch(event.target.checked)
  }
  const handleLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLevels({
      ...levels,
      [event.target.name]: event.target.checked,
    })
    setChallengeLevels({
      ...levels,
      [event.target.name]: event.target.checked,
    })
  }

  const resetStep4Form = () => {
    setStep4FormErrors(defaultStep4FormErrors)
    setPoints({
      amateur: defaultPoints,
      expert: defaultPoints,
      master: defaultPoints,
    })
    setLevels({
      amateur: false,
      expert: false,
      master: false,
    })
    setChallengeLevels({
      amateur: false,
      expert: false,
      master: false,
    })
    setPenaltyVisibilitySwitch(true)
  }

  const saveStep4Form = async () => {
    setStep4FormErrors(defaultStep4FormErrors)
    const step4Errors = defaultStep4FormErrors
    if (!levels.amateur && !levels.expert && !levels.master) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.levelRequired'),
        },
      })
      return
    } else if (
      levels.amateur &&
      !penaltyVisibilitySwitch &&
      (Number.isNaN(points.amateur.vpPlus) ||
        Number.isNaN(points.amateur.xpPlus))
    ) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.amateurPointsRequired'),
        },
      })
      return
    } else if (
      levels.amateur &&
      penaltyVisibilitySwitch &&
      (Number.isNaN(points.amateur.vpPlus) ||
        Number.isNaN(points.amateur.xpPlus) ||
        Number.isNaN(points.amateur.vpMinus) ||
        Number.isNaN(points.amateur.xpMinus))
    ) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.amateurPointsRequired'),
        },
      })
      return
    } else if (
      levels.expert &&
      !penaltyVisibilitySwitch &&
      (Number.isNaN(points.expert.vpPlus) || Number.isNaN(points.expert.xpPlus))
    ) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.expertPointsRequired'),
        },
      })
      return
    } else if (
      levels.expert &&
      penaltyVisibilitySwitch &&
      (Number.isNaN(points.expert.vpPlus) ||
        Number.isNaN(points.expert.xpPlus) ||
        Number.isNaN(points.expert.vpMinus) ||
        Number.isNaN(points.expert.xpMinus))
    ) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.expertPointsRequired'),
        },
      })
      return
    } else if (
      levels.master &&
      !penaltyVisibilitySwitch &&
      (Number.isNaN(points.master.vpPlus) || Number.isNaN(points.master.xpPlus))
    ) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.masterPointsRequired'),
        },
      })
      return
    } else if (
      levels.master &&
      penaltyVisibilitySwitch &&
      (Number.isNaN(points.master.vpPlus) ||
        Number.isNaN(points.master.xpPlus) ||
        Number.isNaN(points.master.vpMinus) ||
        Number.isNaN(points.master.xpMinus))
    ) {
      setStep4FormErrors({
        ...step4Errors,
        level: {
          error: true,
          message: t('pages.challenge.step4.errors.masterPointsRequired'),
        },
      })
      return
    }

    if (challengeId) {
      const formParams: ChallengeStep4RequestParams = {
        challengeId,
        amateur: levels.amateur,
        amateurXpPlus: levels.amateur ? points.amateur.xpPlus : null,
        amateurVpPlus: levels.amateur ? points.amateur.vpPlus : null,
        amateurEpPlus: levels.amateur ? points.amateur.epPlus : null,
        amateurXpMinus:
          levels.amateur || penaltyVisibilitySwitch
            ? points.amateur.xpMinus
            : null,
        amateurVpMinus:
          levels.amateur || penaltyVisibilitySwitch
            ? points.amateur.vpMinus
            : null,
        amateurEpMinus:
          levels.amateur || penaltyVisibilitySwitch
            ? points.amateur.epMinus
            : null,
        expert: levels.expert,
        expertXpPlus: levels.expert ? points.expert.xpPlus : null,
        expertVpPlus: levels.expert ? points.expert.vpPlus : null,
        expertEpPlus: levels.expert ? points.expert.epPlus : null,
        expertXpMinus:
          levels.expert || penaltyVisibilitySwitch
            ? points.expert.xpMinus
            : null,
        expertVpMinus:
          levels.expert || penaltyVisibilitySwitch
            ? points.expert.vpMinus
            : null,
        expertEpMinus:
          levels.expert || penaltyVisibilitySwitch
            ? points.expert.epMinus
            : null,
        master: levels.master,
        masterXpPlus: levels.master ? points.master.xpPlus : null,
        masterVpPlus: levels.master ? points.master.vpPlus : null,
        masterEpPlus: levels.master ? points.master.epPlus : null,
        masterXpMinus:
          levels.master || penaltyVisibilitySwitch
            ? points.master.xpMinus
            : null,
        masterVpMinus:
          levels.master || penaltyVisibilitySwitch
            ? points.master.vpMinus
            : null,
        masterEpMinus:
          levels.master || penaltyVisibilitySwitch
            ? points.master.epMinus
            : null,
        countPenalty: penaltyVisibilitySwitch,
      }
      try {
        setSavingStep4(true)
        const createOrUpdateChallengeStep4Response =
          await ChallengeService.updateChallengeStep4(formParams)

        if (createOrUpdateChallengeStep4Response.data.success) {
          toast.success(t('messages.success.savedSuccessfully'))
          setIsStep4Saved(true)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setSavingStep4(false)
      }
    }
  }

  useEffect(() => {
    // load data if update
    if (challengeDetails) {
      setPoints({
        amateur: {
          xpMinus: challengeDetails.amateurXpMinus,
          vpMinus: challengeDetails.amateurVpMinus,
          epMinus: challengeDetails.amateurEpMinus,
          xpPlus: challengeDetails.amateurXpPlus,
          vpPlus: challengeDetails.amateurVpPlus,
          epPlus: challengeDetails.amateurEpPlus,
        },
        expert: {
          xpMinus: challengeDetails.expertXpMinus,
          vpMinus: challengeDetails.expertVpMinus,
          epMinus: challengeDetails.expertEpMinus,
          xpPlus: challengeDetails.expertXpPlus,
          vpPlus: challengeDetails.expertVpPlus,
          epPlus: challengeDetails.expertEpPlus,
        },
        master: {
          xpMinus: challengeDetails.masterXpMinus,
          vpMinus: challengeDetails.masterVpMinus,
          epMinus: challengeDetails.masterEpMinus,
          xpPlus: challengeDetails.masterXpPlus,
          vpPlus: challengeDetails.masterVpPlus,
          epPlus: challengeDetails.masterEpPlus,
        },
      })
      setLevels({
        amateur: challengeDetails.amateur,
        expert: challengeDetails.expert,
        master: challengeDetails.master,
      })
      setChallengeLevels({
        amateur: challengeDetails.amateur,
        expert: challengeDetails.expert,
        master: challengeDetails.master,
      })
      setPenaltyVisibilitySwitch(challengeDetails.countPenalty)
    }
  }, [challengeDetails, setChallengeLevels])

  if (!challengeId || !isStep3Saved) {
    return (
      <StepContainer>
        <Stack direction={'row'}>
          <div className="step-line inactive">
            <div className="step-circle inactive">4</div>
            <div className="step-arrow inactive"></div>
          </div>
          <Box
            className="form-container"
            textAlign={'center'}
            color={grey[500]}
            position={'relative'}
            mb={2}
            paddingBottom={'24px !important'}
          >
            <Typography variant="subtitle2" fontWeight={'bold'} component="div">
              {t('pages.challenge.step4.title')}
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              dangerouslySetInnerHTML={{
                __html: t('pages.challenge.step4.subtitle'),
              }}
            ></Typography>
            <IconButton
              aria-label="delete"
              disabled
              size="large"
              className="add-button"
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Stack>
      </StepContainer>
    )
  }

  return (
    <StepContainer>
      <Stack direction={'row'}>
        <div className="step-line first">
          <div className="step-circle">4</div>
          <div className="step-arrow first"></div>
        </div>
        <Box className="form-container">
          <Grid container sx={{ marginBottom: 4 }}>
            <Grid item xs={7}>
              <div className="step-title">
                {t('pages.challenge.step4.title')}
              </div>
              <div className="step-subtitle">
                {t('pages.challenge.step4.subtitle')}
              </div>
            </Grid>
            <Grid item xs={5} className="switch-button">
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    value={penaltyVisibilitySwitch}
                    onChange={handlePenaltyVisibilityChange}
                    color="success"
                    checked={penaltyVisibilitySwitch}
                  />
                }
                label={
                  <div className="visibility-label-container">
                    <div className="visibility">
                      {t('pages.challenge.step4.penaltySwitchButtonTitle')}
                    </div>
                    <div className="visibility-label">
                      {t(
                        'pages.challenge.step4.penaltySwitchButtonDescription',
                      )}
                    </div>
                  </div>
                }
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Box
            className="form-style"
            component="form"
            sx={{
              '& .MuiTextField-root': { mb: 1, mt: 1 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl
              error={step4FormErrors.level.error}
              sx={{ width: '100%' }}
            >
              {Object.entries(levels).map((level) => (
                <Grid key={level[0]} container>
                  <Grid className="step4-form_item" item xs>
                    <ChallengeLevelForm
                      key={level[0]}
                      level={level}
                      points={(points as any)[level[0]]}
                      penaltyVisibility={penaltyVisibilitySwitch}
                      handleLevelChange={handleLevelChange}
                      handleLevelPointsChange={(levelName, levelPoints) => {
                        setPoints({
                          ...points,
                          [levelName]: levelPoints,
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              ))}

              {step4FormErrors.level.error && (
                <FormHelperText>{step4FormErrors.level.message}</FormHelperText>
              )}
            </FormControl>
            <Stack
              className="buttons-container"
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              width="100%"
            >
              <SecondaryButton onClick={resetStep4Form} disabled={savingStep4}>
                {t('common.reset')}
              </SecondaryButton>
              <PrimaryButton
                onClick={saveStep4Form}
                disabled={
                  savingStep4 ||
                  !challengeId ||
                  isSaveDisabled ||
                  !challengeUnitId ||
                  (challengeDetails && !challengeDetails?.isEditable)
                }
              >
                {t('common.save')}
              </PrimaryButton>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </StepContainer>
  )
}

export default ChallengeStep4Form
