import React, { useEffect, useMemo, useState } from 'react'
import { Stack, Alert, AlertColor } from '@mui/material'
import FileUploader from '../../../Import/partials/FileUploader'
import ImportColumns from '../../../Import/partials/ImportColumns'
import * as XLSX from 'xlsx'
import { findDuplicates, isStringInteger } from '../../../../../helpers/utils'
import { ImportParameterColumn } from '../../../../../store/Import/types'
import { useTranslation } from 'react-i18next'
import {
  ChallengeParameters,
  ProductAmount,
} from '../../../../../store/Challenge/types'

const { read, utils } = XLSX

type ChallengeImportProductsProps = {
  challengeParameters: ChallengeParameters
  setProducts: React.Dispatch<React.SetStateAction<ProductAmount[]>>
  setProductsMinimum: React.Dispatch<React.SetStateAction<number>>
}

const ChallengeImportProducts: React.FC<ChallengeImportProductsProps> = ({
  challengeParameters,
  setProducts,
  setProductsMinimum,
}) => {
  const { t } = useTranslation()
  const [alertOpen, setAlertOpen] = useState<boolean>(true)
  const [alertData, setAlertData] = useState({
    type: 'error' as AlertColor,
    message: '',
  })
  const [dataObj, setDataObj] = useState<any[]>([])
  const [file, setFile] = useState<File | null>(null)

  const importParameters: ImportParameterColumn[] = useMemo(
    () => [
      {
        columnName: ['Kod produktu'],
        fieldName: 'ean',
        fieldType: 'string',
        nullable: false,
        unique: true,
      },
      {
        columnName: ['Nazwa produktu'],
        fieldName: 'name',
        fieldType: 'string',
        nullable: false,
        unique: false,
      },
      {
        columnName: ['Wymagana ilość'],
        fieldName: 'qty',
        fieldType: 'integer',
        nullable: false,
        unique: false,
      },
    ],
    [],
  )

  const onFileDrop = (file: File) => {
    if (file) {
      setFile(file)
      const reader = new FileReader()
      reader.onload = function (e) {
        if (e && e.target) {
          var data = e.target.result
          let readedData = read(data, { type: 'binary', codepage: 65001 })
          const wsname = readedData.SheetNames[0]
          const ws = readedData.Sheets[wsname]

          /* Convert array to json*/
          const initialParse = utils.sheet_to_json<any>(ws, {
            blankrows: false,
            defval: null,
            header: 1,
            raw: false,
          })

          // build header array based on import parameters
          const header = initialParse[0].map((headerValue: any, i: number) => {
            const foundParam = importParameters.find((param) =>
              param.columnName.includes(headerValue),
            )
            if (foundParam) {
              return foundParam.fieldName
            }
            return i
          })

          // parse again with proper headers
          const dataParse = utils.sheet_to_json<any>(ws, {
            blankrows: false,
            defval: null,
            header,
            raw: false,
          })

          setDataObj(dataParse)
        }
      }
      reader.readAsBinaryString(file)
    }
  }

  useEffect(() => {
    setAlertOpen(false)
    setFile(null)
    setDataObj([])
  }, [])

  useEffect(() => {
    let importData: any[] = []
    let BreakException = {}
    let fileError = {
      type: 'info' as AlertColor,
      message: '',
    }

    setAlertOpen(false)
    setAlertData(fileError)

    try {
      if (dataObj.length - 1 > challengeParameters.unitProductCodeLimit) {
        fileError = {
          type: 'error',
          message: t('import.messages.productsLengthExceeded', {
            length: dataObj.length - 1,
            maxLength: challengeParameters.unitProductCodeLimit,
          }),
        }
        throw BreakException
      }
      let uniqueArr: any = {}
      dataObj.forEach((element, i) => {
        // check if required headers are present
        if (i === 0) {
          importParameters.forEach((param) => {
            if (
              !param.nullable &&
              !param.columnName.includes(element[param.fieldName])
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.columnMissing', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (param.unique) {
              if (!uniqueArr.hasOwnProperty(param.fieldName)) {
                Object.assign(uniqueArr, {
                  [param.fieldName]: [],
                })
              }
            }
          })
        } else {
          importParameters.forEach((param) => {
            if (
              param.fieldType === 'string' &&
              param.length &&
              element[param.fieldName] &&
              element[param.fieldName].length > param.length
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.tooLongString', {
                  column: param.columnName[0],
                  length: param.length,
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (
              ['integer', 'number'].includes(param.fieldType) &&
              !isStringInteger(element[param.fieldName])
            ) {
              fileError = {
                type: 'error',
                message: t('import.messages.wrongColumnType', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (!param.nullable && !element[param.fieldName]) {
              fileError = {
                type: 'error',
                message: t('import.messages.cellIsEmpty', {
                  column: param.columnName[0],
                  line: i + 1,
                }),
              }
              throw BreakException
            }
            if (param.unique) {
              if (uniqueArr.hasOwnProperty(param.fieldName)) {
                uniqueArr[param.fieldName].push(element[param.fieldName])
              }
            }
          })
        }

        let objectRow: any = {}
        importParameters.forEach((param) =>
          Object.assign(objectRow, {
            [param.fieldName]: element[param.fieldName] ?? null,
          }),
        )
        importData.push(objectRow)
      })

      for (const [key, valuesArr] of Object.entries(uniqueArr)) {
        const hasDuplicatedIds = findDuplicates(valuesArr as string[])
        if (hasDuplicatedIds.length > 0) {
          fileError = {
            type: 'error',
            message: t('import.messages.duplicatedIds', {
              column: importData[0][key],
              values: hasDuplicatedIds.join(', '),
            }),
          }
          throw BreakException
        }
      }
      importData.shift() // remove first el from arr
    } catch (e) {
      if (e !== BreakException) {
        throw e
      } else {
        importData = []
        setAlertOpen(true)
        setAlertData(fileError)
      }
    } finally {
      if (importData.length > 0) {
        setProducts(
          importData.map((prod) => ({
            ean: prod.ean,
            name: prod.name,
            qty: parseInt(prod.qty),
            error: false,
          })),
        )
        setProductsMinimum(
          importData.reduce((acc, obj) => acc + parseInt(obj.qty), 0),
        )
        setAlertOpen(false)
      }
    }
  }, [
    dataObj,
    t,
    file,
    importParameters,
    challengeParameters.unitProductCodeLimit,
    setProducts,
    setProductsMinimum,
  ])

  return (
    <div style={{ margin: '10px auto 20px' }}>
      <ImportColumns importParameters={importParameters} />
      <Stack>
        <FileUploader
          file={file}
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              [],
            'application/vnd.ms-excel': [],
          }}
          handleAcceptedFiles={(files) => onFileDrop(files[0] as File)}
        />
      </Stack>
      {alertOpen && (
        <Alert
          severity={alertData.type}
          sx={{ my: 2 }}
          style={{
            whiteSpace: 'pre-line',
            textAlign: 'left',
          }}
        >
          {alertData.message}
        </Alert>
      )}
    </div>
  )
}

export default ChallengeImportProducts
