import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'
import { checkAuth } from '../../../helpers/checkAuth'
import UpdateDate from './partials/UpdateDate'
import { Grid } from '@mui/material'

const Configuration: React.FC = () => {
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  if (!currentUser || !isLoggedIn) {
    return <Redirect to="/login" />
  }

  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <UpdateDate />
      </Grid>
    </Grid>
  )
}

export default Configuration
